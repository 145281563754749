import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {TweenMax} from "gsap";
import {Quart, Quint} from "gsap";

class Section06 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            col1Style: {
                height: '100vh',
                paddingLeft: 0,
                paddingRight: 0
            },
            col2Style: {
                height: '100vh',
                paddingLeft: 0,
                paddingRight: 0
            },
            isResponsive: this.props['is-responsive'],
        }; 
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize, true);
        this.onWindowResize();
    }

    componentDidUpdate() {
        const state = this.state;
        var newState = state;
        newState.isResponsive = this.props['is-responsive'];
        if(state != newState) {
            this.setState(newState);
        }
    }

    onWindowResize = () => {
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (this.w <= 992) {
            this.setState({
                col1Style: {
                    height: 'auto',
                    paddingLeft: 0,
                    paddingRight: 0
                },
                col2Style: {
                    height: 'auto',
                    paddingLeft: 0,
                    paddingRight: 0,
                    display: 'none',
                },
            });
        } else {
            this.setState({
                col1Style: {
                    height: '100vh',
                    paddingLeft: 0,
                    paddingRight: 0
                },
                col2Style: {
                    height: '100vh',
                    paddingLeft: 0,
                    paddingRight: 0,
                    display: 'block',
                },
            });
        }
    }

    openModalCreditos = (event) => {
        event.preventDefault();
        this.props['open-modal-creditos']();
    }

    render () {
        return(
            <section className={this.props.sel + ' ' + this.state.isResponsive + ' section06'}>
                <Container fluid>
                    <Row>
                        <Col lg={8} style={{...this.state.col1Style}}>
                            <div className='col-uno'>
                                <div>
                                    <h1>
                                        <span>Creemos algo</span>
                                        <img src="images/grandioso.svg" alt="Grandioso juntos"></img>
                                    </h1>
                                    <h2><a href='tel:+525559803101'>+52 (55) 5980 3101</a></h2>
                                    {/* <h3>Yahir Vite</h3> */}
                                    <h3><a href='mailto:yahir.vite@terantbwa.com.mx'>yahir.vite@terantbwa.com.mx</a></h3>
                                    {/* <h3>Berenice Carcamo</h3> */}
                                    <h3><a href='mailto:berenice.carcamo@terantbwa.com.mx'>berenice.carcamo@terantbwa.com.mx</a></h3>
                                    <p>Monte Pelvoux 210 Piso 4</p>
                                    <p>Lomas de Chapultepec</p>
                                    <p>Miguel Hidalgo</p>
                                    <div className='creditos'>
                                        <p>@ 2020 Terán \ TBWA</p>
                                        <p><a href='#' onClick={this.openModalCreditos}>Créditos</a></p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} style={{...this.state.col2Style}}>
                            <video id="bg-video-section-06" poster="images/FT-Contacto.jpg" data-autoplay muted loop playsInline webkit-playsinline="true">
                                <source src="video/fondo-contacto.mp4" type="video/mp4"></source>
                            </video>
                            <div className='col-dos'>
                                <div><img src='./images/FT-Manos-compu-contacto.png'></img></div>
                            </div>
                        </Col>
                    </Row>
                </Container>

                {/* <h1>Aprovechamos la <span>tecnología</span> para <span>amplificar</span> las <span>ideas</span></h1>
                <p>Somos un laboratorio de <b>tecnología</b> inspirado por la <b>creatividad</b>. Nuestra misión es ayudar a las marcas a crear <b>experiencias únicas e inolvidables,</b> resultado de la expansión creativa que detonamos a partir de nuestra investigación y prueba de nuevas herramientas tecnológicas.</p> */}

            </section>
        );
    }

}

export default Section06;