import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Utils from "../helpers/Utils";

class Section05 extends React.Component {

    constructor(props) {
        super(props); 
        this.state = {
            openModal: false,
            isMoving: false,
            col1Style: {
                height: 'auto',
                paddingLeft: 0,
                paddingRight: 0
            },
            col2Style: {
                height: 'auto',
                padding: '0',
            },
            previousButtonStyle: {
                position: 'absolute',
                bottom: '-50px',
                left: '200px'
            },
            nextButtonStyle: {
                position: 'absolute',
                bottom: '-50px',
                left: '250px'
            },
            isResponsive: this.props['is-responsive'],
        };
        this.data = {
            '1': {
                'logo': 'nissan.png',
                'logo@2x': 'nissan@2x.png',
                'image': 'FT-TABLETA-1024L.png',
                'image@2x': 'FT-TABLETA-1024L.png',
                'text': '<p class="modal-text-title">Chatbots</p><p>Servicio automatizado de atención y soporte en línea que optimiza los tiempos de respuesta, brindando información de productos de una manera más intuitiva y asertiva, así como captación de leads.</p>',
                'class': 'nissan',
                'video': 'chatbot-nissan.mp4',
                'poster': 'nissan.jpg',
                'extra-class-video': '',
            },
            '2': {
                'logo': 'bachoco.png',
                'logo@2x': 'bachoco.png',
                'image': 'FT-iPHONE-BBVA.png',
                'image@2x': 'FT-iPHONE-BBVA.png',
                'text': '<p class="modal-text-title">Filtros</p><p>Desarrollo y publicación de filtros personalizados para las principales plataformas como Facebook e Instagram.</p>',
                'class': 'bbva',
                'video': 'bachoco-landscape.mp4',
                'poster': 'bachoco.jpg',
                'extra-class-video': '',
            },
            '3': {
                'logo': 'nr.svg',
                'logo@2x': 'nr.svg',
                'image': 'FT-LAPTOP-CON-BASE-1024.png',
                'image@2x': 'FT-LAPTOP-CON-BASE-1024.png',
                'text': '<p class="modal-text-title">Cotizadores</p><p>Herramienta para perfilar y cotizar planes de financiamiento para el sector automotriz.</p>',
                'class': 'nr',
                'video': 'cotizador-nfrm.mp4',
                'poster': 'nfrm.jpg',
                'extra-class-video': '',
            },
            '4': {
                'logo': 'choco.png',
                'logo@2x': 'choco@2x.png',
                'image': 'FT-iPHONE-CHOCOMILK.png',
                'image@2x': 'FT-iPHONE-CHOCOMILK.png',
                'text': '<p class="modal-text-title">Pancho Live</p><p>Convivencia virtual con Pancho Pantera por medio de una tramsmisión en vivo en Facebook Live.</p>',
                'class': 'choco',
                'video': 'pancho.mp4',
                'poster': 'pancho.jpg',
                'extra-class-video': '',
            },
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize, true);
        this.onWindowResize();
    }

    componentDidUpdate() {
        const state = this.state;
        var newState = state;
        newState.isResponsive = this.props['is-responsive'];
        if(state != newState) {
            this.setState(newState);
        }
    }

    onWindowResize = () => {
        const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        
        if (w <= 992 && w > 576) {
            this.setState({
                ...this.state,
                previousButtonStyle: {
                    position: 'absolute',
                    bottom: '-50px',
                    left: '60px'
                },
                nextButtonStyle: {
                    position: 'absolute',
                    bottom: '-50px',
                    left: '110px'
                },
            });
        } else if (w <= 1024 && w > 992) {
            this.setState({
                ...this.state,
                previousButtonStyle: {
                    position: 'absolute',
                    bottom: '-50px',
                    left: '60px'
                },
                nextButtonStyle: {
                    position: 'absolute',
                    bottom: '-50px',
                    left: '110px'
                },
            });
        } else if (w <= 576) {
            this.setState({
                ...this.state,
                previousButtonStyle: {
                    position: 'absolute',
                    bottom: '-50px',
                    left: '40px'
                },
                nextButtonStyle: {
                    position: 'absolute',
                    bottom: '-50px',
                    left: '90px'
                },
            });
        } else {
            if (h <= 820) {
                this.setState({
                    ...this.state,
                    previousButtonStyle: {
                        position: 'absolute',
                        bottom: '-50px',
                        left: '60px'
                    },
                    nextButtonStyle: {
                        position: 'absolute',
                        bottom: '-50px',
                        left: '110px'
                    },
                });
            } else {
                this.setState({
                    ...this.state,
                    previousButtonStyle: {
                        position: 'absolute',
                        bottom: '-50px',
                        left: '200px'
                    },
                    nextButtonStyle: {
                        position: 'absolute',
                        bottom: '-50px',
                        left: '250px'
                    },
                });
            }
        }
    }

    openModalBox = (event) => {
        if (this.state.isMoving) {
            return;
        }
        const div = event.target;
        const dataId = event.target.getAttribute('data-id');
        const modalData = this.data[dataId];
        this.props['toggle-modal-fn'](div.getBoundingClientRect(), modalData);
    }

    render () {
        const responsive = {
            superLargeDesktop: {
              // the naming can be any, depends on you.
              breakpoint: { max: 4000, min: 3000 },
              items: 2,
              slidesToSlide: 1
            },
            desktop: {
              breakpoint: { max: 3000, min: 1024 },
              items: 3,
              slidesToSlide: 1
            },
            tablet: {
              breakpoint: { max: 1024, min: 576 },
              items: 2,
              slidesToSlide: 1
            },
            mobile: {
              breakpoint: { max: 576, min: 0 },
              items: 1,
              slidesToSlide: 1
            }
        };

        const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
            const { carouselState: { currentSlide } } = rest;
            return (
                <div className="carousel-button-group">
                    <a href='#' style={{
                        ...this.state.previousButtonStyle
                    }} className={currentSlide === 0 ? 'disable' : ''} onClick={(event) => {
                        event.preventDefault()
                        previous()
                    }}>
                        <img width={30} height={30} src='images/previous.svg'></img>
                    </a>
                    <a href='#' style={{
                        ...this.state.nextButtonStyle
                    }} onClick={(event) => {
                        event.preventDefault()
                        next()
                    }}>
                        <img width={30} height={30} src='images/next.svg'></img>
                    </a>
                </div>
            );
        };

        return(
            <section className={this.props.sel + ' ' + this.state.isResponsive + ' section05'}>
                
                <Container fluid>
                    <Row>
                        <Col lg={12} style={this.state.col1Style}>
                            <div className='col-uno'>
                                <div>
                                    <h1>
                                        <span>Nuestros</span>
                                        <img src="images/proyectos.svg" alt="Proyectos"></img>
                                    </h1>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ padding: '0' }}>
                        <Col lg={12} style={this.state.col2Style}>
                            <div className='col-dos'>
                                <Carousel 
                                ref={(el) => (this.Carousel = el)}
                                responsive={responsive}
                                swipeable={true}
                                draggable={false}
                                showDots={false}
                                infinite={false}
                                centerMode={false}
                                arrows={false}
                                focusOnSelect={false}
                                // partialVisible={false}
                                beforeChange={() => this.setState({ isMoving: true })}
                                afterChange={() => this.setState({ isMoving: false })}
                                customButtonGroup={<ButtonGroup />}
                                renderButtonGroupOutside={true}
                                additionalTransfrom={-20}
                                >

                                    <div className='carousel-item-project'>
                                        <div className='carousel-item-project-container'>
                                            <div className='carousel-item-project-inner-container'>
                                                <img className='carousel-item-project-logo choco' src={'images/' + Utils.isRetina('choco.png', 'choco@2x.png')} />
                                                <div className='carousel-item-project-description'>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum purus neque, commodo non mattis non, condimentum vitae nunc. Phasellus tincidunt sit amet lectus vitae congue.
                                                </div>
                                                <img className='carousel-item-project-image' src={'images/' + Utils.isRetina('FT-iPHONE-CHOCOMILK.png', 'FT-iPHONE-CHOCOMILK.png')} />
                                            </div>
                                        </div>
                                        <div className='carousel-item-project-button' onClick={this.openModalBox} data-id='4'></div>
                                    </div>

                                    <div className='carousel-item-project'>
                                        <div className='carousel-item-project-container'>
                                            <div className='carousel-item-project-inner-container'>
                                                <img className='carousel-item-project-logo nissan' src={'images/' + Utils.isRetina('nissan.png', 'nissan@2x.png')} />
                                                <div className='carousel-item-project-description'>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum purus neque, commodo non mattis non, condimentum vitae nunc. Phasellus tincidunt sit amet lectus vitae congue.
                                                </div>
                                                <img className='carousel-item-project-image' src={'images/' + Utils.isRetina('FT-TABLETA-1024L.png', 'FT-TABLETA-1024L.png')} />
                                            </div>
                                        </div>
                                        <div className='carousel-item-project-button' onClick={this.openModalBox} data-id='1'></div>
                                    </div>

                                    <div className='carousel-item-project'>
                                        <div className='carousel-item-project-container'>
                                            <div className='carousel-item-project-inner-container'>
                                                <img className='carousel-item-project-logo bbva' src={'images/' + Utils.isRetina('bachoco.png', 'bachoco.png')} />
                                                <div className='carousel-item-project-description'>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum purus neque, commodo non mattis non, condimentum vitae nunc. Phasellus tincidunt sit amet lectus vitae congue.
                                                </div>
                                                <img className='carousel-item-project-image' src={'images/' + Utils.isRetina('FT-iPHONE-BBVA.png', 'FT-iPHONE-BBVA.png')} />
                                            </div>
                                        </div>
                                        <div className='carousel-item-project-button' onClick={this.openModalBox} data-id='2'></div>
                                    </div>

                                    <div className='carousel-item-project'>
                                        <div className='carousel-item-project-container'>
                                            <div className='carousel-item-project-inner-container'>
                                                <img className='carousel-item-project-logo nr' src={'images/' + Utils.isRetina('nr.svg', 'nr.svg')} />
                                                <div className='carousel-item-project-description'>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum purus neque, commodo non mattis non, condimentum vitae nunc. Phasellus tincidunt sit amet lectus vitae congue.
                                                </div>
                                                <img className='carousel-item-project-image' src={'images/' + Utils.isRetina('FT-LAPTOP-CON-BASE-1024.png', 'FT-LAPTOP-CON-BASE-1024.png')} />
                                            </div>
                                        </div>
                                        <div className='carousel-item-project-button' onClick={this.openModalBox} data-id='3'></div>
                                    </div>
                                    
                                </Carousel>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
        );
    }

}

export default Section05;