import React from 'react';
import {TweenMax} from "gsap";
import {Quart, Quint} from "gsap";

class MenuMobile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuIsOpened: false,
            menuMobileHamburgerStyle: {
                display: 'block',
            },
            menuMobileCloseStyle: {
                display: 'none',
            },
            menuActionsContainerClass: 'actions-container-closed'
        };
        this.menuActionsContainer = null;
        this.menuActionsContainerTweenIn = null;
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    componentDidMount(){
        window.addEventListener('resize', this.onWindowResize, true);
    }

    clickHamburger = (event) => {
        event.preventDefault();
        this.setState({
            menuMobileHamburgerStyle: {
                display: 'none',
            },
            menuMobileCloseStyle: {
                display: 'block',
            },
            menuActionsContainerClass: 'actions-container-opened'
        });
    }

    clickMenuButton = (event) => {
        // event.preventDefault();
        this.setState({
            menuMobileHamburgerStyle: {
                display: 'block',
            },
            menuMobileCloseStyle: {
                display: 'none',
            },
            menuActionsContainerClass: 'actions-container-closed'
        });
    }

    clickCloseButton = (event) => {
        event.preventDefault();
        this.setState({
            menuMobileHamburgerStyle: {
                display: 'block',
            },
            menuMobileCloseStyle: {
                display: 'none',
            },
            menuActionsContainerClass: 'actions-container-closed-animated'
        });
    }

    moveSectionDown() {
        window.fullpage_api.moveSectionDown();
    }

    render () {
        return(
            <div className="menu-mobile">
                <a className="logo" href="#acerca"><img src="images/logo-outline.svg"></img></a>
                <a style={this.state.menuMobileHamburgerStyle} className="hamburger" href="#" onClick={this.clickHamburger}><img src="images/hamburger.svg"></img></a>
                <div className={this.state.menuActionsContainerClass}>
                    <div className='actions-container-inner'>
                        <ul id="menu-mobile" className="actions">
                            <li data-menuanchor="acerca">
                                <a href="#acerca" onClick={this.clickMenuButton}>
                                    <span className='num'>1</span>
                                    <span className='title'>Acerca</span>
                                </a>
                            </li>
                            <li data-menuanchor="manifiesto">
                                <a href="#manifiesto" onClick={this.clickMenuButton}>
                                    <span className='num'>2</span>
                                    <span className='title'>Manifiesto</span>
                                </a>
                            </li>
                            <li data-menuanchor="soluciones">
                                <a href="#soluciones" onClick={this.clickMenuButton}>
                                    <span className='num'>3</span>
                                    <span className='title'>Soluciones</span>
                                </a>
                            </li>
                            <li data-menuanchor="equipo">
                                <a href="#equipo" onClick={this.clickMenuButton}>
                                    <span className='num'>4</span>
                                    <span className='title'>Equipo</span>
                                </a>
                            </li>
                            <li data-menuanchor="proyectos">
                                <a href="#proyectos" onClick={this.clickMenuButton}>
                                    <span className='num'>5</span>
                                    <span className='title'>Proyectos</span>
                                </a>
                            </li>
                            <li data-menuanchor="contacto">
                                <a href="#contacto" onClick={this.clickMenuButton}>
                                    <span className='num'>6</span>
                                    <span className='title'>Contacto</span>
                                </a>
                            </li>
                            {/* <li>
                                <a href="#" onClick={() => this.moveSectionDown()}>Next Section</a>
                            </li> */}
                        </ul>
                    </div>
                    <a style={this.state.menuMobileCloseStyle} className="close-button" href='#' onClick={this.clickCloseButton}><img src="images/closeMenu.svg"></img></a>
                </div>
            </div>
        );
    }

}

export default MenuMobile;