import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Section02 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponsive: this.props['is-responsive'],
            col1Style: {
                height: '100vh',
                paddingLeft: 0,
                paddingRight: 0
            },
            col2Style: {
                height: '100vh',
                paddingLeft: 0,
                paddingRight: 0
            },
        };
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;  
    }

    componentDidMount() {
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        window.addEventListener('resize', this.onWindowResize, true);
        this.onWindowResize();
    }

    componentDidUpdate() {
        const state = this.state;
        var newState = state;
        newState.isResponsive = this.props['is-responsive'];
        if(state != newState) {
            this.setState(newState);
        }
    }

    onWindowResize = () => {
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (this.w <= 992) {
            this.setState({
                col1Style: {
                    height: 'auto',
                    paddingLeft: 0,
                    paddingRight: 0
                },
                col2Style: {
                    height: 'auto',
                    paddingLeft: 0,
                    paddingRight: 0
                },
            });
        } else {
            this.setState({
                col1Style: {
                    height: '100vh',
                    paddingLeft: 0,
                    paddingRight: 0
                },
                col2Style: {
                    height: '100vh',
                    paddingLeft: 0,
                    paddingRight: 0
                },
            });
        }
    }

    render () {
        return(
            <section className={this.props.sel + ' ' + this.state.isResponsive + ' section02'}>
                
                <Container fluid>
                    <Row>
                        <Col lg={8} style={this.state.col1Style}>
                            <div className='col-uno'>
                                <div>
                                    <h1>
                                        <span>Unde om</span>
                                        <img src="images/manifiesto.svg" alt="Manifiesto"></img>
                                    </h1>
                                    <p><b>Velocidad e innovación</b> son las leyes que nos rigen, por eso nunca antes la <b>tecnología</b> había estado tan <b>conectada</b> con la vida.</p>
                                    <p>Y a eso nos dedicamos en MIL24. Somos <b>creatividad, somos tecnología y somos conexión.</b></p>
                                    <p>Por eso podemos hacer de cada marca una promesa viva y una <b>experiencia inevitable.</b> Trabajamos hoy trazando las líneas del futuro.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} style={this.state.col2Style}>
                            <div className='col-dos'>
                                <div><img src='./images/FT-CEL-MANIFIESTO.png'></img></div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
        );
    }

}

export default Section02;