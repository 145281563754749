import React from 'react';

class Menu extends React.Component {

    moveSectionDown() {
        window.fullpage_api.moveSectionDown();
    }

    render () {
        return(
            <div className="menu">
                <a className="logo" href="#acerca"><img src="images/logo-outline.svg"></img></a>
                <ul id="menu" className="actions">
                    <li data-menuanchor="acerca" className="active">
                        <a href="#acerca">Acerca</a>
                    </li>
                    <li data-menuanchor="manifiesto">
                        <a href="#manifiesto">Manifiesto</a>
                    </li>
                    <li data-menuanchor="soluciones">
                        <a href="#soluciones">Soluciones</a>
                    </li>
                    <li data-menuanchor="equipo">
                        <a href="#equipo">Equipo</a>
                    </li>
                    <li data-menuanchor="proyectos">
                        <a href="#proyectos">Proyectos</a>
                    </li>
                    <li data-menuanchor="contacto">
                        <a href="#contacto">Contacto</a>
                    </li>
                    {/* <li>
                        <a href="#" onClick={() => this.moveSectionDown()}>Next Section</a>
                    </li> */}
                </ul>
            </div>
        );
    }

}

export default Menu;