import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {TweenMax} from "gsap";
import {Quart, Quint, Linear} from "gsap";

import im1 from '../images/FT-FONDO-WEBSITES.jpg';
import im2 from '../images/FT-CEL-CARTON.png';
import im3 from '../images/FT-FONDO-VR.jpg';
import im4 from '../images/FT-CHAVA-VR.png';
import im5 from '../images/FT-FONDO-AR.jpg'
import im6 from '../images/FT-MANO-CEL-AR.png'
import im7 from '../images/FT-FONDO-INSTALACIONES.jpg'
import im8 from '../images/FT-Nina.png'
import im9 from '../images/FT-FONDO-MAPPING.jpg'
import im10 from '../images/FT-MONUMENTO.png'
import im11 from '../images/FT-Fondo-Prototipos.jpg'
import im12 from '../images/FT-Prototipo.png'
import im13 from '../images/FT-FONDO-IMPRESION-3D.jpg'
import im14 from '../images/FT-ROSTRO-3D.png'
import im15 from '../images/FT-FONDO-Chatbot.jpg'
import im16 from '../images/chat-1.png'
import im17 from '../images/FT-FONDO-AI.jpg'
import im18 from '../images/FT-COMPU-AI.png'

class Section03 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isResponsive: this.props['is-responsive'],
            selected: 'prototipos',
            col1Style: {
                height: '100vh',
                paddingLeft: 0,
                paddingRight: 0
            },
            col2Style: {
                height: '100vh',
                paddingLeft: 0,
                paddingRight: 0
            },
        };
        this.riel_uno = null;
        this.rielUnoTween = null;
        this.riel_dos = null;
        this.rielDosTween = null;
        this.riel_tres = null;
        this.rielTresTween = null;
        this.imageList = [im1, im2, im3, im4, im5, im6, im7, im8, im9, im10, im11, im12, im13, im14, im15, im16, im17, im18];
        this.data = {
            experiential_websites: {
                bg: this.imageList[0],
                img: this.imageList[1],
                style_div: {
                    position: 'relative',
                    left: '-300px',
                    top: '65%',
                    transform: 'translate(0, -50%)',
                    pointerEvents: 'none',
                },
                style_image: {
                    width: '600px',
                    height: 'auto',
                    position: 'relative',
                    animation: 'float 6s ease-in-out infinite',
                    pointerEvents: 'none',
                },
            },
            realidad_virtual: {
                bg: this.imageList[2],
                img: this.imageList[3],
                style_div: {
                    position: 'absolute',
                    left: '-325px',
                    bottom: '0',
                    pointerEvents: 'none',
                },
                style_image: {
                    width: '650px',
                    height: 'auto',
                    position: 'relative',
                    animation: 'none',
                    pointerEvents: 'none',
                },
            },
            realidad_aumentada: {
                bg: this.imageList[4],
                img: this.imageList[5],
                style_div: {
                    position: 'absolute',
                    left: '-250px',
                    bottom: '0',
                    pointerEvents: 'none',
                },
                style_image: {
                    width: '500px',
                    height: 'auto',
                    position: 'relative',
                    animation: 'none',
                    pointerEvents: 'none',
                },
            },
            instalaciones_interactivas: {
                bg: this.imageList[6],
                img: this.imageList[7],
                style_div: {
                    position: 'absolute',
                    left: '-300px',
                    bottom: '0',
                    pointerEvents: 'none',
                },
                style_image: {
                    width: '600px',
                    height: 'auto',
                    position: 'relative',
                    animation: 'none',
                    pointerEvents: 'none',
                },
            },
            video_mapping: {
                bg: this.imageList[8],
                img: this.imageList[9],
                style_div: {
                    position: 'absolute',
                    left: '-300px',
                    bottom: '0',
                    pointerEvents: 'none',
                },
                style_image: {
                    width: '600px',
                    height: 'auto',
                    position: 'relative',
                    pointerEvents: 'none',
                },
            },
            prototipos: {
                bg: this.imageList[10],
                img: this.imageList[11],
                style_div: {
                    position: 'relative',
                    left: '-250px',
                    top: '65%',
                    transform: 'translate(0, -50%)',
                    pointerEvents: 'none',
                },
                style_image: {
                    width: '500px',
                    height: 'auto',
                    position: 'relative',
                    animation: 'float 6s ease-in-out infinite',
                    pointerEvents: 'none',
                },
            },
            impresion_3d: {
                bg: this.imageList[12],
                img: this.imageList[13],
                style_div: {
                    position: 'relative',
                    left: '-250px',
                    top: '65%',
                    transform: 'translate(0, -50%)',
                    pointerEvents: 'none',
                },
                style_image: {
                    width: '500px',
                    height: 'auto',
                    position: 'relative',
                    animation: 'float 6s ease-in-out infinite',
                    pointerEvents: 'none',
                },
            },
            chatbots: {
                bg: this.imageList[14],
                img: this.imageList[15],
                style_div: {
                    position: 'absolute',
                    left: '-300px',
                    bottom: '0',
                    pointerEvents: 'none',
                },
                style_image: {
                    width: '600px',
                    height: 'auto',
                    position: 'relative',
                    pointerEvents: 'none',
                },
            },
            inteligencia_artificial: {
                bg: this.imageList[16],
                img: this.imageList[17],
                style_div: {
                    position: 'relative',
                    left: '-325px',
                    top: '65%',
                    transform: 'translate(0, -50%)',
                    pointerEvents: 'none',
                },
                style_image: {
                    width: '650px',
                    height: 'auto',
                    position: 'relative',
                    animation: 'float 6s ease-in-out infinite',
                    pointerEvents: 'none',
                },
            }
        }; 
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    componentDidMount() {
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        window.addEventListener('resize', this.onWindowResize, true);
        this.onWindowResize();

        this.rielUnoTween = TweenMax.to(this.riel_uno, 90, {transform: 'translateX(-50%)', yoyo: true, repeat: -1, ease: Linear.easeNone}); 
        this.rielDosTween = TweenMax.to(this.riel_dos, 90, {transform: 'translateX(0%)', yoyo: true, repeat: -1, ease: Linear.easeNone});  
        this.rielTresTween = TweenMax.to(this.riel_tres, 90, {transform: 'translateX(-50%)', yoyo: true, repeat: -1, ease: Linear.easeNone});

        this.imageList.forEach((image) => {
            const newImage = new Image();
            newImage.src = image;
            window[image] = newImage;
        });
    }

    onWindowResize = () => {
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if (this.w <= 992) {
            this.setState({
                col1Style: {
                    height: 'auto',
                    paddingLeft: 0,
                    paddingRight: 0
                },
                col2Style: {
                    height: 'auto',
                    paddingLeft: 0,
                    paddingRight: 0
                },
            });
        } else {
            this.setState({
                col1Style: {
                    height: '100vh',
                    paddingLeft: 0,
                    paddingRight: 0
                },
                col2Style: {
                    height: '100vh',
                    paddingLeft: 0,
                    paddingRight: 0
                },
            });
        }
    }

    componentDidUpdate() {
        const state = this.state;
        var newState = state;
        newState.isResponsive = this.props['is-responsive'];
        if(state != newState) {
            this.setState(newState);
        }
    }

    onClickButton = (event) => {
        event.preventDefault();
        let dataNodeName = event.target.getAttribute('data-node');
        let dataNode = this.data[dataNodeName];
        this.setState({
            selected: dataNodeName,
        });
    }

    render () {

        return(
            <section className={this.props.sel + ' ' + this.state.isResponsive +  ' section03'}>
                
                <Container fluid>
                    <Row>
                        <Col lg={8} style={this.state.col1Style}>
                            <div className='col-uno'>
                                <div>
                                    <h1>
                                        <span>Nuestras</span>
                                        <img src="images/soluciones.svg" alt="Soluciones"></img>
                                    </h1>
                                    <div className="tech-slideshow">
                                        <div className="mover-1" ref={div => this.riel_uno = div}>
                                            <a href="#" data-node="experiential_websites" className={ this.state.selected == 'experiential_websites' ? 'active' : '' } onClick={this.onClickButton}>experiential websites</a>
                                            <a href="#" data-node="realidad_virtual" className={ this.state.selected == 'realidad_virtual' ? 'active' : '' } onClick={this.onClickButton}>realidad virtual</a>
                                            <a href="#" data-node="realidad_aumentada" className={ this.state.selected == 'realidad_aumentada' ? 'active' : '' } onClick={this.onClickButton}>realidad aumentada</a>
                                            <a href="#" data-node="experiential_websites" className={ this.state.selected == 'experiential_websites' ? 'active' : '' } onClick={this.onClickButton}>experiential websites</a>
                                            <a href="#" data-node="realidad_virtual" className={ this.state.selected == 'realidad_virtual' ? 'active' : '' } onClick={this.onClickButton}>realidad virtual</a>
                                            <a href="#" data-node="realidad_aumentada" className={ this.state.selected == 'realidad_aumentada' ? 'active' : '' } onClick={this.onClickButton}>realidad aumentada</a>
                                            <a href="#" data-node="experiential_websites" className={ this.state.selected == 'experiential_websites' ? 'active' : '' } onClick={this.onClickButton}>experiential websites</a>
                                            <a href="#" data-node="realidad_virtual" className={ this.state.selected == 'realidad_virtual' ? 'active' : '' } onClick={this.onClickButton}>realidad virtual</a>
                                            <a href="#" data-node="realidad_aumentada" className={ this.state.selected == 'realidad_aumentada' ? 'active' : '' } onClick={this.onClickButton}>realidad aumentada</a>
                                        </div>
                                    </div>
                                    <div className="tech-slideshow">
                                        <div className="mover-2" ref={div => this.riel_dos = div}>
                                            <a href="#" data-node="instalaciones_interactivas" className={ this.state.selected == 'instalaciones_interactivas' ? 'active' : '' } onClick={this.onClickButton}>instalaciones interactivas</a>
                                            <a href="#" data-node="video_mapping" className={ this.state.selected == 'video_mapping' ? 'active' : '' } onClick={this.onClickButton}>video mapping</a>
                                            <a href="#" data-node="prototipos" className={ this.state.selected == 'prototipos' ? 'active' : '' } onClick={this.onClickButton}>prototipos</a>
                                            <a href="#" data-node="instalaciones_interactivas" className={ this.state.selected == 'instalaciones_interactivas' ? 'active' : '' } onClick={this.onClickButton}>instalaciones interactivas</a>
                                            <a href="#" data-node="video_mapping" className={ this.state.selected == 'video_mapping' ? 'active' : '' } onClick={this.onClickButton}>video mapping</a>
                                            <a href="#" data-node="prototipos" className={ this.state.selected == 'prototipos' ? 'active' : '' } onClick={this.onClickButton}>prototipos</a>
                                            <a href="#" data-node="instalaciones_interactivas" className={ this.state.selected == 'instalaciones_interactivas' ? 'active' : '' } onClick={this.onClickButton}>instalaciones interactivas</a>
                                            <a href="#" data-node="video_mapping" className={ this.state.selected == 'video_mapping' ? 'active' : '' } onClick={this.onClickButton}>video mapping</a>
                                            <a href="#" data-node="prototipos" className={ this.state.selected == 'prototipos' ? 'active' : '' }  onClick={this.onClickButton}>prototipos</a>
                                        </div>
                                    </div>
                                    <div className="tech-slideshow">
                                        <div className="mover-3" ref={div => this.riel_tres = div}>
                                            <a href="#" data-node="impresion_3d" className={ this.state.selected == 'impresion_3d' ? 'active' : '' } onClick={this.onClickButton}>impresión 3d</a>
                                            <a href="#" data-node="chatbots" className={ this.state.selected == 'chatbots' ? 'active' : '' } onClick={this.onClickButton}>chatbots</a>
                                            <a href="#" data-node="inteligencia_artificial" className={ this.state.selected == 'inteligencia_artificial' ? 'active' : '' } onClick={this.onClickButton}>inteligencia artificial</a>
                                            <a href="#" data-node="impresion_3d" className={ this.state.selected == 'impresion_3d' ? 'active' : '' } onClick={this.onClickButton}>impresión 3d</a>
                                            <a href="#" data-node="chatbots" className={ this.state.selected == 'chatbots' ? 'active' : '' } onClick={this.onClickButton}>chatbots</a>
                                            <a href="#" data-node="inteligencia_artificial" className={ this.state.selected == 'inteligencia_artificial' ? 'active' : '' } onClick={this.onClickButton}>inteligencia artificial</a>
                                            <a href="#" data-node="impresion_3d" className={ this.state.selected == 'impresion_3d' ? 'active' : '' } onClick={this.onClickButton}>impresión 3d</a>
                                            <a href="#" data-node="chatbots" className={ this.state.selected == 'experiential_wchatbotsebsites' ? 'active' : '' } onClick={this.onClickButton}>chatbots</a>
                                            <a href="#" data-node="inteligencia_artificial" className={ this.state.selected == 'inteligencia_artificial' ? 'active' : '' } onClick={this.onClickButton}>inteligencia artificial</a>
                                            <a href="#" data-node="impresion_3d" className={ this.state.selected == 'impresion_3d' ? 'active' : '' } onClick={this.onClickButton}>impresión 3d</a>
                                            <a href="#" data-node="chatbots" className={ this.state.selected == 'chatbots' ? 'active' : '' } onClick={this.onClickButton}>chatbots</a>
                                            <a href="#" data-node="inteligencia_artificial" className={ this.state.selected == 'inteligencia_artificial' ? 'active' : '' } onClick={this.onClickButton}>inteligencia artificial</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} style={this.state.col2Style}>
                            <div className='col-dos' style={this.w <= 992 ? {} : { backgroundImage: 'url(' + this.data[this.state.selected].bg + ')' }}>
                                <div style={
                                    this.w <= 992 ? {} :
                                    { ...this.data[this.state.selected].style_div }
                                }><img src={ this.data[this.state.selected].img } style={
                                    this.w <= 992 ? {} :
                                    { ...this.data[this.state.selected].style_image }
                                }></img></div>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
        );
    }

}

export default Section03;