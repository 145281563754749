import React from 'react';

class Personajes extends React.Component {

    onMouseMove = (event) => {
        event.preventDefault();
    }

    onMouseOver = (event) => {
        event.preventDefault();
        const group = event.target;
        this.props['toggleDisplayTooltip'](true, event.target.getAttribute('name'), group.getBoundingClientRect());
    }

    onMouseOut = (event) => {
        event.preventDefault();
        this.props['toggleDisplayTooltip'](false);
    }

    onTouchEnd = (event) => {
        event.preventDefault();
        const group = event.target;
        this.props['on-touch-end'](true, event.target.getAttribute('name'), group.getBoundingClientRect());
    }

    render () {
        return(
            
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1650 500" style={{ enableBackground: 'new 0 0 1650 500' }}>
                <g id="YAHIR" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="YAHIR" class="st0" d="M1532.1,224.2c-1-0.5-2.1-1-3.2-1.3c-4.9-1.4-10-1-15-0.6c-0.1,0-0.1,0-0.1,0c-1.2,1.8-2.3,3.6-3.5,5.4
                                c-2,3.1-3.9,6.2-5.9,9.3c-1.4,2.3-2.8,4.6-4.2,6.9c-2.2,3.6-5,7-7.3,10.5c-1,1.5-2.3,2.8-3.6,4.1c-0.7,0.7-1.4,1.3-2.1,2
                                c-0.3,0.2-0.5,0.5-0.8,0.7c-0.1,0.1-0.1,0.1-0.2,0.2s-0.1,0.1-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.2c0.2,2.2,1.2,8.9,3.3,15.1
                                c0.7,1.9,2.8,6.3,3.5,7.6c0.8-0.8,5.7-6.4,8.2-14.7c0,0,0-0.1,0.1-0.1c0,0,3.1-4.2,5.1-6.7c3.4-4.3,20.3-26.1,23.1-30.1
                                c1.5-2.1,3.8-6.1,4.7-7.5c-0.1-0.1-0.2-0.1-0.3-0.2C1533,224.7,1532.6,224.4,1532.1,224.2z"/>
                            <path name="YAHIR" class="st0" d="M1458.2,131.4c1.1,1.2,3.4,3.7,15.1,4.2c0.2-0.9,0.5-1.9,1.1-2.6c0.7-0.8,1.6-1.3,2.6-1.7
                                c3.8-1.7,7.2-3.9,10.3-6.6c-0.1-0.1-0.1-0.2-0.1-0.3c0.8-3.4,1.5-6.9,2.1-10.3c0.1-0.5,0.9-0.3,0.8,0.2c-0.3,1.5-0.6,3-0.8,4.4
                                c1.5,0,2.6-0.9,3.4-2.3c1.2-2.3,1.9-4.8,2.5-7.4c0.3-1.4,0.6-2.7-0.1-4.1c-0.5-0.9-1.3-1.5-2.3-1.6c-1.2,0-1.7,0.9-2.4,1.7
                                c-0.2,0.3-0.6,0.1-0.7-0.2c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,1.2-0.8,3.7-0.8,3.7s-0.2,0.8-1.5,0.7c-1.1-0.1-1.2-0.9-1.2-0.9
                                s0.2-6.1,0.2-6.9s-0.3-1.7-1.1-2.3c-0.7-0.6-3.5-2.5-3.5-3.6s0.6-4.8,1.2-5.4c0,0-2.2-1.9-2.7-2.5s-3.3-1.7-6.1-1.3
                                c-2.8,0.3-4.3,0.9-5.6,1.4s-2.7,1.2-4.3,1.3s-4.7,0.5-7.3,2.5s-4.2,4.3-4.4,5.7c-0.2,1.3,0,4.5-0.5,5.9c0.2,1.9,0.5,4.5,0.7,6.3
                                C1453,113.2,1457.1,130.2,1458.2,131.4L1458.2,131.4z"/>
                            <path name="YAHIR" class="st0" d="M1458.4,155.9c0.2-0.8,0.4-1.6,0.7-2.4c0.4-1,0.9-1.9,1.6-2.8c0.7-0.8,1.3-1.7,2.1-2.5
                                c1.4-1.5,3.4-2.2,5.1-3.4c1.4-1.1,2.6-2.4,3.4-4c0.8-1.4,1.3-2.9,1.7-4.5c-5.8-0.2-9.3-1-11.6-1.8c-0.9,1.6-1.4,3.4-1.9,5.1
                                C1458.3,145,1458.5,150.5,1458.4,155.9z"/>
                            <path name="YAHIR" class="st0" d="M1438.4,243.3c-2.6-5.3-1.9-11.3-1.1-17c0.3-2.3,0.7-4.6,0.9-7c-2.2-0.8-4.6-0.7-6.8,0.2c-0.1,0-0.2,0-0.2,0
                                c-0.1,0.4-0.1,0.7-0.1,1.1c-0.2,2.1-0.3,4.3-0.3,6.4c0,1.3,0,2.6-0.2,4c-0.7,6.4-1.8,12.8-2.9,19.2c-0.2,1.1-0.4,2.2-0.6,3.3
                                c-0.1,0.4-0.1,0.8-0.3,1.2c-0.7,1.3-1.4,2.6-1.8,4s-0.8,3-0.5,4.5c1-3,5-4.1,8.3-5.6c3.6-1.8,4.8-6.6,5.5-8.3
                                c0.1-0.3,0.3-0.5,0.4-0.6l-0.4-4.9C1438.2,243.5,1438.3,243.4,1438.4,243.3z"/>
                            <path name="YAHIR" class="st0" d="M1458.6,244c-4.1-0.6-8.2-2-12.4-1.7c-1,0.1-2,0.2-2.9,0.5c-0.6,0.2-1.2,0.6-1.8,0.7c-0.5,0.1-1,0.2-1.5,0.2
                                l0.8,4.4c0.6,0.1,1,0.2,1,0.2l19.1,0.4l-0.1-4.5C1460.1,244.1,1459.3,244.1,1458.6,244z"/>
                            <path name="YAHIR" class="st0" d="M1499.1,244.1c-4.9,0.1-9.8-0.3-14.7-0.7c-2.2-0.2-4.3-0.3-6.5-0.2s-4.4,0.3-6.5,0.5s-4.2,0.4-6.2,0.5v4
                                c0,0,16.8-0.8,20.2-1.4c3.2-0.5,10.5-1.4,13-1.6C1498.6,244.9,1498.8,244.5,1499.1,244.1z"/>
                            <path name="YAHIR" class="st0" d="M1538.9,209.6c-0.5-0.4-1.1-0.9-1.7-1.3c-0.4-0.3,0-1.1,0.4-0.7c0.3,0.2,0.6,0.4,0.9,0.6
                                c-0.5-2.1-0.9-4.2-0.9-6.3c0-1.2,0-2.3-0.4-3.5c-0.2-0.6-0.5-1.1-0.7-1.6c-0.2-0.3-0.3-0.7-0.7-0.9c0.1,1.2,0.3,2.4,0.5,3.5
                                c0.3,1.2,0.6,2.6-0.2,3.7c-0.3,0.5-1,0-0.7-0.4c0.4-0.7,0.4-1.5,0.3-2.2c-0.2-0.8-0.4-1.6-0.5-2.4c-0.3-1.7-0.4-3.4-0.4-5.1
                                c-0.1-3.2,0-6.4,0-9.5s0-6.3-0.5-9.3c-1-5.8-4.5-10.5-8.1-15.1c-0.3-0.4,0.3-1,0.6-0.6c1.2,1.5,2.3,3,3.4,4.5
                                c0-1.4,0-2.8-0.1-4.1c0-0.9-0.6-1.3-1.2-1.9c-0.5-0.5-0.8-1.1-1-1.8c-0.3-1.3-0.4-2.7-1.3-3.7c-1.4-1.4-2.8-2.7-4.3-3.9
                                c-2.9-2.4-6.1-4.6-9.2-6.8c-1.7-1.2-3.4-2.4-5.1-3.5c-1.7-1-3.4-1.9-5.2-2.7c-3.2-1.5-6.4-2.9-9.4-4.9c-0.2,1.9-1.8,3.3-2.9,4.8
                                c-1.3,1.7-2.5,3.5-3.5,5.4c-0.9,1.6-1.9,3.3-3.5,4.4c-0.2,0.1-0.6,0-0.6-0.3c-0.6-3.3-1.4-7.4-4.9-8.8c-0.5-0.2-0.3-1,0.2-0.8
                                c3.6,1.5,4.7,5.3,5.3,8.8c1.3-1.1,2.1-2.6,2.9-4c0.9-1.6,1.9-3.1,3-4.6c0.5-0.7,1.1-1.4,1.7-2.1c0.6-0.7,1.1-1.4,1.4-2.2
                                c0.3-0.9-0.1-1.8-0.3-2.7c-0.3-0.9-0.6-1.9-0.8-2.8c-0.2-0.8-0.5-1.6-1-2.1s-1.3-0.8-1.9-0.3c-0.1,0.6-0.2,1.1-0.4,1.7
                                c0.2-0.2,0.4-0.3,0.5-0.5c0.4-0.4,1,0.2,0.6,0.6c-0.8,0.8-1.7,1.6-2.6,2.3c-1.8,1.5-3.7,2.8-5.7,4c-1,0.6-2,1.1-3,1.6
                                c-1,0.4-2,0.9-2.8,1.6c-0.8,0.8-0.9,2-1.2,3.1c-0.2,0.9-0.5,1.8-0.8,2.6c-0.6,1.6-1.4,3-2.5,4.3c-1.2,1.4-2.7,2.6-4.2,3.6
                                c-0.9,0.5-1.8,0.9-2.6,1.6c-0.8,0.7-1.5,1.6-2.1,2.4c-0.7,0.9-1.3,1.9-1.7,3c-0.4,1.1-0.7,2.3-1,3.5c-0.6,2.6-1.1,5.2-1.4,7.9
                                c-0.4,3.3-0.3,6.6-0.5,9.8c-0.2,3-0.3,6-0.5,9c-0.2,3.9-0.6,7.8-0.8,11.7c-0.1,2.6-0.1,5.3,0.1,7.9c0.1,2.7,0.3,5.3,0,8
                                c-0.5,4.9-2.3,9.4-4.3,13.9c-1,2.2-2,4.6-2.4,7c-0.5,2.6-0.3,5.2,0.4,7.7c0.2,0.5-0.7,0.8-0.8,0.2c-0.3-1.2-0.6-2.4-0.7-3.6
                                c-0.4-5,1.9-9.4,3.8-13.9c1-2.4,1.9-4.8,2.5-7.3c0.6-2.6,0.9-5.1,0.8-7.8c0-2.7-0.3-5.4-0.3-8c0-2.7,0.1-5.4,0.3-8.1
                                c0.3-3.4,0.5-6.8,0.7-10.3c0.1-2.5,0.3-5.1,0.4-7.6c0.1-1.7,0-3.5,0.1-5.2c0.2-2.6,0.5-5.2,0.9-7.7c0.1-0.3,0.1-0.6,0.2-1
                                c0.1-2.1,0.1-4.3,0.1-6.5c0-3.3,0-6.7,0.4-10c0.4-3.2,1.1-6.5,2.6-9.3c-1.8-0.8-2.6-1.7-3.1-2.3l-0.1-0.1
                                c-0.2-0.2-0.3-0.6-0.6-1.2c-1.8,1.1-3.7,2.2-5,3.9c-0.5,0.7-0.9,1.5-1.2,2.3c-0.3,1-0.2,2-0.2,3c0,2.1,0.1,4.1,0.3,6.2
                                c0.1,1,0.2,1.9,0.4,2.9c0.1,0.4,0.7,2.7,1.3,2.2c0.2-0.1,0.3-0.5,0.4-0.7s0.3-0.5,0.4-0.7c0.2-0.4,0.5-0.9,0.8-1.3
                                c0.5-0.9,1.1-1.8,1.6-2.8c0.3-0.5,1,0,0.7,0.4c-0.7,1.2-1.4,2.4-2.2,3.7c-0.3,0.6-0.7,1.2-1.1,1.8c-0.2,0.4-0.6,0.6-1,0.5
                                c-0.9-0.2-1.3-1.3-1.6-2.1c-0.3-1-0.5-2.1-0.6-3.1c-0.2-1.4-0.3-2.8-0.3-4.2c-1.1,0.5-2.2,1.1-3.2,1.8c-4.3,2.9-7.7,7.1-9.6,11.9
                                c-1.1,2.6-1.6,5.4-1.9,8.2c-0.3,3.3-0.5,6.6-0.5,9.9s0.3,6.5,1.2,9.7c0.8,3.1,1.6,6.1,1.2,9.2c-0.2,1.5-0.5,3-1.1,4.4
                                c-0.5,1.4-1.2,2.7-1.7,4.1c-0.5,1.4-0.8,2.7-0.7,4.2c0.1,1.2,0.2,2.5-0.7,3.5c-0.4,0.5-1,0.9-1.3,1.4c-0.2,0.5-0.3,1-0.3,1.5
                                c0,1.1,0.3,2.2,0.4,3.4c0.1,0.6,0.1,1.1,0.1,1.7c0,0.3-0.1,0.6-0.2,0.8c2.2-0.8,4.6-0.9,6.8-0.2c0-0.2,0-0.5,0.1-0.8
                                c0.1-2.9-0.2-5.8-0.2-8.7c-0.1-5.8,1.4-11.5,1.4-17.2c0-2.8-0.5-5.5-0.6-8.2s0-5.4,0-8.1c0-1.2,0-2.5-0.1-3.8
                                c-0.1-1.4-0.3-2.8-0.4-4.2c-0.2-2.9,0-5.7,0.6-8.5c0.1-0.5,0.9-0.3,0.8,0.2c-0.7,3.2-0.7,6.3-0.4,9.5c0.1,1.5,0.3,2.9,0.4,4.4
                                s0,2.9,0,4.4c-0.1,2.6-0.1,5.2,0.1,7.8c0.3,2.8,0.6,5.6,0.4,8.4s-0.6,5.6-0.9,8.4c-0.3,2.9-0.5,5.7-0.4,8.5s0.3,5.7,0.1,8.6
                                c-0.2,2.8-0.7,5.6-1.1,8.3c-0.7,5.1-1.1,10.5,1.2,15.2c0.7-0.1,1.5-0.1,2.2-0.3c0.4-0.1,0.8-0.4,1.2-0.5c0.5-0.2,1-0.3,1.6-0.4
                                c1.1-0.2,2.1-0.3,3.2-0.3c4.2,0,8.2,1.4,12.4,1.9c4.3,0.5,8.7-0.1,13-0.5c2.1-0.2,4.3-0.4,6.4-0.4c2.2,0,4.4,0.2,6.7,0.4
                                c4.5,0.4,8.9,0.6,13.4,0.5c0.1,0,0.1,0,0.2,0c0.2-0.3,0.3-0.5,0.5-0.8c1.3-2.4,2.8-4.7,4.3-7c2-3.2,4-6.3,6-9.5
                                c0-0.1,0-0.1,0-0.2c0.5-1.2,0.2-2.6,0.2-3.8c-0.1-1.4-0.1-2.7,0-4.1c0.2-2.4,0.8-4.8,2.1-6.9c0.1-0.1,0.1-0.1,0.2-0.1
                                c0-0.4,0.1-0.9,0.3-1.3c-1.3-0.2-2.2-1.4-2.5-2.6c-0.5-1.6-0.3-3.1-1.3-4.5c-0.5-0.7-1-1.2-1.3-2c-0.3-0.7-0.4-1.4-0.5-2.1
                                c-0.1-0.9,0.1-1.9,0.2-2.8c-0.1,0-0.1,0-0.2-0.1c-1.9-1.8-2.9-4.2-3.5-6.7c-0.6-2.6-0.9-5.3-1.8-7.8c-0.4-1.2-1-2.3-1.4-3.5
                                s-0.7-2.4-0.7-3.7c0-2.7,0.5-5.5,1-8.1c0.6-2.8,1.2-5.5,2-8.2c0.1-0.5,1-0.3,0.8,0.2c-0.8,2.9-1.5,5.8-2.1,8.8
                                c-0.5,2.6-1.1,5.4-0.9,8c0.2,2.5,1.6,4.7,2.3,7.1c1.3,4.3,1.1,9.6,4.5,13c0.1-0.3,0.1-0.7,0.2-1c0.3-1.8,0.6-3.7,0.9-5.5
                                c0.1-0.5,0.9-0.3,0.8,0.2c-0.3,2.1-0.7,4.2-1,6.3c-0.3,1.8-0.8,3.8,0,5.6c0.4,0.9,1.1,1.5,1.6,2.3s0.7,1.6,0.8,2.5
                                c0.2,1.4,0.5,3.7,2.4,3.8c0.1,0,0.1,0,0.2,0l0,0c0.6-0.6,1.4-0.8,2.2-0.9c0.9,0,1.8,0.2,2.7,0.3c1.1,0.2,2.2,0.2,3.3,0.1
                                c2.2-0.1,4.3-0.7,6.3-1.6c0.5-0.2,0.9,0.5,0.4,0.7c-1.9,0.9-4,1.5-6.2,1.7c-1.8,0.2-3.6-0.1-5.4-0.3c-0.8-0.1-1.8-0.2-2.5,0.2
                                c-0.7,0.5-0.9,1.3-0.9,2.1c0,0.9,0.1,1.8,0.2,2.6c0.1,1,0.3,2.1,0.2,3.2c-0.1,0.9-0.5,1.6-0.8,2.5c-0.2,0.6-0.3,1.7,0.4,1.9
                                c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2-0.1c2.7-0.2,5.5-0.5,8.2-0.4c2.2,0.1,4.3,0.3,6.4,0.9c1,0.3,2,0.6,3,1.1
                                c0.5,0.2,1,0.5,1.5,0.8c0.4,0.2,0.8,0.6,1.2,0.7c0.5,0.1,0.8-0.6,0.9-1c0.2-0.5,0.5-1,0.7-1.5c0.5-1.1,1-2.2,1.5-3.3
                                c0.6-1.3,1.5-2.9,1.5-4.4l0,0c-0.5,1.2-1.1,2.1-2.1,2.9c-1,0.8-2.2,1.4-3.4,1.9c-0.5,0.2-0.9-0.5-0.4-0.7c1.9-0.9,4.1-1.9,5-4
                                c0.2-0.6,0.4-1,0.8-1.5l0,0c0,0,0,0,0-0.1c0.4-0.5,0.8-1,0.9-1.6C1539.8,210.5,1539.4,210,1538.9,209.6z"/>
                        </g>
                        <path name="YAHIR" class="st1" d="M1461.7,134.2c0,0,11.3,1.9,11.8,1.8c0,0-2.9,7.9-4.4,8.3C1470.5,138.9,1461.5,134.4,1461.7,134.2z"/>
                        <g>
                            <path name="YAHIR" class="st2" d="M1461.3,115.4c1.2,0.6,2.5,0.9,3.9,0.9c0.6,0,0.6-0.9,0-0.8c-1.2,0-2.4-0.2-3.4-0.8
                                c-0.2-0.1-0.6-0.2-0.7-0.5c-0.2-0.3-0.1-0.7,0-1.1c0.1-0.6,0.2-1.3,0.3-1.9c0.4-2.6,0.8-5.2,1.3-7.8c0.1-0.5-0.7-0.8-0.8-0.2
                                c-0.7,3.1-1.1,6.2-1.6,9.3c-0.1,0.6-0.3,1.3-0.2,1.9C1460.3,114.9,1460.8,115.2,1461.3,115.4z"/>
                            <path name="YAHIR" class="st2" d="M1461.2,123c3.7-2.4,8.6,0.4,12.2-2.3c0.4-0.3,0-1.1-0.4-0.7c-1.8,1.3-4,1.2-6.1,1.2
                                c-2.1-0.1-4.3-0.1-6.1,1.1C1460.3,122.6,1460.7,123.3,1461.2,123z"/>
                            <path name="YAHIR" class="st2" d="M1540.5,210.5c-0.1-0.6-0.5-1.2-1-1.6c0.1-0.1,0.1-0.2,0-0.3c-0.4-1.3-0.7-2.6-0.8-3.9
                                c-0.2-1.3-0.2-2.7-0.2-4c0-1.2-0.3-2.3-0.8-3.4c-0.4-0.9-0.8-2.1-1.8-2.3c-0.1,0-0.1,0-0.2,0c0-0.2,0-0.3,0-0.5
                                c-0.2-3.3,0-6.5,0-9.8s0.1-6.6-0.4-9.8c-0.4-2.9-1.3-5.7-2.7-8.3c-0.4-0.8-0.9-1.6-1.4-2.4c0-1.6-0.1-3.3-0.1-4.9
                                c0-0.7,0-1.4-0.5-2c-0.4-0.6-1-1-1.4-1.6c-0.3-0.5-0.4-1.2-0.5-1.8c-0.2-0.7-0.3-1.4-0.5-2c-0.3-0.7-0.9-1.2-1.4-1.7
                                c-0.7-0.7-1.4-1.4-2.2-2c-2.9-2.5-6-4.8-9.1-7c-3.4-2.4-6.7-4.9-10.5-6.7c-3.8-1.9-7.9-3.5-11.4-5.9c-0.1-0.7-0.4-1.5-0.6-2.2
                                c-0.3-1-0.6-2.1-0.9-3.1c-0.3-0.8-0.8-1.7-1.7-2c-0.5-0.2-1-0.3-1.5-0.2c0.1-0.6,0.2-1.1,0.3-1.6c1.6,0.1,2.9-0.7,3.8-2
                                c0.8-1.2,1.4-2.6,1.8-3.9c0.5-1.5,0.9-3.1,1.2-4.7c0.3-1.6,0.5-3.3-0.5-4.8c-0.7-0.9-1.7-1.6-2.9-1.6c-0.3,0-0.6,0.1-0.9,0.2
                                l-0.3-5.3c-0.4-9.4-4.1-12.6-7.1-15.3s-9.4-3.6-11-4.3c-1.5-0.7-3.4-2-4.4-2.8s-3.1,0.9-3.1,0.9c-0.3-0.6-1.9-1.9-3-1.8
                                s-3.3,3-3.3,3c-0.5-0.6-1.8-1.1-1.8-1.1c-2,0.7-3.8,5.6-3.8,5.6c-1.1-0.2-1.5,0-1.5,0c-1.3,0.9-1.4,7-1.2,9
                                c0.1,2-0.7,3.2-1.4,5.2c-0.7,2-0.1,6,0,6.3c0.3,0.6,0.8,1.1,1.2,1.3c0.2,1.9,0.5,4.6,0.7,6.5c0.3,2.4,3.3,15.1,5,20.1h-0.1
                                c-1.9,1.1-3.8,2.2-5.1,4c-0.6,0.8-1.1,1.6-1.4,2.5c-0.3,1.1-0.4,2.2-0.4,3.4c0,0.8,0,1.6,0.1,2.4c-0.1,0-0.2,0-0.3,0
                                c-4.6,2.2-8.5,5.9-11.2,10.2c-1.3,2.2-2.3,4.5-3,6.9c-0.8,3-1,6.2-1.2,9.3c-0.2,3.3-0.3,6.6-0.1,9.8c0.1,1.6,0.3,3.2,0.7,4.7
                                c0.4,1.6,0.8,3.2,1.2,4.9c0.7,3.2,0.6,6.5-0.6,9.6c-1,2.7-2.7,5.2-2.6,8.2c0,0.6,0.1,1.3,0.1,1.9c0,0.9-0.4,1.4-1,1.9
                                c-0.8,0.8-1.3,1.6-1.3,2.7c-0.1,1.1,0.2,2.2,0.4,3.3c0.1,0.5,0.2,1.1,0.1,1.6c0,0.5-0.2,0.9-0.3,1.3c-0.2,0.6-0.2,1.2-0.3,1.8
                                c-0.1,1-0.2,2.1-0.2,3.1c-0.1,2.1-0.1,4.2-0.2,6.3c-0.1,2.5-0.6,5.1-1,7.6c-0.5,3.6-1.1,7.2-1.7,10.7c-0.2,1.4-0.4,2.7-0.7,4.1
                                c-0.1,0.6-0.2,1.3-0.3,1.9l0,0c-0.1,0.2-0.2,0.3-0.3,0.5c-0.4,0.6-0.7,1.3-1,1.9c-0.8,1.8-1.4,3.8-1.4,5.8c0,1,0.2,1.9,0.7,2.7
                                c-0.2,4-0.5,10.5-0.8,13c-0.3,2.7,0.1,2.1,0.9,5.4c0.8,3.2,2,10.5,0.5,14.4s-2.3,4.2-2.2,8.2c0.1,4-0.8,21.4-2.2,27.5
                                c-1.3,6-3.4,15.6-1.6,19.4s5.2,5.5,4.6,9.3c-0.7,3.8-1.9,7.7-0.4,10.6s4.8,39.3,5.7,42.6s1.3,4.3,1.3,6.2c-0.1,1.9-1,3,0,4.8
                                s1.6,4.6,1,5.4c-0.5,0.8-1.8,3.2-1,3.9c0,0-3.7,6.3-5,7.3c0,0-9.7,4.2-12.5,5.5c0,0-3,0.4-4,3c0,0-1.6,3.1-0.8,4.2
                                c1.3,1.6,11,4.8,27.8,0.8c4.9-1.6,10.9-3.6,15.1-6c0,0,4.4-1.8,7.5-2.1c3.1-0.4,8.7-2.8,10-4c1.2-1.2,0.8-5.2,0-5.9
                                c0,0-1.8-5.9-2.8-7c0,0-3.2-9.8-3.9-10.6c0,0,0.7-1.8-0.9-3.6c0,0,2.6-6-1.1-10.1c0,0-4-46-5.8-50c0,0,2.3-4.2-0.9-6.6
                                c0,0,5-9.8,5.4-11.8s-0.4-9.1,0.5-11.6c0.9-2.4,7.4-14,7.4-14s4.3,23.3,4.7,26.8c0.4,3.5,3.4,9.4,2.6,13.4s-1.6,5-0.4,6.7
                                c0,0,0.4,2.1-0.7,3.7c0,0-0.8,0.8,1.2,5.2c0,0,2.6,6,2.8,8.7c0,0,2.2,37.4,1.1,39.5c0,0-4,5.5-3.1,9.7c0,0-1.1-0.1-1.1,1.9
                                s-0.9,9.5-0.1,10.8c0,0,1.2,0.9,0.8,1.5s-3.6,7.3-4.8,9c-1.2,1.8-8.5,7.3-8.6,8.9c0,0-0.9,0.5-0.8,2s1.1,4,3.2,4.6
                                c3,0.5,18.3,1.6,24.2-2.1c5.9-3.7,12.4-9.1,13.1-10.2s0.7-3.2,0-5.1l-0.7-3.2c0,0,2-5.8,1.3-9.3c0,0,0.5-1.9,0.8-5.1
                                s0.9-3.9,1.6-5.8c0,0,0.3-4-0.8-5.9c0,0-0.6-2.6,0.8-5.1c0,0,1.4-2.1,0.6-3.6c0,0,1.6-16,2.4-19.9s1.8-16.1,0.8-19.6
                                c0,0-2.3-3.6-1.9-6s1.9-10.9,1.5-13.3s-3.4-6.6-3.1-7.9s0.8-8.9-0.9-10.1c0,0-0.8-4,0.1-8.5c0.9-4.4,0.5-15.2,1.9-19
                                c1.3-3.8,3-14.3,2.7-16.7c0,0,1.5-5.2,2-7.7c1.1-5,0.4-18.8,0.3-21.3c5.9-7.6,18.1-23.4,20.4-26.7c1.7-2.4,4.4-7,4.9-7.9h0.1
                                c0.9-0.3,1.2-1.5,1.5-2.3c0.5-1.2,1.1-2.4,1.6-3.6c0.9-1.9,2.2-4.2,1.6-6.3C1540.1,212.3,1540.7,211.6,1540.5,210.5z
                                M1451.8,103.4c0.5-1.3,0.3-4.6,0.5-5.9c0.2-1.4,1.9-3.6,4.4-5.7s5.6-2.5,7.3-2.5c1.7-0.1,3-0.7,4.3-1.3c1.3-0.6,2.9-1.1,5.6-1.4
                                c2.8-0.3,5.6,0.7,6.1,1.3c0.5,0.6,2.7,2.5,2.7,2.5c-0.6,0.6-1.2,4.3-1.2,5.4s2.8,3,3.5,3.6s1.1,1.5,1.1,2.3s-0.2,6.9-0.2,6.9
                                s0.1,0.9,1.2,0.9c1.3,0.1,1.5-0.7,1.5-0.7s0.8-2.4,0.8-3.7c0-0.2,0.1-0.3,0.1-0.5c0.1,0.3,0.4,0.4,0.7,0.2
                                c0.7-0.8,1.2-1.7,2.4-1.7c1,0,1.9,0.7,2.3,1.6c0.7,1.3,0.4,2.7,0.1,4.1c-0.5,2.5-1.2,5.1-2.5,7.4c-0.8,1.4-1.8,2.3-3.4,2.3
                                c0.3-1.5,0.6-3,0.8-4.4c0.1-0.5-0.7-0.8-0.8-0.2c-0.6,3.5-1.3,6.9-2.1,10.3c0,0.1,0,0.3,0.1,0.3c-3.1,2.7-6.5,4.9-10.3,6.6
                                c-0.9,0.4-1.9,0.9-2.6,1.7c-0.6,0.8-0.9,1.7-1.1,2.6c-11.7-0.4-14-2.9-15.1-4.2l-0.1-0.1c-1-1.1-5.1-18.1-5.6-21.6
                                C1452.3,107.9,1452,105.3,1451.8,103.4z M1473,136.4c-0.4,1.6-0.9,3.1-1.7,4.5c-0.9,1.5-2.1,2.9-3.4,4c-1.6,1.3-3.6,2-5.1,3.4
                                c-0.8,0.8-1.4,1.6-2.1,2.5s-1.2,1.7-1.6,2.8c-0.3,0.8-0.5,1.6-0.7,2.4c0-5.4-0.1-10.9,1.2-16.2c0.4-1.8,1-3.5,1.9-5.1
                                C1463.7,135.4,1467.3,136.2,1473,136.4z M1438.2,249.2c-0.7,1.8-1.9,6.6-5.5,8.3c-3.2,1.5-7.3,2.7-8.3,5.6
                                c-0.3-1.5,0.1-3.1,0.5-4.5c0.5-1.4,1.1-2.7,1.8-4c0.2-0.4,0.2-0.8,0.3-1.2c0.2-1.1,0.4-2.2,0.6-3.3c1.1-6.4,2.2-12.8,2.9-19.2
                                c0.1-1.3,0.1-2.6,0.2-4c0.1-2.1,0.2-4.3,0.3-6.4c0-0.4,0.1-0.7,0.1-1.1c0.1,0,0.1,0,0.2,0c2.1-0.9,4.6-1,6.8-0.2
                                c-0.2,2.3-0.5,4.7-0.9,7c-0.8,5.6-1.4,11.7,1.1,17c-0.1,0.1-0.2,0.2-0.2,0.4l0.4,4.9C1438.5,248.7,1438.3,248.9,1438.2,249.2z
                                M1441.8,248.3c0,0-0.4-0.1-1-0.2l-0.8-4.4c0.5-0.1,1-0.1,1.5-0.2c0.6-0.1,1.2-0.5,1.8-0.7c0.9-0.3,1.9-0.5,2.9-0.5
                                c4.2-0.3,8.3,1.1,12.4,1.7c0.7,0.1,1.5,0.2,2.2,0.2l0.1,4.5L1441.8,248.3z M1465.1,248.3v-4c2.1-0.1,4.2-0.3,6.2-0.5
                                c2.2-0.2,4.3-0.5,6.5-0.5c2.2-0.1,4.4,0,6.5,0.2c4.9,0.4,9.8,0.7,14.7,0.7c-0.2,0.4-0.5,0.8-0.7,1.2c-2.5,0.2-9.8,1.1-13,1.6
                                C1481.8,247.5,1465.1,248.3,1465.1,248.3z M1529.2,232.6c-2.8,3.9-19.7,25.8-23.1,30.1c-2,2.5-5.1,6.7-5.1,6.7l-0.1,0.1
                                c-2.4,8.4-7.4,13.9-8.2,14.7c-0.7-1.3-2.8-5.7-3.5-7.6c-2.1-6.2-3.1-12.9-3.3-15.1c0.1,0,0.2-0.1,0.3-0.2c-0.1,0.1,0,0,0.1,0
                                c0.1-0.1,0.1-0.1,0.2-0.2c0.2-0.2,0.5-0.5,0.8-0.7c0.7-0.6,1.4-1.3,2.1-2c1.3-1.3,2.6-2.6,3.6-4.1c2.4-3.6,5.1-6.9,7.3-10.5
                                c1.4-2.3,2.8-4.6,4.2-6.9c2-3.1,3.9-6.2,5.9-9.3c1.2-1.8,2.3-3.6,3.5-5.4h0.1c5-0.4,10.1-0.9,15,0.6c1.1,0.3,2.2,0.8,3.2,1.3
                                c0.5,0.2,0.9,0.6,1.4,0.8c0.1,0.1,0.2,0.1,0.3,0.2C1533,226.5,1530.6,230.5,1529.2,232.6z M1511.4,222.7
                                c-0.1-1.4-0.2-2.7-0.1-4.1c0.1-2.1,0.5-4.3,1.5-6.2c0.1,0.6,0.1,1.1,0.2,1.6c0.1,1.1,0.4,2.4,0.2,3.5c-0.2,1-0.9,1.8-0.9,2.9
                                c0,0.6,0.3,1.2,0.7,1.6c-0.5,0.8-1,1.5-1.4,2.3C1511.5,223.7,1511.5,223.2,1511.4,222.7z M1537.4,218.7c-0.5,1.1-1,2.2-1.5,3.3
                                c-0.2,0.5-0.5,1-0.7,1.5c-0.2,0.4-0.4,1.1-0.9,1c-0.4-0.1-0.9-0.5-1.2-0.7c-0.5-0.3-1-0.5-1.5-0.8c-1-0.4-1.9-0.8-3-1.1
                                c-2.1-0.6-4.3-0.8-6.4-0.9c-2.7-0.1-5.5,0.2-8.2,0.4c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1-0.1c-0.8-0.3-0.6-1.3-0.4-1.9
                                c0.3-0.8,0.7-1.6,0.8-2.5c0.1-1-0.1-2.1-0.2-3.2c-0.1-0.9-0.2-1.8-0.2-2.6s0.2-1.7,0.9-2.1c0.7-0.5,1.7-0.4,2.5-0.2
                                c1.8,0.3,3.6,0.5,5.4,0.3c2.2-0.2,4.2-0.8,6.2-1.7c0.5-0.2,0.1-1-0.4-0.7c-2,0.9-4.1,1.5-6.3,1.6c-1.1,0.1-2.2,0-3.3-0.1
                                c-0.9-0.1-1.8-0.4-2.7-0.3c-0.8,0-1.6,0.2-2.2,0.9l0,0c-0.1,0-0.1,0-0.2,0c-1.8,0-2.2-2.4-2.4-3.8c-0.1-0.9-0.3-1.7-0.8-2.5
                                s-1.2-1.4-1.6-2.3c-0.8-1.7-0.3-3.8,0-5.6c0.3-2.1,0.7-4.2,1-6.3c0.1-0.5-0.7-0.8-0.8-0.2c-0.3,1.8-0.6,3.7-0.9,5.5
                                c-0.1,0.3-0.1,0.7-0.2,1c-3.4-3.4-3.2-8.7-4.5-13c-0.7-2.4-2.2-4.6-2.3-7.1c-0.2-2.6,0.4-5.4,0.9-8c0.6-2.9,1.3-5.9,2.1-8.8
                                c0.1-0.5-0.7-0.8-0.8-0.2c-0.7,2.7-1.4,5.4-2,8.2c-0.5,2.6-1.1,5.4-1,8.1c0,1.3,0.3,2.5,0.7,3.7c0.4,1.2,1,2.3,1.4,3.5
                                c0.9,2.5,1.1,5.2,1.8,7.8c0.6,2.5,1.6,5,3.5,6.7c0.1,0.1,0.1,0.1,0.2,0.1c-0.2,0.9-0.3,1.9-0.2,2.8c0,0.7,0.2,1.4,0.5,2.1
                                c0.3,0.8,0.9,1.4,1.3,2c1,1.4,0.9,2.9,1.3,4.5c0.4,1.2,1.2,2.4,2.5,2.6c-0.2,0.4-0.3,0.9-0.3,1.3c-0.1,0-0.1,0.1-0.2,0.1
                                c-1.3,2.1-1.9,4.5-2.1,6.9c-0.1,1.4-0.1,2.7,0,4.1c0.1,1.3,0.3,2.6-0.2,3.8c0,0.1,0,0.1,0,0.2c-2,3.1-4,6.3-6,9.5
                                c-1.4,2.3-2.9,4.6-4.3,7c-0.2,0.3-0.3,0.5-0.5,0.8c-0.1,0-0.1,0-0.2,0c-4.5,0.1-8.9-0.1-13.4-0.5c-2.2-0.2-4.4-0.4-6.7-0.4
                                c-2.2,0-4.3,0.2-6.4,0.4c-4.3,0.4-8.7,1-13,0.5c-4.2-0.5-8.2-1.9-12.4-1.9c-1.1,0-2.2,0.1-3.2,0.3c-0.5,0.1-1.1,0.2-1.6,0.4
                                c-0.4,0.1-0.8,0.4-1.2,0.5c-0.7,0.2-1.5,0.2-2.2,0.3c-2.3-4.7-1.9-10.2-1.2-15.2c0.4-2.8,0.9-5.5,1.1-8.3c0.2-2.9,0-5.7-0.1-8.6
                                c-0.1-2.9,0-5.7,0.4-8.5c0.3-2.8,0.8-5.6,0.9-8.4c0.2-2.8-0.1-5.6-0.4-8.4c-0.2-2.6-0.2-5.2-0.1-7.8c0-1.5,0.1-2.9,0-4.4
                                s-0.2-2.9-0.4-4.4c-0.3-3.2-0.2-6.4,0.4-9.5c0.1-0.5-0.7-0.8-0.8-0.2c-0.6,2.8-0.8,5.7-0.6,8.5c0.1,1.4,0.3,2.8,0.4,4.2
                                c0.1,1.2,0.1,2.5,0.1,3.8c0,2.7-0.2,5.4,0,8.1c0.1,2.7,0.6,5.4,0.6,8.2c0,5.8-1.4,11.4-1.4,17.2c0,2.9,0.3,5.8,0.2,8.7
                                c0,0.2,0,0.5-0.1,0.8c-2.2-0.8-4.6-0.7-6.8,0.2c0.1-0.2,0.2-0.6,0.2-0.8c0.1-0.6,0-1.1-0.1-1.7c-0.1-1.1-0.4-2.2-0.4-3.4
                                c0-0.5,0.1-1,0.3-1.5c0.3-0.6,0.9-0.9,1.3-1.4c0.9-1,0.7-2.2,0.7-3.5c-0.1-1.5,0.2-2.8,0.7-4.2s1.2-2.7,1.7-4.1
                                c0.6-1.4,0.9-2.9,1.1-4.4c0.3-3.2-0.4-6.2-1.2-9.2c-0.8-3.2-1.1-6.4-1.2-9.7c0-3.3,0.2-6.6,0.5-9.9c0.3-2.8,0.8-5.6,1.9-8.2
                                c1.9-4.8,5.3-9,9.6-11.9c1-0.7,2.1-1.3,3.2-1.8c0.1,1.4,0.2,2.8,0.3,4.2c0.1,1,0.3,2.1,0.6,3.1c0.3,0.8,0.7,1.9,1.6,2.1
                                c0.4,0.1,0.8-0.2,1-0.5c0.4-0.6,0.7-1.2,1.1-1.8c0.7-1.2,1.4-2.4,2.2-3.7c0.3-0.5-0.5-0.9-0.7-0.4c-0.5,0.9-1.1,1.8-1.6,2.8
                                c-0.2,0.4-0.5,0.9-0.8,1.3c-0.1,0.2-0.3,0.5-0.4,0.7s-0.2,0.5-0.4,0.7c-0.6,0.5-1.2-1.9-1.3-2.2c-0.2-0.9-0.3-1.9-0.4-2.9
                                c-0.2-2.1-0.3-4.1-0.3-6.2c0-1,0-2,0.2-3c0.2-0.8,0.7-1.6,1.2-2.3c1.3-1.7,3.2-2.8,5-3.9c0.2,0.6,0.4,1,0.6,1.2l0.1,0.1
                                c0.5,0.6,1.3,1.4,3.1,2.3c-1.5,2.8-2.2,6.1-2.6,9.3c-0.4,3.3-0.4,6.7-0.4,10c0,2.1,0,4.3-0.1,6.5c-0.1,0.3-0.1,0.6-0.2,1
                                c-0.5,2.6-0.8,5.1-0.9,7.7c-0.1,1.8-0.1,3.5-0.1,5.2c-0.1,2.5-0.2,5.1-0.4,7.6c-0.2,3.4-0.4,6.8-0.7,10.3
                                c-0.2,2.7-0.4,5.4-0.3,8.1c0,2.7,0.3,5.4,0.3,8s-0.2,5.2-0.8,7.8c-0.6,2.5-1.5,5-2.5,7.3c-1.9,4.5-4.2,8.9-3.8,13.9
                                c0.1,1.2,0.3,2.4,0.7,3.6c0.2,0.5,1,0.3,0.8-0.2c-0.7-2.5-0.9-5.1-0.4-7.7c0.5-2.4,1.5-4.7,2.4-7c2-4.5,3.8-9,4.3-13.9
                                c0.3-2.7,0.1-5.3,0-8c-0.1-2.6-0.2-5.3-0.1-7.9c0.2-3.9,0.6-7.8,0.8-11.7c0.2-3,0.3-6,0.5-9c0.2-3.3,0.1-6.6,0.5-9.8
                                c0.3-2.6,0.8-5.3,1.4-7.9c0.3-1.2,0.6-2.4,1-3.5s1-2,1.7-3c0.7-0.9,1.3-1.7,2.1-2.4c0.8-0.7,1.7-1.1,2.6-1.6
                                c1.6-1,3.1-2.1,4.2-3.6c1.1-1.3,1.9-2.8,2.5-4.3c0.3-0.9,0.6-1.7,0.8-2.6c0.3-1.1,0.4-2.3,1.2-3.1c0.8-0.8,1.8-1.2,2.8-1.6
                                c1-0.5,2.1-1,3-1.6c2-1.1,3.9-2.5,5.7-3.9c0.9-0.7,1.7-1.5,2.6-2.3c0.4-0.4-0.2-1-0.6-0.6c-0.2,0.2-0.4,0.3-0.5,0.5
                                c0.1-0.6,0.2-1.1,0.4-1.7c0.6-0.5,1.4-0.2,1.9,0.3c0.6,0.6,0.8,1.4,1,2.1c0.3,0.9,0.6,1.9,0.8,2.8c0.3,0.9,0.6,1.7,0.3,2.6
                                c-0.2,0.8-0.8,1.6-1.4,2.2c-0.6,0.7-1.1,1.4-1.7,2.1c-1.1,1.4-2.1,3-3,4.6c-0.8,1.4-1.6,2.9-2.9,4c-0.7-3.5-1.8-7.3-5.3-8.8
                                c-0.5-0.2-0.7,0.6-0.2,0.8c3.4,1.4,4.3,5.6,4.9,8.8c0.1,0.3,0.4,0.4,0.6,0.3c1.6-1.1,2.6-2.7,3.5-4.4c1.1-1.9,2.2-3.6,3.5-5.4
                                c1.1-1.4,2.7-2.9,2.9-4.8c2.9,2,6.2,3.4,9.4,4.9c1.8,0.8,3.5,1.7,5.2,2.7c1.8,1.1,3.4,2.3,5.1,3.5c3.1,2.2,6.2,4.4,9.2,6.8
                                c1.5,1.2,2.9,2.6,4.3,3.9c0.9,1,1,2.4,1.3,3.7c0.2,0.7,0.5,1.3,1,1.8c0.6,0.6,1.1,1,1.2,1.9c0,1.4,0.1,2.8,0.1,4.1
                                c-1.1-1.5-2.2-3-3.4-4.5c-0.3-0.4-0.9,0.2-0.6,0.6c3.6,4.5,7.1,9.2,8.1,15.1c0.5,3.1,0.5,6.2,0.5,9.3c0,3.2-0.1,6.4,0,9.5
                                c0,1.7,0.1,3.4,0.4,5.1c0.1,0.8,0.3,1.6,0.5,2.4c0.1,0.8,0.2,1.6-0.3,2.2c-0.3,0.5,0.4,0.9,0.7,0.4c0.7-1.1,0.4-2.5,0.2-3.7
                                c-0.3-1.2-0.4-2.3-0.5-3.5c0.3,0.2,0.5,0.6,0.7,0.9c0.3,0.5,0.5,1.1,0.7,1.6c0.4,1.1,0.4,2.3,0.4,3.5c0.1,2.1,0.4,4.2,0.9,6.3
                                c-0.3-0.2-0.6-0.4-0.9-0.6c-0.4-0.3-0.9,0.4-0.4,0.7c0.6,0.4,1.1,0.8,1.7,1.3c0.5,0.4,0.9,0.9,0.8,1.6c-0.1,0.6-0.5,1.1-0.9,1.6
                                c0,0,0,0,0,0.1l0,0c-0.4,0.5-0.6,0.9-0.8,1.5c-0.8,2.1-3.1,3.1-5,4c-0.5,0.2-0.1,1,0.4,0.7c1.2-0.5,2.3-1.1,3.4-1.9
                                c1-0.8,1.7-1.7,2.1-2.9l0,0C1538.9,215.8,1538,217.4,1537.4,218.7z"/>
                            <path name="YAHIR" class="st2" d="M1494.6,216c-0.4,0.3,0,1.1,0.4,0.7c5.4-4,7.3-10.6,10.8-16.1c0.3-0.5-0.4-0.9-0.7-0.4
                                C1501.6,205.5,1499.8,212.1,1494.6,216z"/>
                        </g>
                        <path name="YAHIR" class="st3" d="M1434.9,254.6c-0.2-0.1-2.6-1.1-5.7-1c-3.7,0.1-5.5,1.4-6.2,2.4s-0.8,2.7-0.3,3.3c0.5,0.6,1.2,0.8,1.3,0.7
                            c0.1-0.1,1.8-2.2,3.8-2.6c2.1-0.4,4.2-0.3,4.8,0.1c0,0,1.2-0.4,2.8-2.2C1435.8,254.9,1434.9,254.6,1434.9,254.6z"/>
                        <path name="YAHIR" class="st3" d="M1441.5,243.4c0,0,0.5,5.4,1.1,6.2s1.4,1.8,4.9,1.8c3.4,0,6.6-0.4,7.1-0.7s1.2-1.2,1.2-3.4s-0.5-4.1-0.5-4.1
                            s-5.2-1.1-6.6-1.1C1447,242.2,1445.1,241.9,1441.5,243.4z"/>
                        <circle name="YAHIR" class="st3" cx="1474" cy="143.7" r="1.2"/>
                        <circle name="YAHIR" class="st3" cx="1463.4" cy="153.1" r="1.2"/>
                        <circle name="YAHIR" class="st3" cx="1454.3" cy="172.4" r="1.2"/>
                        <circle name="YAHIR" class="st3" cx="1452.2" cy="194.4" r="1.2"/>
                        <circle name="YAHIR" class="st3" cx="1451.3" cy="215.1" r="1.2"/>
                        <circle name="YAHIR" class="st3" cx="1445.6" cy="235.4" r="1.2"/>
                        <path name="YAHIR" class="st3" d="M1436.4,284.5c7.1,0.1,14.1,0.6,21.1,1.8c2,0.3,4,0.7,5.9,1.1c0.5,0.1,0.8-0.7,0.2-0.8
                            c-6.9-1.4-14-2.3-21.1-2.7c-2.1-0.1-4.1-0.2-6.1-0.2C1435.8,283.7,1435.8,284.5,1436.4,284.5L1436.4,284.5z"/>
                        <path name="YAHIR" class="st3" d="M1450.8,287.7c2.1,0.1,4.2,0.4,6.3,0.8c0.5,0.1,0.8-0.7,0.2-0.8c-2.2-0.4-4.3-0.6-6.5-0.8
                            C1450.2,286.8,1450.2,287.6,1450.8,287.7L1450.8,287.7z"/>
                        <path name="YAHIR" class="st3" d="M1457.4,290.8c0.9,1.5,1.5,3.1,2,4.8s1.1,3.3,1.6,4.9c1,3.2,2.1,6.4,3.5,9.5c0.8,1.8,1.7,3.5,2.7,5.2
                            c0.3,0.5,1,0,0.7-0.4c-3.6-5.9-5.5-12.5-7.6-19c-0.6-1.8-1.2-3.7-2.2-5.3C1457.8,289.9,1457.1,290.4,1457.4,290.8L1457.4,290.8z"
                            />
                        <path name="YAHIR" class="st3" d="M1448.3,252.6c-0.1,2-0.5,3.9-1.2,5.8c-0.6,1.8-1.3,3.5-1.7,5.4c-0.8,3.6-0.9,7.4-0.3,11.1
                            c0.1,0.5,0.9,0.3,0.8-0.2c-0.6-3.7-0.5-7.6,0.4-11.2c0.5-1.8,1.2-3.6,1.8-5.4c0.6-1.8,0.9-3.6,1-5.4
                            C1449.2,252.1,1448.4,252.1,1448.3,252.6L1448.3,252.6z"/>
                        <path name="YAHIR" class="st3" d="M1485.8,251.4c-0.5,3-1.1,6.1-0.8,9.2c0.2,3,1,5.9,1.7,8.8c1.3,5,2.7,10.1,5.3,14.7c0.7,1.2,1.5,2.4,2.4,3.5
                            c0.9,1.2,2.3,2.2,2.6,3.8c0.1,0.5,0.9,0.3,0.8-0.2c-0.3-1.2-1.1-2.2-1.9-3.1c-0.9-1-1.7-2-2.4-3.1c-1.4-2.2-2.5-4.5-3.4-7
                            s-1.6-5-2.3-7.5c-0.7-2.8-1.5-5.6-1.9-8.4c-0.5-3.5,0-7,0.6-10.4C1486.7,251.1,1485.9,250.9,1485.8,251.4L1485.8,251.4z"/>
                        <path name="YAHIR" class="st0" d="M1432.2,437.5c8,3.7,17.1,1.7,25-1.1c2.2-0.8,4.4-1.7,6.3-3.1c0.4-0.3,0-1-0.4-0.7c-3.3,2.3-7.4,3.6-11.3,4.6
                            c-4,1.1-8.2,1.8-12.4,1.4c-2.3-0.2-4.7-0.8-6.8-1.8C1432.1,436.6,1431.7,437.3,1432.2,437.5L1432.2,437.5z"/>
                        <path name="YAHIR" class="st0" d="M1476.4,441c7.2-0.8,13.8,3.1,20.9,4c0.5,0.1,0.5-0.8,0-0.9c-7-0.9-13.6-4.8-20.9-4
                            C1475.9,440.2,1475.9,441.1,1476.4,441L1476.4,441z"/>
                        <path name="YAHIR" class="st0" d="M1461.4,460.5c5.4,0.6,10.9,1.1,16.3,0.6c2.5-0.2,5.1-0.6,7.5-1.6c2.3-1,4.2-2.6,6.1-4.2c2.4-2,4.9-3.8,7.2-6
                            c0.4-0.4-0.2-1-0.6-0.6c-1.9,1.9-4.1,3.5-6.2,5.2c-1.9,1.5-3.8,3.3-6,4.4c-2.3,1.2-5,1.6-7.6,1.9s-5.2,0.3-7.8,0.2
                            c-3-0.1-6-0.4-9-0.8C1460.8,459.6,1460.8,460.4,1461.4,460.5L1461.4,460.5z"/>
                        <path name="YAHIR" class="st0" d="M1411,452.3c7.7,1.6,15.8,1.6,23.5-0.1c3.9-0.9,7.7-2.3,11.4-3.8c1.8-0.8,3.6-1.5,5.5-2.1
                            c1.7-0.5,3.4-1,5.1-1.5c3.6-1.1,7.2-2.6,10-5.2c0.4-0.4-0.2-1-0.6-0.6c-2.5,2.3-5.6,3.7-8.8,4.7c-3.3,1.1-6.7,2-10,3.3
                            c-1.9,0.8-3.7,1.6-5.6,2.3c-1.8,0.7-3.6,1.2-5.4,1.7c-3.8,1-7.7,1.5-11.6,1.6c-4.4,0.1-8.8-0.2-13.1-1.1
                            C1410.7,451.4,1410.5,452.2,1411,452.3L1411,452.3z"/>
                        <g>
                            <path name="YAHIR" class="st2" d="M1474,142.2c-0.8,0-1.4,0.6-1.4,1.4s0.7,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4
                                C1475.5,142.9,1474.8,142.2,1474,142.2z M1474,144.7c-0.5,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S1474.6,144.7,1474,144.7z"/>
                            <path name="YAHIR" class="st2" d="M1463.4,151.6c-0.8,0-1.4,0.6-1.4,1.4s0.7,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4
                                C1464.8,152.3,1464.2,151.6,1463.4,151.6z M1463.4,154.1c-0.5,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1S1463.9,154.1,1463.4,154.1z"/>
                            <path name="YAHIR" class="st2" d="M1454.3,173.8c0.8,0,1.4-0.6,1.4-1.4s-0.7-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4
                                C1452.8,173.2,1453.5,173.8,1454.3,173.8z M1454.3,171.4c0.5,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S1453.7,171.4,1454.3,171.4z"/>
                            <path name="YAHIR" class="st2" d="M1452.2,195.8c0.8,0,1.4-0.6,1.4-1.4s-0.7-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4S1451.4,195.8,1452.2,195.8z
                                M1452.2,193.4c0.5,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S1451.6,193.4,1452.2,193.4z"/>
                            <path name="YAHIR" class="st2" d="M1451.3,216.6c0.8,0,1.4-0.6,1.4-1.4s-0.7-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4
                                C1449.8,215.9,1450.5,216.6,1451.3,216.6z M1451.3,214.1c0.5,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S1450.7,214.1,1451.3,214.1z"/>
                            <path name="YAHIR" class="st2" d="M1445.6,233.9c-0.8,0-1.4,0.6-1.4,1.4s0.7,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4
                                C1447,234.6,1446.4,233.9,1445.6,233.9z M1445.6,236.4c-0.5,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1
                                C1446.5,235.9,1446.1,236.4,1445.6,236.4z"/>
                        </g>
                    </g>
                </g>
                <g id="LUIS" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="LUIS" class="st0" d="M1320.9,122.3c1.8-0.4,4.1,0.2,5.6,1.2c0.8,0.5,1.6,1.2,2.5,1.7c0.8,0.5,1.8,0.8,2.7,1.2
                                c1.1,0.4,2.3,0.9,3.3,1.5c0-0.1,0-0.1,0.1-0.2c0.9-1.7,2.2-3.4,3.9-4.3c1.1-0.6,3.3-1.2,3.9,0.3c0.3,0.8-0.2,1.6-0.6,2.3
                                c-0.4,0.9-0.7,1.8-0.9,2.7c-0.2,0.9-0.2,1.7-0.1,2.5c7.3-7,6.9-22,6.9-22.2s0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4
                                c0,0.3,0.1,3-0.4,6.7c1.5-0.3,2.2-1.9,2.7-3.1c0.6-1.5,1.2-3,1.5-4.6c0.1-0.8,0.2-1.7,0.1-2.5c-0.1-0.6-0.2-1.3-0.8-1.6
                                c-0.6-0.3-1.5,0.2-2,0.5c-0.5,0.4-0.9,0.9-1.2,1.5c0,0.1-0.1,0.1-0.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.5c-0.5,0.4-0.9,0.5-1.1-0.3
                                s0.1-2.6,0-3.7s-0.8-4-1.3-4.7s-1.2-1.8-1.4-3.7c-0.2-1.8-1.9-6.4-2.3-6.8s-2.8-0.4-5.9-1.1s-7.1-1.1-9.8-0.8
                                c-2.7,0.3-8.7,2.1-9.2,3.5s-1.8,6.8-2.5,8s-0.1,8.8-0.1,8.9c-0.1,0.2-0.1,0.3-0.2,0.5l0,0c0,0.1,1.6,12.2,4.2,16.7
                                C1319.6,122.8,1320.4,122.5,1320.9,122.3z"/>
                            <path name="LUIS" class="st0" d="M1322.6,129.9c0.2-0.5,0.5-0.9,0.9-1.3c-0.8-0.6-1.7-1.2-2.6-1.6C1321.3,127.9,1321.9,129.1,1322.6,129.9
                                C1322.6,130,1322.6,130,1322.6,129.9z"/>
                            <path name="LUIS" class="st0" d="M1312.4,105c-0.7,0.6-0.4,2.1-0.3,2.9c0.2,1.3,0.4,2.7,0.8,4c0.5,1.8,1.1,4.5,2.9,5.4
                                c-1.1-4.2-1.8-8.8-1.9-10.2C1313.5,107,1312.9,106.2,1312.4,105z"/>
                            <path name="LUIS" class="st0" d="M1344.8,128.4c-0.9,1.4-1.9,2.8-3.2,3.9c0.3,0.8,0.6,1.6,0.7,2.5c0.1,0.5,0.1,1.1,0.2,1.6
                                c1-1.3,2.6-2.2,3.7-3.4c-0.1,0-0.1-0.1-0.1-0.1c-0.4-0.9-0.7-1.9-1-2.9C1345,129.5,1344.8,129,1344.8,128.4z"/>
                            <path name="LUIS" class="st0" d="M1282.6,174.1c2.4-1.4,8.7-3.9,11.9-3.3c2.5,0.4,7.9,2.1,10.7,4.1c-0.6-3.3-1.2-6.6-1.2-10
                                c0-1.3,0.1-2.6,0.2-3.9c-0.4-1.5-0.7-3.1-1.1-4.6c-0.2-0.8-0.4-1.6-0.7-2.4c-0.2-0.8-0.4-1.7-1-2.3c-0.3-0.4,0.2-1,0.6-0.6
                                c0.6,0.7,0.9,1.6,1.1,2.4c0.2,0.8,0.4,1.6,0.7,2.4c0.2,0.9,0.4,1.7,0.7,2.6c0.2-1.6,0.4-3.3,0.5-4.9c0-0.5,0.9-0.6,0.8,0
                                c-0.2,2.5-0.6,4.9-0.8,7.4c0,0.1,0,0.1,0,0.1c-0.1,1.2-0.2,2.4-0.2,3.5c0,3.6,0.7,7.2,1.3,10.8c0,0.1,0,0.1,0,0.2
                                c1.5,0.7,13.3,6,19.7,7.4c5.1,1.2,14.6,3,19,3.9c0.1-0.4,0.2-0.9,0.4-1.5c0.2-0.5,0.6-1.6,1.8-2.1c0.7-0.3,1.4-0.3,2.3-0.1
                                c-1.7-3.1-6.8-12.8-8.5-16.6c-1-2.2-2.2-4.7-3.3-7.1c-0.1,1.5-0.2,2.9-0.3,4.4c-0.1,0.9-0.1,1.8-0.2,2.7c-0.1,0.7-0.2,1.3-1,1.5
                                c-0.8,0.1-1.7-0.1-2.4-0.5c-0.4-0.2-0.7-0.4-0.7-0.8c-0.1-0.5,0-1.1,0-1.6c0.2-4.2,0.5-8.5,0.9-12.7c0.1-0.5,0.9-0.6,0.8,0v0.1
                                c0.1,0,0.3,0,0.4,0.1c0.3,0.4,1.4,2.4,2.6,4.9c0,0,0,0.1,0.1,0.1c1.4,2.9,3,6.3,4,8.5c1.9,4.3,8.5,16.5,9,17.4
                                c0.3,0.3,1.7,1.7,2,3.4c0.1,0.4,0.2,0.8,0.2,0.9c6.8,7.9,16.6,9.4,16.7,9.4s0.1,0,0.2,0.1c1.7,1.2,4.7,0.6,5.7,0
                                c1.2-0.7,5-5.8,6.6-9.3c1.5-3.2,1.1-7.8,0.6-9.8c-0.2-1.1-3.4-3.6-5.7-4.9c0.4,0.5,0.7,0.9,1,1.3c0.1,0.2,0,0.5-0.2,0.6
                                c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0-0.3-0.1-0.4-0.2c-1-2-5.5-5.1-9.7-8.1c-1.2-0.8-2.3-1.6-3.5-2.5c-0.6-0.5-1.4-1.1-2.2-1.8
                                c-0.1,0-0.1-0.1-0.2-0.1c-4.8-4.3-12-12.5-15-15.9c-0.8-0.9-1.4-1.6-1.5-1.7c-0.2-0.1-0.2-0.4-0.1-0.6s0.4-0.2,0.6-0.1
                                c0.1,0.1,0.1,0.1,1.6,1.8c2.3,2.7,7.3,8.2,11.6,12.6c0.1-2.6,0-5.1-0.2-7.7c-0.1-0.6,0.8-0.5,0.8,0c0.3,2.8,0.3,5.6,0.2,8.3
                                c0,0.1,0,0.1,0,0.1c0.7,0.7,1.4,1.4,2.1,2c0.6-1.7,2.1-3,3.1-4.5c0.1-0.2,0.4-0.3,0.6-0.1c0.2,0.1,0.3,0.4,0.2,0.6
                                c-1.1,1.5-2.6,2.9-3.2,4.7c0.7,0.7,1.4,1.2,2,1.7c1.1,0.8,2.3,1.6,3.5,2.5c2.7,1.9,5.4,3.8,7.3,5.4c2,0.3,7.9,4.5,8.3,6.3
                                c0.3,1.5,0.7,4.4,0.2,7.3c0.5-0.8,0.9-1.7,1.2-2.6c0.9-2.7,1.2-5.7,0.4-8.5c-0.2-0.8-0.6-1.5-1.2-2.1s-1.2-1.1-1.8-1.6
                                c-1.2-1.2-2.2-2.4-3.3-3.7c-0.3-0.4,0.2-1,0.6-0.6c1.3,1.6,2.6,3.1,4.1,4.5c0.3,0.3,0.7,0.6,1,0.9c-0.2-1.2-0.2-2.4-0.7-3.6
                                c-0.4-1-1.2-1.8-2-2.4c-0.9-0.7-1.9-1.6-3.2-1.5c-0.6,0.1-0.5-0.8,0-0.9c1.3-0.1,2.4,0.6,3.4,1.4c0.1,0.1,0.3,0.2,0.4,0.3
                                c0.1-2.3-0.4-4.6-1.1-6.8c0,0.8,0,1.6,0.1,2.4c0,0.6-0.8,0.6-0.8,0c0-2.2,0-4.5-0.5-6.7c-0.3-1.3-0.7-2.6-1.5-3.6
                                c-0.6-0.7-1.3-1.3-2-1.9c-2.2-1.6-4.7-2.7-7.4-3.2c1.1,0.8,2.1,1.8,3.1,2.7c0.4,0.4-0.2,1-0.6,0.6c-1.3-1.2-2.6-2.4-4.1-3.4
                                s-3.2-1.6-4.9-2.2c-1.7-0.6-3.4-1.1-5.1-1.4c-0.6-0.1-1.2-0.3-1.9-0.5c0.7,0.8,1.6,1.5,2.5,2.1c0.4,0.3,0,1-0.4,0.7
                                c-1.2-0.8-2.3-1.8-3.2-2.9c-0.3-0.3-0.5-0.7-0.8-1l0,0c-0.1-0.1-0.1-0.1-0.1-0.1c-0.4-0.5-1-1-1.6-1.2c-0.1,0.8-0.3,1.6-0.5,2.4
                                c-0.4,1.8-0.5,3.7-1.3,5.4c-0.2,0.3-0.7,0.2-0.8-0.1c-0.3-1.6-0.7-3.3-1.8-4.6c-1-1.2-2.5-1.9-4-2.5c-0.5-0.2-0.3-1,0.2-0.8
                                c1.6,0.6,3.2,1.4,4.4,2.7c0.9,1,1.3,2.3,1.7,3.6c0.3-1.2,0.5-2.5,0.7-3.7c0.4-1.6,0.8-3.3,0.6-5c-0.2-3.2-2.7-5.2-5.5-6.4
                                c0,0,0,0,0,0.1c0.2,0.9,0.5,1.7,0.8,2.6c0-0.1,0.1-0.1,0.1-0.2c0.3-0.5,1-0.1,0.7,0.4c-1.2,2.1-3.7,3-4.8,5.1
                                c-0.1,0.1-0.1,0.2-0.2,0.2c0,1-0.1,2-0.1,3v0.1c0.7,0.9,1.4,1.7,2.2,2.6c0.4,0.4-0.2,1-0.6,0.6c-1.6-1.7-3-3.6-4.3-5.6
                                c-0.3-0.5,0.4-0.9,0.7-0.4c0.4,0.6,0.8,1.2,1.3,1.8c0.1-1.7,0.2-3.4-0.1-5.1c-0.3-1.7-1.2-3.2-1-5c0.2-1.6,0.7-3.1,1.4-4.6
                                c0.2-0.4,0.5-0.9,0.1-1.2c-0.3-0.2-0.7-0.2-1-0.2c-0.8,0-1.5,0.4-2.2,0.8c-1.3,0.9-2.3,2.3-3,3.7c0,0.1-0.1,0.1-0.1,0.1
                                c0.3,0.2,0.6,0.4,0.8,0.6c0.4,0.4-0.2,1-0.6,0.6c-1.4-1.2-3.2-1.9-4.9-2.6c-0.7-0.3-1.4-0.6-2.1-0.9c-0.8-0.4-1.4-0.9-2.2-1.4
                                c-1.4-1-3.4-1.8-5.2-1.6c-0.8,0.1-1.6,0.5-2.2,1.1c-0.2,0.2-0.7,0.7-0.5,1c0.1,0.3,0.9,0.4,1.2,0.5c0.1,0,0.2,0.1,0.2,0.1
                                s0.1,0,0.1,0.1c1.4,0.5,2.7,1.3,3.8,2.2c0.2-0.2,0.5-0.3,0.7-0.4c0.5-0.2,0.9,0.5,0.4,0.7c-0.6,0.2-1,0.6-1.4,1.1
                                c-0.3,0.4-0.5,1-0.8,1.4c0,0,0,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c-0.3,0.4-0.7,0.8-1.1,1.1c-0.5,0.4-0.9,0.9-1.3,1.5
                                c-1.2,2-2.7,4.5-2,6.9c0.3,1.1,1.1,2.1,1.9,3c0.8,0.8,1.7,1.6,2.6,2.2c3.5,2.1,7.8,2.5,10.9,5.4c0.4,0.4-0.2,1-0.6,0.6
                                c-1.9-1.7-4.3-2.5-6.6-3.4c-2.1-0.8-4.1-1.6-5.8-3.1c-1.7-1.5-3.4-3.6-3.4-5.9c0-1.1,0.3-2.1,0.8-3.1c-0.2,0.1-0.3,0.2-0.5,0.3
                                s-0.4,0.2-0.6,0.4c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0-0.1,0.1-0.1,0.1s0,0-0.1,0.1l0,0c-0.2,0.1-0.4,0.2-0.6,0
                                c-0.2-0.1-0.2-0.5,0-0.6c0.8-0.6,1.5-1.1,2.4-1.6c0.1-0.1,0.2-0.1,0.3,0c0.4-0.9,1-1.8,1.6-2.6c0.4-0.5,0.9-0.9,1.4-1.3
                                c0.2-0.1,0.3-0.3,0.4-0.4c-0.2-0.3-0.5-0.6-0.7-1c-0.3,0.4-0.7,0.7-1.1,0.9c-0.7,0.5-1.4,0.9-2.1,1.4s-1.4,1-2.1,1.5
                                c-0.6,0.5-1.4,1-1.6,1.8s0,1.7,0.2,2.6c0.1,0.9,0.3,1.8,0.5,2.7s0.3,1.7,0.5,2.6c0.1,0.3,0.1,0.6,0.2,0.9l0,0
                                c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.2,0.2-0.4,0.4-0.6c0.3-0.5,1,0,0.7,0.4c-0.3,0.5-0.8,1.8-1.6,1.5c-0.3-0.1-0.4-0.4-0.5-0.7
                                c-0.1-0.5-0.2-1.1-0.3-1.6c-0.2-1-0.4-1.9-0.6-2.9c0,0,0,0-0.1,0c-1.7,0.5-3.4,0.3-5.1,0.3c-3.7,0-7.4,0.5-11,1.3
                                c-1.7,0.4-3.6,0.8-5.2,1.6c-1.4,0.7-2.5,1.8-3.4,3s-1.6,2.6-2.3,3.9c-0.8,1.6-1.7,3.2-2.3,4.9c-1.4,3.3-2,6.8-2.6,10.4
                                c-0.3,1.7-0.6,3.4-1.5,4.9c-0.9,1.4-1.9,2.7-2.3,4.4c-0.3,1.5-0.2,3.1,0.5,4.4C1279.7,177.6,1281.1,174.9,1282.6,174.1z
                                M1282.6,168.9c3.2-1.6,6.8-1.9,10.3-2.1c0.6,0,0.5,0.8,0,0.9c-3.4,0.3-6.8,0.5-9.9,2C1282.6,169.9,1282.1,169.2,1282.6,168.9z"
                                />
                            <path name="LUIS" class="st0" d="M1369.3,198.4c-1.1-0.2-10.2-1.9-16.8-9.3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.4-0.6,1.6-0.6,2.1
                                c-0.1,0.6-1.6,4.7-2.8,7.2c1.2,0.2,2.2,0.8,3.3,1.4c1.2,0.7,2.5,1.3,3.9,1.6c1.7,0.4,3.2,0.1,4.8-0.4c2.5-0.8,5.1-2,7.7-0.7
                                c0.5,0.2,0.1,1-0.4,0.7c-1.2-0.6-2.4-0.6-3.7-0.3c-0.7,0.2-1.3,0.4-2,0.6c0.3,0.4,0.9,0.9,1.4,1c1.3,0.1,3.1,0.4,4.1,0.9
                                c0.8,0.4,2.5,0.6,3.8-0.8c1.3-1.4,2.2-2.4,2.2-3.7c-0.6,0.1-1.3,0.2-1.9,0.2C1371.3,199.2,1370.2,198.9,1369.3,198.4z"/>
                            <path name="LUIS" class="st0" d="M1328.4,201.4c4.9-0.5,9.8-1.2,14.7-2.2c0.4-0.1,0.9-0.2,1.3-0.3c0-0.2,0-0.4,0.1-0.6
                                c0.7-2.6,1.9-6.7,1.9-7.3c0-0.5-0.8-2.1-1.4-3.2c-4.3-0.8-14.1-2.7-19.3-3.9c-6.8-1.6-19.9-7.5-20-7.6c-0.1,0-0.1-0.1-0.1-0.1
                                c-2-2-8.1-4.1-11.2-4.7c-2.9-0.5-8.8,1.8-11.4,3.2c-1.4,0.8-3.1,4.1-3.6,5.4c0.1,0.2,0.1,0.4,0.2,0.6c0.4,1.8,0.3,3.6,0.4,5.4
                                c0.1,2,0.4,4.1,1.1,6c0.3,0.9,0.8,1.8,1.5,2.5c0.8,0.9,1.9,1.2,3,1.5c3.5,1,7,1.8,10.5,2.7c4.5,1.1,8.8,2.5,13.4,3.1
                                C1315.8,202.6,1322.1,202.1,1328.4,201.4z"/>
                            <path name="LUIS" class="st0" d="M1378,246.2c-0.1-0.9-0.1-1.8-0.3-2.7c-0.2-0.9-0.6-1.6-1.1-2.3c-0.9-1.3-1.9-2.4-2.4-4
                                c-0.4-1.4-0.5-3-0.4-4.5c0.1-3.1,0.7-6.5-0.7-9.4c-0.4-0.9-1-1.7-1.5-2.5l0,0c-0.3-0.5-0.7-1-1-1.5c-0.4-0.7-0.7-1.4-0.9-2.1
                                c-0.2-0.7-0.3-1.6-0.9-2.1c-0.4-0.4,0.2-1,0.6-0.6c0.6,0.6,0.8,1.4,1,2.3c0.2,0.9,0.6,1.7,1.1,2.5l0,0c0.2-5.1,1.4-10.3,0.3-15.2
                                c-0.7,0.5-1.5,0.7-2.3,0.7c-0.6,0-1.2-0.1-1.8-0.4c-0.7-0.4-2-0.7-3.8-0.9c-1.2-0.1-2.1-1.3-2.2-1.5c-0.5,0.2-1.1,0.4-1.6,0.5
                                c-1.5,0.4-3.1,0.4-4.6,0c-1.4-0.4-2.7-1-3.9-1.7c-1-0.6-2-1.1-3.1-1.3c-0.2,0.4-0.4,0.6-0.5,0.8c-0.7,0.8-1.2,1.1-1.8,1.1
                                c-0.3,0-0.6-0.1-1-0.2c-0.4-0.1-0.7-0.7-0.9-1.1V200c-0.1,0-0.2,0.1-0.4,0.1c-1.4,0.3-2.7,0.5-4.1,0.7
                                c-6.3,1.1-12.6,1.9-18.9,2.3c-5.3,0.3-10.7,0.2-15.9-1c-1.9-0.4-3.9-1-5.8-1.5h-0.1c1.1,4.3,0.8,8.7,0.4,13.1
                                c-0.2,2-0.3,4-0.3,6.1c-0.1,2.1-0.1,4.1-0.1,6.2c-0.1,4-0.7,8-1.6,11.9c-0.5,1.9-1,3.8-1.7,5.7c-0.6,1.8-1.4,3.6-1.7,5.5
                                c-0.3,1.9-0.2,3.8,0.6,5.5c0.7,1.5,1.8,2.9,2.9,4.1c0.4-0.3,0.9-0.6,1.4-0.9c0.3-0.1,0.8-0.2,1.1-0.2c0.5-0.1,1-0.1,1.4-0.2
                                c0.9-0.1,1.7-0.2,2.6-0.3c3.7-0.4,7.5-0.7,11.2-0.9c6.6-0.4,13.2-0.8,19.9-0.8c3.3,0,6.7,0.1,10,0.4c3.3,0.3,6.6,0.9,9.9,1.3
                                c6.3,0.7,12.7,0,19,1c0.8,0.1,1.6,0.3,2.3,0.4c0.7,0.2,1.6,0.3,2.3,0.6c0.4,0.2,0.6,0.8,0.8,1.1c0.5,0.8,1,1.5,1.5,2.3
                                c0.6-3.8-0.5-7.5-2-11C1378.7,249.6,1378.1,248,1378,246.2z M1300.7,235.4c2-1.8,4.2-3.5,6.4-5.1c7.9-5.8,16.4-10.9,23.2-18
                                c0.4-0.4,1,0.2,0.6,0.6c-1.9,2-3.9,3.8-6,5.5c-7.6,6.2-16.2,11.1-23.6,17.6C1300.9,236.4,1300.3,235.8,1300.7,235.4z M1325,251
                                c-2.1,0.2-4.2,0.5-6.3,0.6c-3.7,0.3-7.4,0.4-11.1,0.5c-1.8,0-3.5-0.1-5.3,0c-1.7,0-3.5,0.2-5.1,0.9c-0.5,0.2-0.9-0.5-0.4-0.7
                                c1.8-0.8,3.7-1,5.6-1c3.7,0,7.5,0.1,11.2-0.1c3.8-0.2,7.6-0.5,11.4-0.9C1325.5,250.1,1325.5,250.9,1325,251z M1332.7,219.3
                                c-2.9,1.8-5.8,3.6-8.7,5.4c-0.5,0.3-0.9-0.4-0.4-0.7c2.9-1.8,5.8-3.6,8.7-5.4C1332.8,218.3,1333.2,219,1332.7,219.3z
                                M1373.7,255.5c-2.5-0.7-5.1-1-7.7-1.3c-9.1-0.9-18-2.6-26.8-5.3c-0.5-0.2-0.3-1,0.2-0.8c2.5,0.8,5,1.5,7.5,2.1
                                c4.4,1.1,8.9,1.9,13.5,2.6c4.5,0.6,9.1,0.6,13.5,1.9C1374.4,254.9,1374.2,255.7,1373.7,255.5z"/>
                        </g>
                        <path name="LUIS" class="st1" d="M1344.8,128.2l-3.3,4.1l0.8,3.7C1342.3,136,1345.3,131.5,1344.8,128.2z"/>
                        <path name="LUIS" class="st3" d="M1334.3,152.6c-0.3,3.3-1,14.2-0.9,14.4c0.1,0.1,0.3,0.2,0.4,0.3c0.5,0.2,1,0.4,1.5,0.4
                            c0.4,0.1,0.9,0,1.2-0.3c0.2-0.2,0.7-8.4,0.8-9.5"/>
                        <g>
                            <path name="LUIS" class="st2" d="M1328.8,112.4c0.5-0.1,0.3-0.9-0.2-0.8c-0.4,0.1-0.7,0-1.1-0.1c-0.3-0.1-0.8-0.4-0.8-0.8s0.2-0.9,0.3-1.2
                                c0.1-0.4,0.2-0.8,0.3-1.2c0.5-1.7,1.1-3.4,1.9-5c0.2-0.5-0.5-0.9-0.7-0.4c-0.9,2-1.7,4.2-2.3,6.3c-0.1,0.5-0.3,1-0.3,1.5
                                c0,0.4,0.2,0.8,0.6,1.1C1327.1,112.3,1328,112.5,1328.8,112.4z"/>
                            <path name="LUIS" class="st2" d="M1324.4,118.9c1.8-1.7,4.4-1.8,6.8-1.8c2.6,0.1,5.4,0.4,7.9-0.6c0.5-0.2,0.3-1-0.2-0.8
                                c-4.8,1.9-10.8-1.2-15.1,2.6C1323.4,118.7,1324,119.3,1324.4,118.9z"/>
                            <path name="LUIS" class="st2" d="M1332.3,122.8C1332.3,122.8,1332.2,122.8,1332.3,122.8L1332.3,122.8c-0.2,0.1-0.3,0.2-0.3,0.2
                                c-0.2,0.1-0.3,0.1-0.4,0.1s-0.1,0-0.2,0.1h-0.1l0,0c-0.2,0-0.3,0-0.5,0s-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4
                                c0.7,0,1.4-0.2,2.1-0.5c0.2-0.1,0.3-0.4,0.2-0.6C1332.7,122.8,1332.5,122.7,1332.3,122.8z"/>
                            <path name="LUIS" class="st2" d="M1283.1,169.7c3.1-1.5,6.6-1.8,9.9-2c0.5,0,0.6-0.9,0-0.9c-3.5,0.3-7.1,0.6-10.3,2.1
                                C1282.1,169.2,1282.6,169.9,1283.1,169.7z"/>
                            <path name="LUIS" class="st2" d="M1302.3,252.1c1.8,0,3.5,0,5.3,0c3.7,0,7.4-0.2,11.1-0.5c2.1-0.2,4.2-0.4,6.3-0.6c0.5-0.1,0.6-0.9,0-0.9
                                c-3.8,0.4-7.6,0.8-11.4,0.9c-3.7,0.2-7.5,0.1-11.2,0.1c-1.9,0-3.8,0.2-5.6,1c-0.5,0.2-0.1,1,0.4,0.7
                                C1298.8,252.2,1300.6,252.1,1302.3,252.1z"/>
                            <path name="LUIS" class="st2" d="M1339.3,248.9c8.7,2.7,17.7,4.4,26.8,5.3c2.6,0.2,5.2,0.5,7.7,1.3c0.5,0.2,0.8-0.7,0.2-0.8
                                c-4.3-1.3-9-1.3-13.5-1.9c-4.5-0.6-9-1.5-13.5-2.6c-2.5-0.6-5-1.3-7.5-2.1C1339,247.9,1338.8,248.8,1339.3,248.9z"/>
                            <path name="LUIS" class="st2" d="M1301.3,236c7.4-6.5,16-11.4,23.6-17.6c2.1-1.7,4.2-3.6,6-5.5c0.4-0.4-0.2-1-0.6-0.6
                                c-6.8,7.1-15.4,12.2-23.2,18c-2.2,1.6-4.3,3.3-6.4,5.1C1300.3,235.8,1300.9,236.4,1301.3,236z"/>
                            <path name="LUIS" class="st2" d="M1324.1,224.7c2.9-1.8,5.8-3.6,8.7-5.4c0.5-0.3,0-1-0.4-0.7c-2.9,1.8-5.8,3.6-8.7,5.4
                                C1323.2,224.3,1323.6,225,1324.1,224.7z"/>
                            <path name="LUIS" class="st2" d="M1394.7,442.8c-0.7-1.7-0.5-2.8-1.6-4c-1-1.2-3.5-3.6-7.4-8.5c-2.3-2.9-2.6-5.6-3-5.9c0,0-0.1-0.8,0.1-1.7
                                c0,0,1.6,0.1,1.9-1.1s2.1-5.5,0.7-5.8c0,0,0.4-11.5,0.8-16.3s1.3-15,0.8-18.4c-0.4-3.4-1.2-9.2-0.7-13.3s0.8-10.1,0.7-11.4
                                c0,0-0.3-1.8-0.8-2.9c0,0,0.8-8.1-1-12.8c0,0,0.2-3-0.6-3.4c0,0,1.9-5.2,0.1-10.2c0,0,0.2-18.2,0.3-22.6
                                c0.1-4.4,0.1-17.1,0.1-18.8c-0.1-1.7-0.3-4.5-1-6.5s-1.6-4.5-1.5-6.1s-0.6-8.4-0.7-8.8c0,0-0.1-0.2-0.3-0.5
                                c0.2,0,0.3-0.1,0.3-0.3c0.9-3.6,0.2-7.4-1.1-10.8c-0.7-1.7-1.5-3.4-1.9-5.2s-0.1-3.6-0.8-5.3c-0.6-1.5-1.9-2.6-2.7-4.1
                                c-0.7-1.4-0.9-3.1-0.9-4.6c0-3.2,0.7-6.5-0.4-9.6c-0.4-1.3-1.2-2.4-1.9-3.5c0.1-5.7,1.7-11.5,0.2-17.1l0.1-0.1
                                c1.5-1.5,2.6-2.9,2.4-4.6c0.3-0.1,0.5-0.2,0.8-0.4c1.4-0.9,5.4-6.2,7-9.7c0.1-0.2,0.1-0.3,0.2-0.5c0,0,0.1,0,0.1-0.1
                                c1.9-2.6,3.2-5.6,3.4-8.8c0.1-1.5,0-3-0.3-4.4c-0.1-0.4-0.2-0.9-0.4-1.3l0,0c-0.6-2-0.3-4.2-1.2-6.1c-0.3-0.6-0.7-1.1-1.2-1.6
                                v-0.1c0.3-3.7-1-7.3-2.2-10.8c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2c-0.3-1.5-0.7-3.1-1.7-4.4c-1.4-1.9-3.6-3.3-5.7-4.2
                                c-1.9-0.9-3.9-1.4-6-1.6h-0.1c-1.2-0.8-2.6-1.3-3.9-1.8c-1.7-0.6-3.5-1.1-5.3-1.6c-1.3-0.3-3-0.5-4.1-1.4l0,0
                                c-0.6-0.7-1.3-1.4-2.2-1.6c0.1-0.8,0.1-1.5,0-2.3c-0.5-3.5-3.4-5.7-6.5-6.9c-0.1-0.5-0.2-1.1-0.1-1.6c1.7-3.3,2.6-7.1,3.1-10.3
                                l0,0c1.4-0.2,2.3-1.1,3-2.3c0.8-1.4,1.3-2.9,1.8-4.4c0.4-1.5,0.7-3.2,0.4-4.7c-0.2-1.2-0.9-2.3-2.3-2.2c0.2-1,0.8-6.1-1.1-11.1
                                c-2.1-5.6-2.3-6-3.8-6c0,0,0.5-4.8-3.6-6.5s-9.4-4.2-11.6-4.9s-6.6-1.7-9,0s-3.5,2.1-4,3.2s-1.5,1.9-2.6,3.2s-1.8,2.6-2.2,4.4
                                c-0.4,1.8-1.9,4.1-2.5,5s-1.2,4.8-0.9,7.6c0.1,1.3,0.8,4,1.6,6.1c-1,0.6-1,1.9-0.9,2.9c0.2,1.6,0.4,3.2,0.8,4.8
                                c0.6,2.3,1.4,5.5,3.9,6.3c0.1,0,0.1,0,0.2,0c0.6,2.2,1.3,4.3,2.2,5.7c-0.4,0.5-0.6,1.1-0.3,1.8c0.2,0.4,0.6,0.5,1.1,0.7
                                c0.2,0.1,0.4,0.1,0.7,0.2c0.2,0.4,0.6,1.4,1.2,2.4c-0.4,0.8-1.3,1.2-2.1,1.7c-0.9,0.6-1.8,1.2-2.7,1.8c-0.7,0.5-1.4,1-2,1.6
                                c-0.6,0.7-0.6,1.6-0.5,2.5c0.1,0.8,0.2,1.6,0.4,2.5h-0.1c-1.8,0.5-3.7,0.3-5.5,0.3c-1.9,0-3.8,0.2-5.6,0.4
                                c-1.8,0.2-3.6,0.5-5.4,0.9c-1.7,0.4-3.5,0.8-5.1,1.6c-2.7,1.4-4.2,4.1-5.6,6.8c-1.7,3.2-3.3,6.4-4.1,9.9c-0.4,1.8-0.7,3.5-1,5.3
                                s-0.4,3.6-1.4,5.2c-0.9,1.4-1.9,2.7-2.3,4.3c-0.4,1.5-0.4,3.2,0.1,4.7c0.2,0.6,0.5,1.1,0.7,1.8c0.3,1,0.4,2.1,0.4,3.1
                                c0.1,1.8,0.1,3.6,0.4,5.4c0.3,2,0.8,4.1,2.1,5.7c0.6,0.8,1.4,1.4,2.3,1.8c1.5,0.7,3.2,1,4.8,1.4c3.2,0.9,6.5,1.6,9.8,2.4
                                c0,0.1,0,0.1,0,0.2c1.1,4,0.8,8.1,0.6,12.1c-0.2,2-0.3,4.1-0.4,6.1c-0.1,2.1-0.1,4.2-0.1,6.3c-0.1,4.1-0.6,8.2-1.5,12.2
                                c-0.5,2-1,3.9-1.7,5.8c-0.7,1.9-1.4,3.7-1.8,5.6c-0.4,2-0.3,4,0.6,5.9c0.8,1.8,2,3.4,3.4,4.9c0.1,0,0.1,0.1,0.2,0.1
                                c-0.2,0.3-1.2,2.1-0.8,4.5c0.5,2.6,0.6,4.2,0.3,5.7c-0.3,1.5,0.2,5.4,0.1,7.6s-0.4,6.5-0.4,13.3s1.8,31.8,4.8,37.8
                                c0,0,0.4,14.2,1.7,18c1.4,3.8,0.6,3.9,0,6.4s0.2,2,0.8,4.2c0,0-1.4,3.8-0.9,6.3s0.4,10.5,0.9,19.1s0.5,20.1,1.8,22.4
                                c1.4,2.3,4.1,9.9,3.3,12.3c-0.7,2.5-1.2,2.7-0.7,3.6c0,0-1.7,1.4-1.4,2.6c0.4,1.2-0.2,3,1.7,3.8c2,0.9,2.6,1.9,2.1,3.3
                                c-0.5,1.5-2.2,6.4-4.9,9c-2.7,2.6-5.3,5.4-4.8,8.5c0,0-0.5,4.4,4.6,5.2c4.9,0.8,16.3-1.2,19.1-4.6c1.3-1.5,2.1-3.7,3.2-5
                                c1.2-1.2,3.1-1.4,2-7.5c0,0-0.9-6.3-1.4-9.2c0,0,1.9-0.2,3.5-0.8s3.4-1.5,3.4-3.4c0,0,0.2-2.6-1-3.3c0.2,0,0.7-0.5,1-8
                                c0.4-9-1.4-32.1-1.8-35.8c-0.3-3.8-0.5-4.9-0.1-6.8s0.2-6.2-1.2-9c0,0,2.1-6,0.3-9.9c0,0,1.5-4.7,1.7-7.3
                                c0.2-2.5-0.3-15.5,0.8-19.3c1-3.8,0.9-9.2,0.8-10.6s1.4-6.4,2.1-8.2c0.7-1.8,1.8-6.9,2.1-9c0.3-2,0.3-5.8,0.7-6.8
                                c0,0,1.8,2.6,1.6,4.9c-0.3,2.3-0.1,7.1,0.4,9.6s1.2,9.5,1.9,11.9c0.7,2.4,4.2,14,4.7,17.6s1.9,7.3,1.9,9.5s-0.7,4,0.8,5.4
                                c1.6,1.4,1.9,1.3,1.8,3.8s0.8,6.8,0.9,8c0.1,1.3,0.9,12.7,1.8,18.4s2.3,18.4,3.1,21.3c0.7,2.9,1.6,12.6,1.8,13.8
                                c0.2,1.2-0.1,1.8-0.6,2s-1.2,0.5-0.7,2s0.3,3.8,1.3,4.9s3.5,1,3.8,0.9s0,1,0,2s0.4,4.9,1.1,6.2s1.2,5.1,1,5.5
                                c-0.1,0.4-0.6,0.9-0.4,2.5c0.2,1.8,1.1,1.9,3.2,4c2.6,2.5,6.3,6.2,14.1,6.1c0,0,7.9-0.6,9-2.1
                                C1393.8,446.8,1395.4,444.5,1394.7,442.8z M1372.2,202.7c-1.3,1.4-3,1.2-3.8,0.8c-1-0.6-2.8-0.8-4.1-0.9c-0.6-0.1-1.1-0.6-1.4-1
                                c0.7-0.2,1.3-0.4,2-0.6c1.3-0.3,2.5-0.3,3.7,0.3c0.5,0.2,0.9-0.5,0.4-0.7c-2.6-1.3-5.2-0.1-7.7,0.7c-1.6,0.5-3.1,0.8-4.8,0.4
                                c-1.4-0.3-2.7-0.9-3.9-1.6c-1.1-0.6-2.1-1.2-3.3-1.4c1.2-2.4,2.8-6.6,2.8-7.2c0-0.5,0.4-1.7,0.6-2.1c0-0.1,0.1-0.2,0.1-0.3
                                c6.6,7.4,15.7,9.1,16.8,9.3c0.9,0.6,2,0.8,3.1,0.8c0.7,0,1.3-0.1,1.9-0.2C1374.4,200.2,1373.5,201.3,1372.2,202.7z M1345.1,130
                                c0.2,1,0.6,2,1,2.9c0,0.1,0.1,0.1,0.1,0.1c-1.1,1.2-2.6,2.1-3.7,3.4c0-0.5-0.1-1.1-0.2-1.6c-0.1-0.9-0.4-1.7-0.7-2.5
                                c1.2-1.1,2.3-2.4,3.2-3.9C1344.8,129,1345,129.5,1345.1,130z M1315,97.2c0.7-1.2,2.1-6.6,2.5-8c0.5-1.4,6.6-3.1,9.2-3.5
                                c2.7-0.3,6.7,0.1,9.8,0.8c3.1,0.7,5.5,0.8,5.9,1.1c0.4,0.3,2.1,5,2.3,6.8c0.2,1.8,0.9,3,1.4,3.7s1.2,3.6,1.3,4.7
                                c0.1,1.1-0.2,2.8,0,3.7c0.2,0.8,0.6,0.8,1.1,0.3c0.1-0.1,0.2-0.2,0.3-0.5l0.1-0.1c0.3-0.6,0.7-1.1,1.2-1.5s1.4-0.8,2-0.5
                                s0.8,1,0.8,1.6c0.1,0.8,0.1,1.7-0.1,2.5c-0.3,1.6-0.8,3.1-1.5,4.6c-0.5,1.2-1.2,2.8-2.7,3.1c0.5-3.7,0.4-6.4,0.4-6.7
                                c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.4,15.1-6.9,22.2c-0.2-0.8-0.1-1.6,0.1-2.5s0.5-1.9,0.9-2.8
                                c0.3-0.7,0.9-1.5,0.6-2.3c-0.6-1.5-2.9-0.9-3.9-0.3c-1.7,0.9-3.1,2.6-3.9,4.3c0,0.1,0,0.1-0.1,0.2c-1-0.6-2.2-1.1-3.3-1.5
                                c-0.9-0.4-1.8-0.7-2.7-1.2c-0.9-0.5-1.6-1.1-2.5-1.7c-1.6-1-3.8-1.7-5.6-1.2c-0.5,0.1-1.3,0.5-2,1c-2.6-4.5-4.2-16.6-4.2-16.7
                                l0,0c0.1-0.1,0.1-0.3,0.2-0.5C1315,105.9,1314.3,98.4,1315,97.2z M1312.9,111.9c-0.3-1.3-0.6-2.6-0.8-4c-0.1-0.8-0.4-2.2,0.3-2.9
                                c0.5,1.1,1,1.9,1.5,2.1c0.2,1.4,0.9,5.9,1.9,10.2C1314,116.4,1313.4,113.7,1312.9,111.9z M1322.6,129.9
                                C1322.6,130,1322.6,130,1322.6,129.9c-0.7-0.8-1.3-2-1.7-2.9c0.9,0.4,1.8,1,2.6,1.6C1323.2,129,1322.9,129.5,1322.6,129.9z
                                M1278.6,174.6c0.3-1.7,1.4-2.9,2.3-4.4s1.2-3.1,1.5-4.9c0.5-3.5,1.2-7.1,2.6-10.4c0.7-1.7,1.5-3.3,2.3-4.9
                                c0.7-1.4,1.4-2.7,2.3-3.9c0.9-1.3,2-2.4,3.4-3c1.6-0.8,3.5-1.2,5.2-1.6c3.6-0.8,7.3-1.2,11-1.3c1.7,0,3.5,0.1,5.1-0.3
                                c0,0,0,0,0.1,0c0.2,1,0.4,1.9,0.6,2.9c0.1,0.5,0.2,1.1,0.3,1.6c0.1,0.3,0.2,0.6,0.5,0.7c0.8,0.3,1.3-1,1.6-1.5
                                c0.3-0.5-0.4-0.9-0.7-0.4c-0.1,0.2-0.2,0.4-0.4,0.6c-0.1,0.1-0.1,0.2-0.2,0.3l0,0c-0.1-0.3-0.1-0.6-0.2-0.9
                                c-0.2-0.9-0.3-1.7-0.5-2.6c-0.2-0.9-0.3-1.8-0.5-2.7c-0.1-0.8-0.3-1.7-0.2-2.6c0.2-0.8,0.9-1.3,1.6-1.8s1.4-1,2.1-1.5
                                s1.4-0.9,2.1-1.4c0.4-0.3,0.8-0.6,1.1-0.9c0.2,0.4,0.5,0.7,0.7,1c-0.1,0.1-0.2,0.3-0.4,0.4c-0.5,0.4-1,0.8-1.4,1.3
                                c-0.6,0.8-1.1,1.7-1.6,2.6c-0.1,0-0.2,0-0.3,0c-0.8,0.5-1.6,1-2.4,1.6c-0.2,0.1-0.2,0.5,0,0.6c0.2,0.2,0.4,0.1,0.6,0l0,0
                                c0,0,0,0,0.1-0.1l0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.3-0.2,0.5-0.3c-0.4,1-0.8,2-0.8,3.1
                                c0,2.4,1.7,4.4,3.4,5.9c1.7,1.5,3.8,2.4,5.8,3.1c2.3,0.9,4.8,1.7,6.6,3.4c0.4,0.4,1-0.2,0.6-0.6c-3.1-2.8-7.4-3.2-10.9-5.4
                                c-1-0.6-1.8-1.3-2.6-2.2c-0.8-0.9-1.6-1.8-1.9-3c-0.7-2.4,0.8-4.9,2-6.9c0.4-0.6,0.8-1.1,1.3-1.5c0.4-0.3,0.8-0.7,1.1-1.1
                                c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0.3-0.5,0.4-1,0.8-1.4c0.4-0.5,0.9-0.8,1.4-1.1c0.5-0.2,0.1-0.9-0.4-0.7
                                c-0.3,0.1-0.5,0.2-0.7,0.4c-1.2-0.9-2.4-1.7-3.8-2.2c0,0-0.1,0-0.1-0.1c-0.1,0-0.2-0.1-0.2-0.1c-0.3-0.1-1-0.2-1.2-0.5
                                c-0.2-0.4,0.2-0.8,0.5-1c0.6-0.6,1.4-1,2.2-1.1c1.8-0.2,3.8,0.6,5.2,1.6c0.7,0.5,1.4,1,2.2,1.4c0.7,0.3,1.4,0.7,2.1,0.9
                                c1.7,0.7,3.5,1.3,4.9,2.6c0.4,0.4,1-0.2,0.6-0.6c-0.3-0.2-0.5-0.4-0.8-0.6c0.1,0,0.1-0.1,0.1-0.1c0.7-1.4,1.7-2.8,3-3.7
                                c0.6-0.4,1.4-0.8,2.2-0.8c0.3,0,0.7,0,1,0.2c0.4,0.3,0.1,0.9-0.1,1.2c-0.7,1.4-1.3,3-1.4,4.6c-0.2,1.8,0.7,3.3,1,5
                                s0.2,3.4,0.1,5.1c-0.4-0.6-0.9-1.2-1.3-1.8c-0.3-0.5-1,0-0.7,0.4c1.3,2,2.8,3.8,4.3,5.6c0.4,0.4,1-0.2,0.6-0.6
                                c-0.8-0.8-1.5-1.7-2.2-2.6v-0.1c0.1-1,0.1-2,0.1-3c0.1,0,0.2-0.1,0.2-0.2c1.2-2.1,3.7-2.9,4.8-5.1c0.3-0.5-0.5-0.9-0.7-0.4
                                c0,0.1-0.1,0.1-0.1,0.2c-0.3-0.8-0.6-1.7-0.8-2.6c0,0,0,0,0-0.1c2.7,1.2,5.2,3.2,5.5,6.4c0.1,1.7-0.3,3.3-0.6,5
                                c-0.3,1.2-0.4,2.5-0.7,3.7c-0.3-1.3-0.8-2.5-1.7-3.6c-1.1-1.3-2.8-2.1-4.4-2.7c-0.5-0.2-0.7,0.6-0.2,0.8c1.5,0.6,3,1.2,4,2.5
                                c1.1,1.3,1.4,3,1.8,4.6c0.1,0.4,0.6,0.4,0.8,0.1c0.8-1.7,0.9-3.6,1.3-5.4c0.2-0.8,0.4-1.6,0.5-2.4c0.7,0.2,1.2,0.7,1.6,1.2
                                c0,0.1,0.1,0.1,0.1,0.1l0,0c0.3,0.3,0.5,0.7,0.8,1c0.9,1.1,2,2.1,3.2,2.9c0.5,0.3,0.9-0.4,0.4-0.7c-0.9-0.6-1.7-1.3-2.5-2.1
                                c0.6,0.2,1.2,0.4,1.9,0.5c1.7,0.4,3.4,0.9,5.1,1.4c1.7,0.6,3.4,1.2,4.9,2.2s2.8,2.2,4.1,3.4c0.4,0.4,1-0.2,0.6-0.6
                                c-1-0.9-2-1.9-3.1-2.7c2.7,0.5,5.2,1.6,7.4,3.2c0.7,0.6,1.4,1.2,2,1.9c0.8,1,1.2,2.4,1.5,3.6c0.5,2.2,0.5,4.4,0.5,6.7
                                c0,0.6,0.9,0.6,0.8,0c0-0.8,0-1.6-0.1-2.4c0.7,2.2,1.2,4.5,1.1,6.8c-0.1-0.1-0.3-0.2-0.4-0.3c-1-0.8-2.1-1.5-3.4-1.4
                                c-0.5,0.1-0.6,0.9,0,0.9c1.2-0.1,2.3,0.7,3.2,1.5c0.8,0.7,1.7,1.4,2,2.4c0.4,1.2,0.5,2.4,0.7,3.6c-0.3-0.3-0.6-0.6-1-0.9
                                c-1.5-1.4-2.8-2.9-4.1-4.5c-0.3-0.4-0.9,0.2-0.6,0.6c1.1,1.3,2.1,2.6,3.3,3.7c0.6,0.6,1.2,1.1,1.8,1.6c0.6,0.6,1,1.3,1.2,2.1
                                c0.8,2.8,0.5,5.8-0.4,8.5c-0.3,0.9-0.7,1.8-1.2,2.6c0.4-2.8,0.1-5.8-0.2-7.3c-0.4-1.8-6.3-6-8.3-6.3c-1.9-1.6-4.6-3.5-7.3-5.4
                                c-1.2-0.8-2.3-1.6-3.5-2.5c-0.6-0.4-1.3-1-2-1.7c0.5-1.9,2.1-3.2,3.2-4.7c0.1-0.2,0-0.5-0.2-0.6s-0.4,0-0.6,0.1
                                c-1.1,1.5-2.5,2.8-3.1,4.5c-0.7-0.6-1.4-1.3-2.1-2c0-0.1,0-0.1,0-0.1c0.1-2.8,0.1-5.6-0.2-8.3c-0.1-0.5-0.9-0.6-0.8,0
                                c0.2,2.6,0.3,5.1,0.2,7.7c-4.3-4.4-9.2-9.9-11.6-12.6c-1.5-1.7-1.5-1.7-1.6-1.8c-0.2-0.1-0.5-0.1-0.6,0.1
                                c-0.1,0.2-0.1,0.4,0.1,0.6c0.1,0.1,0.7,0.8,1.5,1.7c3,3.4,10.2,11.5,15,15.9c0,0.1,0.1,0.1,0.2,0.1c0.8,0.7,1.6,1.4,2.2,1.8
                                c1.1,0.8,2.3,1.6,3.5,2.5c4.3,3,8.7,6.1,9.7,8.1c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.1,0,0.2-0.1c0.2-0.1,0.3-0.4,0.2-0.6
                                c-0.2-0.4-0.5-0.9-1-1.3c2.3,1.3,5.5,3.8,5.7,4.9c0.4,2,0.9,6.5-0.6,9.8c-1.6,3.5-5.5,8.6-6.6,9.3s-4.1,1.2-5.7,0
                                c-0.1,0-0.1-0.1-0.2-0.1s-9.9-1.5-16.7-9.4c0-0.2-0.1-0.5-0.2-0.9c-0.4-1.7-1.7-3.1-2-3.4c-0.5-0.9-7-13.1-9-17.4
                                c-1-2.2-2.6-5.6-4-8.5c0,0,0-0.1-0.1-0.1c-1.2-2.5-2.2-4.5-2.6-4.9c-0.1-0.1-0.2-0.2-0.4-0.1v-0.1c0.1-0.6-0.8-0.5-0.8,0
                                c-0.4,4.2-0.7,8.5-0.9,12.7c0,0.5-0.1,1.1,0,1.6c0,0.4,0.3,0.6,0.7,0.8c0.7,0.3,1.6,0.6,2.4,0.5c0.9-0.2,0.9-0.7,1-1.5
                                c0.1-0.9,0.2-1.8,0.2-2.7c0.1-1.4,0.2-2.9,0.3-4.4c1.1,2.3,2.3,4.9,3.3,7.1c1.7,3.8,6.8,13.5,8.5,16.6c-0.9-0.2-1.7-0.2-2.3,0.1
                                c-1.1,0.5-1.6,1.6-1.8,2.1c-0.2,0.6-0.3,1.2-0.4,1.5c-4.4-0.9-13.9-2.7-19-3.9c-6.3-1.5-18.2-6.8-19.7-7.4c0-0.1,0-0.1,0-0.2
                                c-0.6-3.6-1.3-7.1-1.3-10.8c0-1.2,0.1-2.4,0.2-3.5c0-0.1,0-0.1,0-0.1c0.2-2.5,0.6-4.9,0.8-7.4c0-0.6-0.8-0.5-0.8,0
                                c-0.1,1.6-0.3,3.3-0.5,4.9c-0.2-0.9-0.4-1.7-0.7-2.6c-0.2-0.8-0.4-1.6-0.7-2.4c-0.3-0.9-0.5-1.7-1.1-2.4c-0.4-0.4-1,0.2-0.6,0.6
                                c0.6,0.6,0.8,1.5,1,2.3s0.4,1.6,0.7,2.4c0.4,1.5,0.8,3,1.1,4.6c-0.1,1.3-0.2,2.6-0.2,3.9c0,3.4,0.6,6.7,1.2,10
                                c-2.8-2-8.2-3.7-10.7-4.1c-3.3-0.6-9.5,1.9-11.9,3.3c-1.5,0.9-2.9,3.6-3.6,5C1278.4,177.7,1278.3,176.1,1278.6,174.6z
                                M1333.8,166.8L1333.8,166.8c0-0.6,0-1.1,0.1-1.7c0.1-2,0.2-4.1,0.4-6.1c0.1-1.8,0.2-3.6,0.4-5.4c0.4,0.7,1.2,2.3,2.2,4.4
                                c-0.1,1.5-0.2,3.1-0.3,4.6c-0.1,1-0.2,1.9-0.2,2.9c0,0.4-0.1,0.7-0.1,1.1c0,0.2,0,0.4-0.1,0.6c-0.2,0.3-1.2,0-1.6-0.1
                                c-0.2-0.1-0.4-0.2-0.7-0.2C1333.8,166.8,1333.8,166.8,1333.8,166.8z M1285.7,196.1c-1.1-0.3-2.2-0.7-3-1.5
                                c-0.7-0.7-1.2-1.6-1.5-2.5c-0.7-1.9-1-3.9-1.1-6c-0.1-1.8-0.1-3.6-0.4-5.4c0-0.2-0.1-0.4-0.2-0.6c0.6-1.3,2.2-4.6,3.6-5.4
                                c2.5-1.4,8.4-3.7,11.4-3.2c3.1,0.5,9.1,2.6,11.2,4.7l0.1,0.1c0.1,0.1,13.2,6,20,7.6c5.2,1.2,14.9,3.1,19.3,3.9
                                c0.6,1.1,1.4,2.8,1.4,3.2c0,0.6-1.2,4.7-1.9,7.3c-0.1,0.2-0.1,0.4-0.1,0.6c-0.4,0.1-0.8,0.2-1.3,0.3c-4.8,0.9-9.8,1.6-14.7,2.2
                                c-6.2,0.7-12.6,1.2-18.8,0.4c-4.6-0.6-8.9-2-13.4-3.1C1292.7,198,1289.1,197.2,1285.7,196.1z M1294.8,254.4
                                c-0.8-1.8-1-3.6-0.6-5.5c0.3-1.9,1.1-3.7,1.7-5.5c0.7-1.9,1.2-3.8,1.7-5.7c0.9-3.9,1.5-7.9,1.6-11.9c0.1-2.1,0.1-4.1,0.1-6.2
                                c0.1-2,0.2-4,0.3-6.1c0.3-4.4,0.6-8.8-0.4-13.1h0.1c1.9,0.5,3.8,1.1,5.8,1.5c5.2,1.1,10.6,1.3,15.9,1c6.3-0.4,12.7-1.2,18.9-2.3
                                c1.4-0.2,2.7-0.5,4.1-0.7c0.1,0,0.2-0.1,0.4-0.1v0.1c0.2,0.4,0.5,0.9,0.9,1.1c0.3,0.1,0.7,0.2,1,0.2c0.6,0,1.1-0.3,1.8-1.1
                                c0.2-0.2,0.3-0.5,0.5-0.8c1.1,0.2,2.1,0.7,3.1,1.3c1.3,0.7,2.6,1.3,3.9,1.7c1.5,0.4,3.1,0.4,4.6,0c0.5-0.1,1.1-0.3,1.6-0.5
                                c0.1,0.2,1,1.4,2.2,1.5c1.8,0.2,3.1,0.5,3.8,0.9c0.5,0.3,1.1,0.4,1.8,0.4c0.8,0,1.5-0.2,2.3-0.7c1.2,5,0,10.2-0.3,15.2l0,0
                                c-0.5-0.8-0.9-1.6-1.1-2.5c-0.2-0.8-0.4-1.6-1-2.3c-0.4-0.4-1,0.2-0.6,0.6c0.6,0.6,0.7,1.4,0.9,2.1c0.2,0.7,0.5,1.4,0.9,2.1
                                c0.3,0.5,0.6,1,1,1.5l0,0c0.6,0.8,1.1,1.6,1.5,2.5c1.4,2.9,0.8,6.3,0.7,9.4c0,1.5,0,3.1,0.4,4.5c0.5,1.5,1.4,2.7,2.4,4
                                c0.5,0.7,0.9,1.4,1.1,2.3c0.2,0.9,0.2,1.8,0.3,2.7c0.2,1.8,0.7,3.4,1.4,5c1.4,3.5,2.6,7.2,2,11c-0.5-0.8-1-1.5-1.5-2.3
                                c-0.2-0.3-0.5-0.9-0.8-1.1c-0.7-0.3-1.6-0.5-2.3-0.6c-0.8-0.2-1.6-0.3-2.3-0.4c-6.3-0.9-12.7-0.3-19-1c-3.3-0.4-6.6-1-9.9-1.3
                                s-6.7-0.4-10-0.4c-6.6,0-13.2,0.4-19.9,0.8c-3.8,0.2-7.5,0.5-11.2,0.9c-0.9,0.1-1.7,0.2-2.6,0.3c-0.5,0.1-1,0.1-1.4,0.2
                                s-0.8,0.1-1.1,0.2c-0.5,0.2-0.9,0.6-1.4,0.9C1296.6,257.3,1295.6,256,1294.8,254.4z"/>
                        </g>
                        <path name="LUIS" class="st3" d="M1300.1,273c1.9-0.2,3.8-0.5,5.5-1.3c1.4-0.6,2.6-1.6,3.6-2.8c1.9-2.4,2.8-5.6,2.8-8.7c0-0.5-0.9-0.5-0.8,0
                            c0,2.9-0.8,5.8-2.6,8.1c-0.9,1.1-2,2-3.2,2.6c-1.6,0.8-3.5,1-5.2,1.2C1299.5,272.1,1299.5,273,1300.1,273L1300.1,273z"/>
                        <path name="LUIS" class="st3" d="M1335.5,263.4c0,1.6,0.2,3.2,0.4,4.8c0.2,1.5,0.2,3.1,0.3,4.7c0.2,3,0.4,6,1,8.9c0.3,1.6,0.7,3.3,1.5,4.8
                            c0.4,0.8,0.9,1.5,1.6,2c0.4,0.3,0.9,0.4,1.4,0.5s0.8-0.7,0.2-0.8c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.3-0.1-0.5-0.2
                            c-0.3-0.2-0.4-0.3-0.7-0.5c-0.3-0.3-0.5-0.6-0.7-1c-0.7-1.3-1.1-2.8-1.4-4.3c-0.6-2.7-0.8-5.5-1-8.2c-0.1-1.8-0.1-3.5-0.3-5.3
                            c-0.2-1.7-0.4-3.4-0.4-5.1C1336.3,262.8,1335.4,262.8,1335.5,263.4L1335.5,263.4z"/>
                        <path name="LUIS" class="st3" d="M1361.2,259.9c0.5,2.8,2.1,5.3,4.2,7.1c2.4,2,5.4,3.1,8.4,3.6c1.9,0.3,3.8,0.3,5.6,0.1c0.5,0,0.6-0.9,0-0.9
                            c-3.9,0.3-8.1-0.1-11.6-2.2c-3-1.7-5.3-4.6-5.9-8C1361.9,259.1,1361.1,259.3,1361.2,259.9L1361.2,259.9z"/>
                        <path name="LUIS" class="st3" d="M1366.5,260.7c0.2,0.3,0.2,0.7,0.3,1.1c0,0.5,0.1,0.9,0.1,1.4c0.1,0.9,0.2,1.9,0.2,2.8c0.1,0.5,0.9,0.5,0.8,0
                            c-0.1-1-0.2-2-0.2-3c-0.1-0.9,0-1.9-0.5-2.7C1367,259.8,1366.2,260.3,1366.5,260.7L1366.5,260.7z"/>
                        <path name="LUIS" class="st3" d="M1371,261.6c1.4,0,2.7-0.2,4-0.5c0.2,0,0.4-0.3,0.3-0.5s-0.3-0.3-0.5-0.3c-1.3,0.2-2.5,0.4-3.8,0.4
                            C1370.4,260.8,1370.4,261.7,1371,261.6L1371,261.6z"/>
                        <path name="LUIS" class="st3" d="M1313.7,258c0,0.5,0,1.1,0.2,1.6s0.5,0.8,1,1s1.2,0.1,1.7,0c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.4-0.7
                            c0.1-0.6,0.1-1.3,0.1-1.9c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4c0,0.3,0,0.6,0,0.9c0,0.1,0,0.3,0,0.4s0,0.2,0,0.2v0.1
                            c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0,0,0h-0.1c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.5,0.1-0.8,0.1c-0.1,0-0.3,0-0.4,0s-0.1,0-0.2,0h-0.1
                            c-0.2-0.1-0.3-0.2-0.4-0.4s-0.2-0.4-0.2-0.6c0-0.3,0-0.6,0-0.9c0-0.2-0.2-0.4-0.4-0.4C1313.9,257.6,1313.7,257.8,1313.7,258
                            L1313.7,258z"/>
                        <path name="LUIS" class="st3" d="M1326.1,257.8c0.1,0.1,0.2,0.4,0.3,0.6c0.2,0.3,0.4,0.5,0.7,0.5c0.3,0.1,0.6,0.1,1,0.1s0.8,0,1.2,0
                            c0.7,0,1.4,0.1,2-0.2c0.5-0.3,0.8-0.9,0.8-1.5c0-0.5-0.9-0.5-0.8,0c0,0.4-0.2,0.8-0.6,0.9c-0.3,0.1-0.6,0-0.9,0s-0.6,0-0.9,0
                            s-0.6,0-0.9,0c-0.2,0-0.5,0-0.7-0.1c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.1-0.5,0-0.6,0.1
                            C1325.8,257.4,1325.9,257.7,1326.1,257.8L1326.1,257.8z"/>
                        <path name="LUIS" class="st3" d="M1338.9,256.6c-0.3,0.5,0,1.2,0.5,1.4c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.7,0.1,1.1,0.1
                            c0.7,0.1,1.4,0.1,2.1,0.1s1.3-0.5,1.2-1.2c0-0.2-0.3-0.4-0.5-0.3s-0.3,0.3-0.3,0.5c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1
                            c-0.2,0-0.4,0-0.6,0c-0.5,0-0.9,0-1.4-0.1c-0.2,0-2.1-0.2-1.9-0.5C1339.9,256.6,1339.2,256.2,1338.9,256.6L1338.9,256.6z"/>
                        <path name="LUIS" class="st3" d="M1310.2,421.4c3.9-1.2,7.8-2.7,11.4-4.6c0.5-0.2,0.1-1-0.4-0.7c-3.6,1.8-7.4,3.3-11.2,4.5
                            C1309.5,420.7,1309.7,421.6,1310.2,421.4L1310.2,421.4z"/>
                        <path name="LUIS" class="st0" d="M1302.6,449.1c4.3,1.2,8.8,0.9,13.1-0.1c1.9-0.5,3.9-0.9,5.6-1.9c0.8-0.5,1.5-1.1,2-1.8
                            c0.6-0.9,1.1-1.9,1.6-2.8c1.2-2.1,2.7-4,4.4-5.8c0.4-0.4-0.2-1-0.6-0.6c-1.4,1.5-2.6,3-3.7,4.7c-0.6,0.9-1.1,1.7-1.5,2.6
                            c-0.5,0.9-1,1.8-1.8,2.5c-1.4,1.2-3.4,1.7-5.2,2.1c-2.1,0.5-4.2,0.9-6.4,1.1c-2.4,0.1-4.9,0-7.2-0.7
                            C1302.3,448.1,1302.1,449,1302.6,449.1L1302.6,449.1z"/>
                        <path name="LUIS" class="st0" d="M1312.3,427.3c0.2-1.6,1.8-2.3,3.2-2.6c1.6-0.4,3.4-0.6,5.1-0.6c1.4,0,2.3,0.8,3.4,1.6c0.9,0.7,1.8,1.3,3,1.3
                            c0.6,0,0.6-0.9,0-0.9c-1.3,0-2.4-1.1-3.4-1.9c-0.5-0.4-1-0.7-1.6-0.9c-0.8-0.3-1.8-0.2-2.6-0.2c-1.7,0.1-3.6,0.3-5.2,0.9
                            c-1.4,0.5-2.5,1.6-2.7,3.1C1311.4,427.8,1312.3,427.8,1312.3,427.3L1312.3,427.3z"/>
                        <path name="LUIS" class="st0" d="M1368.9,437.9c2.8,2.5,5.3,5.4,8.8,6.9c3.4,1.4,7.2,1.3,10.8,0.5c2-0.4,3.8-1.2,5.6-2.3c0.5-0.3,0-1-0.4-0.7
                            c-1.5,0.9-3.1,1.6-4.8,2c-1.5,0.4-3.1,0.6-4.6,0.6c-1.9,0.1-3.8-0.2-5.5-0.8c-1.7-0.6-3.2-1.6-4.6-2.8c-1.6-1.3-2.9-2.8-4.5-4.1
                            C1369,436.9,1368.4,437.5,1368.9,437.9L1368.9,437.9z"/>
                        <path name="LUIS" class="st0" d="M1367.7,425.1c0.9,0.3,2.2,0.3,3.2,0.2c1.2-0.1,2-0.9,2.8-1.7c0.5-0.5,1-0.7,1.6-0.7c0.8-0.1,1.7-0.1,2.5-0.1
                            c0.4,0,0.8,0,1.3,0c0.3,0,0.8,0,1.1,0.1c0.6,0.3,0.8,1.1,1.1,1.6c0.3,0.5,1,0,0.7-0.4c-0.4-0.6-0.6-1.3-1.1-1.8
                            c-0.6-0.5-1.3-0.5-2-0.5c-0.9,0-1.8,0-2.7,0.1c-0.7,0-1.4,0-2.1,0.3c-1.2,0.5-1.7,1.8-2.9,2.1c-0.5,0.1-1.1,0.1-1.7,0.1
                            c-0.5,0-1.1,0-1.6-0.2C1367.4,424.1,1367.2,424.9,1367.7,425.1L1367.7,425.1z"/>
                        <path name="LUIS" class="st3" d="M1334.1,155.1L1334.1,155.1c-0.5,0-0.8-0.4-0.8-0.9c0-0.2,0-5.9,3.7-8.7s7-3.2,9.8-1.2
                            c0.4,0.3,0.5,0.8,0.2,1.2s-0.8,0.5-1.2,0.2c-1.4-1-3.7-1.9-7.8,1.2c-3,2.3-3,7.3-3,7.3C1335,154.7,1334.6,155.1,1334.1,155.1z"/>
                        <g>
                            <path name="LUIS" class="st3" d="M1345.6,187.9c0,0,0.2-1.6,0.4-2.6c0.3-1,2.4-2.6,4.4-0.6c0,0,2.3,2.5,1.4,4.8c0,0-0.5,1.4-0.6,2
                                s-2.6,7.1-3.5,8.1s-1.4,1.1-2.1,0.8c-0.4-0.1-1.1-1-0.7-2.1c0,0,1.9-6.4,2-7.1S1345.6,187.9,1345.6,187.9z"/>
                            
                                <ellipse name="LUIS" transform="matrix(0.7781 -0.6281 0.6281 0.7781 182.0111 888.9547)" class="st0" cx="1349.3" cy="186.9" rx="0.9" ry="2"/>
                        </g>
                    </g>
                </g>
                <g id="JUAN" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="JUAN" class="st0" d="M1172.1,102.7c0,2.6,0.4,6.3,1.8,7c-0.7-3.4-1.1-6.1-1.2-7.1C1172.5,102.7,1172.3,102.7,1172.1,102.7z"/>
                            <path name="JUAN"  class="st0" d="M1182.4,127.3c1.9,2.2,5.3,6.3,9,6.7c2.3,0.2,8.9-3.7,13.2-12.1c0,0,0,0,0-0.1c0.6-1.2,1.2-2.5,1.7-3.8
                                c0,0,1.1-4,1.5-6.6c0-0.2,0.3-0.4,0.5-0.3c0.2,0,0.3,0.2,0.3,0.3c0.1,0,0.1,0.1,0.2,0.1c0.9,0.9,2.2,0.8,3.2,0.2
                                c1.1-0.7,1.7-1.9,2.1-3.1c0.5-1.3,0.9-2.7,1.4-4.1c0.4-1.2,0.8-2.6,0.3-3.9c-2.2,0.6-3.8-0.3-3.8-0.3c0.5,0.5,0.6,3.2,0.6,3.2
                                c-0.9-1-3.1-1.3-3.1-1.3c-0.5,0.6-1.9,4.8-1.9,4.8c-1-1.6-1.4-5.9-1.5-7.2c-0.3,0-0.6,0-0.9,0c-1.9-0.1-3.6-1.1-5.2-1.9
                                c-0.1-0.1-0.3-0.1-0.4-0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-6.1,0.8-7.3-1.4-8.5-3.4c-0.3-0.6-0.7-1.2-1.1-1.6
                                c-5.1-0.4-6.2,0.2-7.2,2.4c-1.1,2.1-3.7,4.6-6.4,6.2c-0.8,0.5-1.7,0.8-2.6,1c0.2,2.1,2.2,17.6,8,23.9
                                C1181.8,126.6,1182.1,126.9,1182.4,127.3z"/>
                            <path name="JUAN"  class="st0" d="M1181.6,94.8c1.3-2.9,2.9-3.5,8.4-3c0.1,0,0.2,0.1,0.3,0.1c0.6,0.6,1,1.3,1.4,2c1,1.7,1.9,3.3,6.2,3
                                c-0.2-0.1-0.5-0.2-0.7-0.4c-0.6-0.3-1.1-0.8-1-1.5c0.2-1.2,1.3-1.6,2.4-1.7c-1.7-0.4-3.5-0.6-5.3-0.5c-0.6,0-0.6-0.8,0-0.8
                                c2.4-0.1,4.8,0.2,7.1,1c0.1,0.1,0.2,0.1,0.3,0.2c1.8-0.1,3.6-0.1,5.3-0.7c1.7-0.6,2.9-1.8,3.9-3.3c0.3-0.5,0.6-0.9,0.9-1.4
                                c0.3-0.9,0.6-1.8,0.7-2.8c0.2-1,0.1-2.1,0.1-3.1c0-0.4,0-0.8-0.1-1.1c-0.2,0.7-0.4,1.4-0.7,2c-0.2,0.5-0.4,0.9-0.7,1.4
                                c-0.2,0.3-0.4,0.7-0.8,0.9c-0.3,0.2-0.5,0.1-0.8,0c-0.2,0.3-0.4,0.6-0.6,0.8c-2.7,2.8-8.4,3.3-13.3,3.8l-1.3,0.1
                                c-3.4,0.3-5.3-0.3-6.7-0.7c-0.8-0.2-1.4-0.4-2.1-0.4c-0.3,0-0.6,0-1,0c-1.3,0-2.5,0-3.4,0.8l-0.3,0.2c-1.1,0.9-1.9,1.6-4.6,2
                                c-1.5,0.2-4.7,0.3-7.7,0.3c-2.6,0-4.9-0.1-5.9-0.4c0,0-0.3-0.1-0.8-0.3c1.3,2,3.6,4.9,6.3,6.9c0.1,0.1,0.2,0.3,0.2,0.4
                                s0,2.5,2.3,3c0,0,3.3,0.4,5.8-1.1C1178.1,99,1180.6,96.7,1181.6,94.8z M1179.7,92.7c2-1.6,4.4-2.4,6.9-2.3c0.6,0,0.6,0.9,0,0.8
                                c-2.3-0.1-4.5,0.6-6.3,2.1C1179.8,93.6,1179.2,93,1179.7,92.7z M1174.9,96.6c-1.8,1.3-3.9,2.1-6.1,2.4c-0.2,0-0.5-0.1-0.5-0.3
                                c-0.1-0.2,0.1-0.5,0.3-0.5c2.1-0.3,4.2-1.1,5.9-2.3C1175,95.6,1175.4,96.3,1174.9,96.6z"/>
                            <path name="JUAN"  class="st0" d="M1177.4,141.5c1.3,0.9,2.7,1.7,4.2,2.3c1.7,0.6,3.4,0.9,5.1,1c3.3,0.2,6.6,0,9.5-1.6c5.4-3,8.1-8.8,10.9-14
                                c-0.1-0.1-0.2-0.2-0.2-0.3c-0.2-0.3-0.4-0.7-0.6-1c-0.3-0.7-0.6-1.3-0.8-2c-0.3-0.8-0.6-1.7-0.7-2.5
                                c-4.2,7.6-10.3,11.5-13.1,11.5c-0.1,0-0.2,0-0.3,0c-4.1-0.4-7.7-4.7-9.6-7c-0.3-0.3-0.5-0.6-0.7-0.8c-0.6,2-1.8,3.7-3.4,5.1
                                c-1.7,1.4-3.7,2.5-5.7,3.4C1173.3,137.9,1175.1,139.9,1177.4,141.5z"/>
                            <path name="JUAN"  class="st0" d="M1175.2,90.6c2.4-0.3,3-0.8,4.1-1.7l0.3-0.3c1.2-0.9,2.6-1,4-1c0.3,0,0.6,0,0.9,0c0.9-0.1,1.6,0.2,2.5,0.5
                                c1.4,0.4,3.1,1,6.3,0.7l1.3-0.1c4.8-0.4,10.2-1,12.7-3.5c0.2-0.2,0.4-0.4,0.5-0.7c-0.2-0.2-0.4-0.3-0.5-0.5
                                c-0.8-0.6-1.7-1.1-2.6-1.6c-0.7-0.4-1.3-0.8-2.1-1.1c-0.1-0.1-0.3-0.1-0.4-0.2c-0.4,0.6-0.8,1.1-1.3,1.6c-1.5,1.4-3.8,3.5-9.4,4
                                c-3.4,0.3-5.3-0.3-6.7-0.7c-0.8-0.2-1.4-0.4-2.1-0.4c-0.3,0-0.6,0-1,0c-1.3,0-2.5,0-3.4,0.8l-0.3,0.2c-1.1,0.9-1.9,1.6-4.6,2
                                c-1.5,0.2-4.7,0.3-7.7,0.3c-2.5,0-4.8-0.1-5.8-0.4c-0.2,0-0.5-0.1-0.9-0.3c1,1.8,2.9,2.2,2.9,2.2
                                C1163.8,91.1,1172.3,90.9,1175.2,90.6z"/>
                            <path name="JUAN"  class="st0" d="M1166.9,75.8c0.2,0,0.4,0,0.5-0.1s0.2-0.2,0.2-0.4c0-0.3-0.2-0.7-0.6-0.7h-0.1c-0.2,0-0.3,0.1-0.4,0.1
                                c-0.1,0.1-0.2,0.2-0.2,0.4C1166.2,75.4,1166.5,75.7,1166.9,75.8z"/>
                            <path name="JUAN"  class="st0" d="M1199.4,72.2c0.2,0,0.4,0,0.5-0.1s0.2-0.2,0.2-0.4c0-0.3-0.2-0.7-0.6-0.7h-0.1c-0.3,0-0.6,0.2-0.7,0.5
                                c0,0.2,0,0.3,0.1,0.4C1199,72.1,1199.2,72.2,1199.4,72.2z"/>
                            <path name="JUAN"  class="st0" d="M1160.3,87.5c1.8,0.5,10.3,0.4,13.1,0c2.4-0.3,3-0.8,4.1-1.7l0.3-0.3c1.2-0.9,2.6-1,4-1c0.3,0,0.6,0,0.9,0
                                c0.9,0,1.6,0.2,2.5,0.5c1.4,0.4,3.1,1,6.3,0.7c5.3-0.5,7.3-2.4,8.8-3.7c0.6-0.6,1-1.2,1.4-1.9c0-0.1,0.1-0.1,0.1-0.1
                                c0.3-0.5,0.6-1,0.9-1.6v-0.1c0.1-0.2,0.2-0.4,0.2-0.6c0.5-1.2,0.9-2.5,1.1-3.7c0.1-0.5,0.1-1,0.1-1.6c0-0.1,0-0.2,0.1-0.3
                                c0.1-1,0.1-1.9,0-2.7c-0.1-1.7-4.2-2.3-5.7-2.4c-0.7-0.1-1.5-0.1-2.5-0.1c-4.6,0-13.2,0.8-29.1,4c-0.4,0.1-0.8,0.2-0.9,0.2
                                c-2.9,0.5-4.3,2.8-4.7,3.5l0,0c-1.8,3.3-2.8,5.8-3.2,7.6c0,0,0.1,1.9,0.3,4.3c0.6,0.9,1.6,1,1.6,1
                                C1160.2,87.5,1160.2,87.5,1160.3,87.5z M1198.2,71.5c0.1-0.6,0.7-1.1,1.4-1c0.7,0.1,1.2,0.7,1.1,1.3c0,0.3-0.2,0.6-0.5,0.8
                                c-0.2,0.2-0.5,0.2-0.7,0.2c-0.1,0-0.1,0-0.2,0c-0.3,0-0.6-0.2-0.8-0.4C1198.3,72.1,1198.2,71.8,1198.2,71.5z M1165.7,75
                                c0-0.3,0.2-0.6,0.5-0.8c0.3-0.2,0.6-0.3,0.9-0.2c0.7,0.1,1.2,0.7,1.1,1.3c0,0.3-0.2,0.6-0.5,0.8c-0.2,0.2-0.5,0.2-0.7,0.2
                                c-0.1,0-0.1,0-0.2,0C1166.1,76.2,1165.6,75.7,1165.7,75z"/>
                            <path name="JUAN"  class="st0" d="M1205.1,74.2c-0.2,1.4-0.6,2.8-1.2,4.1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.3-0.2,0.4
                                c-0.1,0.2-0.2,0.4-0.3,0.7c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c0.1,0,0.2,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.7,0.3
                                c0.8,0.4,1.6,0.8,2.3,1.3c0.7,0.4,1.3,0.8,1.9,1.2c0.1,0.1,0.3,0.2,0.4,0.3c1.1-1.9,1.8-4.5,1.9-7c-0.3-0.6-0.9-1.1-1.4-1.6
                                c-1.1-0.8-2.3-1.6-3.7-1.8C1205.2,73.4,1205.1,73.8,1205.1,74.2z M1205.7,80.2c0.5-0.7,0.8-1.4,1-2.3c0.2-0.8,0.1-1.7-0.1-2.6
                                c-0.1-0.3,0.1-0.6,0.4-0.7s0.6,0.1,0.7,0.4c0.2,1,0.3,2,0.1,3c-0.2,0.9-0.6,1.8-1.1,2.6c-0.2,0.2-0.5,0.3-0.7,0.2
                                C1205.7,80.8,1205.6,80.5,1205.7,80.2z"/>
                            <path name="JUAN"  class="st0" d="M1210.4,74.8L1210.4,74.8c0-1.1-0.2-2.1-0.5-3c-0.6-1.5-2.9-2.4-4.6-2.9c0,0.2,0.1,0.3,0.1,0.5
                                c0,0.8,0,1.7,0,2.6C1207.2,72.3,1209.1,73.4,1210.4,74.8z"/>
                            <path name="JUAN"  class="st0" d="M1239.4,160.7c0.2-0.1,0.4-0.2,0.7-0.3c1.9-0.9,3.6-2.3,4.5-4.2c-2.2,0.8-4.4,1.6-6.7,2.3
                                C1238.4,159.6,1239.1,160.3,1239.4,160.7z"/>
                            <path name="JUAN"  class="st0" d="M1246.9,91.8c-4.7-2.5-16.1-11.7-16.6-12.1c-0.1-0.1-9.3-8.8-10.2-9.6c-1-0.9-5.1-2.9-7.3-3.9
                                c-0.6-0.3-1.1-0.5-1.4-0.7c-1.1-0.6-9.6-0.3-10.4,0c-0.4,0.2-0.9,0.3-1.4,0.5c1.3,0.2,3.5,0.6,4.8,1.6c0.9,0.2,5.3,1.1,6.4,3.7
                                c0-0.5,0.2-1,0.4-1.5c0.2-0.5,1-0.1,0.7,0.4c-0.4,0.7-0.4,1.6-0.2,2.4c0.3,0.8,0.8,1.3,1.3,1.9c1,1.2,1.8,2.5,2,4.1
                                c0.1,0.5-0.7,0.8-0.8,0.2c-0.2-0.9-0.5-1.7-0.9-2.5s-1-1.3-1.6-2c-0.2-0.2-0.3-0.5-0.5-0.7c0.1,0.4,0.1,0.8,0.1,1.3
                                c0.1,0,0.2,0.1,0.3,0.3c0.5,2.2,0.8,4.4,0.8,6.7c0,1.1,0,2.2-0.1,3.3c0,0.1-0.1,0.3-0.1,0.4c0.1-0.2,0.3-0.4,0.4-0.6
                                c1-1.3,2.2-2.5,3.7-3.5c0.5-0.3,0.9,0.4,0.4,0.7c-1.6,1-2.9,2.4-4,4c-0.4,0.6-0.8,1.3-1.2,1.9c0,0.1,0,0.1,0,0.2
                                s-0.1,0.2-0.2,0.2c-0.4,0.7-0.9,1.4-1.4,2.1c-0.9,1.2-2.1,2.2-3.6,2.7c-1.6,0.6-3.4,0.7-5.1,0.8c-0.9,0-1.9,0-2.9,0.2
                                c-0.6,0.1-1.9,0.9-1,1.5c0.8,0.5,1.7,0.8,2.5,1.2c0.8,0.4,1.7,0.9,2.5,1.2s1.8,0.7,2.7,0.8c1,0.1,1.9-0.2,2.8-0.4
                                c1.7-0.4,3.5-0.9,5.1-1.6c2.6-1.2,4.8-3.6,7.6-4.3h0.1c0.5-0.1,1-0.2,1.5-0.2s0.5,0.6,0.2,0.8c2.5,1.1,7.4,3.3,9.6,4.8
                                c2.9,2,19.2,17.7,21.8,20.4c0.5,0.5,1,1.1,1.6,1.8c2.8,3.2,7.5,8.6,14.3,10.8c0.2,0.1,0.3,0.3,0.3,0.5c-0.1,0.2-0.2,0.3-0.4,0.3
                                c-0.1,0-0.1,0-0.1,0c-4.3-1.4-7.7-4-10.4-6.6c-4.4,0.6-14.6,6.9-14.7,7c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.3-0.1-0.4-0.2v-0.1
                                c-6.7,7.5-7.3,17.7-7,23.2c0,0.9,0.3,1.8,0.6,2.6c0,0,0,0,0.1,0c24.8-7.5,42.5-24.6,44.4-26.6c3.9-3.9,2.2-9.3,2.2-9.3
                                C1280.2,112.2,1251.4,94.2,1246.9,91.8z"/>
                            <path name="JUAN"  class="st0" d="M1243.4,131.2c-0.7-0.1-1.4-0.1-2.2,0c-1.1,0.1-2.1,0.6-3.3,0.7c-0.5,0-1.1-0.1-1.6,0
                                c-0.4,0.1-0.8,0.4-1.3,0.6c-0.9,0.4-1.9,0.3-2.9,0.2c-1.1-0.1-2.2-0.1-3.2,0.3c-1,0.4-1.8,0.8-2.8,1c-2,0.3-4.1-0.2-6.1,0.3
                                c-1,0.2-1.9,0.8-2.8,1.2s-1.9,0.8-2.9,1c-0.5,0.1-0.8-0.7-0.2-0.8c1-0.2,1.9-0.5,2.8-0.9c-0.6-0.8-1.6-1.1-2.4-1.6
                                c-0.4-0.2-0.8-0.6-1.1-0.9c-0.4-0.5-0.7-0.8-1.3-1c-0.5-0.1-1-0.2-1.4-0.6c-0.5-0.4-0.8-1-1.2-1.4c-0.6-0.6-1.5-1.6-2.5-1.5
                                c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.2-0.1,0.3-0.1c0.4-0.1,0.7,0.3,0.5,0.6c-1.9,3.4-3.6,7-6,10.1
                                c-1.9,2.4-4.2,4.5-7.1,5.8c-3.2,1.4-6.9,1.3-10.3,0.9c-3.3-0.4-6.3-1.9-8.9-4c-2.4-1.9-4.3-4.3-5.6-7c-0.2-0.5,0.5-0.9,0.7-0.4
                                c0.1,0.2,0.2,0.5,0.3,0.7c0.6-0.3,1.3-0.6,1.9-0.9c-0.9-0.5-1.9-0.8-2.8-0.4c-1,0.4-1.1,1.6-2.1,2.1c-0.5,0.2-1,0.4-1.4,0.6
                                s-0.9,0.5-1.4,0.7c-1,0.5-2,0.9-3.1,1.2s-2.2,0.6-3.2,1s-1.9,1-2.9,1.4c-2.1,0.9-4.3,1.4-6.4,2.3c-0.9,0.4-2,0.8-2.8,1.5
                                c-0.4,0.4-0.7,0.8-0.7,1.3c0,0,0,0,0,0.1c-0.1,0.5,0,1,0,1.5c0,0.6-0.8,0.5-0.8,0c0-0.3,0-0.6,0-0.8c-0.8,0.5-1.3,1.3-1.8,2.1
                                c-0.8,1.5-1.3,3.1-1.8,4.7c-1,3.8-1.6,7.6-2.2,11.4c-0.7,4.1-1.3,8.2-2.4,12.3c-0.5,1.8-1,3.6-1.8,5.3c-0.2,0.4-0.4,0.8-0.6,1.2
                                c-0.3,0.6-0.7,1.2-0.1,1.7c0.4,0.4,1.2,0.6,1.7,0.8c0.3,0.1,0.6,0.2,0.9,0.3l0,0c0.4,0.1,0.8,0.2,1.3,0.4
                                c5.9,1.6,11.8,2.9,17.9,3.8c0.2-1.7,0.6-3.4,0.9-5.1c0.2-1.1,0.2-2.1,0-3.2c-0.2-1-0.6-1.9-1-2.9c-3.2-7.7-5.1-15.7-6.9-23.7
                                c-0.1-0.5,0.7-0.8,0.8-0.2c1,4.6,2.1,9.3,3.4,13.9c0.6,2,1.2,4,1.9,5.9c0.7,1.9,1.7,3.8,2.3,5.8c0.6,2,0.4,4,0,6.1
                                s-0.8,4.3-0.8,6.5c0,1.1,0,2.1,0.1,3.2c0,0.5,0.1,1.1,0,1.6c0,0.5-0.2,1.1-0.2,1.6c-0.1,0.9,0.5,1.7,0.9,2.5s0.6,1.6,0.6,2.6
                                c-0.1,1.1-0.3,2.1-0.3,3.1s0.3,2,0.4,3c0.1,1.3,0,2.6-0.4,3.9c0.7-0.7,1.4-1.4,1.7-2.3c0.2-0.5,1-0.3,0.8,0.2
                                c-0.3,1-1,1.7-1.7,2.4s-1.6,1.4-2.1,2.2c-0.7,1.1-0.3,2.3,0.1,3.5c0.5,1.3,0.8,2.5,0.5,3.9c-0.3,1.5-1.1,2.8-1.9,4
                                s-1.5,2.5-1.7,4s0,3.1,0.2,4.6s0.1,3-0.1,4.5c-0.2,1.6-0.6,3.1-0.7,4.6s-0.1,3-0.4,4.5c-0.3,1.4-1.2,2.7-1.3,4.2
                                c-0.2,1.4,0,2.9-0.3,4.3c-0.3,1.3-1.1,2.4-1.9,3.5c-0.7,1-1.6,2.3-1.7,3.6c-0.1,1,0.4,1.4,1.3,1.5c1.9,0.4,3.9,0.1,5.8,0.2
                                c1.3,0,2.6,0.2,3.9,0.4c1.6,0.1,3.2,0.2,4.9,0.2c3.3-0.1,6.5-0.6,9.8-0.8c6.3-0.4,12.7,1.2,19,0.7c3.2-0.2,6.2-1,9.3-1.7
                                c2.9-0.7,5.9-1.4,8.9-1.7c3-0.4,6.1-0.5,9.1-1.1c1.4-0.3,2.9-0.7,4.2-1.2c1.2-0.5,2.5-1.2,3-2.4c1.2-2.7-0.9-5.6-1.8-8
                                c-0.5-1.5-0.6-2.9-0.6-4.5c0-1.5,0.2-3.1-0.1-4.6c-0.3-1.4-1-2.6-1.6-3.9c-0.6-1.3-1.1-2.6-1.4-4c-0.3-1.5-0.4-2.9-0.7-4.4
                                c-0.2-1.3-0.6-2.5-1.2-3.6c-0.3,1.1-0.4,2.1-0.2,3.3c0.1,0.5-0.7,0.8-0.8,0.2c-0.2-1.6,0-3.2,0.5-4.7c0.5-1.6,1.2-3.1,1.5-4.8
                                c0.5-3.1-0.4-6.3,0.1-9.5c0.4-2.9,1.9-5.6,2.1-8.5c0.1-1.6-0.2-3.1-0.4-4.7c-0.2-1.5-0.1-2.9,0.2-4.3c0.5-2.8,1.6-5.6,1.4-8.5
                                c-0.1-0.8-0.2-1.6-0.1-2.4c0-0.7,0.2-1.4,0.3-2.1c0.3-1.5,0.6-3,1-4.4c0.7-2.9,1.6-5.7,3.1-8.2c0.8-1.3,1.7-2.5,2.6-3.7
                                c0.8-1,1.5-2.1,2.5-2.8s2-0.8,3.2-1c0.5-0.1,1.1-0.2,1.6-0.4c-0.8-0.9-2.4-3.1-2.5-5.7C1235.7,149.5,1236.4,139,1243.4,131.2z
                                M1227.2,166.4c-0.3-2.1-0.6-4.2-0.9-6.3c-1.1-7.4-2.7-14.9-6-21.7c-0.2-0.5,0.5-0.9,0.7-0.4c0.9,1.9,1.7,3.9,2.4,5.9
                                c2.5,7.2,3.6,14.8,4.6,22.3C1228.1,166.8,1227.3,167,1227.2,166.4z"/>
                            <path name="JUAN"  class="st0" d="M1151.8,453.8c-7.9-0.2-8.3-2.4-9.2-3.8c-0.3-0.4-0.5-1.2-0.7-2c-0.7,1.1-0.8,2.9-0.8,3.4
                                c0.1,1.6,0.8,2,1.7,2.5c0.5,0.3,1.1,0.6,1.6,1.3c1,1.4,3.4,2.7,6,3.3c2.7,0.6,9.5,0.4,15.1,0c6.2-0.5,6.2-3.2,6.2-4.2
                                c0-1.5-0.1-2.8-1.1-2.9l0,0c-0.3,1-1.6,1.7-1.6,1.7C1166.3,453.9,1159.7,454,1151.8,453.8z"/>
                            <path name="JUAN"  class="st0" d="M1137.2,299.3c-0.1-0.9-0.1-1.9,0.2-2.8c0.3-0.9,0.7-1.7,1-2.6c0.4-1.5,0.4-3.3,1.4-4.6
                                c0.5-0.7,1.3-1.1,2-1.5c0.8-0.4,1.6-0.8,2.5-1.1c0.5-0.1,0.8,0.7,0.2,0.8c-0.1,0-0.2,0.1-0.2,0.1c0.6,2,1.5,3.9,2.7,5.6
                                c0.6,0.9,1.2,1.7,1.9,2.5c0.2,0.2,0.3,0.4,0.5,0.6c0.9-5.2-0.7-10-0.7-10.1v-0.1c-0.1-2.1-1.2-6.4-1.9-7.7
                                c-0.7-1.3-2.7-5.3-3.4-7c-0.7-1.7-1.4-4.3-1.4-4.4c-0.1-0.2,0.1-0.5,0.3-0.5c0.2-0.1,0.5,0.1,0.5,0.3c0,0,0.3,1.2,0.7,2.4
                                c0.4-5.3,1.8-21,3.2-26.6c1.7-6.7,4.2-22.2,4.8-26.7l0.2-1.4c0.3-2.4,0.7-5.3,1.1-8c-0.3,0.7-0.5,1.3-0.8,2
                                c-1.3,2.9-2.7,5.8-3.6,8.9c-0.2,0.5-1,0.3-0.8-0.2c0.5-1.7,1.2-3.3,1.9-4.9c1.2-2.7,2.5-5.3,3.4-8.1c1.4-4.3,2.1-8.8,3.7-13
                                c-3.4-0.5-6.9-1.1-10.3-1.9c-1.8-0.4-3.6-0.8-5.4-1.3c-1-0.3-2-0.5-3-0.8c0,1.1,0.1,3.7-0.2,6.2c-0.1,1.2-1.1,5.9-2.3,11.7
                                c-2.1,9.9-4.9,23.6-5.2,29c-0.3,5.1-0.2,16.6-0.1,25.8c0.1,4.1,0.1,7.7,0.1,10.1c0.2-0.4,0.3-0.8,0.4-1.1
                                c0.2-0.5,0.4-1.1,0.4-1.2c0-0.2,0.2-0.4,0.5-0.4c0.2,0,0.4,0.2,0.4,0.5c0,0.2-0.2,0.6-0.5,1.4c-0.5,1.5-1.4,3.9-1.8,6
                                c-0.5,3.2-1.2,12.7-0.5,14.2c0.3,0.7,0.8,1.4,1.2,2c0.6,0.8,1.2,1.7,1.5,2.7s0.3,2.2,0.3,3.2c0,0.7,0,1.6,0.2,1.8
                                c0.3,0.4,2.6,2,5.5,2.9c-0.1-0.1-0.2-0.3-0.2-0.4C1137.5,300.9,1137.3,300.1,1137.2,299.3z"/>
                            <path name="JUAN"  class="st0" d="M1202.1,433.2c-3.2-2.5-8.5-3.4-9.3-4c-0.2-0.1-0.3-0.5-0.3-0.9c-0.2,0-0.8,0.3-0.8,1.1c0,1.8,0.3,3,0.9,3.8
                                c0.3,0.4,2.5,1.1,4.1,1.6c1.5,0.5,3,1,3.8,1.4c0.3,0.2,0.8,0.4,1.3,0.6c2.5,1.3,6.7,3.4,8.1,4.8c1.8,1.8,6.9,2.7,13.6,2.5
                                c5.5-0.2,8.4-1.9,8.7-2.7c0.3-0.7,0.8-2.7,0.2-3.8c-0.2-0.3-0.4-0.5-0.7-0.6c0.2,0.5,0.2,0.7,0.2,0.7c-4.8,2-11.2,2.3-16.7,1.3
                                C1209.7,438.2,1205.3,435.7,1202.1,433.2z"/>
                            <path name="JUAN"  class="st0" d="M1149.4,300.8c0.2-0.8,0.2-1.7,0-2.5s-0.8-1.4-1.3-2.1c-2.1-2.4-3.6-5.2-4.6-8.2c-0.6,0.2-1.1,0.5-1.6,0.8
                                c-0.8,0.4-1.4,0.9-1.8,1.8c-0.3,0.8-0.4,1.6-0.6,2.4c-0.2,0.9-0.4,1.7-0.8,2.5c-0.3,0.8-0.7,1.6-0.7,2.5s0.1,1.8,0.3,2.6
                                c0.3,1,0.9,2,1.5,2.9c0.7,1.1,1.6,1.8,2.9,2.2c1.5,0.4,3-0.1,4.2-0.9C1148.2,303.7,1149.1,302.3,1149.4,300.8z"/>
                        </g>
                        <path name="JUAN"  class="st3" d="M1204.1,68.1c0,0,5.2,0.9,6.2,3.5c1.4,3.6,0.1,10.9-2.7,13.8s-9.1,3.3-14.4,3.8s-6.7-1.2-8.8-1.1
                            c-1.6,0.1-3.4-0.1-4.6,0.9c-1.3,1-1.9,1.7-4.7,2.1c-2.8,0.4-11.4,0.5-13.3,0c0,0-8.9-2.1,0.1-14.2"/>
                        <path name="JUAN"  class="st1" d="M1160.9,74.4c0.1-0.2,1.6-3.2,5.1-3.8c2.1-0.4,23.7-4.9,32.6-4.1c0,0,6.1,0.4,6.2,2.9c0.1,2.6,0,8.2-4.2,12.9
                            c-1.7,1.5-3.8,3.3-9.1,3.8c-5.3,0.5-6.7-1.2-8.8-1.1c-1.6,0.1-3.4-0.1-4.6,0.9c-1.3,1-1.9,1.7-4.7,2.1c-2.8,0.4-11.4,0.5-13.3,0
                            C1160.1,88,1153.6,87.7,1160.9,74.4z"/>
                        <ellipse name="JUAN"  transform="matrix(0.1266 -0.992 0.992 0.1266 944.9007 1223.1166)" class="st0" cx="1167" cy="75" rx="0.9" ry="1"/>
                        <ellipse name="JUAN"  transform="matrix(0.1266 -0.992 0.992 0.1266 976.825 1252.2871)" class="st0" cx="1199.5" cy="71.4" rx="0.9" ry="1"/>
                        <path name="JUAN"  class="st1" d="M1202.1,80.3c-0.1,0.2,1,0.6,1.2,0.6c1.9,1,3.8,2,5.3,3.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0.1,0.4-0.2,0.5-0.3
                            c1.1-1.6,1.8-3.5,1.8-5.4c0-0.7,0.3-1.4-0.3-2.2c-0.8-1-1.6-2.1-2.7-2.8c-1.1-0.7-2.3-1.3-3.6-1.4
                            C1204.7,72.4,1204.7,76.4,1202.1,80.3z"/>
                        <path name="JUAN"  class="st1" d="M1181.3,126.6c0,0,6.8,8.4,10.6,7.9c5.7-0.7,12.8-11.2,12.8-11.2s-7.2,15.4-12.7,15.4
                            C1182.8,138.8,1181.3,126.6,1181.3,126.6z"/>
                        <g>
                            <path name="JUAN"  class="st2" d="M1207,74.7c-0.3,0.1-0.4,0.4-0.4,0.7c0.2,0.8,0.2,1.7,0.1,2.6c-0.2,0.8-0.5,1.6-1,2.3
                                c-0.2,0.2,0,0.6,0.2,0.7c0.3,0.2,0.6,0.1,0.7-0.2c0.6-0.8,0.9-1.7,1.1-2.6c0.2-1,0.1-2-0.1-3C1207.6,74.8,1207.3,74.7,1207,74.7z
                                "/>
                            <path name="JUAN"  class="st2" d="M1166.8,76.3c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.7-0.2c0.3-0.2,0.4-0.5,0.5-0.8c0.1-0.6-0.4-1.2-1.1-1.3
                                c-0.3,0-0.6,0-0.9,0.2s-0.4,0.5-0.5,0.8C1165.6,75.7,1166.1,76.2,1166.8,76.3z M1166.5,74.7c0.1-0.1,0.3-0.1,0.4-0.1h0.1
                                c0.4,0.1,0.7,0.4,0.6,0.7c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.3,0.2-0.5,0.1c-0.4-0.1-0.7-0.4-0.6-0.7
                                C1166.3,74.9,1166.3,74.8,1166.5,74.7z"/>
                            <path name="JUAN"  class="st2" d="M1199.3,72.8c0.1,0,0.1,0,0.2,0c0.3,0,0.5-0.1,0.7-0.2c0.3-0.2,0.4-0.5,0.5-0.8c0.1-0.6-0.4-1.2-1.1-1.3
                                c-0.7-0.1-1.3,0.3-1.4,1c0,0.3,0.1,0.6,0.3,0.9C1198.7,72.6,1199,72.7,1199.3,72.8z M1199.5,71L1199.5,71
                                c0.5,0.1,0.8,0.4,0.7,0.7c0,0.2-0.1,0.3-0.2,0.4c-0.2,0.1-0.3,0.2-0.5,0.1c-0.2,0-0.4-0.1-0.5-0.2s-0.2-0.3-0.1-0.4
                                C1198.8,71.2,1199.1,71,1199.5,71z"/>
                            <path name="JUAN"  class="st2" d="M1174.5,95.9c-1.8,1.2-3.8,2-5.9,2.3c-0.2,0-0.3,0.3-0.3,0.5c0.1,0.2,0.3,0.3,0.5,0.3
                                c2.2-0.3,4.3-1.2,6.1-2.4C1175.4,96.3,1175,95.6,1174.5,95.9z"/>
                            <path name="JUAN"  class="st2" d="M1180.3,93.3c1.8-1.4,4.1-2.1,6.3-2.1c0.6,0,0.6-0.8,0-0.8c-2.5-0.1-5,0.8-6.9,2.3
                                C1179.2,93,1179.8,93.6,1180.3,93.3z"/>
                            <path name="JUAN"  class="st2" d="M1284.9,121.5c-4-9.8-33.1-28.1-37.7-30.5c-4.7-2.4-16.3-11.9-16.4-12c-0.4-0.4-9.3-8.7-10.2-9.6
                                c-1-0.9-4.2-2.5-7.5-4.1c-0.6-0.3-1.1-0.5-1.4-0.7c-1.3-0.7-10.1-0.4-11.1,0c-0.4,0.2-0.9,0.3-1.5,0.6c-0.8,0.3-1.3,0.5-1.6,0.6
                                c-4.3-0.2-13,0.4-30.7,3.9c-0.4,0.1-0.7,0.2-0.9,0.2c-3.3,0.6-5,3.3-5.4,4.1l0.5,0.3l-0.5-0.3c-3.3,6-4.3,10.3-3,12.7l0,0
                                c0.1,0.4,0.2,0.8,0.3,1.2c0.2,0.6,0.6,1.2,0.9,1.6c0.3,0.6,3.3,5.9,7.7,9.3c0.1,1.2,0.8,3.3,3.2,3.8c0.1,0,0.5,0.1,1.2,0.1
                                c0.2,0,0.3,0,0.5,0c0,1.9,0.2,7.4,2.8,7.8h0.1l0,0c1.2,5.3,3.2,11.8,6.3,15.7l0,0c-0.4,2.1-1.8,3.9-3.5,5.3
                                c-0.8,0.7-1.7,1.3-2.7,1.8l0,0c-1.6-1-3.9-1.6-5.2,0.3c-0.3,0.4-0.4,0.9-0.9,1.1c-0.5,0.3-1.1,0.4-1.6,0.7
                                c-1.1,0.5-2.1,1.2-3.3,1.6c-1.1,0.4-2.3,0.6-3.4,1.1s-2.2,1-3.2,1.5c-2.2,1.1-4.6,1.6-6.9,2.6c-1.1,0.5-2.4,0.9-3.2,1.8
                                c-0.4,0.4-0.6,0.9-0.7,1.3c-2.3,1.1-3.4,4-4.1,6.2c-1.2,3.8-1.8,7.7-2.4,11.6c-0.7,4.3-1.4,8.6-2.4,12.8c-0.5,1.9-1,3.8-1.8,5.6
                                c-0.2,0.4-0.3,0.9-0.6,1.3c-0.2,0.5-0.6,1-0.6,1.6c-0.2,1.2,0.9,1.9,2,2.3c0.4,0.1,0.8,0.3,1.2,0.4c0,0.9,0.1,3.7-0.1,6.4
                                c-0.1,1.2-1.1,5.8-2.2,11.6c-2.1,10-4.9,23.6-5.2,29.1c-0.3,5.1-0.2,16.6-0.1,25.9c0.1,6.2,0.2,11.6,0,13
                                c-0.2,0.7-0.4,1.4-0.5,2.1c-0.5,2.7-1.3,12.7-0.5,14.7c0.3,0.8,0.8,1.5,1.3,2.2c0.6,0.8,1.1,1.5,1.4,2.4c0.2,0.9,0.3,2,0.3,3
                                c0,1.2,0.1,2,0.3,2.4c0.6,0.7,3.4,2.5,6.5,3.4h0.1h0.1l0,0c0.7,1.1,1.3,2.1,2.5,2.8c1.4,0.8,3,1,4.5,0.4c0.2-0.1,0.3-0.1,0.5-0.2
                                c-0.7,2.2-2,14.7,0.1,18.9c0,0-0.1,2.5-1,7.6s-4,10.2,1,16.5c0,0-2.6,0.3-2.5,5s-0.3,9.4-1.5,14.5s-0.4,1.9-0.9,13.7
                                c-0.4,11.8-1,25.3-1.5,27.6s-2.3,3.6-1.6,6.3c0,0,0,1-1.3,2.3c0,0-1.5,0.9-1,6.3c0.4,5.4-0.6,7.8,2.8,9c0,0-2.2,3.9,1.5,5.7
                                c0,0,1.2,0.4,1.2,2.8c0,1.2,0.1,3,0.3,4.6c-1.5,1.2-1.6,4.2-1.5,4.7c0.2,2.1,1.2,2.7,2.2,3.2c0.5,0.3,0.9,0.5,1.3,1
                                c1.4,1.9,4.4,3.2,6.5,3.7c1.3,0.3,3.4,0.4,5.8,0.4c3.1,0,6.6-0.2,9.6-0.5c4.7-0.4,7.1-2.1,7.1-5.1c0-1.3,0-3.6-2-3.9
                                c-0.1,0-0.1,0-0.2,0c-0.1-0.4-0.4-0.8-0.9-1.2c-0.8-0.7-2.3-1.6-3.9-5s-3.4-8.5-3.4-8.5s3.1-1.7,3.8-4.8c0.7-3.1-1.9-7.2-1.6-9.2
                                c0,0,2.8-1.9,2.6-6s-2.9-9.8-2.1-14c0.9-4.2,2.6-13.7,3.8-19.4s0.7-11.8,0.6-12.9c0,0,2.6-5.3,2.6-10.4c0,0,6.1-7.6,6.1-11.1
                                s-0.4-6.9,0-8.8s4.2-4.8,3.9-19.1c0,0,3.9-8.2,4.7-11.7c0.7-3.5,1.8-6.4,3.7-6.4c0,0,0.4,5.9,1.3,9.5s1.9,7.2,2.5,11.2
                                s1.2,6.1,2.3,7.9c0,0-0.6,1.6-0.7,5.7c-0.2,4.1,1.8,7.8,2.3,9.2c0.6,1.5-0.2,2.6,0,5s1.9,4.3,1.3,5.9s-2.9,3.3-1.8,6
                                c0,0,0.7,1.1-0.6,4.8c-1.3,3.6-3.5,11.2-3.5,15.3s-0.3,14.8-1.5,19.3s-3.2,9.6-3.5,12c-0.3,2.3,1.6,7.8,2.6,9.4
                                c0,0,1,0.7,1.8,1.2c0,0-0.5,1.8-0.7,3.3h-0.1c-0.6,0.1-1.7,0.6-1.7,2c0,2,0.4,3.5,1.1,4.4c0.5,0.6,2,1.1,4.6,1.9
                                c1.4,0.5,2.9,0.9,3.6,1.3c0.3,0.2,0.8,0.4,1.3,0.6c2.3,1.2,6.6,3.4,7.9,4.7c2.5,2.4,8.8,2.8,12.6,2.8c0.6,0,1.2,0,1.6,0
                                c5.6-0.2,9.1-1.9,9.6-3.4c0.1-0.1,1.1-2.9,0.1-4.6c-0.4-0.7-1.1-1.1-2-1.2c-0.8-1.5-2.6-3.8-6.5-6.8c0,0-6.6-4.4-8.9-7.9
                                c0,0,3.8-7.8,3.5-10.1c-0.3-2.3-2.1-3.2-3.1-3.2c0,0-1.2-6-0.2-8.8s8.5-28.6,8.8-31.6c0,0,3.1-8.9,1.8-14c0,0,4.5-12.9,5.3-15.9
                                s-0.7-6-0.9-6.9c0,0,1.1-8.5,1.1-15c-0.1-6.6-0.9-24-1.1-27.9c0,0-2.1-10.2-2.6-13s0-4.2,0-6.6c0-2.2-1-4.7-1.4-5.7
                                c0.7-0.5,1.2-1.2,1.5-2c0.5-1.3,0.3-2.7-0.1-4c-0.4-1.4-1.2-2.7-1.7-4.1c-0.6-1.5-0.7-3-0.6-4.6c0.1-1.5,0.2-3.1-0.2-4.6
                                c-0.3-1.4-1-2.7-1.6-4c-0.7-1.4-1.2-2.8-1.4-4.4c-0.2-1.5-0.5-3-0.8-4.5c-0.3-1.4-0.7-2.7-1.4-3.8c0-0.1-0.1-0.1-0.1-0.1
                                c0.5-1.4,1.1-2.9,1.4-4.3c0.6-2.8,0-5.6,0-8.3c0.1-3,1.4-5.6,2-8.5c0.3-1.5,0.3-2.9,0.1-4.4c-0.2-1.5-0.4-3-0.3-4.5
                                c0.2-2.8,1.3-5.5,1.6-8.3c0.1-0.7,0.1-1.3,0.1-2c-0.1-0.8-0.2-1.6-0.1-2.4c0.1-1.5,0.5-3,0.8-4.5c0.6-2.9,1.3-5.8,2.7-8.4
                                c0.7-1.4,1.5-2.6,2.4-3.8c0.8-1.1,1.6-2.3,2.6-3.3c0.5-0.5,1-0.9,1.7-1.1c0.6-0.2,1.2-0.2,1.8-0.3c0.8-0.1,1.5-0.4,2.2-0.6h0.1
                                c0.4-0.1,0.7-0.3,1.1-0.4c2.3-1.1,4.1-2.8,5.1-5.2v-0.1c20.8-8.3,35.2-22.3,36.9-24C1286.9,127.5,1285,121.6,1284.9,121.5z
                                M1161.4,74.6L1161.4,74.6c0.4-0.7,1.8-3,4.7-3.6c0.2,0,0.5-0.1,0.9-0.2c15.9-3.1,24.4-4,29.1-4c1,0,1.8,0,2.5,0.1
                                c1.4,0.1,5.6,0.7,5.7,2.4c0,0.8,0,1.7,0,2.7c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.5-0.1,1-0.1,1.6c-0.2,1.2-0.6,2.4-1.1,3.7
                                c-0.1,0.2-0.2,0.4-0.2,0.6v0.1c-0.3,0.6-0.6,1.1-0.9,1.6l-0.1,0.1c-0.4,0.6-0.9,1.3-1.4,1.9c-1.5,1.3-3.5,3.2-8.8,3.7
                                c-3.2,0.3-4.9-0.2-6.3-0.7c-0.8-0.3-1.6-0.5-2.5-0.5c-0.3,0-0.6,0-0.9,0c-1.4,0-2.9,0-4,1l-0.3,0.3c-1.1,0.9-1.7,1.4-4.1,1.7
                                c-2.8,0.4-11.3,0.5-13.1,0h-0.1c0,0-1-0.1-1.6-1c-0.7-1.1-0.3-4.3-0.3-4.3C1158.6,80.4,1159.6,77.9,1161.4,74.6z M1159.1,88.3
                                c0.4,0.2,0.8,0.3,0.9,0.3c1,0.3,3.3,0.4,5.8,0.4c3,0,6.2-0.1,7.7-0.3c2.7-0.3,3.5-1,4.6-2l0.3-0.2c0.9-0.7,2.1-0.7,3.4-0.8
                                c0.3,0,0.7,0,1,0c0.7,0,1.3,0.2,2.1,0.4c1.4,0.4,3.3,1,6.7,0.7c5.7-0.6,7.9-2.6,9.4-4c0.5-0.5,0.9-1.1,1.3-1.6
                                c0.1,0.1,0.3,0.1,0.4,0.2c0.7,0.3,1.4,0.7,2.1,1.1c0.9,0.5,1.8,1,2.6,1.6c0.2,0.1,0.3,0.3,0.5,0.5c-0.2,0.2-0.3,0.5-0.5,0.7
                                c-2.4,2.5-7.9,3-12.7,3.5l-1.3,0.1c-3.2,0.3-4.9-0.2-6.3-0.7c-0.9-0.3-1.6-0.5-2.5-0.5c-0.3,0-0.6,0-0.9,0c-1.4,0-2.9,0-4,1
                                l-0.3,0.3c-1.1,0.9-1.7,1.4-4.1,1.7c-2.8,0.4-11.3,0.5-13.1,0C1162,90.5,1160.1,90,1159.1,88.3z M1208,83.1
                                c-0.6-0.5-1.2-0.9-1.9-1.2c-0.8-0.4-1.6-0.9-2.3-1.3c-0.2-0.1-0.4-0.2-0.7-0.3c-0.1,0-0.2-0.1-0.2-0.1c0.1-0.1,0.2-0.3,0.2-0.4
                                s0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.1,0.1-0.3,0.2-0.4c0-0.1,0.1-0.2,0.1-0.3c0.6-1.3,1-2.7,1.2-4.1
                                c0.1-0.4,0.1-0.8,0.2-1.1c1.3,0.3,2.6,1,3.7,1.8c0.6,0.4,2.1,1.9,2.4,2.5c0.2,2.4-0.8,4.9-2.1,6.9
                                C1209.1,84.1,1208.1,83.2,1208,83.1z M1210.4,74.8c-1.3-1.3-3.2-2.4-5-2.8c0.1-0.9,0.1-1.8,0-2.6c0-0.2,0-0.3-0.1-0.5
                                c1.7,0.5,4,1.4,4.6,2.9C1210.2,72.6,1210.3,73.7,1210.4,74.8L1210.4,74.8z M1169.8,101.7c-2.3-0.5-2.3-2.9-2.3-3
                                c0-0.2-0.1-0.3-0.2-0.4c-2.7-2-4.9-4.9-6.3-6.9c0.4,0.2,0.7,0.3,0.8,0.3c1,0.3,3.3,0.4,5.9,0.4c3,0,6.2-0.1,7.7-0.3
                                c2.7-0.3,3.5-1,4.6-2l0.3-0.2c0.9-0.7,2.1-0.7,3.4-0.8c0.3,0,0.7,0,1,0c0.7,0,1.3,0.2,2.1,0.4c1.4,0.4,3.3,1,6.7,0.7l1.3-0.1
                                c5-0.5,10.7-1,13.3-3.8c0.2-0.2,0.4-0.5,0.6-0.8c0.2,0.1,0.5,0.1,0.8,0c0.3-0.2,0.6-0.6,0.8-0.9c0.3-0.4,0.5-0.9,0.7-1.4
                                c0.3-0.7,0.5-1.3,0.7-2c0,0.4,0.1,0.8,0.1,1.1c0,1,0.1,2.1-0.1,3.1c-0.2,0.9-0.4,1.9-0.7,2.8c-0.3,0.5-0.6,0.9-0.9,1.4
                                c-1,1.5-2.2,2.8-3.9,3.3c-1.7,0.6-3.5,0.6-5.3,0.7c0-0.1-0.1-0.2-0.3-0.2c-2.3-0.8-4.7-1.1-7.1-1c-0.6,0-0.6,0.9,0,0.8
                                c1.8-0.1,3.6,0.1,5.3,0.5c-1.1,0.2-2.2,0.6-2.4,1.7c-0.2,0.8,0.3,1.2,1,1.5c0.2,0.1,0.5,0.2,0.7,0.4c-4.3,0.3-5.3-1.3-6.2-3
                                c-0.4-0.7-0.8-1.4-1.4-2c-0.1-0.1-0.2-0.1-0.3-0.1c-5.5-0.4-7.2,0.2-8.4,3c-1,1.9-3.5,4.2-6,5.8
                                C1173.1,102,1169.8,101.7,1169.8,101.7z M1221,93.5c1.1,0.5,8.2,3.5,10.8,5.3c2.8,2,19.1,17.5,21.7,20.3c0.4,0.5,1,1.1,1.6,1.7
                                c0.9,1.1,2.1,2.4,3.5,3.8c-3.7,0.8-10.2,4.5-13,6.1c0,0-0.1,0-0.2-0.1c-0.3-0.1-0.7-0.1-1-0.1l0,0c-0.7-0.1-1.5-0.2-2.2-0.2
                                c-1.1,0-2,0.2-3,0.5c-0.5,0.1-1,0.2-1.5,0.2s-1-0.1-1.5,0.1c-0.4,0.1-0.8,0.4-1.2,0.6c-0.6,0.2-1.1,0.3-1.7,0.2
                                c-1.1-0.1-2.1-0.2-3.2,0c-1,0.2-1.9,0.7-2.9,1.1c-2,0.7-4.2,0.2-6.2,0.4c-1.1,0.1-2.1,0.5-3.1,1c-0.6-0.9-1.6-1.3-2.5-1.8
                                c-0.5-0.3-0.9-0.6-1.3-1.1s-0.8-0.8-1.4-1c-0.7-0.2-1.2-0.3-1.6-0.9c-0.4-0.6-0.8-1.1-1.3-1.5c-0.7-0.6-1.8-1.3-2.8-1
                                c-0.2-0.4-0.4-0.9-0.5-1.4c-0.4-1.1-0.8-2.3-0.8-3.5c0.6-1.2,1.2-2.5,1.7-3.9c0,0,0.1-0.2,0.2-0.6c0.3,0.2,1,0.6,1.8,0.7
                                c0,0-0.3-1.9,0-2.9c0,0,2.8,0.7,4.7-0.6c0,0-1.7-1-2-2c0,0,0,0,0.1,0c0.7-0.3,1.3-0.8,1.8-1.4c0.5,0.1,3.2,0.4,4.7-1.9
                                c0,0-2.6,0.4-3.3-0.7c0.5-1.3,0.9-2.7,1.3-4c0.5-1.4,0.7-2.9,0.3-4.3c0.9-0.4,2-1.1,3-2.3c0,0-2.8-0.6-3.9-1.6
                                c0.2-0.2,0.5-0.3,0.8-0.5C1217.9,95,1219.3,93.9,1221,93.5z M1206.2,99.8c0.1,1.3,0.5,5.7,1.5,7.2c0,0,1.4-4.2,1.9-4.8
                                c0,0,2.2,0.3,3.1,1.3c0,0-0.2-2.8-0.6-3.2c0,0,1.6,0.9,3.8,0.3c0.4,1.3,0.1,2.6-0.3,3.9s-0.9,2.7-1.4,4.1c-0.4,1.2-1,2.4-2.1,3.1
                                c-1,0.7-2.4,0.7-3.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.4,0.1-0.5,0.3c-0.5,2.6-1.5,6.6-1.5,6.6
                                c-0.5,1.4-1.1,2.7-1.7,3.8c0,0,0,0,0,0.1c-4.3,8.5-10.9,12.4-13.2,12.1c-3.7-0.4-7.2-4.5-9-6.7c-0.3-0.4-0.6-0.7-0.8-1
                                c-5.8-6.3-7.8-21.8-8-23.9c0.9-0.2,1.8-0.5,2.6-1c2.7-1.6,5.3-4.1,6.4-6.2c1-2.2,2.1-2.8,7.2-2.4c0.4,0.5,0.8,1,1.1,1.6
                                c1.1,2,2.4,4.2,8.5,3.4c0.1,0,0.2-0.1,0.3-0.1c0.1,0.1,0.3,0.1,0.4,0.2c1.7,0.8,3.3,1.8,5.2,1.9
                                C1205.6,99.8,1205.9,99.8,1206.2,99.8z M1172.1,102.7c0.2,0,0.4-0.1,0.6-0.1c0.1,1,0.5,3.7,1.2,7.1
                                C1172.6,109,1172.1,105.3,1172.1,102.7z M1177.6,132.1c1.6-1.3,2.9-3.1,3.4-5.1c0.2,0.2,0.4,0.5,0.7,0.8c1.9,2.3,5.6,6.6,9.6,7
                                c0.1,0,0.2,0,0.3,0c2.8,0,8.9-3.8,13.1-11.5c0.2,0.9,0.4,1.7,0.7,2.5c0.2,0.7,0.5,1.3,0.8,2c0.2,0.4,0.4,0.7,0.6,1
                                c0,0.1,0.1,0.2,0.2,0.3c-2.8,5.2-5.5,11-10.9,14c-2.9,1.6-6.2,1.8-9.5,1.6c-1.8-0.1-3.5-0.3-5.1-1c-1.5-0.6-2.9-1.4-4.2-2.3
                                c-2.2-1.6-4.1-3.6-5.5-6C1173.9,134.6,1175.9,133.5,1177.6,132.1z M1132.5,299.2c-0.1-0.2-0.2-1.1-0.2-1.8c0-1.1-0.1-2.3-0.3-3.2
                                c-0.3-1-0.9-1.9-1.5-2.7c-0.5-0.7-0.9-1.3-1.2-2c-0.6-1.5,0-11,0.5-14.2c0.3-2,1.2-4.5,1.8-6c0.3-0.9,0.4-1.2,0.5-1.4
                                c0-0.2-0.1-0.4-0.4-0.5c-0.2,0-0.4,0.1-0.5,0.4c0,0.1-0.2,0.7-0.4,1.2c-0.1,0.3-0.3,0.7-0.4,1.1c0-2.4,0-6-0.1-10.1
                                c-0.1-9.3-0.3-20.8,0.1-25.9c0.3-5.4,3.2-19,5.2-29c1.2-5.9,2.2-10.5,2.3-11.7c0.2-2.5,0.2-5.1,0.2-6.2c1,0.3,2,0.5,3,0.8
                                c1.8,0.5,3.6,0.9,5.4,1.3c3.4,0.8,6.8,1.4,10.3,1.9c-1.6,4.2-2.2,8.7-3.7,13c-0.9,2.8-2.3,5.4-3.4,8.1c-0.7,1.6-1.4,3.3-1.9,4.9
                                c-0.2,0.5,0.7,0.8,0.8,0.2c0.8-3.1,2.3-6,3.6-8.9c0.3-0.6,0.6-1.3,0.8-2c-0.4,2.8-0.8,5.6-1.1,8l-0.2,1.4
                                c-0.6,4.5-3.2,19.9-4.8,26.7c-1.4,5.6-2.7,21.3-3.2,26.6c-0.4-1.2-0.7-2.4-0.7-2.4c-0.1-0.2-0.3-0.4-0.5-0.3s-0.4,0.3-0.3,0.5
                                c0,0.1,0.7,2.8,1.4,4.4c0.7,1.7,2.7,5.7,3.4,7c0.7,1.3,1.8,5.6,1.9,7.7v0.1c0,0,1.6,4.9,0.7,10.1c-0.2-0.2-0.3-0.4-0.5-0.6
                                c-0.7-0.8-1.3-1.6-1.9-2.5c-1.2-1.7-2.1-3.6-2.7-5.6c0.1,0,0.2-0.1,0.2-0.1c0.5-0.1,0.3-1-0.2-0.8c-0.9,0.3-1.7,0.7-2.5,1.1
                                c-0.7,0.4-1.5,0.8-2,1.5c-1,1.3-0.9,3-1.4,4.6c-0.3,0.9-0.7,1.7-1,2.6s-0.3,1.8-0.2,2.8c0.1,0.8,0.2,1.6,0.6,2.4
                                c0.1,0.1,0.2,0.3,0.2,0.4C1135.1,301.2,1132.9,299.6,1132.5,299.2z M1156.8,197.8c0,0.9-0.3,1.9-0.1,2.8c0.2,0.8,0.6,1.5,1,2.3
                                c0.5,1.1,0.4,2.2,0.2,3.4c-0.2,1.1-0.2,2.1,0,3.1c0.2,1.1,0.3,2.1,0.2,3.2c-0.1,1.1-0.4,2.3-1,3.3c-0.1,0.1-0.1,0.2,0,0.3
                                c-0.3,0.4-0.6,0.8-0.8,1.2c-0.5,1.2-0.2,2.4,0.2,3.5c0.2,0.6,0.5,1.3,0.6,2c0.1,0.8,0,1.6-0.2,2.4c-0.5,1.5-1.4,2.7-2.2,4
                                c-0.7,1.3-1.2,2.6-1.3,4.1c-0.1,1.6,0.2,3.2,0.3,4.7c0.1,1.6-0.1,3.2-0.3,4.9c-0.3,1.6-0.5,3.1-0.6,4.7c-0.1,1.6-0.2,3.1-0.8,4.6
                                c-0.2,0.6-0.5,1.2-0.7,1.9c-0.2,0.8-0.3,1.6-0.3,2.4c0,0.7,0,1.4-0.1,2.1c-0.1,0.8-0.4,1.6-0.8,2.3c-0.7,1.2-1.6,2.2-2.2,3.5
                                c-0.5,1-1,2.3-0.5,3.4c0.2,0.5,0.7,0.8,1.2,1c-0.5,1.2-1.9,5.2-2,7.6c-0.8-1.7-2-4-2.5-5.2c0,0,0,0,0-0.1
                                c0-0.2,1.7-21.6,3.3-28.2c1.7-6.8,4.2-22.3,4.8-26.8l0.2-1.4c0.6-4.4,1.4-10.3,2.3-14.1v-0.1c0.2-0.6,0.3-1.3,0.5-1.9
                                c0.4-1.7,0.8-3.4,1.3-5c0,0.3,0,0.6,0,0.9C1156.6,195.7,1156.8,196.7,1156.8,197.8z M1142.7,305.6c-1.3-0.4-2.2-1.1-2.9-2.2
                                c-0.6-0.9-1.2-1.8-1.5-2.9c-0.2-0.8-0.3-1.7-0.3-2.6s0.4-1.7,0.7-2.5c0.3-0.8,0.6-1.6,0.8-2.5c0.1-0.8,0.2-1.6,0.6-2.4
                                s1-1.3,1.8-1.8c0.5-0.3,1.1-0.6,1.6-0.8c1,3,2.5,5.8,4.6,8.2c0.6,0.6,1.1,1.2,1.3,2.1c0.2,0.8,0.2,1.7,0,2.5
                                c-0.3,1.5-1.2,3-2.5,3.9C1145.7,305.5,1144.2,306,1142.7,305.6z M1168.9,453c0,0,1.3-0.6,1.6-1.7l0,0c1,0.1,1.1,1.5,1.1,2.9
                                c0,1,0,3.7-6.2,4.2c-5.6,0.5-12.4,0.7-15.1,0c-2.6-0.6-4.9-1.9-6-3.3c-0.5-0.6-1.1-1-1.6-1.3c-0.9-0.5-1.5-0.9-1.7-2.5
                                c0-0.5,0.1-2.3,0.8-3.4c0.2,0.9,0.4,1.6,0.7,2c0.9,1.3,1.3,3.5,9.2,3.8S1166.3,453.9,1168.9,453z M1231.7,437.9
                                c0,0,0-0.2-0.2-0.7c0.3,0.1,0.6,0.3,0.7,0.6c0.7,1.1,0.1,3.1-0.2,3.8c-0.3,0.8-3.2,2.5-8.7,2.7c-6.7,0.2-11.8-0.8-13.6-2.5
                                c-1.4-1.4-5.6-3.6-8.1-4.8c-0.5-0.3-0.9-0.5-1.3-0.6c-0.8-0.4-2.3-0.9-3.8-1.4c-1.6-0.5-3.8-1.2-4.1-1.6c-0.6-0.8-0.9-2-0.9-3.8
                                c0-0.8,0.5-1,0.8-1.1c0,0.4,0.1,0.8,0.3,0.9c0.9,0.6,6.1,1.5,9.3,4c3.2,2.5,7.6,5,13,6C1220.5,440.3,1226.9,440,1231.7,437.9z
                                M1236.9,161.4c-1.2,0.2-2.2,0.3-3.2,1c-1,0.8-1.8,1.8-2.5,2.8c-0.9,1.2-1.8,2.4-2.6,3.7c-1.5,2.5-2.4,5.4-3.1,8.2
                                c-0.3,1.5-0.7,2.9-1,4.4c-0.1,0.7-0.2,1.4-0.3,2.1c-0.1,0.8,0.1,1.6,0.1,2.4c0.2,2.9-0.9,5.7-1.4,8.5c-0.3,1.4-0.3,2.9-0.2,4.3
                                c0.2,1.6,0.5,3.1,0.4,4.7c-0.2,3-1.6,5.6-2.1,8.5c-0.5,3.2,0.4,6.4-0.1,9.5c-0.3,1.6-1,3.2-1.5,4.8c-0.5,1.5-0.8,3.1-0.5,4.7
                                c0.1,0.5,0.9,0.3,0.8-0.2c-0.2-1.1,0-2.2,0.2-3.3c0.6,1.1,0.9,2.4,1.2,3.6c0.3,1.5,0.5,2.9,0.7,4.4c0.2,1.4,0.7,2.7,1.4,4
                                c0.6,1.3,1.3,2.5,1.6,3.9c0.3,1.5,0.2,3.1,0.1,4.6c0,1.5,0.1,3,0.6,4.5c0.9,2.4,3,5.3,1.8,8c-0.6,1.2-1.8,2-3,2.4
                                c-1.4,0.5-2.8,0.9-4.2,1.2c-3,0.6-6.1,0.8-9.1,1.1c-3,0.4-5.9,1-8.9,1.7c-3.1,0.7-6.2,1.5-9.3,1.7c-6.3,0.5-12.7-1.1-19-0.7
                                c-3.3,0.2-6.5,0.8-9.8,0.8c-1.6,0-3.2,0-4.9-0.2c-1.3-0.1-2.6-0.3-3.9-0.4c-1.9-0.1-3.9,0.1-5.8-0.2c-0.9-0.2-1.4-0.5-1.3-1.5
                                c0.1-1.3,1-2.5,1.7-3.6c0.8-1.1,1.6-2.2,1.9-3.5c0.3-1.4,0.1-2.9,0.3-4.4c0.2-1.5,1-2.8,1.3-4.2c0.3-1.5,0.3-3,0.4-4.5
                                c0.1-1.6,0.5-3.1,0.7-4.6s0.2-3,0.1-4.5c-0.2-1.5-0.4-3.1-0.2-4.6c0.2-1.5,0.9-2.7,1.7-4c0.8-1.3,1.6-2.6,1.9-4s0-2.6-0.5-3.9
                                c-0.4-1.2-0.8-2.3-0.1-3.5c0.5-0.9,1.3-1.5,2.1-2.2c0.7-0.7,1.4-1.5,1.7-2.4c0.2-0.5-0.6-0.7-0.8-0.2c-0.3,0.9-1,1.6-1.7,2.3
                                c0.4-1.2,0.6-2.6,0.4-3.9c-0.1-1-0.4-2-0.4-3c0-1.1,0.3-2.1,0.3-3.1c0.1-0.9-0.2-1.7-0.6-2.6c-0.4-0.8-0.9-1.6-0.9-2.5
                                c0-0.5,0.2-1.1,0.2-1.6s0-1.1,0-1.6c-0.1-1.1-0.1-2.1-0.1-3.2c0.1-2.2,0.4-4.3,0.8-6.5c0.4-2.1,0.7-4,0-6.1
                                c-0.6-2-1.6-3.9-2.3-5.8s-1.3-3.9-1.9-5.9c-1.3-4.6-2.4-9.2-3.4-13.9c-0.1-0.5-0.9-0.3-0.8,0.2c1.8,8.1,3.7,16.1,6.9,23.7
                                c0.4,0.9,0.8,1.9,1,2.9c0.2,1.1,0.2,2.1,0,3.2c-0.3,1.7-0.7,3.4-0.9,5.1c-6-0.9-12-2.1-17.9-3.8c-0.4-0.1-0.9-0.2-1.3-0.4l0,0
                                c-0.3-0.1-0.6-0.2-0.9-0.3c-0.6-0.2-1.3-0.3-1.7-0.8c-0.5-0.5-0.2-1.1,0.1-1.7c0.2-0.4,0.4-0.8,0.6-1.2c0.7-1.7,1.3-3.5,1.8-5.3
                                c1.1-4,1.7-8.2,2.4-12.3c0.6-3.8,1.2-7.7,2.2-11.4c0.4-1.6,0.9-3.2,1.8-4.7c0.5-0.8,1-1.6,1.8-2.1c0,0.3,0,0.6,0,0.8
                                c0,0.5,0.9,0.6,0.8,0c0-0.5-0.1-1,0-1.5c0,0,0,0,0-0.1c0.1-0.5,0.3-1,0.7-1.3c0.8-0.6,1.9-1.1,2.8-1.5c2.1-0.9,4.3-1.4,6.4-2.3
                                c1-0.4,1.9-1,2.9-1.4s2.1-0.7,3.2-1s2.1-0.7,3.1-1.2c0.4-0.2,0.9-0.5,1.4-0.7s1-0.4,1.4-0.6c0.9-0.5,1.1-1.7,2.1-2.1
                                c1-0.4,1.9-0.1,2.8,0.4c-0.6,0.3-1.2,0.6-1.9,0.9c-0.1-0.2-0.2-0.5-0.3-0.7c-0.2-0.5-1-0.1-0.7,0.4c1.2,2.8,3.2,5.1,5.6,7
                                c2.6,2.1,5.6,3.6,8.9,4c3.4,0.4,7.1,0.5,10.3-0.9c2.8-1.2,5.2-3.3,7.1-5.8c2.4-3.1,4.1-6.7,6-10.1c0.2-0.3-0.1-0.8-0.5-0.6
                                c-0.1,0-0.2,0.1-0.3,0.1c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.4c1-0.1,1.9,0.8,2.5,1.5c0.4,0.5,0.7,1.1,1.2,1.4
                                c0.4,0.3,0.9,0.4,1.4,0.6c0.6,0.2,0.9,0.5,1.3,1c0.3,0.4,0.7,0.7,1.1,0.9c0.8,0.5,1.8,0.8,2.4,1.6c-0.9,0.4-1.8,0.8-2.8,0.9
                                c-0.5,0.1-0.3,0.9,0.2,0.8c1-0.2,2-0.5,2.9-1c0.9-0.4,1.8-1,2.8-1.2c2-0.4,4.1,0.1,6.1-0.3c1-0.2,1.9-0.6,2.8-1
                                c1-0.4,2.1-0.4,3.2-0.3c1,0.1,1.9,0.2,2.9-0.2c0.4-0.2,0.8-0.5,1.3-0.6s1.1,0,1.6,0c1.1-0.1,2.1-0.6,3.3-0.7c0.7-0.1,1.4,0,2.2,0
                                c-7,7.7-7.7,18.3-7.4,24c0.1,2.6,1.7,4.8,2.5,5.7C1238,161.1,1237.5,161.3,1236.9,161.4z M1240.1,160.4c-0.2,0.1-0.4,0.2-0.7,0.3
                                c-0.3-0.3-1-1.1-1.5-2.1c2.3-0.7,4.5-1.5,6.7-2.3C1243.6,158.1,1242,159.5,1240.1,160.4z M1237.5,157.8L1237.5,157.8
                                c-0.4-0.7-0.7-1.6-0.7-2.5c-0.2-5.5,0.4-15.7,7-23.2v0.1c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.2-0.1c0.1-0.1,10.3-6.3,14.7-7
                                c2.7,2.6,6.2,5.2,10.4,6.6c0.1,0,0.1,0,0.1,0c0.2,0,0.3-0.1,0.4-0.3s-0.1-0.5-0.3-0.5c-6.7-2.2-11.5-7.6-14.3-10.8
                                c-0.6-0.7-1.1-1.3-1.6-1.8c-2.6-2.7-18.9-18.3-21.8-20.4c-2.1-1.5-7.1-3.7-9.6-4.8c0.3-0.2,0.3-0.8-0.2-0.8s-1.1,0-1.5,0.2h-0.1
                                c-2.8,0.7-5,3.1-7.6,4.3c-1.6,0.7-3.4,1.2-5.1,1.6c-0.9,0.2-1.9,0.5-2.8,0.4s-1.9-0.4-2.7-0.8c-0.9-0.4-1.7-0.8-2.5-1.2
                                c-0.8-0.4-1.7-0.8-2.5-1.2c-1-0.6,0.4-1.4,1-1.5c0.9-0.2,1.9-0.2,2.9-0.2c1.7-0.1,3.5-0.2,5.1-0.8c1.5-0.5,2.6-1.5,3.6-2.7
                                c0.5-0.7,0.9-1.4,1.4-2.1c0.1-0.1,0.2-0.1,0.2-0.2s0-0.1,0-0.2c0.4-0.7,0.8-1.3,1.2-1.9c1.1-1.5,2.4-2.9,4-4c0.5-0.3,0-1-0.4-0.7
                                c-1.4,0.9-2.6,2.1-3.7,3.5c-0.2,0.2-0.3,0.4-0.4,0.6c0-0.1,0.1-0.3,0.1-0.4c0.2-1.1,0.2-2.2,0.1-3.3c-0.1-2.2-0.3-4.5-0.8-6.7
                                c0-0.2-0.1-0.2-0.3-0.3c0-0.4-0.1-0.9-0.1-1.3c0.1,0.2,0.3,0.5,0.5,0.7c0.5,0.7,1.1,1.3,1.6,2c0.4,0.8,0.7,1.6,0.9,2.5
                                c0.1,0.5,0.9,0.3,0.8-0.2c-0.3-1.6-1-2.9-2-4.1c-0.5-0.6-1.1-1.2-1.3-1.9c-0.3-0.8-0.2-1.6,0.2-2.4c0.2-0.5-0.5-0.9-0.7-0.4
                                s-0.4,1-0.4,1.5c-1.1-2.5-5.6-3.5-6.4-3.7c-1.3-1-3.5-1.4-4.8-1.6c0.5-0.2,1.1-0.4,1.4-0.5c0.8-0.3,9.3-0.6,10.4,0
                                c0.3,0.2,0.8,0.4,1.4,0.7c2.2,1.1,6.3,3.1,7.3,3.9c0.9,0.8,10.1,9.5,10.2,9.6c0.5,0.4,11.9,9.6,16.6,12.1
                                c4.5,2.4,33.3,20.5,37.3,30.1c0,0.1,1.8,5.4-2.2,9.3C1280,133.1,1262.4,150.3,1237.5,157.8z"/>
                            <path name="JUAN"  class="st2" d="M1194.6,122c-3.4,2-7.4,1.1-11,0.4c-0.5-0.1-0.8,0.7-0.2,0.8c3.9,0.7,8.1,1.6,11.7-0.5
                                C1195.5,122.5,1195.1,121.8,1194.6,122z"/>
                            <path name="JUAN"  class="st2" d="M1186.4,118.7c1.2,0.3,2.6,0.3,3.7-0.3c0.5-0.3,0.1-1-0.4-0.7c-1,0.6-2.2,0.5-3.3,0.1
                                c-0.5-0.2-1-0.4-1.5-0.7c-0.6-0.4-0.8-0.8-0.7-1.5c0.2-2.6,0.7-5.2,1.4-7.8c0.1-0.5-0.7-0.8-0.8-0.2c-0.4,1.5-0.8,3.1-1,4.7
                                c-0.1,0.8-0.2,1.5-0.3,2.3c-0.1,0.7-0.2,1.4,0,2.1C1183.7,117.8,1185.3,118.4,1186.4,118.7z"/>
                            <path name="JUAN"  class="st2" d="M1196.3,195.9c-4.3,4.7-8.5,9.4-12.8,14.1c-4.1,4.6-8.2,9.2-12.4,13.6c-2.3,2.4-4.7,4.7-7.2,6.8
                                c-0.4,0.4,0.2,1,0.6,0.6c4.5-3.8,8.5-8.2,12.5-12.6c4.2-4.7,8.5-9.4,12.8-14.1c2.4-2.6,4.8-5.2,7.1-7.9
                                C1197.3,196.1,1196.7,195.5,1196.3,195.9z"/>
                            <path name="JUAN"  class="st2" d="M1187.6,215.1c-5.9,5.5-11.2,11.5-15.8,18.1c-0.3,0.4,0.4,0.9,0.7,0.4c4.6-6.5,9.9-12.5,15.7-17.9
                                C1188.6,215.4,1188,214.8,1187.6,215.1z"/>
                            <path name="JUAN"  class="st2" d="M1221.1,138.1c-0.2-0.5-1-0.1-0.7,0.4c3.3,6.8,4.9,14.2,6,21.7c0.3,2.1,0.6,4.2,0.9,6.3
                                c0.1,0.5,0.9,0.3,0.8-0.2c-1-7.5-2.1-15.1-4.6-22.3C1222.8,141.9,1222,139.9,1221.1,138.1z"/>
                        </g>
                        
                            <ellipse name="JUAN"  transform="matrix(0.9319 -0.3628 0.3628 0.9319 -30.6752 435.2862)" class="st3" cx="1143.6" cy="299.3" rx="3.8" ry="4.4"/>
                        <path name="JUAN"  class="st2" d="M1147.6,297.7c-1-2.5-3.5-3.8-5.7-3c-1.1,0.4-1.8,1.3-2.2,2.4c-0.4,1.1-0.3,2.4,0.1,3.6
                            c0.5,1.2,1.3,2.2,2.3,2.7c0.6,0.3,1.3,0.5,2,0.5c0.4,0,0.9-0.1,1.3-0.2c1.1-0.4,1.8-1.3,2.2-2.4
                            C1148.1,300.2,1148,298.9,1147.6,297.7z M1146.9,301.1c-0.3,0.9-0.9,1.6-1.8,1.9c-0.8,0.3-1.8,0.2-2.6-0.2c-0.9-0.5-1.6-1.3-2-2.3
                            s-0.4-2.1-0.1-3.1c0.3-0.9,0.9-1.6,1.8-1.9c0.3-0.1,0.7-0.2,1-0.2c1.4,0,2.9,1.1,3.6,2.7C1147.2,299,1147.3,300.1,1146.9,301.1z"
                            />
                        <g>
                            <path name="JUAN"  class="st3" d="M1151.2,275.3c5.6,0.4,11.3-0.8,16.2-3.5c0.5-0.3,0.1-1-0.4-0.7c-4.8,2.6-10.3,3.8-15.8,3.4
                                C1150.7,274.4,1150.7,275.3,1151.2,275.3L1151.2,275.3z"/>
                            <path name="JUAN"  class="st3" d="M1160.3,277.3c1.3-0.3,2.5-0.7,3.7-1.2c0.2-0.1,0.3-0.4,0.2-0.6c-0.1-0.2-0.4-0.2-0.6-0.1
                                c-1.1,0.5-2.3,0.9-3.5,1.1c-0.2,0-0.4,0.3-0.3,0.5C1159.8,277.3,1160,277.4,1160.3,277.3L1160.3,277.3z"/>
                            <path name="JUAN"  class="st3" d="M1191.4,272.9c-0.4,3.8-0.7,7.6-0.5,11.4c0.1,1.5,0.8,2.6,2.3,3.2c1.6,0.7,3.3,0.8,5,0.5
                                c0.5-0.1,0.3-0.9-0.2-0.8c-1.5,0.3-3.3,0.2-4.7-0.5c-0.6-0.3-1.1-0.7-1.3-1.3c-0.3-0.7-0.2-1.6-0.2-2.4c0-3.3,0.2-6.7,0.6-10
                                C1192.3,272.3,1191.4,272.3,1191.4,272.9L1191.4,272.9z"/>
                            <path name="JUAN"  class="st3" d="M1200.2,270.2c-0.9,5.2-1.4,10.4-1.5,15.7c0,0.5,0.8,0.5,0.8,0c0.1-5.2,0.6-10.4,1.5-15.5
                                C1201.1,269.9,1200.3,269.6,1200.2,270.2L1200.2,270.2z"/>
                            <path name="JUAN"  class="st3" d="M1214,267.6c0.9,0.6,2,0.7,3.1,0.7c1.3,0,2.6,0,3.9-0.2c0.5,0,0.6-0.9,0-0.9c-1.2,0.1-2.4,0.2-3.6,0.2
                                c-1,0-2.1,0-2.9-0.5C1214,266.5,1213.6,267.3,1214,267.6L1214,267.6z"/>
                            <path name="JUAN"  class="st3" d="M1190.6,302.1c1.7-1,2.9-2.3,3.7-4.1c0.8-1.9,1.8-3.7,3.2-5.3c0.3-0.4-0.3-1-0.6-0.6c-1.2,1.5-2.3,3.2-3.1,5
                                c-0.4,0.9-0.8,1.8-1.4,2.5s-1.4,1.3-2.2,1.8C1189.7,301.6,1190.1,302.4,1190.6,302.1L1190.6,302.1z"/>
                            <path name="JUAN"  class="st3" d="M1147.7,324.8c0.9-4.5,2.1-9,3.6-13.4c0.2-0.5-0.6-0.7-0.8-0.2c-1.5,4.4-2.7,8.8-3.6,13.4
                                C1146.8,325.1,1147.6,325.3,1147.7,324.8L1147.7,324.8z"/>
                            <path name="JUAN"  class="st0" d="M1142.8,439.4c2.7-1.3,4.9-3.6,7.7-4.8c3.4-1.5,7.1-0.5,10.6,0.4c0.5,0.1,0.8-0.7,0.2-0.8
                                c-3.5-1-7.3-1.9-10.8-0.5c-3,1.2-5.2,3.6-8.1,5C1141.9,438.9,1142.3,439.6,1142.8,439.4L1142.8,439.4z"/>
                            <path name="JUAN"  class="st0" d="M1195.2,424.7c1.5,0.6,3,1.2,4.6,1.2c1.7,0,2.9-0.7,4.1-1.7c2.5-2,5.7-2.9,8.9-2.6c0.6,0,0.5-0.8,0-0.9
                                c-3-0.3-6,0.5-8.5,2.1c-1.3,0.9-2.4,2.1-4.1,2.2s-3.2-0.6-4.7-1.2C1194.9,423.7,1194.7,424.5,1195.2,424.7L1195.2,424.7z"/>
                            <path name="JUAN"  class="st3" d="M1226,350.4c-0.8-4.1-1.8-8.2-1.8-12.4c0-0.5-0.9-0.5-0.8,0c0,4.3,1,8.5,1.8,12.7
                                C1225.2,351.2,1226.1,350.9,1226,350.4L1226,350.4z"/>
                        </g>
                    </g>
                </g>
                <g id="RICK" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="RICK"  class="st0" d="M1092.5,181.6c0.4-0.2,0.7-0.4,1.1-0.7c0.3-0.2,0.7-0.4,0.9-0.6c0.4-0.5-0.3-1.5-0.6-2l0,0
                                c0-0.1-0.1-0.1-0.1-0.1c-0.4-0.7-0.7-1.3-1.1-1.9c-0.4-0.6-0.8-1.1-1.6-1.1c-0.6-0.1-1.2-0.1-1.8-0.4c-0.5-0.2-0.9-0.6-1.3-1
                                c-0.5-0.4-1.1-0.8-1.8-0.6c-0.7,0.1-1.3,0.5-1.8,0.9c-1.1,0.8-2.2,1.7-3.2,2.6l-0.1,0.1c-1.2,1-2.3,2.1-3.4,3.2
                                c-1.9,1.9-3.8,3.8-5.6,5.8c5.3-0.4,10.4-0.4,13.4,0.5C1087.7,184.6,1090.1,183.1,1092.5,181.6z"/>
                            <path name="RICK" class="st0" d="M1022.7,199.1c0.8,0.2,2,0.4,3,0.6c-0.9-0.9-1.6-1.9-2-2.4c0,0,0,0-0.1,0c-0.2,0.1-0.6,0.1-1.1,0.1
                                c-0.4,0-1,0-1.6,0c-0.2,0-0.4,0-0.5,0C1020.9,198,1021.7,198.8,1022.7,199.1z"/>
                            <path name="RICK" class="st0" d="M1103,185.6c-0.3,1.4-0.9,3.2-1.5,5.1c1.1-0.5,2-1.2,2.5-2.2c0-0.1-0.1-0.1-0.1-0.2
                                C1103.9,187.3,1103.5,186.4,1103,185.6z"/>
                            <path name="RICK" class="st0" d="M1021,196.5c0.9,0,2.2,0.1,2.4,0l0.1-0.1l0.1-0.1c0.9-1,3-6.3,3.1-7.2c0.1-0.4,0-0.7-0.1-0.8
                                c0,0-0.1-0.1-0.2-0.1c0.1,0.4-0.1,0.9-0.1,1c0,0-1.4,3.3-2.2,5c-0.6,1.4-1,1.6-1.2,1.7c-0.1,0-0.2,0.1-0.3,0c-0.2,0-1.1,0-1.9,0
                                c-0.5,0-1,0-1.2,0h-0.1c0.1,0.3,0.2,0.6,0.4,0.6C1020,196.5,1020.5,196.5,1021,196.5z"/>
                            <path name="RICK" class="st0" d="M1099.8,182.2c-1.4-1.1-3-2-4.5-3c0,0.1,0.1,0.2,0.1,0.3c0.2,0.7,0,1.2-0.6,1.6c-0.7,0.5-1.4,0.9-2.1,1.3
                                c-3,1.9-6.1,3.9-9.1,5.8c-0.5,0.3-0.9-0.4-0.4-0.7c0.4-0.2,0.8-0.5,1.2-0.8c-6.7-1.5-22.2,0.6-29,1.6c-1.8,0.2-3,0.4-3.6,0.4
                                c-0.2,0-0.5,0-0.9,0c-2.4,0-7.6-0.3-12.2-0.5c-3.9-0.2-7.6-0.4-8.5-0.4c-1.6,0.1-2.4,0.4-2.7,0.6c0,0.2,0,0.5,0,0.8
                                c-0.1,0.5-0.8,2.5-1.5,4.2c-0.8,1.8-1.3,2.8-1.7,3.3c0.4,0.6,1.6,2.4,3,3.2c0.8,0.4,33.3,14.2,44.4,15.8c2.6,0,19.7-4.4,21.5-7
                                c2-2.8,8-18.4,9.1-24C1101.6,183.7,1100.7,182.9,1099.8,182.2z"/>
                            <path name="RICK" class="st0" d="M1066.9,123.9c0.9,1.1,1.5,2.3,2.1,3.6c0.3,0.7,0.6,1.5,0.8,2.2c0.2,0.5,0.3,1.1,0.3,1.6s-0.8,0.6-0.8,0
                                c0-0.5-0.2-1-0.3-1.5c-0.2-0.7-0.5-1.4-0.8-2c-0.4-1-0.9-2-1.6-2.9c-1.1,3.7-2.5,7.2-3.3,8.3c-2,2.6-7.1,3.4-7.3,3.4
                                c-0.8,0.1-1.5,0.2-2.3,0.2c-4.7,0-8.7-2.2-12-5c0.1,1.5,0,3.1-0.1,4.6l0,0c0.7,1.3,1.2,2.8,1.7,4.2c0.3,1,0.7,2,1.3,2.9
                                c0.7,1.1,1.5,2,2.3,2.9c1.6,1.9,3,3.9,4,6c1.1,2.2,1.6,4.5,2,6.9c0.4,2.1,0.7,4.2,1.7,6.1c0.2-0.6,0.4-1.3,0.6-1.9
                                c1.1-3.6,2.3-7.3,3.8-10.8c2.9-6.6,7.4-12.5,9.9-19.4c0.2-0.5,1-0.3,0.8,0.2c-1.3,3.8-3.3,7.3-5.3,10.7c-1.8,3.2-3.7,6.4-5.1,9.8
                                c-1.5,3.5-2.6,7.2-3.7,10.8c-1.2,3.8-2.3,7.6-3.5,11.4c-1.1,3.7-2.2,7.5-2.1,11.4c0,0,0,0,0,0.1c0.9,0,1.5,0,1.9,0
                                c0.5,0,1.8-0.2,3.5-0.4c3.8-0.5,9.7-1.3,15.5-1.8c0,0,0,0,0-0.1c2.4-2.8,5-5.5,7.8-8c0.6-0.5,1.2-1.1,1.8-1.6
                                c0.3-2.5,1-5,1.8-7.4c0.8-2.2,1.6-4.4,2.3-6.6c0.7-2.1,1.2-4.2,1.7-6.4c1-4.1,2.1-8.4,4.7-11.8c0.3-0.4,1.1,0,0.7,0.4
                                c-1.5,1.9-2.4,4-3.2,6.3c-1.3,4.1-2,8.3-3.4,12.4c-1.4,4-3.1,8-3.7,12.3c0.3-0.3,0.7-0.6,1-0.8c1.1-0.9,2.3-2.1,3.8-2.2
                                c1.4-0.2,2.2,0.9,3.3,1.6c0.6,0.4,1.3,0.4,2,0.5s1.2,0.4,1.5,1c0.5,0.7,0.9,1.4,1.3,2.1c0.1,0.1,0.2,0.3,0.2,0.4
                                c2.1,1.3,4.3,2.6,6.2,4.1c0.9,0.7,1.7,1.5,2.3,2.3c0.1,0.1,0.1,0.1,0.2,0.2c0.6,0.9,1.2,1.9,1.4,2.9c0.2-0.5,0.3-1.1,0.4-1.7
                                c0.3-3.1-0.8-6.1-1.5-9.1c-1.3-6.1,0-12.4-0.2-18.5c-0.1-3.1-0.5-6.1-1-9.2c-0.4-2.7-0.7-5.7-2.2-8.1c-1.4-2.3-4-3.5-6.5-4.3
                                c-2.7-0.8-5.5-1.3-8.2-2.3c-4.2-1.7-8.1-4.1-12.1-6.2c0.8,2,1.2,4.2,1.2,6.4c0,2.7-0.6,5.5-1.3,8.1c-0.6,2.3-1.4,4.9-3.1,6.6
                                c-0.4,0.4-0.9,0.7-1.4,0.4c-0.2-0.1-0.4-0.3-0.5-0.5c-0.5-0.7-1-1.4-1.4-2.2c-0.3-0.5,0.4-0.9,0.7-0.4c0.5,0.7,0.9,1.4,1.4,2.1
                                c0.1,0.1,0.1,0.2,0.2,0.2c0.3,0,0.5-0.4,0.7-0.7c0.4-0.5,0.7-1,1-1.5c1.1-2,1.6-4.2,2-6.4c0.5-2.4,0.9-4.8,0.7-7.3
                                c-0.1-1.8-0.6-3.7-1.5-5.5c-0.1-0.1-0.2-0.2-0.1-0.3c-1-2.1-2.6-4-4.5-5.2C1067.3,122.5,1067.1,123.2,1066.9,123.9z"/>
                            <path name="RICK" class="st0" d="M1019.2,195c0,0.1,0.2,0.1,0.2,0.1c0.8,0,3.1,0,3.2,0c0.1-0.1,0.3-0.3,0.7-1.2c0.7-1.7,2.2-5,2.2-5
                                c0.1-0.2,0.1-0.6,0.1-0.6s-0.1-0.1-0.4,0c-2.3,0.5-4.1,1.2-4.4,1.6l-1.6,4.9C1019.2,194.7,1019.2,194.9,1019.2,195z"/>
                            <path name="RICK" class="st0" d="M1016.3,196.3c0,0.1,0,0.2-0.1,0.2l0,0c0.3,0.2,0.7,0.4,1.2,0.6c0.6,0.2,1.3,0.4,2,0.5c0.1,0,0.2,0,0.2,0.1
                                c-0.1-0.2-0.2-0.3-0.3-0.4c-0.8-0.2-1-1.4-1-2.1c0-0.1,0-0.1,0-0.1c-0.1-0.3,0-0.6,0-0.7l1.3-4
                                C1018.1,191.4,1016.9,193.4,1016.3,196.3z"/>
                            <path name="RICK" class="st0" d="M1083.5,451.9v-0.1c-0.2,0.1-0.8,0.3-0.7,1.2v0.1c0.2,1.1,0.3,2.7,1.6,3.7c1.2,1,18.3,0.9,20.8,0
                                c1.2-0.5,1.6-1.8,1.9-3.5c0.2-1.1-0.3-1.4-0.5-1.4l0,0c-0.2,0.9-0.6,1.2-0.6,1.2c-0.9,0.4-11.1,0.6-11.1,0.6
                                C1083.6,454,1083.5,451.9,1083.5,451.9z"/>
                            <path name="RICK" class="st0" d="M1038.2,127.1c3.9,4.9,9.9,9.8,17.5,8.7c0,0,5-0.8,6.8-3.1c1.5-1.9,5.2-13.7,5.2-17.2c0-1.5-0.6-3.9-1.4-6.8
                                c-0.9-3.5-2-7.5-2.1-11c0-0.1,0-0.2,0.1-0.3c-0.1-0.1-0.2-0.3-0.4-0.6c-1.2-2.3-3.8-6.5-6.5-8.1s-4.2-3.3-6-3.2
                                c-1.6,0.1-3.8,0.7-6.7,2.2c-2.9,1.5-6.5,2.8-7.2,3.4c-1.5,1.4-4.2,2.7-6.2,2.7c0,0,3.1,5.3,2.6,6.7s-1.8,2.3-1.8,2.3
                                s-1.3,0.8,0.1,5.2c0,0,1.1,2.2-0.3,2.7c-1.5,0.6-2.3,0.5-4-2.5c0,0-0.4-0.7-1.1-1.2c-0.2,0.2-0.4,0.2-0.6,0
                                c-0.5-0.6-1.4-0.1-1.6,0.6c-0.3,0.7-0.2,1.6-0.2,2.3c0,0.9,0.2,1.8,0.5,2.6c0.3,0.8,0.8,1.5,1.2,2.2c0.9,1.4,1.9,2.8,3.1,4.1
                                c0.9,1,2.5,2.1,3.9,1.4c0-0.1,0.1-0.1,0.1-0.1c0.4-0.2,0.5,0.1,0.7,0.5C1034.8,122.3,1036.3,124.7,1038.2,127.1L1038.2,127.1z"/>
                            <path name="RICK" class="st0" d="M1094.8,257.9c0.3-0.6,0.3-1.4,0.5-2c0.3-1.6,0.5-3.3,0.7-4.9c0.2-1.6,0.2-3.2,0.1-4.7
                                c-0.1-1.6-0.5-3.1-0.9-4.6c-0.8-2.8-1.7-5.5-2-8.4c-0.3-2.6-0.2-5.3,0-7.9c0.3-5.1,1.1-10.4,0.2-15.5c-3.9,3-19.8,6.8-21.6,6.8
                                c-1.4-0.2-3-0.6-4.9-1.1c-15.8,4.9-28.8,6.1-37.4,6.1c-4,0-7.1-0.3-9.1-0.6c-0.3,1-0.6,2-0.8,2.9c-0.6,2.5-1,5-1.2,7.5
                                c-0.2,3,0.2,6.1,0.3,9.1s-0.2,6-0.7,8.9c-0.3,1.5-0.7,2.9-1.1,4.4c-0.4,1.3-1,2.6-1.4,3.9c-0.2,0.5-0.3,1.2-0.2,1.7
                                c0.1,0.7,0.7,0.9,1.3,1c1.2,0.2,2.4,0.6,3.5,1.2c2.3,1.1,4.3,2.7,6.4,4.1c2.4,1.6,4.8,2.6,7.6,3.2c2.9,0.6,6,0.8,8.9,1
                                c3,0.2,6.1,0.3,9.1,0c3.1-0.3,6-1.3,8.9-2.7c2.6-1.2,5.2-2.6,8-3.4c2.7-0.8,5.4-1.1,8.1-1.4c5.5-0.5,11.3-0.6,16.3-3.2
                                C1093.9,259.1,1094.5,258.6,1094.8,257.9z"/>
                            <path name="RICK" class="st0" d="M1021.2,187.5c0.2-2.5-0.1-5.1-0.9-7.4c-0.7-2.1-1.8-4-2.6-6.1s-1.2-4.3-1.4-6.5c-0.3-4.6,0.2-9.2-0.8-13.8
                                c-0.1-0.5,0.7-0.8,0.8-0.2c0.6,2.5,0.7,5.1,0.7,7.7c0,2.3,0,4.6,0.2,6.9c0.2,2.4,0.8,4.6,1.7,6.8c0.9,2,1.9,4,2.5,6.1
                                c0.6,2.2,0.8,4.5,0.6,6.8c0,0.1,0,0.1-0.1,0.2l0,0c0.1,0.1,0.1,0.1,0.1,0.2c1.1-0.4,2.3-0.7,2.9-0.8c0.3-0.1,0.5-0.1,0.7,0
                                c0.7-0.1,1.2,0,1.5,0.3l0,0c0.4-0.2,1.3-0.6,3-0.7c1,0,4.5,0.2,8.6,0.4c3.6,0.2,7.6,0.4,10.2,0.5l0,0c0-4.4,1.2-8.5,2.5-12.7
                                c0.9-2.8,1.7-5.6,2.6-8.4c-1.3-2.3-1.8-5-2.2-7.6c-0.4-2.4-1-4.6-2.1-6.8c-1.2-2.2-2.7-4.2-4.3-6.1c-0.8-0.9-1.5-1.7-2-2.8
                                c-0.5-0.9-0.8-1.9-1.1-2.8c-0.7-2-1.2-4.1-2.8-5.6c-0.4-0.4,0.2-1,0.6-0.6c0.2,0.2,0.5,0.5,0.7,0.7c0.1-1.4,0.1-2.9,0-4.3
                                c-1-0.9-1.9-2-2.8-3c-0.7,1.1-1.1,2.5-1.2,3.8c0,0.3-0.1,0.7-0.1,1l0,0c0,0.1,0,0.1,0,0.2c0,0.5,0,1,0,1.5c0,1.1,0,2.1,0,3.2
                                c0,4.1,0,8.2,0,12.3c0.4-0.3,0.7-0.8,1-1.1c0.4-0.5,0.7-1,1-1.5c0.7-1.1,1.3-2.1,1.8-3.3c0.2-0.5,1-0.1,0.7,0.4
                                c-0.6,1.4-1.3,2.7-2.2,3.9c-0.4,0.6-0.8,1.2-1.3,1.7c-0.4,0.5-0.8,0.8-1.4,0.9c-0.2,0-0.4-0.2-0.4-0.4c0-4.4,0-8.9-0.1-13.3
                                c0-1.2-0.1-2.6,0-3.9c-3.6,0.7-7,2.4-10.3,4c-2,1-4,2-6.1,2.8c-0.7,0.2-1.3,0.5-2,0.7c-2.4,0.8-4.8,1.7-6.7,3.3s-3.2,4.1-4.1,7.6
                                c-0.3,1.1-0.5,2.1-0.8,3.2c-0.4,1.8-0.8,3.7-1.5,5.6c-0.4,1.1-0.9,2.1-1.3,3.1c-0.3,0.7-0.6,1.4-0.9,2.1
                                c-2.3,5.6-2.5,7.4-3.3,17.3l-0.1,1.3c1-0.8,2.3-1.3,3.5-1.6c0.5-0.2,0.8,0.7,0.2,0.8c-1.4,0.4-2.8,1-3.9,2.1
                                c-0.7,1.5-0.6,1.9-0.4,2.1C1000.2,186.9,1012.9,182.9,1021.2,187.5z"/>
                            <path name="RICK" class="st0" d="M1040,437c-1.2,0.7-1.9,1.8-3.8,4.2c-2,2.4-5.2,3.1-5.2,3.1c-3.7,1.1-11.3,0.2-14.4-0.6
                                c-3-0.8-2.3-2.2-2.3-2.2l0,0c-0.8,0.3-0.9,1.5-0.9,2.9c0,3,2.1,4.6,6.2,4.8c4.8,0.2,11.4,0.3,13.9-1.1c1.1-0.6,2.5-2.4,3.7-3.9
                                c0.8-1,1.5-2,2.2-2.5c0.2-0.2,0.4-0.4,0.7-0.6c1.2-1.1,2.8-2.4,3-3.3c0.1-0.4,0.1-2.5-0.6-3.6C1041.8,436,1040.8,436.5,1040,437z
                                "/>
                            <path name="RICK" class="st0" d="M1065.2,215c-13.8-3.9-36.6-13.6-38.2-14.3c-0.5,0-3.2-0.3-4.6-0.7c-0.7-0.2-1.4-0.7-1.9-1.2
                                c-0.5,0-1-0.1-1.4-0.2c-0.7-0.2-1.5-0.4-2.2-0.6c-0.7-0.3-1.3-0.6-1.8-1.2c-0.7-0.7-1.3-1.6-1.4-2.7c0-0.6,0.8-0.5,0.8,0
                                c0.1,0.7,0.5,1.3,0.9,1.9c0.8-3.5,2.4-5.8,4.5-6.6c0.2-0.3,0.6-0.5,1.1-0.7c-7.9-4.9-20.8-0.8-22.8-0.1
                                c0.2,2.9,1.7,24.1,5.6,29.6c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.5,0.3,2.2,0.3c0.8,0,7.9,0,12.3,1.2
                                C1022,220.7,1039.8,222.6,1065.2,215z"/>
                        </g>
                        <path name="RICK" class="st1" d="M1041.5,131.7c0,0,7.9,5.5,13.4,4.8c5.4-0.7,7.8-1.4,11.5-11.2c0,0-4.1,18.5-10.1,17.2
                            C1050.2,141.2,1044,135.7,1041.5,131.7z"/>
                        <path name="RICK" class="st3" d="M1021.1,190c0.4-0.4,4-1.6,4.5-1.5c0,0,1.5-1.4,1.7,0.1c0.1,0.7-3,6.7-3.2,7.9c-0.1,0.8,2.4,3.4,2.4,3.4
                            s-4.2,0.1-5.4-1.1c-2.9-2.8-2.1-2.5-1.7-3.6C1019.9,193.9,1020.7,190.4,1021.1,190z"/>
                        <g>
                            <path name="RICK" class="st2" d="M1048.8,122.1c1,0.2,2,0.4,3,0.4c1.2,0,2.3-0.2,3.4-0.4c2.2-0.5,4.5-0.8,6.6-1.8c0.5-0.2,0.1-1-0.4-0.7
                                c-2,0.9-4.1,1.2-6.2,1.7c-1.1,0.2-2.1,0.5-3.2,0.4c-1,0-2-0.2-3-0.4C1048.5,121.2,1048.3,122,1048.8,122.1z"/>
                            <path name="RICK" class="st2" d="M1052.6,113.9c1.4,0.2,2.8-0.1,4.1-0.6c1.1-0.4,2.2-1.2,2.5-2.4c0.3-1.3-0.5-2.3-1.3-3.2
                                c-1-1.1-1.8-2.3-2.5-3.7c-0.3-0.5-1-0.1-0.7,0.4c0.6,1.1,1.3,2.1,2,3.1c0.6,0.8,1.7,1.7,1.7,2.8c0,1.1-1.2,1.8-2.2,2.2
                                c-1.2,0.5-2.4,0.6-3.6,0.5C1052,113,1052,113.8,1052.6,113.9z"/>
                            <path name="RICK" class="st2" d="M1065.1,149.7c-4.2,5.3-6.6,11.6-9.1,17.9c-0.7,1.9-1.4,3.8-1.6,5.8c0,0.6,0.8,0.5,0.8,0
                                c0.2-1.7,0.7-3.4,1.3-5c0.6-1.5,1.2-3.1,1.8-4.6c1.2-3,2.5-6,4.1-8.8c0.9-1.6,2-3.2,3.1-4.6
                                C1066,149.9,1065.4,149.3,1065.1,149.7z"/>
                            <path name="RICK" class="st2" d="M1048,159.1c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8S1047,159.1,1048,159.1z M1048,156.2
                                c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C1046.8,156.7,1047.4,156.2,1048,156.2z"/>
                            <path name="RICK" class="st2" d="M1054.5,177.2c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S1055.4,177.2,1054.5,177.2z
                                M1054.5,180.1c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S1055.1,180.1,1054.5,180.1z"/>
                            <path name="RICK" class="st2" d="M1054,223.4c1,0,1.8-0.8,1.8-1.8s-0.8-1.8-1.8-1.8s-1.8,0.8-1.8,1.8S1053.1,223.4,1054,223.4z M1054,220.5
                                c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S1053.4,220.5,1054,220.5z"/>
                            <path name="RICK" class="st2" d="M1054.5,239.9c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8S1055.4,239.9,1054.5,239.9z
                                M1054.5,242.9c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1s1.1,0.5,1.1,1.1S1055.1,242.9,1054.5,242.9z"/>
                            <path name="RICK" class="st2" d="M1049.8,249.4c0.2,1.6,0.3,3.1,0.5,4.7c0.2,1.6,0.6,3.1,1,4.6c0.9,3.4,1.2,6.9,0.8,10.4
                                c-0.1,0.5,0.8,0.8,0.8,0.2c0.4-3,0.3-6.1-0.3-9.1c-0.3-1.5-0.8-3-1.2-4.6c-0.3-1.6-0.5-3.2-0.6-4.8c-0.6-6.3-1-12.6-0.9-19
                                c0.1-3.6,0.3-7.3,0.7-10.9c0.1-0.6-0.8-0.5-0.8,0c-0.7,6.3-0.9,12.7-0.7,19.1C1049.2,243.2,1049.5,246.3,1049.8,249.4z"/>
                            <path name="RICK" class="st2" d="M1106.9,451L1106.9,451c0-0.2,0-0.3,0-0.5c0.1-2.1-1.2-4.7-1.3-5.9s-0.8-1.2-1.2-3.1s0.8-4.2-0.2-6.1
                                c0,0,4.7-10.6,4.5-14s-3-12.2-4.2-13.7s-1.1-3.3-1-4.6c0.1-1.3,1.4-12.9,0.8-18.4c-0.6-5.4-2-19.3-2.1-20.9
                                c-0.2-1.6-1.1-3.6-0.8-4.9c0.3-1.3,1.3-3.2,0.4-5.4s-2.3-7.9-2.3-8.4c0,0,2.5-9.5,0.2-12.8c0,0,1.3-5.1-0.1-7.8
                                c0,0,0.4-5.5,0.1-7.6s-0.5-5.7-0.3-9.5c0.3-3.8,0.3-15.8-0.4-21.1c-0.6-5.4-1-19.5-2.7-23.6l-1.4-3.5c0.6-0.7,0.9-1.6,1-2.6
                                c0.5-3.1,1.1-6.4,0.9-9.6c-0.2-3.2-1.2-6.2-2-9.3c-0.8-2.8-1.1-5.6-1.1-8.5c0-5.6,1-11.2,0.7-16.9c-0.1-1.2-0.2-2.3-0.5-3.5
                                c1.5-2.3,4.9-10.5,7.2-17.2c2.1-0.6,3.6-2.2,4.3-4.3c0.8-2.5,0.4-5.1-0.2-7.6c-0.4-1.5-0.8-2.9-1.1-4.4c-0.3-1.6-0.4-3.2-0.4-4.8
                                c0-3.3,0.3-6.5,0.4-9.8c0.1-3.2-0.2-6.3-0.7-9.4c-0.2-1.6-0.5-3.3-0.8-4.9c-0.2-1.4-0.5-2.7-1-4.1c-0.9-2.6-2.7-4.5-5.1-5.7
                                c-2.6-1.3-5.5-1.8-8.3-2.5c-2.8-0.8-5.4-2.1-8-3.4c-2.5-1.3-5-2.8-7.6-4.1c-1.1-2.2-2.7-4.3-4.8-5.6c-0.1,0-0.1-0.1-0.2-0.1
                                c0.6-2.3,1-4.3,1-5.5c0-1.6-0.7-4.1-1.4-7c-0.9-3.5-2-7.4-2.1-10.8c0-0.1,0-0.2-0.1-0.3c0.2-0.5,0.2-1.6,0.2-1.6
                                s-0.5-9.7-0.5-12.4c0-2.8-2.3-3.6-3.6-4.8c-1.3-1.2-3.3-4.3-3.3-4.3c-2.1-3.8-8-3.6-8.9-3.5c-1,0.1-3.9-0.1-12-0.2
                                c-8.2-0.1-15.6,10.8-15.9,17.6c-0.3,6.8,3.5,18.1,3.5,18.1l0,0c-0.2,0.2-0.5,0.4-0.6,0.7c-0.3,0.7-0.4,1.4-0.4,2.1
                                c0,0.9,0,1.7,0.2,2.6c0.4,1.7,1.5,3.1,2.4,4.5c0.5,0.7,1,1.4,1.6,2.1c0.5,0.6,0.9,1.2,1.5,1.7c1.1,0.9,2.6,1.5,3.9,0.9
                                c0.9,1.7,2.3,3.9,4.1,6.2c-1.1,1.5-1.5,3.3-1.6,5.2c-3.8,0.7-7.3,2.4-10.7,4.1c-2,1-4,2-6.1,2.8c-0.6,0.2-1.3,0.5-2,0.7
                                c-2.4,0.9-5,1.7-6.9,3.5c-2,1.8-3.4,4.3-4.4,8c-0.3,1.1-0.5,2.2-0.8,3.2c-0.4,1.8-0.8,3.7-1.5,5.4c-0.4,1-0.8,2.1-1.3,3
                                c-0.3,0.7-0.7,1.4-0.9,2.1c-2.4,5.7-2.6,7.7-3.4,17.6l-0.2,2.2c-0.9,1.9-0.8,2.6-0.1,3.2c0.2,2.4,1.6,24.1,5.7,30
                                c0,0.1,0.1,0.2,0.2,0.3c0.4,0.5,1.3,0.7,2.8,0.7c3.5,0,8.8,0.3,12.1,1.2c0.3,0.1,0.6,0.1,1,0.2c-0.8,2.3-1.3,4.6-1.6,7
                                c-0.4,2.8-0.5,5.5-0.4,8.3c0.2,3.2,0.4,6.3,0.1,9.5c-0.3,3.2-1,6.3-2.1,9.3c-0.5,1.5-2.2,4.5-0.6,5.9c-1.8,1.1-0.1,7.3-0.8,10.7
                                c-0.7,3.7-1,13.3,0,19.2s3.2,22.6,3.3,26.2s-0.5,9.8,1.5,12.1c0,0-2.3,5.1,0,11.1c0,0-1.5,15.5-1.5,20.1c0,4.5,3,24.7,4.1,29.4
                                c1.1,4.7,1,5.6,1.1,7.6s-0.2,4.5,0.8,6.3c0,0,0.4,2.1-0.6,4.6s-1.5,4.7-2.2,6.2s-2.8,2.1-2.5,4.6c0.4,2.5,0.8,6.4,1.8,6.7
                                c0,0,1.2,0.9,2,0.5c0,0,0.5,2.4-0.3,3.8c-0.7,1.2-5.5,7.5-6.8,10.3c-0.1,0-0.2,0-0.2,0c-2,0.2-2,2.6-2,3.9c0,3.5,2.5,5.6,7.1,5.8
                                c1.7,0.1,3.2,0.1,4.6,0.1c5.1,0,8.2-0.4,9.8-1.4c1.3-0.7,2.7-2.6,3.9-4.2c0.8-1,1.5-1.9,2-2.4c0.2-0.2,0.4-0.4,0.7-0.6
                                c1.5-1.3,3-2.6,3.3-3.8c0.1-0.5,0.3-3.7-1.2-4.9c0,0,0,0-0.1,0c0.1-0.9,0.1-2,0-3.4c0,0,10.2-7,2.6-26.1c0,0,0.3-1.6,0.6-2.8
                                s0.5-6.1,0.2-6.8c-0.4-0.7-1.2-1.3-1.2-1.3s1.5-7.2,1.7-10.5s0.9-22,0.5-23.6s-2.7-3.8-2.3-5.3c0.4-1.5,2.8-4.1,2.8-5.2
                                s0.6-5.6,0.4-6.6s-1.4-0.6-0.5-2.4s1.8-5.6,2-7.1s0.4-10,0.8-12.8c0.5-2.8,0.2-7.3,0-8.4s0.8-3.7,1.4-5.1s2.1-6.5,1.4-11.7
                                c0,0,2.1-5.2,1.6-7.5c0,0-0.2-1.9,0.1-2.2c0,0,1,1.2,1.4,3.8c0.5,2.6,1.2,7.5,2.7,12s3.8,10.9,4.3,13.1s1.8,7.9,1.8,10.8
                                s-0.2,6.5,0.8,8.2s3.5,7,4.1,10s1.1,6.7,0.9,8.6c-0.2,2-0.5,3.8,1.5,5.9c0,0-1.1,4.7,0,6.2c0,0,0.6,5.8,0.5,7.3
                                c-0.2,1.5,0.4,15.7,0.4,18.1c0.1,2.4,0.4,8.1,1,9.9s1.7,7,1.5,10.6s-0.9,7.7,0,9.7c1,2,3.5,7,3.8,9.4c0.4,2.3-0.5,3.8,1.1,6
                                s3.4,2.4,3.7,2.7s-0.3,1.8,0,2.4c0.2,0.5-0.7,8.7-0.8,11.9h-0.1c-0.6,0.1-1.7,0.8-1.5,2.3v0.1c0.2,1.2,0.4,3.1,2,4.4
                                c0.8,0.6,4.9,0.9,9.4,0.9c5.4,0,11.2-0.4,12.3-0.8c1.8-0.7,2.2-2.6,2.5-4.2C1108.4,451.8,1107.4,451.1,1106.9,451z M1027.3,199.9
                                c-1.4-0.8-2.6-2.6-3-3.2c0.4-0.5,0.9-1.5,1.7-3.3c0.7-1.7,1.4-3.7,1.5-4.2c0-0.3,0-0.6,0-0.8c0.3-0.2,1-0.5,2.7-0.6
                                c0.9,0,4.6,0.2,8.5,0.4c4.6,0.3,9.8,0.5,12.2,0.5c0.4,0,0.7,0,0.9,0c0.5,0,1.8-0.2,3.6-0.4c6.8-0.9,22.3-3,29-1.6
                                c-0.4,0.2-0.8,0.5-1.2,0.8c-0.5,0.3,0,1,0.4,0.7c3-1.9,6.1-3.9,9.1-5.8c0.7-0.4,1.4-0.8,2.1-1.3c0.5-0.4,0.8-0.9,0.6-1.6
                                c0-0.1-0.1-0.2-0.1-0.3c1.5,0.9,3.1,1.9,4.5,3c0.9,0.7,1.8,1.5,2.6,2.4c-1.1,5.6-7.2,21.2-9.1,24c-1.8,2.6-18.9,7-21.5,7
                                C1060.7,214.1,1028.1,200.2,1027.3,199.9z M1025.1,188.2c0.3-0.1,0.4,0,0.4,0c0.1,0.1,0,0.4-0.1,0.6c0,0-1.4,3.3-2.2,5
                                c-0.4,0.9-0.6,1.2-0.7,1.2c-0.1,0-2.3,0-3.2,0c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1,0-0.3,0-0.4l1.6-4.9
                                C1021,189.4,1022.8,188.7,1025.1,188.2z M1023.6,196.3C1023.5,196.3,1023.5,196.4,1023.6,196.3c-0.1,0.1-0.2,0.1-0.2,0.2
                                c-0.2,0.1-1.5,0-2.4,0c-0.5,0-0.9,0-1.3,0c-0.2,0-0.3-0.3-0.4-0.6h0.1c0.3,0,0.8,0,1.2,0c0.8,0,1.7,0,1.9,0c0.1,0,0.2,0,0.3,0
                                c0.2-0.1,0.7-0.4,1.2-1.7c0.7-1.7,2.2-5,2.2-5c0-0.1,0.2-0.6,0.1-1c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.4,0.1,0.8
                                C1026.6,190,1024.5,195.3,1023.6,196.3z M1018.4,194.4c0,0.1-0.1,0.4,0,0.7v0.1c0,0.7,0.2,1.8,1,2.1c0.1,0.1,0.2,0.3,0.3,0.4
                                c-0.1,0-0.2,0-0.2-0.1c-0.7-0.1-1.3-0.3-2-0.5c-0.4-0.1-0.8-0.3-1.2-0.6l0,0c0-0.1,0-0.1,0.1-0.2c0.6-2.9,1.8-4.9,3.3-5.9
                                L1018.4,194.4z M1020.4,197.3c0.2,0,0.4,0,0.5,0c0.6,0,1.2,0,1.6,0c0.5,0,0.9,0,1.1-0.1c0,0,0,0,0.1,0c0.4,0.6,1.1,1.6,2,2.4
                                c-1-0.1-2.2-0.3-3-0.6C1021.7,198.8,1020.9,198,1020.4,197.3z M1081,176.6L1081,176.6c1.1-1,2.2-1.9,3.3-2.7
                                c0.5-0.4,1.1-0.8,1.8-0.9c0.7-0.1,1.3,0.2,1.8,0.6c0.4,0.3,0.8,0.7,1.3,1c0.6,0.3,1.2,0.3,1.8,0.4c0.8,0.1,1.2,0.5,1.6,1.1
                                s0.8,1.3,1.1,1.9c0,0,0,0.1,0.1,0.1l0,0c0.3,0.6,1,1.5,0.6,2c-0.2,0.3-0.6,0.4-0.9,0.6c-0.4,0.2-0.7,0.4-1.1,0.7
                                c-2.4,1.5-4.7,3-7.1,4.5c-3-0.9-8.1-0.9-13.4-0.5c1.8-2,3.6-3.9,5.5-5.8C1078.7,178.7,1079.8,177.7,1081,176.6z M1101.5,190.7
                                c0.7-2,1.2-3.7,1.5-5.1c0.5,0.8,0.8,1.7,0.9,2.7c0,0.1,0,0.1,0.1,0.2C1103.5,189.5,1102.6,190.3,1101.5,190.7z M1071.9,127
                                c0,0.1,0,0.2,0.1,0.3c0.8,1.8,1.3,3.7,1.5,5.5c0.2,2.5-0.2,4.9-0.7,7.3c-0.5,2.2-1,4.4-2,6.4c-0.3,0.5-0.6,1-1,1.5
                                c-0.2,0.2-0.4,0.6-0.7,0.7c-0.2,0-0.2,0-0.2-0.2c-0.5-0.7-0.9-1.4-1.4-2.1c-0.3-0.5-1,0-0.7,0.4c0.5,0.7,0.9,1.5,1.4,2.2
                                c0.2,0.2,0.3,0.4,0.5,0.5c0.5,0.2,1.1-0.1,1.4-0.4c1.7-1.7,2.5-4.3,3.1-6.6c0.7-2.6,1.2-5.4,1.3-8.1c0-2.2-0.4-4.4-1.2-6.4
                                c4,2.1,7.9,4.5,12.1,6.2c2.7,1.1,5.5,1.5,8.2,2.3c2.5,0.8,5,2,6.5,4.3c1.5,2.4,1.8,5.4,2.2,8.1c0.5,3,0.9,6.1,1,9.2
                                c0.2,6.2-1.1,12.4,0.2,18.5c0.7,3,1.8,6,1.5,9.1c-0.1,0.6-0.2,1.1-0.4,1.7c-0.3-1.1-0.8-2-1.4-2.9c0-0.1-0.1-0.1-0.2-0.2
                                c-0.7-0.9-1.5-1.6-2.3-2.3c-1.9-1.5-4.1-2.8-6.2-4.1c-0.1-0.1-0.2-0.3-0.2-0.4c-0.4-0.7-0.8-1.5-1.3-2.1c-0.4-0.5-0.9-0.9-1.6-1
                                s-1.4-0.1-2-0.5c-1.1-0.7-1.9-1.8-3.3-1.6c-1.5,0.2-2.7,1.4-3.8,2.2c-0.3,0.3-0.7,0.5-1,0.8c0.6-4.2,2.4-8.3,3.7-12.3
                                c1.4-4.1,2-8.3,3.4-12.4c0.7-2.2,1.7-4.4,3.2-6.3c0.3-0.4-0.4-0.9-0.7-0.4c-2.6,3.4-3.7,7.7-4.7,11.8c-0.5,2.1-1,4.3-1.7,6.4
                                c-0.7,2.2-1.6,4.4-2.3,6.6c-0.8,2.4-1.5,4.9-1.8,7.4c-0.6,0.5-1.2,1.1-1.8,1.6c-2.8,2.5-5.3,5.2-7.8,8c0,0,0,0,0,0.1
                                c-5.7,0.4-11.6,1.2-15.5,1.8c-1.7,0.2-3,0.4-3.5,0.4c-0.4,0-1.1,0-1.9,0c0,0,0,0,0-0.1c0-3.9,1-7.7,2.1-11.4
                                c1.2-3.8,2.3-7.6,3.5-11.4c1.1-3.6,2.2-7.3,3.7-10.8c1.4-3.4,3.2-6.6,5.1-9.8c2-3.5,3.9-6.9,5.3-10.7c0.2-0.5-0.6-0.7-0.8-0.2
                                c-2.4,6.9-6.9,12.7-9.9,19.4c-1.5,3.5-2.7,7.1-3.8,10.8c-0.2,0.6-0.4,1.3-0.6,1.9c-0.9-1.9-1.3-4-1.7-6.1c-0.4-2.4-0.9-4.7-2-6.9
                                s-2.5-4.2-4-6c-0.8-1-1.7-1.9-2.3-2.9c-0.6-0.9-0.9-1.9-1.3-2.9c-0.5-1.4-0.9-2.9-1.7-4.2l0,0c0.2-1.5,0.2-3,0.1-4.6
                                c3.3,2.8,7.3,5,12,5c0.8,0,1.5-0.1,2.3-0.2c0.2,0,5.3-0.9,7.3-3.4c0.8-1.1,2.2-4.6,3.3-8.3c0.6,0.9,1.1,1.9,1.6,2.9
                                c0.3,0.7,0.6,1.3,0.8,2c0.2,0.5,0.3,1,0.3,1.5c0,0.6,0.9,0.6,0.8,0c0-0.6-0.2-1.1-0.3-1.6c-0.2-0.8-0.5-1.5-0.8-2.2
                                c-0.6-1.2-1.2-2.5-2.1-3.6c0.2-0.7,0.4-1.4,0.6-2.1C1069.4,123,1070.9,124.9,1071.9,127z M1033.2,120.1
                                C1033.1,120.1,1033.1,120.2,1033.2,120.1c-1.5,0.8-3.1-0.3-4-1.3c-1.1-1.3-2.1-2.7-3.1-4.1c-0.5-0.7-0.9-1.4-1.2-2.2
                                c-0.3-0.8-0.5-1.7-0.5-2.6c0-0.8-0.1-1.6,0.2-2.3c0.2-0.6,1.1-1.2,1.6-0.6c0.2,0.2,0.4,0.2,0.6,0c0.7,0.6,1.1,1.2,1.1,1.2
                                c1.7,3,2.5,3.1,4,2.5s0.3-2.7,0.3-2.7c-1.4-4.4-0.1-5.2-0.1-5.2s1.3-0.9,1.8-2.3s-2.6-6.7-2.6-6.7c2,0,4.7-1.3,6.2-2.7
                                c0.7-0.6,4.3-1.9,7.2-3.4c2.9-1.5,5.1-2.1,6.7-2.2c1.8-0.1,3.3,1.7,6,3.2c2.7,1.6,5.2,5.8,6.5,8.1c0.1,0.2,0.2,0.4,0.4,0.6
                                c-0.1,0.1-0.1,0.2-0.1,0.3c0.1,3.4,1.2,7.4,2.1,11c0.7,2.8,1.4,5.2,1.4,6.8c0,3.5-3.7,15.3-5.2,17.2c-1.8,2.3-6.8,3.1-6.8,3.1
                                c-7.6,1.1-13.6-3.8-17.5-8.7l0,0c-1.9-2.4-3.4-4.8-4.3-6.5C1033.7,120.3,1033.6,120,1033.2,120.1z M1002,165.7
                                c0.3-0.7,0.6-1.4,0.9-2.1c0.5-1,0.9-2,1.3-3.1c0.7-1.8,1.1-3.7,1.5-5.6c0.2-1.1,0.5-2.1,0.8-3.2c1-3.5,2.3-5.9,4.1-7.6
                                c1.8-1.6,4.3-2.5,6.7-3.3c0.7-0.2,1.3-0.5,2-0.7c2.1-0.8,4.2-1.8,6.1-2.8c3.3-1.6,6.7-3.3,10.3-4c0,1.3,0,2.6,0,3.9
                                c0,4.4,0,8.9,0.1,13.3c0,0.2,0.2,0.4,0.4,0.4c0.6-0.1,1-0.4,1.4-0.9c0.5-0.6,0.9-1.1,1.3-1.7c0.9-1.2,1.6-2.5,2.2-3.9
                                c0.2-0.5-0.5-0.9-0.7-0.4c-0.5,1.1-1.1,2.2-1.8,3.3c-0.3,0.5-0.7,1-1.1,1.5c-0.2,0.3-0.6,0.8-0.9,1.1c0-4.1,0-8.2-0.1-12.3
                                c0-1.1,0-2.1,0-3.2c0-0.5,0-1,0-1.5c0-0.1,0-0.1,0-0.2l0,0c0-0.3,0-0.7,0.1-1c0.2-1.3,0.6-2.6,1.2-3.8c0.8,1,1.8,2,2.8,3
                                c0.1,1.4,0.1,2.9,0,4.3c-0.2-0.3-0.4-0.5-0.7-0.7c-0.4-0.4-1,0.2-0.6,0.6c1.6,1.5,2.1,3.6,2.8,5.6c0.3,1,0.7,1.9,1.1,2.8
                                c0.5,1,1.3,1.9,2,2.8c1.6,1.9,3.2,3.8,4.3,6.1c1.1,2.1,1.7,4.4,2.1,6.8c0.5,2.6,0.9,5.2,2.2,7.6c-0.9,2.8-1.7,5.6-2.6,8.4
                                c-1.3,4.1-2.5,8.3-2.5,12.7l0,0c-2.7-0.1-6.6-0.3-10.2-0.5c-4.1-0.2-7.6-0.4-8.6-0.4c-1.7,0.1-2.6,0.4-3,0.7l0,0
                                c-0.3-0.3-0.8-0.4-1.5-0.3c-0.2-0.1-0.5,0-0.7,0c-0.6,0.1-1.8,0.4-2.9,0.8c0-0.1-0.1-0.1-0.1-0.2l0,0c0-0.1,0.1-0.1,0.1-0.2
                                c0.2-2.3,0.1-4.6-0.6-6.8c-0.6-2.1-1.6-4.1-2.5-6.1c-0.9-2.2-1.5-4.4-1.7-6.8c-0.2-2.3-0.2-4.6-0.2-6.9c0-2.6-0.1-5.2-0.7-7.7
                                c-0.1-0.5-1-0.3-0.8,0.2c1.1,4.5,0.5,9.2,0.8,13.8c0.2,2.2,0.6,4.4,1.4,6.5c0.8,2.1,1.8,4,2.6,6.1c0.8,2.4,1.1,4.9,0.9,7.4
                                c-8.2-4.7-21-0.6-23.1,0.1c-0.2-0.2-0.3-0.6,0.4-2.1c1-1,2.5-1.7,3.9-2.1c0.5-0.1,0.3-1-0.2-0.8c-1.2,0.4-2.5,0.9-3.5,1.6
                                l0.1-1.3C999.5,173.1,999.7,171.3,1002,165.7z M1006.3,218.5c-1.6,0-2.1-0.2-2.2-0.3c0-0.1,0-0.1-0.1-0.2
                                c-3.9-5.4-5.4-26.7-5.6-29.6c2-0.7,14.8-4.8,22.8,0.1c-0.5,0.2-0.9,0.5-1.1,0.7c-2.2,0.8-3.8,3.2-4.5,6.6
                                c-0.5-0.5-0.9-1.2-0.9-1.9c0-0.5-0.9-0.6-0.8,0c0.1,1,0.7,1.9,1.4,2.7c0.5,0.6,1.1,0.9,1.8,1.2s1.4,0.5,2.2,0.6
                                c0.5,0.1,0.9,0.2,1.4,0.2c0.5,0.5,1.2,1,1.9,1.2c1.4,0.5,4.1,0.7,4.6,0.7c1.6,0.7,24.4,10.4,38.2,14.3
                                c-25.4,7.6-43.2,5.7-46.7,4.8C1014.2,218.6,1007.1,218.5,1006.3,218.5z M1015.2,257.8c0.4-1.3,1-2.6,1.4-3.9
                                c0.5-1.4,0.8-2.9,1.1-4.4c0.6-2.9,0.8-5.9,0.7-8.9s-0.5-6.1-0.3-9.1c0.2-2.5,0.6-5,1.2-7.5c0.2-1,0.5-2,0.8-2.9
                                c2,0.3,5.1,0.6,9.1,0.6c8.7,0,21.7-1.2,37.4-6.1c1.9,0.5,3.5,0.9,4.9,1.1c1.9,0,17.8-3.7,21.6-6.8c0.9,5.1,0.2,10.4-0.2,15.5
                                c-0.2,2.6-0.3,5.3,0,7.9c0.3,2.9,1.2,5.6,2,8.4c0.4,1.5,0.8,3,0.9,4.6s0.1,3.2-0.1,4.7c-0.2,1.6-0.4,3.3-0.7,4.9
                                c-0.1,0.7-0.2,1.4-0.5,2c-0.3,0.7-0.9,1.2-1.6,1.5c-5,2.6-10.8,2.7-16.3,3.2c-2.7,0.3-5.5,0.6-8.1,1.4c-2.8,0.8-5.3,2.1-8,3.4
                                c-2.8,1.4-5.8,2.3-8.9,2.7c-3,0.3-6.1,0.2-9.1,0s-6-0.4-8.9-1c-2.8-0.6-5.3-1.6-7.6-3.2c-2.1-1.4-4.1-3-6.4-4.1
                                c-1.1-0.5-2.3-0.9-3.5-1.2c-0.6-0.1-1.2-0.3-1.3-1C1014.9,258.9,1015.1,258.3,1015.2,257.8z M1042.9,437.6
                                c-0.2,0.9-1.8,2.2-3,3.3c-0.2,0.2-0.5,0.4-0.7,0.6c-0.6,0.5-1.4,1.5-2.2,2.5c-1.2,1.6-2.6,3.3-3.7,3.9c-2.5,1.4-9.1,1.3-13.9,1.1
                                c-4.1-0.1-6.2-1.8-6.2-4.8c0-1.4,0.1-2.6,0.9-2.9l0,0c0,0-0.7,1.5,2.3,2.2s10.7,1.7,14.4,0.6c0,0,3.2-0.7,5.2-3.1
                                s2.6-3.5,3.8-4.2c0.8-0.5,1.8-1,2.3-3C1043.1,435.1,1043,437.2,1042.9,437.6z M1107.2,453.3c-0.3,1.7-0.7,3-1.9,3.5
                                c-2.5,1-19.6,1-20.8,0c-1.3-1-1.5-2.6-1.6-3.7V453c-0.1-0.9,0.5-1.2,0.7-1.2v0.1c0,0,0.1,2,11.5,1.9c0,0,10.2-0.3,11.1-0.6
                                c0,0,0.4-0.3,0.6-1.2l0,0C1106.8,452,1107.4,452.2,1107.2,453.3z"/>
                        </g>
                        <path name="RICK" class="st3" d="M1036.6,107.2c0.3,0.2,0.7,0.3,1,0.4s0.4,0.2,0.5,0.5c0.2,0.8,0.5,1.7,0.9,2.4c0.5,0.9,1.4,1.5,2.4,1.8
                            c1.6,0.4,3.3-0.1,4.9-0.5c1.7-0.4,3.3-0.9,4.2-2.5c0.6-1,0.9-2.4,0.9-3.6c0-0.7-0.1-1.4-0.2-2.1l0,0c1.3-1,2.9-0.4,2.9-0.4l0,0
                            c0.1,0.6,0.3,1.1,0.7,1.7c0.7,1,1.4,2.4,2.3,3.1c1.4,1.2,2.8,0.8,4.5,0.6c1.6-0.2,3.4-0.4,4.7-1.3c0.8-0.6,1.4-1.5,1.5-2.6
                            c0.2-0.9,0-1.7,0-2.6c0-0.3-0.8-4.3-1.6-4.6c-0.8-0.3-1.7-0.2-2.8-0.1c-2.4,0.1-4.6,0.6-6.8,1.4c-1.1,0.4-2.4,1-2.6,2.3v0.1
                            c-0.7-0.1-2.1,0-3.5,0.8c0,0,0-0.1-0.1-0.1c-0.7-1.1-2.1-1.2-3.3-1.2c-2.4,0.1-4.6,0.5-6.9,1.3c-1,0.4-2,0.8-3,1.4
                            c-0.7,0.4-1.9,1.1-2.1,1.9C1035.1,106.4,1035.9,106.9,1036.6,107.2z M1054.9,101.8c0-1.3,1.2-1.8,2.2-2.2c2.6-0.9,5.5-1.5,8.2-1.3
                            c1.7,0.2,1.8,3.7,1.8,3.8l0,0l0,0l0,0c0,0.2,0,0.4,0.1,0.6c0,0.3,0.1,0.7,0.1,1.1c0,0.8-0.2,1.6-0.6,2.3c-0.7,1-1.9,1.4-3.1,1.6
                            c-0.9,0.2-1.9,0.3-2.8,0.4c-0.8,0.1-1.7,0.2-2.4-0.1c-1.1-0.4-1.8-1.4-2.4-2.3C1055.3,104.6,1054.9,103.1,1054.9,101.8z
                            M1039.1,103.5c2.5-1.2,5.4-1.8,8.1-1.9c1.1,0,2.4,0,2.9,1.2s0.7,2.7,0.4,4c-0.2,1.1-0.5,2.2-1.4,3c-0.6,0.5-1.4,0.8-2.2,1
                            c-0.9,0.3-1.8,0.5-2.8,0.7c-1.1,0.2-2.4,0.3-3.4-0.3c-0.7-0.4-1.1-1.1-1.4-1.9c-0.1-0.3-0.2-0.7-0.3-1c0-0.2-0.1-0.4-0.2-0.6l0,0
                            l0,0l0,0C1038.8,107.6,1037.7,104.3,1039.1,103.5z"/>
                        <path name="RICK" class="st3" d="M1036.1,103.8c0,0-2,1.8-0.8,2.8c0.5,0.4-8.4,0.4-8.6-0.3C1026.4,104.9,1033.8,104.1,1036.1,103.8z"/>
                        <path name="RICK" class="st0" d="M1023.4,425.8c1.5-0.5,3-0.8,4.6-0.9c1.5-0.2,3.1-0.3,4.6,0s2.7,1.2,4,1.9c1.2,0.7,2.5,1.3,3.9,1.7
                            c0.5,0.2,0.8-0.7,0.2-0.8c-1.5-0.5-2.9-1.2-4.2-1.9c-1.3-0.8-2.6-1.5-4.1-1.8c-1.5-0.2-3.1-0.1-4.7,0c-1.6,0.2-3.1,0.5-4.6,1
                            C1022.6,425.1,1022.8,426,1023.4,425.8L1023.4,425.8z"/>
                        <path name="RICK" class="st0" d="M1087.3,436c1.8-1.5,4.6-1.8,6.9-1.9c2.6-0.1,5.2,0.3,7.6,1.2c0.5,0.2,0.7-0.6,0.2-0.8
                            c-2.6-0.9-5.5-1.3-8.2-1.2c-2.5,0.1-5.2,0.5-7.1,2.1C1086.3,435.8,1086.9,436.4,1087.3,436L1087.3,436z"/>
                        <path name="RICK" class="st3" d="M1043.4,342.8c-2.8,2.9-5.6,5.8-8.5,8.6c-2.5,2.5-5.2,4.9-8.5,6.2c-0.5,0.2-0.3,1,0.2,0.8
                            c3.3-1.3,6.1-3.7,8.6-6.2c3-2.9,5.8-5.9,8.7-8.9C1044.4,343,1043.8,342.4,1043.4,342.8L1043.4,342.8z"/>
                        <path name="RICK" class="st3" d="M1023.1,417.8c2.4,0.3,4.9,0.4,7.3,0.5c2.1,0.1,4.3,0.3,6,1.6c0.4,0.3,0.9-0.4,0.4-0.7
                            c-1.8-1.3-4-1.6-6.1-1.7c-2.6-0.1-5.1-0.3-7.7-0.5C1022.5,416.9,1022.5,417.8,1023.1,417.8L1023.1,417.8z"/>
                        <path name="RICK" class="st3" d="M1085.8,431.3c3.5-0.8,7.1-1.3,10.7-1.3c0.9,0,1.8,0,2.7,0c0.6,0,1.4,0.2,1.9-0.2c1.3-1-1.3-2.4-2-2.7
                            c-0.5-0.2-0.9,0.5-0.4,0.7c0.3,0.1,0.6,0.3,0.9,0.5c0.2,0.1,0.8,0.4,0.9,0.6c0.1,0.3-0.4,0.2-0.6,0.2c-0.4,0-0.8,0-1.1,0
                            c-1.5,0-2.9,0-4.4,0c-2.9,0.2-5.8,0.6-8.7,1.3C1085,430.6,1085.2,431.5,1085.8,431.3L1085.8,431.3z"/>
                        <path name="RICK" class="st3" d="M1096.2,400c2,2.2,4.1,4.4,6.1,6.5c0.4,0.4,1-0.2,0.6-0.6c-2-2.2-4.1-4.4-6.1-6.5
                            C1096.5,399,1095.9,399.6,1096.2,400L1096.2,400z"/>
                        <path name="RICK" class="st3" d="M1080.9,358.1c3.6,2.3,7.4,4.3,11.3,6c0.5,0.2,0.9-0.5,0.4-0.7c-3.9-1.7-7.7-3.7-11.3-6
                            C1080.9,357.1,1080.4,357.8,1080.9,358.1L1080.9,358.1z"/>
                        <path name="RICK" class="st3" d="M1043.6,284c8.3-0.8,16.8,0.2,24.7,3.1c0.5,0.2,0.7-0.6,0.2-0.8c-7.9-2.9-16.5-4-24.9-3.2
                            C1043,283.2,1043,284.1,1043.6,284L1043.6,284z"/>
                        <path name="RICK" class="st3" d="M1058.7,284.6c5.4-0.5,10.9-1.2,16-2.8c0.5-0.2,0.3-1-0.2-0.8c-5.1,1.6-10.5,2.3-15.8,2.8
                            C1058.2,283.8,1058.2,284.6,1058.7,284.6L1058.7,284.6z"/>
                    </g>
                </g>
                <g id="OCTAVIO" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="OCTAVIO" class="st0" d="M905.4,242.7c-1.2-0.3-2.4-0.6-3.5-0.9C903,242.2,904.2,242.5,905.4,242.7z"/>
                            <path name="OCTAVIO" class="st0" d="M874.8,136.9c-0.3-0.2-0.6-0.4-1-0.6c-4.7-2.9-9.8-5.1-14.9-7.2c0.3,0.5,0.5,0.9,0.5,1.3
                                c0.7,2.9,1.4,6.5,0,11.6c0,0.2-0.2,0.3-0.4,0.3h-0.1c-0.2-0.1-0.4-0.3-0.3-0.5c1.3-4.9,0.7-8.3,0-11.1c-0.7-2.9-7.8-10-12.9-15.1
                                c-2.5-2.5-4.7-4.7-6-6.2c-3.8-4.5-8.2-16.2-8.4-16.7c0-0.1,0-0.2,0-0.3c1.2-3.2,0.9-13.5,0.9-13.6c0-0.2,0.2-0.4,0.4-0.4
                                s0.4,0.2,0.4,0.4c0,0.3,0.2,4.2,0,7.9c0.2,0,0.5-0.1,0.7-0.3c0.5-0.4,0.8-1.1,1-2.1c0.3-2,0.3-7.2,0-8.5v-0.1
                                c-0.6-2.8-1.6-5.8-4.8-5.3c-0.2,0-0.4-0.1-0.5-0.3c0-0.1-1-2.9-4.2-1.8c-0.2,0.1-0.4,0-0.5-0.2c-0.1-0.1-1.5-2.4-5.4-1.2l-1.1,1
                                c-0.2,0.2-0.5,0.1-0.6-0.1c-0.2-0.2-0.1-0.4,0-0.6l1.1-0.9l0.1-0.1l7.6-6.3c0,0,0.1,0,0.1-0.1c1-0.5,1.6-1,1.8-1.7
                                s-0.2-1.2-0.2-1.3c-0.3-0.5-0.7-0.8-1.2-1c-1.1-0.3-2.6,0.2-4.6,1.5c-2.1,1.3-6.6,4.9-9.1,7.4c-2.5,2.6-2,6.7-2,6.7
                                c0.4,7.5,3.8,17.8,4.6,19.8c0.9,2.2,3.8,3.4,3.8,3.4c0.2,0.1,0.3,0.3,0.2,0.6c-0.1,0.2-0.2,0.3-0.4,0.3c0,0-0.1,0-0.2,0
                                c0,0-0.3-0.1-0.7-0.3c2.1,6.1,13.2,36.4,28.2,52.3c0.1,0.1,7.5,8.9,20.2,15.3C875.1,153.5,875.3,138.8,874.8,136.9z"/>
                            <path name="OCTAVIO" class="st0" d="M903.4,136.2c-0.1,0.1-0.1,0.1-0.2,0.1c1,2,2.2,3.8,3.7,5.4c1.3,1.5,2.8,2.9,4.5,4c1.6,1.1,3.2,1.8,5.1,2.2
                                c1.7,0.3,3.5,0.4,5.3,0.2c0.7-0.1,1.2-0.2,1.8-0.5c0.6-0.4,1.1-0.8,1.6-1.3c-0.6,0.1-1.1,0.1-1.6,0.1c-0.1,0-0.2,0-0.3,0
                                c-4-0.1-10.5-5.4-12.8-8.2c-1.6-2.1-2.3-5.8-2.3-5.9c0-0.2,0.1-0.4,0.3-0.5c0.2,0,0.5,0.1,0.5,0.4c0,0,0.6,3.6,2.1,5.5
                                c2.4,3.1,8.8,7.8,12.1,7.9c2.6,0,4.9-0.4,10.8-6.7c5.4-5.9,6.3-8.9,7.1-11.6v-0.2c0.5-1.9,2-5.8,3.6-8.6c0.1-0.2,0.2-0.4,0.3-0.8
                                c0.9-1.9,2.2-7.7,1.8-9.4c-0.4-1.6-2.3-5.2-7.7-6.2c0,0-3-0.4-5.7-1.6s-6.5-2.7-9.5-2.1s-3.2,2.5-3.3,3.8s0,3.4-1.2,4
                                s-4,0.8-5,1.8s-1.6,4-1.8,5.4c0,0-0.1,1.9-0.4,1.9c-0.9,0-1.9-1.3-1.9-1.3s-0.2-0.8-0.2-2.3c0.1-1.6-0.1-4.3-0.3-5.2
                                c-0.1-0.5-0.3-1.1-1-1.7c-0.1,0-0.1,0-0.2-0.1c-1.4-1-3.4-0.8-4.4,0.7c-0.5,0.8-0.6,1.8-0.7,2.7c-0.1,1-0.1,1.9-0.1,2.9
                                c0,1.9,0.2,3.8,0.8,5.6c0.6,1.6,1.8,3,3.6,3.2c0.5,0.1,0.5,0.9,0,0.8c-0.1,0-0.2,0-0.3-0.1c0.1,2.9,0,5.8-0.4,8.7
                                c-0.2,1.1-0.4,2.1-0.9,3c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.3-0.2,0.4C905.3,134.5,904.4,135.4,903.4,136.2z"/>
                            <path name="OCTAVIO" class="st0" d="M839.6,88.3c0.1,1.4-0.9,2.7-2.3,2.8l-4.7,0.3c-0.1,0.4-0.2,0.7-0.3,1c0.1,0.2,0.2,0.4,0.3,0.8l6.4-0.6
                                c1.2-0.1,2-1.1,1.9-2.3l-3-35.6c-0.1-1.4-0.5-2.4-1.1-2.9c-0.2-0.2-0.5-0.3-0.7-0.4c0.2,0.3,0.4,0.8,0.4,1.2L839.6,88.3z"/>
                            <path name="OCTAVIO" class="st0" d="M888.1,448.4c-2.6-1-2.8-2.2-3.7-4.4c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4,0.3-0.8,0.8-0.8,1.5
                                c0,2.1,0.8,5.3,1.7,5.9c0.4,0.3,0.9,0.5,1.4,0.7c0.8,0.4,1.7,0.7,2.3,1.4c0.1,0.1,0.2,0.3,0.4,0.5c1,1.3,3.2,4.4,16.9,3.9
                                c5.2-0.5,10.9-1.3,11.5-1.9s1.2-3.3,0.6-4.9c-0.2-0.5-0.4-0.8-0.7-0.9c-0.1,0.4-0.2,0.7-0.2,0.7c-5.3,3.1-19.2,3.1-22.8,2.2
                                C892.1,452,890,449.1,888.1,448.4z"/>
                            <path name="OCTAVIO" class="st0" d="M926.5,442.5c-1.8-0.4-2.5-2-2.9-3.3c-0.2,0.3-0.5,0.6-0.5,1c0,2,1.3,4.9,2.2,5.9c0.9,1.1,4.1,1.6,6.6,2
                                c2.2,0.4,4.1,0.7,4.9,1.3c0.2,0.1,0.4,0.3,0.6,0.5c1.7,1.5,4.5,4,14.6,3.2c5-0.3,13.3-1.3,14.3-3.1c0.5-1,0.5-2.7-0.1-4.1
                                c-0.3-0.8-0.8-1.3-1.2-1.5c-0.1,0.7-0.4,1.2-0.4,1.2c-7.7,5.8-23.2,3-24.1,2.4C939.6,447.3,927.9,442.7,926.5,442.5z"/>
                            <path name="OCTAVIO" class="st0" d="M987.4,168.1c-0.3-2.2-1.4-7.8-2.2-11.9c-0.5-2.4-0.8-4.3-1-5c-0.2-1.4-0.8-2.4-1.5-3.6
                                c-0.3-0.4-0.5-0.9-0.8-1.4c-0.5-0.9-1.4-3.7-2.5-6.6c-1.1-3.1-2.2-6.3-2.8-7.4c-1.1-2.1-4.7-13.7-5.2-16.4
                                c-0.4-1.9-1.2-2.1-1.4-2.1c-2.7,1.6-1.1,6.6-0.3,9.1c0.2,0.5,0.3,0.9,0.4,1.2c0.5,1.9,0,2.7,0,2.8c-0.1,0.2-0.3,0.2-0.4,0.2
                                c1,1.4,1.9,2.6,2,2.7c0.2,0.2,0.2,0.4,0,0.6c-0.2,0.2-0.4,0.2-0.6,0c-0.3-0.3-4-5.3-5.4-7.5c-0.4-0.7-1-1.6-1.6-2.5
                                c-1.1-1.8-2.7-4.5-3.2-4.7c-0.3-0.1-1.3,0-1.9,0.5c-0.4,0.4-0.5,1-0.2,1.8c0.9,2.6,3.7,7.7,4.2,8.3c0,0,2.2,3.6,2.8,5
                                c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.3,0.4,0.1,0.6s-0.4,0.3-0.6,0.1c-0.2-0.1-0.3-0.2-0.5-0.3l0,0c-1.4-0.8-2.9-1.6-3.2-1.5
                                c-0.7,0.2-3.8,4.3-3.8,5.1c0.2,0.5,0.7,1.8,1,2.4c0.8-0.3,1.6-0.5,2.1-0.7h0.1c0.3,0,2.1,0.2,3.2,0.3c-0.7-0.6-2.3-1.5-3.7-2.2
                                c-0.2-0.1-0.3-0.4-0.2-0.6s0.4-0.3,0.6-0.2c3.2,1.6,4.7,2.9,4.6,3.6c0,0.2-0.2,0.4-0.5,0.4c-1.2-0.1-3.4-0.4-4-0.4
                                c-1.5,0.4-3.1,1-3.6,1.2c-0.2,0.7-0.3,1.9-0.1,2.4c0.3,0.1,1,0.4,1.7-0.5c0.1-0.1,0.3-0.2,0.5-0.1c5.6,1.6,5.8,2.2,5.9,2.3
                                c0,0.1,1.9,7.6,9.7,12.4c0.2,0.1,0.3,0.4,0.1,0.6c-0.1,0.1-0.2,0.2-0.4,0.2c0,0,0,0-0.1,0c0.2,3.2-1,11.7-1.1,12.8v0.1
                                c-4.7,11.1-3.8,15-3.8,15c0.1,0.2-0.1,0.5-0.3,0.5h-0.1c-0.2,0-0.4-0.1-0.4-0.3s-1-4,3.5-14.9c-1.4-1.9-7.1-6.7-8.9-8.2
                                c-0.1,0.5-0.4,0.9-0.8,1.3c-0.9,0.9-2,1.6-3.1,2.4c-1.1,0.9-2.2,1.5-3.4,2.3c-1.3,0.8-2.7,1.6-4,2.4c-1,0.6-2.1,1.2-3.2,1.6
                                c0,0.6-0.1,1.2-0.2,1.8c1.4,1.3,10.8,9.8,15.1,14c4.2,4.1,10.3,4.9,12.5,5.2c0.2,0,0.4,0.1,0.5,0.1c1.6,0.2,3.8,0.8,4.1,0.9
                                c1,0,1.3-0.5,1.8-1.3c0.1-0.2,0.2-0.4,0.4-0.6c0.8-1.1,2.5-7.3,3.4-10.6c1.3-4.6,0.6-9.2,0.1-12.6L987.4,168.1z"/>
                            <path name="OCTAVIO" class="st0" d="M951.6,155.5c0.3-0.4,0.9,0.2,0.6,0.6c-1.1,1.4-1.2,3.2-1.4,4.9c-0.2,2-0.4,4-0.6,6.1
                                c-0.1,1.3-0.2,2.6-0.3,3.9c1.1-0.5,2.2-1.1,3.2-1.7c1.3-0.8,2.6-1.6,4-2.4c0.3-0.2,0.6-0.4,0.9-0.5c1-0.6,2-1.4,2.9-2.1
                                c0.5-0.4,1-0.8,1.5-1.2c0.4-0.3,0.8-0.7,1-1.2c0.1-0.4,0.1-0.8,0-1.2c-2.1,2-4.6,3.5-7.2,4.6c-0.5,0.2-0.7-0.6-0.2-0.8
                                c2.6-1.1,5-2.6,7.1-4.6l0,0c-0.2-0.3-0.4-0.6-0.5-0.9c-1.1-1.6-2.8-2.7-4.1-4c-0.7-0.7-1.3-1.4-1.8-2.3c-0.6-1-1-2-1.4-3
                                c-1-2-2.6-3.8-4.4-5.1c-0.8-0.6-1.8-1.1-2.7-1.7c-0.8-0.5-1.5-1-2.3-1.4c-1.6-0.9-3.4-1.6-5.1-2.2c-1.3-0.4-2.6-0.8-4-0.9
                                c0.5,0.6,0.8,1.4,0.5,2.1c-0.3,0.8-0.8,1.5-1.3,2.2c-0.8,1.1-1.6,2.1-2.6,3c-2.2,2.3-4.7,4.3-7.6,5.7c-0.5,0.2-0.9-0.5-0.4-0.7
                                c1.5-0.7,2.9-1.6,4.2-2.7c2-1.6,3.9-3.5,5.5-5.6c0.5-0.6,1-1.3,1.3-2.1c0.1-0.3,0.1-0.4,0-0.7c-0.2-0.4-0.4-0.8-0.7-1.1
                                c-0.3,0.4-0.7,0.7-1,1.1c-3.7,4-6.1,5.7-8,6.4c-0.7,1-1.5,1.8-2.5,2.4c-0.7,0.4-1.4,0.6-2.2,0.6c-1.9,0.1-3.8,0.1-5.7-0.3
                                c-2-0.4-3.8-1.2-5.5-2.4c-3.6-2.6-6.9-6.2-8.6-10.4c-0.2-0.5,0.5-0.9,0.7-0.4c0,0,0,0,0,0.1c0.8-0.7,1.6-1.5,2.3-2.4
                                c-0.1,0-0.1,0-0.2,0.1c-0.3,0.1-0.5,0.2-0.8,0.3c-0.9,0.3-1.6,0.9-2.4,1.5c-1.3,1-2.7,2-4.5,2c-1.1,0-2.2-0.3-3.3-0.3
                                s-2.2,0.2-3.3,0.5c-1,0.3-1.8,0.4-2.8-0.1s-1.9-0.3-2.9-0.1c-1.2,0.2-2.4,0.6-3.5,0.9c0.8,0.2,1.6,0.4,2.4,0.6
                                c1,0.2,1.9,0.5,2.8,1.1c1.4,0.9,2.6,2.1,3.7,3.3c0.4,0.4-0.2,1-0.6,0.6c-1.2-1.3-2.5-2.6-4-3.4c-1-0.5-2.1-0.7-3.2-0.9
                                c-0.7-0.2-1.4-0.4-2-0.5c-0.3-0.1-0.7-0.1-1-0.1h-0.1c-0.6,0-1.2,0-1.7-0.2c-0.4-0.1-0.8-0.3-1.1-0.5c-0.2-0.1-0.5-0.3-0.7-0.4
                                c0.4,4.1-0.8,17.4-8.1,25.7c1.8,1.8,11,11,12.8,18c0.1,0.2-0.1,0.5-0.3,0.5h-0.1c-0.2,0-0.4-0.1-0.4-0.3
                                c-0.9-3.4-3.6-7.4-6.4-10.8c0.7,2.4,1.3,4.7,1.9,7.1c1,4.5,1.8,9.1,2.8,13.6c0.5,2.2,1,4.4,1.7,6.5c0.5,1.8,1.3,3.5,2,5.2
                                c0.1-2,0-4-0.4-6c-0.1-0.5,0.7-0.8,0.8-0.2c0.5,2.2,0.6,4.5,0.4,6.8c0,0.4-0.1,0.9-0.1,1.3c0,0.1,0,0.1,0,0.2
                                c-0.3,3.2-0.9,6.4-0.8,9.7c0,1.9,0.4,3.8,1,5.6c0.7,1.9,1.5,3.7,1.5,5.7c0,0.6-0.8,0.6-0.8,0c0-1.9-0.9-3.7-1.5-5.5
                                c-0.5,2-0.9,4-1.1,6.1c-0.1,1.2-0.2,2.4-0.2,3.5c0,0.7,0.1,1.5,0.7,1.9c0.5,0.4,1.3,0.5,1.9,0.7c2.8,0.9,5.3,2.4,7.9,3.7
                                c2.6,1.3,5.3,2.5,8.1,3.4c5.5,1.9,11.3,2.9,17.1,3.1c2.9,0.1,5.8,0,8.7-0.4c1.5-0.2,3.1-0.4,4.6-0.9s2.9-1.1,4.2-1.8
                                c1.2-0.7,2.5-1.4,3.7-2s2.4-1.3,3.5-2.2c1.7-1.4,3.2-3.9,2.2-6.1c-0.2,0.2-0.4,0.4-0.5,0.6c-1,1.1-2.2,1.6-3.5,2.3
                                c-1.4,0.7-2.8,1.4-4.2,1.9c-3,1.3-6.2,2.2-9.4,2.8c-0.5,0.1-0.8-0.7-0.2-0.8c3.5-0.6,6.9-1.7,10.2-3.1c1.4-0.6,2.8-1.3,4.1-2
                                c0.6-0.3,1.2-0.6,1.8-1c0.5-0.4,0.8-0.9,1.2-1.3c0.3-0.4,0.6-0.8,0.8-1.2c0.2-0.7,0.3-1.5,0.4-2.2c0.2-1.8,0.1-3.6-0.1-5.3
                                c-0.6-6.5-1.8-13-1.6-19.5c-0.9,2-1.8,4-2.7,6c-0.2,0.5-1,0.1-0.7-0.4c1.7-3.7,3.4-7.4,5.1-11.2c1.4-3.2,2.6-6.4,3.4-9.9
                                c0.8-3.4,1.2-6.8,1.5-10.3c0.3-3.6,0.7-7.2,1-10.7c0.2-1.6,0.3-3.3,0.5-4.9C950.3,158,950.6,156.6,951.6,155.5z"/>
                        </g>
                        <path name="OCTAVIO" class="st1" d="M908.1,132.5c0.1-0.2,3.2,12,17.1,13.8c0,0-5.9,2.1-8.8,0.5C908.5,142.2,908.1,132.5,908.1,132.5z"/>
                        <path name="OCTAVIO" class="st2" d="M836.5,52.7c-0.1-1.4-1.4-2.5-2.8-2.3l-19,1.6c-1.4,0.1-2.5,1.4-2.3,2.8l0.6,9.6c0,0,13.5-12.7,15.3-7.9
                            c0.3,0.8,0.3,1.5,0,2.2c-1,2.1-3.5,3.1-5.2,4.5c-0.7,0.5-1.3,1.1-1.9,1.7c-0.1,0.1-0.7,0.7-0.6,0.8c0.1,0.2,0.9,0.2,1,0.2
                            c0.5,0,1,0.1,1.4,0.3c0.5,0.2,0.9,0.6,1.3,1c0.5,0.4,0.9,0.8,1.6,0.8c0.5,0,1-0.2,1.5-0.2c1.3,0.2,1.8,1.5,2.9,2.1
                            c1.2,0.6,2.5,0.7,3.5,1.6c1.8,1.7,1.6,4.7,1.7,6.9c0.1,1.5,0.2,3,0.1,4.4c-0.1,1.4-0.3,2.8-1.2,3.9c-0.4,0.5-1.2,0.2-1.5,0.7
                            s-0.5,3.5-0.8,3.9l0.1,0.1l5.1-0.3c1.4-0.1,2.5-1.4,2.3-2.8L836.5,52.7z"/>
                        <path name="OCTAVIO" class="st2" d="M909.1,104.3c-1.2-0.9-2.8-1-4.2-0.3c-1.4,0.8-2,2.2-2.1,3.7c-0.2,1.9-0.2,3.9,0,5.8c0.2,1.7,0.4,3.4,1.2,4.8
                            c0.8,1.5,2.2,2.6,3.9,2.7c0.5,0.1,0.5-0.8,0-0.8c-1.7-0.2-3-1.7-3.6-3.2c-0.7-1.8-0.8-3.7-0.8-5.6c0-1,0-1.9,0.1-2.9
                            c0.1-0.9,0.1-1.9,0.7-2.7c1-1.5,3-1.8,4.4-0.7C909.2,105.4,909.6,104.7,909.1,104.3L909.1,104.3z"/>
                        <path name="OCTAVIO" class="st2" d="M923.5,146.5c-0.1,0-0.2,0-0.3,0c-4-0.1-10.5-5.4-12.8-8.2c-1.6-2.1-2.3-5.8-2.3-5.9c0-0.2,0.1-0.4,0.3-0.5
                            c0.2,0,0.5,0.1,0.5,0.4c0,0,0.6,3.6,2.1,5.5c2.4,3.1,8.8,7.8,12.1,7.9c2.6,0,4.9-0.4,10.8-6.7c5.4-5.9,6.3-8.9,7.1-11.6v-0.2
                            c0.7-2.6,3.2-8.9,5.4-11c0.2-0.2,0.4-0.2,0.6,0s0.2,0.4,0,0.6c-2,1.9-4.4,7.9-5.2,10.7v0.2c-0.8,2.8-1.7,5.9-7.3,11.9
                            C928.8,145.9,926.2,146.5,923.5,146.5z"/>
                        <path name="OCTAVIO" class="st2" d="M918.9,133.6c3.1,3.1,7.2,5.4,11.8,5.2c0.5,0,0.5-0.9,0-0.9c-4.3,0.2-8.2-2-11.2-4.9
                            C919.1,132.6,918.5,133.2,918.9,133.6L918.9,133.6z"/>
                        <path name="OCTAVIO" class="st2" d="M929.3,134.3c1.2,0.5,2.6,1.1,3.9,0.6c1.4-0.4,1.8-1.6,2-2.9c0.2-1.6,0.2-3.2,0.2-4.7c0-1.7,0.2-3.3,0.5-5
                            c0.1-0.5-0.7-0.8-0.8-0.2c-0.3,1.3-0.4,2.7-0.5,4.1s0,2.8-0.1,4.2c0,1.1,0,2.9-1.1,3.6c-1.2,0.7-2.6,0.1-3.7-0.4
                            C929.3,133.4,928.8,134.1,929.3,134.3L929.3,134.3z"/>
                        <path name="OCTAVIO" class="st2" d="M903.4,136.2c1-0.9,2-1.8,2.7-2.9c0.7-1.1,1-2.4,1.2-3.6c0.4-2.9,0.4-5.9,0.3-8.9c0-0.6-0.9-0.6-0.8,0
                            c0.1,2.8,0,5.6-0.3,8.4c-0.2,1.3-0.5,2.5-1.1,3.6c-0.7,1.1-1.6,1.9-2.6,2.8C902.4,136,903,136.6,903.4,136.2L903.4,136.2z"/>
                        <path name="OCTAVIO" class="st2" d="M909.5,121.7L909.5,121.7C909.6,121.8,909.5,121.8,909.5,121.7c0.2,0.1,0.3,0.2,0.4,0.4
                            c0.1,0.1,0.2,0.1,0.3,0.1s0.1,0,0.2,0l0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.3s0-0.2-0.1-0.3c-0.2-0.2-0.4-0.3-0.6-0.5
                            c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0.1l-0.1,0.1c0,0.1-0.1,0.2,0,0.3v0.1
                            C909.4,121.6,909.4,121.7,909.5,121.7L909.5,121.7z"/>
                        <path name="OCTAVIO" class="st2" d="M909.4,120.2c0.4,0.3,0.8,0.5,1.2,0.8c0.2,0.1,0.5,0,0.6-0.2s0-0.4-0.2-0.6c-0.4-0.3-0.8-0.5-1.2-0.8
                            c-0.2-0.1-0.5,0-0.6,0.2C909.1,119.8,909.2,120.1,909.4,120.2L909.4,120.2z"/>
                        <path name="OCTAVIO" class="st2" d="M909.5,118.7c0.4,0.3,0.8,0.5,1.1,0.8c0,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0c0.1,0,0.2-0.1,0.2-0.2
                            s0.1-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.4-0.3-0.8-0.5-1.1-0.8c0,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.1-0.3,0
                            c-0.1,0-0.2,0.1-0.3,0.2s-0.1,0.2,0,0.3S909.4,118.6,909.5,118.7L909.5,118.7z"/>
                        <path name="OCTAVIO" class="st2" d="M909.7,117.2c0.5,0.3,1,0.7,1.5,1.1c0.1,0.1,0.2,0.1,0.3,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.2,0-0.4-0.2-0.6
                            c-0.5-0.3-1-0.7-1.5-1.1c-0.1-0.1-0.2-0.1-0.3,0c-0.1,0-0.2,0.1-0.3,0.2C909.4,116.9,909.5,117.1,909.7,117.2L909.7,117.2z"/>
                        <path name="OCTAVIO" class="st2" d="M910,116c0.5,0.2,0.9,0.5,1.3,0.8c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.5,0.1,0.6-0.2c0.1-0.2,0-0.5-0.2-0.6
                            c-0.5-0.2-0.9-0.5-1.3-0.8c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.5-0.1-0.6,0.2C909.7,115.6,909.7,115.9,910,116L910,116z"/>
                        <path name="OCTAVIO" class="st2" d="M906.7,103.8c-0.2-0.1,0.8-9.9,4.7-14.4s7.8-9.5,23.2-5.4c0,0,0.7-0.8,0.4-1.6c0,0,2.2,0.5,3.5,2.2
                            c0,0,2-0.7,2.6-3.3c0,0,1.1,1.4,1.1,3.8c0,0,1.5-1.1,1.5-2.4c0,0,3.2,3.1,1.8,7.3c0,0,1.9,0,2.7-1.7c0,0,0.4,2.3-1,4.2
                            c0,0,1.6,3.3,2.3,3.5c0,0,2,0.3,2.7-0.1c0,0,0.1,1.4-1.1,2.4c0,0,4.1,4-0.4,9.7c0,0,0.7,1.8-2,6.8c-1.4,2.1-5.2,6.3-3.6,2.9
                            c0.9-1.9,2.2-7.7,1.8-9.4s-2.3-5.2-7.7-6.2c0,0-3-0.4-5.7-1.6s-6.5-2.7-9.5-2.1s-3.2,2.5-3.3,3.8s0,3.4-1.2,4s-4,0.8-5,1.8
                            s-1.6,4-1.8,5.4c0,0-0.1,1.9-0.4,1.9c-0.9,0-1.9-1.3-1.9-1.3s-0.2-0.8-0.2-2.3c0.1-1.6-0.1-4.3-0.3-5.2
                            C909.7,106,909.1,104.7,906.7,103.8z"/>
                        <path name="OCTAVIO" class="st2" d="M902.1,136c1.8,4.1,5,7.7,8.6,10.4c1.7,1.2,3.4,2.1,5.5,2.4c1.9,0.4,3.8,0.4,5.7,0.3c0.8-0.1,1.5-0.2,2.2-0.6
                            c1-0.6,1.9-1.5,2.6-2.6c0.3-0.5-0.4-0.9-0.7-0.4c-0.6,0.9-1.4,1.7-2.3,2.2c-0.6,0.4-1.1,0.5-1.8,0.5c-1.8,0.1-3.6,0.1-5.3-0.2
                            c-1.9-0.3-3.6-1.1-5.1-2.2c-1.6-1.2-3.1-2.5-4.5-4c-1.7-1.9-3.1-3.9-4.1-6.2C902.6,135.1,901.9,135.5,902.1,136L902.1,136z"/>
                        <path name="OCTAVIO" class="st2" d="M935.4,138.2c0.4,0.3,0.8,0.9,1,1.3c0.1,0.3,0.1,0.4,0,0.7c-0.3,0.8-0.8,1.4-1.3,2.1
                            c-1.6,2.1-3.5,3.9-5.5,5.6c-1.3,1-2.7,1.9-4.2,2.7c-0.5,0.2-0.1,1,0.4,0.7c2.8-1.4,5.4-3.5,7.6-5.7c0.9-0.9,1.8-1.9,2.6-3
                            c0.5-0.7,1-1.4,1.3-2.2c0.4-1-0.6-2.3-1.4-2.9C935.3,137.2,934.9,137.9,935.4,138.2L935.4,138.2z"/>
                        <path name="OCTAVIO" class="st2" d="M896.3,139.9c2.8,2.5,5.6,5,8.2,7.6c2.5,2.5,4.7,5.2,6.2,8.4c0.2,0.5,1,0.1,0.7-0.4c-1.5-3.2-3.8-5.9-6.2-8.4
                            c-2.7-2.7-5.5-5.2-8.3-7.8C896.5,138.9,895.9,139.5,896.3,139.9L896.3,139.9z"/>
                        <path name="OCTAVIO" class="st2" d="M879.5,138.6c1.8-0.6,3.5-1.1,5.4-1.5c1-0.2,2-0.4,2.9,0.1c0.9,0.4,1.8,0.4,2.8,0.1c1.1-0.3,2.2-0.5,3.3-0.5
                            s2.2,0.3,3.3,0.3c1.8,0,3.2-1,4.5-2c0.7-0.6,1.5-1.1,2.4-1.5c0.3-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.4-0.1,0.5-0.2c0,0,0.2,0,0.2-0.1
                            c0.2-0.1,0.2,0.2-0.1-0.2c0.3,0.5,1,0,0.7-0.4c-0.3-0.5-1-0.2-1.5-0.1c-0.7,0.2-1.3,0.4-1.9,0.8c-1.5,0.9-2.8,2.3-4.4,2.8
                            c-1.1,0.4-2.2,0.1-3.3,0s-2.2-0.1-3.3,0.1c-1.1,0.2-2.4,0.9-3.5,0.5c-0.5-0.2-0.9-0.4-1.4-0.5s-1,0-1.5,0.1
                            c-2.1,0.3-4.2,1-6.3,1.6C878.8,137.9,879,138.8,879.5,138.6L879.5,138.6z"/>
                        <path name="OCTAVIO" class="st2" d="M857.9,128.6c5.5,2.2,11,4.6,16,7.7c0.9,0.5,1.7,1.1,2.6,1.7c0.3,0.2,0.7,0.4,1.1,0.5
                            c0.9,0.2,1.9,0.1,2.8,0.3c0.7,0.1,1.4,0.4,2,0.5c1.1,0.2,2.2,0.4,3.2,0.9c1.6,0.8,2.8,2.2,4,3.4c0.4,0.4,1-0.2,0.6-0.6
                            c-1.1-1.2-2.3-2.4-3.7-3.3c-0.8-0.5-1.8-0.8-2.8-1.1c-1.3-0.3-2.7-0.8-4-0.9c-0.9-0.1-1.9,0-2.7-0.5s-1.5-1-2.3-1.5
                            c-1.7-1.1-3.5-2-5.3-2.9c-3.7-1.9-7.5-3.5-11.4-5.1C857.6,127.5,857.4,128.4,857.9,128.6L857.9,128.6z"/>
                        <path name="OCTAVIO" class="st2" d="M859.1,142.2L859.1,142.2c-0.3-0.1-0.5-0.3-0.4-0.5c1.3-4.9,0.7-8.3,0-11.1c-0.7-2.9-7.8-10-12.9-15.1
                            c-2.5-2.5-4.7-4.7-6-6.2c-3.8-4.5-8.2-16.2-8.4-16.7c0-0.1,0-0.2,0-0.3c1.2-3.2,0.9-13.5,0.9-13.6c0-0.2,0.2-0.4,0.4-0.4
                            s0.4,0.2,0.4,0.4c0,0.4,0.4,10.3-0.9,13.8c0.5,1.4,4.7,12.2,8.2,16.3c1.2,1.4,3.4,3.6,5.9,6.1c5.5,5.5,12.4,12.3,13.2,15.5
                            c0.7,2.9,1.4,6.5,0,11.6C859.5,142.1,859.3,142.2,859.1,142.2z"/>
                        <path name="OCTAVIO" class="st2" d="M833,87.4c-0.2,0-0.4,0-0.4,0c-0.2-0.1-0.4-0.3-0.3-0.5c0-0.2,0.3-0.4,0.5-0.3c0,0,0.6,0.1,1.1-0.2
                            c0.5-0.4,0.8-1.1,1-2.1c0.3-2,0.3-7.2,0-8.5v-0.1c-0.6-2.8-1.6-5.8-4.8-5.3c-0.2,0-0.4-0.1-0.5-0.3c0-0.1-1-2.9-4.2-1.8
                            c-0.2,0.1-0.4,0-0.5-0.2c-0.1-0.1-1.5-2.5-5.5-1.2c-0.2,0.1-0.5-0.1-0.5-0.3c-0.1-0.2,0-0.5,0.3-0.5c3.8-1.3,5.7,0.5,6.3,1.3
                            c1.2-0.3,2.2-0.2,3.1,0.2c1,0.5,1.5,1.4,1.8,1.9c4.1-0.5,5.1,4.4,5.4,6v0.1c0.3,1.5,0.3,6.9,0,8.8c-0.2,1.3-0.6,2.2-1.4,2.7
                            C833.8,87.4,833.3,87.4,833,87.4z"/>
                        <path name="OCTAVIO" class="st2" d="M819.6,95.4c0,0-0.1,0-0.2,0c-0.1-0.1-3.3-1.3-4.3-3.8c-0.8-2-4.2-12.4-4.6-20c0-0.2-0.6-4.6,2.2-7.4
                            c2.5-2.6,7-6.2,9.2-7.5c2.2-1.4,4-1.9,5.3-1.6c0.8,0.2,1.3,0.6,1.8,1.3c0,0,0.6,0.9,0.3,1.9c-0.2,0.9-1,1.6-2.2,2.2l-8.8,7.3
                            c-0.2,0.2-0.5,0.1-0.6-0.1c-0.2-0.2-0.1-0.4,0-0.6l8.8-7.4c0,0,0.1,0,0.1-0.1c1-0.5,1.6-1,1.8-1.7s-0.2-1.2-0.2-1.3
                            c-0.3-0.5-0.7-0.8-1.2-1c-1.1-0.3-2.6,0.2-4.6,1.5c-2.1,1.3-6.6,4.9-9.1,7.4c-2.5,2.6-2,6.7-2,6.7c0.4,7.5,3.8,17.8,4.6,19.8
                            c0.9,2.2,3.8,3.4,3.8,3.4c0.2,0.1,0.3,0.3,0.2,0.6C819.9,95.3,819.7,95.4,819.6,95.4z"/>
                        <path name="OCTAVIO" class="st2" d="M880.2,181.7c-0.2,0-0.4-0.1-0.4-0.3c-1.8-7.1-12.1-17.1-12.9-17.8c-13-6.5-20.6-15.6-20.7-15.6
                            c-16.8-17.8-28.6-53.4-28.7-53.7c-0.1-0.2,0-0.5,0.3-0.5c0.2-0.1,0.5,0.1,0.5,0.3c0.1,0.4,11.9,35.8,28.5,53.4
                            c0.1,0.1,7.6,9.1,20.5,15.5c0,0,0.1,0,0.1,0.1c0.5,0.4,11.2,10.7,13.1,18.3C880.6,181.4,880.5,181.7,880.2,181.7L880.2,181.7z"/>
                        <path name="OCTAVIO" class="st2" d="M867.1,163.6c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.2-0.4,0-0.6c8.6-9.4,8.5-25,8-26.2c-0.1-0.2,0-0.5,0.2-0.6
                            s0.5,0,0.6,0.2c0.8,1.9,0.3,17.8-8.1,27.1C867.4,163.6,867.2,163.6,867.1,163.6z"/>
                        <path name="OCTAVIO" class="st2" d="M871.9,168.9c1.6,5,2.8,10.1,3.8,15.3c0.9,4.4,1.7,8.8,3,13.1c0.8,2.7,2,5.3,3,7.9c0.2,0.5,1,0.3,0.8-0.2
                            c-0.9-2.3-2-4.6-2.7-7c-0.7-2.1-1.2-4.3-1.7-6.5c-1-4.5-1.8-9.1-2.8-13.6c-0.7-3.1-1.5-6.1-2.5-9.1
                            C872.6,168.1,871.8,168.4,871.9,168.9L871.9,168.9z"/>
                        <path name="OCTAVIO" class="st2" d="M881.4,197.1c0.8,3.9,0.3,7.8-0.1,11.8c-0.4,3.6-0.8,7.3,0.3,10.8c0.7,2.2,1.8,4.2,1.9,6.5
                            c0,0.6,0.9,0.6,0.8,0c0-2-0.9-3.9-1.5-5.7c-0.7-1.8-1-3.7-1-5.6c-0.1-3.8,0.8-7.5,1-11.2c0.1-2.3,0-4.6-0.4-6.8
                            C882.1,196.4,881.3,196.6,881.4,197.1L881.4,197.1z"/>
                        <path name="OCTAVIO" class="st2" d="M881.4,219.1c-0.7,2.3-1.1,4.6-1.4,7c-0.2,1.2-0.3,2.3-0.3,3.5c0,1.1,0,2.4,0.8,3.2c0.4,0.4,1,0.6,1.5,0.8
                            c0.8,0.2,1.5,0.5,2.3,0.8c1.5,0.6,2.9,1.3,4.3,2c2.7,1.4,5.4,2.8,8.3,3.9c5.7,2.2,11.8,3.5,17.9,3.8c3.1,0.2,6.2,0.1,9.3-0.2
                            s6.2-0.9,9-2.4c1.6-0.8,3-1.7,4.6-2.5c1.4-0.7,2.8-1.5,4-2.5c2.1-1.8,3.5-5,1.9-7.5c-0.3-0.5-1,0-0.7,0.4c1.5,2.3,0,5.1-1.9,6.6
                            c-1.1,0.9-2.3,1.5-3.5,2.2s-2.5,1.4-3.7,2c-1.4,0.7-2.8,1.4-4.2,1.8c-1.5,0.4-3,0.7-4.6,0.9c-2.9,0.3-5.8,0.5-8.7,0.4
                            c-5.8-0.2-11.6-1.2-17.1-3.1c-2.8-0.9-5.5-2.1-8.1-3.4c-2.6-1.3-5.1-2.8-7.9-3.7c-0.6-0.2-1.4-0.3-1.9-0.7
                            c-0.6-0.4-0.7-1.2-0.7-1.9c-0.1-1.2,0-2.4,0.2-3.5c0.3-2.5,0.7-5,1.5-7.4C882.4,218.8,881.6,218.6,881.4,219.1L881.4,219.1z"/>
                        <path name="OCTAVIO" class="st2" d="M925.5,237.4c3.2-0.6,6.4-1.5,9.4-2.8c1.4-0.6,2.8-1.2,4.2-1.9c1.2-0.6,2.5-1.2,3.5-2.3
                            c0.6-0.7,1.4-1.6,1.6-2.5c0.4-1.7,0.5-3.5,0.4-5.3c-0.1-3.7-0.8-7.4-1.2-11.1c-0.5-4.1-0.8-8.3-0.4-12.5c0-0.6-0.8-0.5-0.8,0
                            c-0.6,7.2,0.8,14.3,1.5,21.4c0.2,1.8,0.3,3.6,0.1,5.3c-0.1,0.7-0.1,1.6-0.4,2.2c-0.2,0.4-0.5,0.8-0.8,1.2
                            c-0.3,0.5-0.8,0.9-1.2,1.3c-0.5,0.4-1.2,0.7-1.8,1c-1.4,0.7-2.7,1.4-4.1,2c-3.3,1.4-6.7,2.5-10.2,3.1
                            C924.7,236.7,925,237.5,925.5,237.4L925.5,237.4z"/>
                        <path name="OCTAVIO" class="st2" d="M939.3,206.9c1.5-3.3,3-6.6,4.5-9.9c1.4-3.1,2.8-6.2,3.6-9.5c1.8-6.6,2.1-13.6,2.7-20.4c0.2-2,0.4-4,0.6-6.1
                            c0.2-1.7,0.3-3.5,1.4-4.9c0.3-0.4-0.3-1-0.6-0.6c-0.9,1.2-1.3,2.5-1.5,4c-0.2,1.6-0.3,3.3-0.5,4.9c-0.3,3.6-0.7,7.2-1,10.7
                            c-0.3,3.4-0.7,6.9-1.5,10.3c-0.8,3.4-2,6.7-3.4,9.9c-1.7,3.7-3.4,7.5-5.1,11.2C938.4,206.9,939.1,207.4,939.3,206.9L939.3,206.9z"
                            />
                        <path name="OCTAVIO" class="st2" d="M884.2,169.4c2.1-2.9,4.7-5.4,7.8-7.2c0.5-0.3,0-1-0.4-0.7c-3.2,1.9-6,4.5-8.1,7.5
                            C883.1,169.4,883.9,169.8,884.2,169.4L884.2,169.4z"/>
                        <path name="OCTAVIO" class="st2" d="M935.6,138.3c1.7-0.1,3.4,0.4,5,0.9c1.8,0.6,3.5,1.3,5.1,2.2c0.8,0.4,1.5,0.9,2.3,1.4
                            c0.9,0.5,1.8,1.1,2.7,1.7c1.8,1.3,3.4,3.1,4.4,5.1c0.5,1,0.9,2,1.4,3c0.5,0.8,1.1,1.6,1.8,2.3c1.4,1.3,3,2.4,4.1,4
                            c0.6,0.8,1.2,1.9,0.9,2.9c-0.2,0.5-0.6,0.9-1,1.2c-0.5,0.4-1,0.8-1.5,1.2c-1,0.7-1.9,1.5-2.9,2.1c-0.3,0.2-0.6,0.4-0.9,0.5
                            c-1.3,0.8-2.6,1.6-4,2.4c-1.2,0.7-2.5,1.5-3.8,1.9c-0.5,0.2-0.3,1,0.2,0.8c1.2-0.4,2.4-1.1,3.5-1.7c1.3-0.8,2.7-1.6,4-2.4
                            c1.2-0.7,2.3-1.4,3.4-2.3c1-0.8,2.2-1.5,3.1-2.4c0.8-0.8,1-1.7,0.8-2.8c-0.4-2-2.1-3.4-3.6-4.7c-0.8-0.7-1.5-1.3-2.2-2.1
                            c-0.8-0.8-1.3-1.8-1.8-2.8c-0.5-1.1-0.9-2.1-1.6-3.1c-0.7-1-1.5-1.8-2.3-2.6c-1.6-1.6-3.6-2.6-5.5-3.8c-1.8-1.1-3.7-2-5.7-2.7
                            c-1.9-0.7-4-1.3-6-1.2C935,137.4,935,138.3,935.6,138.3L935.6,138.3z"/>
                        <path name="OCTAVIO" class="st2" d="M956.1,165.2c2.8-1.1,5.3-2.7,7.5-4.8c0.4-0.4-0.2-1-0.6-0.6c-2,2-4.5,3.5-7.1,4.6
                            C955.4,164.6,955.6,165.4,956.1,165.2L956.1,165.2z"/>
                        <path name="OCTAVIO" class="st2" d="M975.4,156.9c-0.1,0-0.2,0-0.2-0.1c-7.7-4.8-9.8-11.8-10-12.8c-0.5-0.3-2.9-1.2-5-1.8c-1,1.1-2.2,0.7-2.8,0.4
                            l-0.1-0.1c-0.6-1-0.1-3.1,0-3.3c0-0.1,0.1-0.1,0.1-0.2c0.5-0.5,3-1.2,4.1-1.5h0.1c0.3,0,2.1,0.2,3.2,0.3c-0.7-0.6-2.3-1.5-3.7-2.2
                            c-0.2-0.1-0.3-0.4-0.2-0.6s0.4-0.3,0.6-0.2c3.2,1.6,4.7,2.9,4.6,3.6c0,0.2-0.2,0.4-0.5,0.4c-1.2-0.1-3.4-0.4-4-0.4
                            c-1.5,0.4-3.1,1-3.6,1.2c-0.2,0.7-0.3,1.9-0.1,2.4c0.3,0.1,1,0.4,1.7-0.5c0.1-0.1,0.3-0.2,0.5-0.1c5.6,1.6,5.8,2.2,5.9,2.3
                            c0,0.1,1.9,7.6,9.7,12.4c0.2,0.1,0.3,0.4,0.1,0.6C975.7,156.8,975.6,156.9,975.4,156.9z"/>
                        <path name="OCTAVIO" class="st2" d="M959,139.1c-0.2,0-0.3-0.1-0.4-0.3c0-0.1-0.9-2.1-1.2-2.9c0-0.2-0.2-0.6,1.1-2.4c0,0,2.5-3.6,3.5-3.7
                            c0.9-0.1,3.5,1.5,4.2,1.9c0.2,0.1,0.3,0.4,0.1,0.6s-0.4,0.3-0.6,0.1c-1.5-0.9-3.3-1.9-3.7-1.8c-0.7,0.2-3.8,4.3-3.8,5.1
                            c0.2,0.7,1.1,2.7,1.1,2.8C959.4,138.7,959.3,139,959,139.1C959.1,139.1,959.1,139.1,959,139.1z"/>
                        <path name="OCTAVIO" class="st2" d="M965.6,132.2c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.4-2.3-4.3-2.9-5c-0.6-0.8-3.5-6-4.3-8.6c-0.5-1.4,0-2.2,0.5-2.7
                            c0.8-0.8,2.2-0.9,2.8-0.7c0.6,0.2,1.6,1.8,3.6,5.1c0.6,0.9,1.1,1.8,1.6,2.5c1.4,2.2,5,7,5.3,7.3c0.2,0.2,0.2,0.4,0,0.6
                            c-0.2,0.2-0.4,0.2-0.6,0c-0.3-0.3-4-5.3-5.4-7.5c-0.4-0.7-1-1.6-1.6-2.5c-1.1-1.8-2.7-4.5-3.2-4.7c-0.3-0.1-1.3,0-1.9,0.5
                            c-0.4,0.4-0.5,1-0.2,1.8c0.9,2.6,3.7,7.7,4.2,8.3c0,0,3.2,5.1,3,5.5C965.9,132.1,965.8,132.2,965.6,132.2L965.6,132.2z"/>
                        <path name="OCTAVIO" class="st2" d="M981.9,194.7C981.8,194.7,981.8,194.7,981.9,194.7c-0.1,0-2.6-0.6-4.2-0.9c-0.1,0-0.3,0-0.5-0.1
                            c-2.4-0.3-8.6-1.2-13-5.5c-4.6-4.5-15.2-14.1-15.3-14.1c-0.2-0.2-0.2-0.4,0-0.6c0.2-0.2,0.4-0.2,0.6,0c0.1,0.1,10.7,9.6,15.3,14.2
                            c4.2,4.1,10.3,4.9,12.5,5.2c0.2,0,0.4,0.1,0.5,0.1c1.6,0.2,3.8,0.8,4.1,0.9c1,0,1.3-0.5,1.8-1.3c0.1-0.2,0.2-0.4,0.4-0.6
                            c0.8-1.1,2.5-7.3,3.4-10.6c1.3-4.6,0.6-9.2,0.1-12.6l-0.1-0.7c-0.3-2.2-1.4-7.8-2.2-11.9c-0.5-2.4-0.8-4.3-1-5
                            c-0.2-1.4-0.8-2.4-1.5-3.6c-0.3-0.4-0.5-0.9-0.8-1.4c-0.5-0.9-1.4-3.7-2.5-6.6c-1.1-3.1-2.2-6.3-2.8-7.4
                            c-1.1-2.1-4.7-13.7-5.2-16.4c-0.4-1.9-1.2-2.1-1.4-2.1c-2.7,1.6-1.1,6.6-0.3,9.1c0.2,0.5,0.3,0.9,0.4,1.2c0.5,1.9,0,2.7,0,2.8
                            c-0.1,0.2-0.4,0.3-0.6,0.2s-0.3-0.4-0.2-0.6c0,0,0.3-0.7,0-2.2c-0.1-0.3-0.2-0.7-0.4-1.2c-0.9-2.7-2.7-8.2,0.8-10.1h0.1
                            c0.1,0,1.8-0.3,2.4,2.7c0.5,2.5,4.1,14.2,5.2,16.2c0.6,1.1,1.7,4.3,2.8,7.5c1,2.8,2,5.7,2.4,6.5c0.2,0.5,0.5,0.9,0.8,1.4
                            c0.7,1.2,1.3,2.3,1.6,3.9c0.1,0.7,0.5,2.7,1,5c0.8,4.1,1.9,9.7,2.3,11.9l0.1,0.7c0.5,3.4,1.2,8.1-0.1,12.9
                            c-1.6,5.9-2.8,9.8-3.5,10.8c-0.1,0.2-0.2,0.4-0.3,0.6C983.9,193.9,983.4,194.7,981.9,194.7z"/>
                        <path name="OCTAVIO" class="st2" d="M970,185.3c-0.2,0-0.4-0.1-0.4-0.3s-1-4.2,3.8-15.5c0.6-4.6,1.4-12,1-13.3c-0.1-0.2,0-0.5,0.3-0.5
                            c0.2-0.1,0.5,0.1,0.5,0.3c0.6,1.9-0.8,12.5-1,13.7v0.1c-4.7,11.1-3.8,15-3.8,15C970.5,185,970.3,185.3,970,185.3
                            C970.1,185.3,970.1,185.3,970,185.3z"/>
                        <path name="OCTAVIO" class="st2" d="M973.6,170.5c-0.1,0-0.3-0.1-0.4-0.2c-1.4-2.1-9.6-8.8-9.6-8.8c-0.2-0.1-0.2-0.4-0.1-0.6
                            c0.2-0.2,0.4-0.2,0.6-0.1c0.3,0.3,8.4,6.8,9.8,9c0.1,0.2,0.1,0.5-0.1,0.6C973.8,170.5,973.7,170.5,973.6,170.5z"/>
                        <path name="OCTAVIO" class="st2" d="M958.9,169.2c-0.5-0.3-1.5-0.7-2.1-0.3c-0.3,0.2-0.3,0.4-0.3,0.7c0,0.5-0.2,1-0.3,1.5c-0.2,0.9-0.4,1.7,0,2.5
                            c0.2,0.4,0.4,0.8,0.6,1.2c0.1,0.4,0.2,0.8,0.2,1.1c0.2,0.8,0.5,1.3,1.2,1.7c0.4,0.3,0.8,0.6,1.1,1c0.2,0.3,0.4,0.7,0.6,1
                            c1,1.3,2.9,1.1,3.9-0.2c0.2-0.2-0.2-0.4-0.4-0.2c-0.5,0.7-1.3,1-2.1,0.8s-1.2-0.8-1.5-1.5c-0.3-0.5-0.6-0.9-1.1-1.2
                            c-0.3-0.2-0.6-0.4-0.8-0.7s-0.3-0.6-0.3-1c-0.1-0.6-0.2-1.1-0.5-1.6c-0.4-0.8-0.7-1.4-0.5-2.3c0.1-0.6,0.4-1.3,0.5-1.9
                            c0-0.1,0-0.3,0-0.4c0.1-0.3,0.2-0.4,0.5-0.5c0.4-0.1,0.9,0.2,1.2,0.4C958.9,169.7,959.2,169.3,958.9,169.2L958.9,169.2z"/>
                        <path name="OCTAVIO" class="st2" d="M964.6,178.5c-0.2-0.9-0.8-1.6-1.5-2.2c-0.9-0.8-2.4-1.9-2.2-3.2c0.1-1.1,0.4-2.1,0.3-3.2
                            c0-1-0.3-1.9-0.8-2.8c-0.2-0.2-0.5,0-0.4,0.2c0.6,0.9,0.8,2,0.8,3.1c0,0.6-0.1,1.1-0.2,1.7c-0.1,0.5-0.3,1.1-0.2,1.6
                            c0.2,1,1.2,1.8,1.9,2.4c0.8,0.7,1.7,1.4,1.8,2.5C964.3,178.8,964.7,178.7,964.6,178.5L964.6,178.5z"/>
                        <path name="OCTAVIO" class="st2" d="M961.2,166.7c0.4,0.1,0.8,0.2,1.2,0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.3,0.1,0.5,0.2c0.2,0.1,0.5-0.2,0.2-0.4
                            s-0.7-0.2-1.1-0.3c-0.4-0.1-0.8-0.2-1.2-0.3C961.1,166.2,961,166.6,961.2,166.7L961.2,166.7z"/>
                        <path name="OCTAVIO" class="st2" d="M962.4,167.8c-0.2,0.5-0.3,1-0.5,1.6c0,0.1,0,0.2,0.2,0.3c0.1,0,0.2,0,0.3-0.1c0.2-0.5,0.3-1,0.5-1.6
                            c0-0.1,0-0.2-0.2-0.3C962.6,167.6,962.5,167.7,962.4,167.8L962.4,167.8z"/>
                        <path name="OCTAVIO" class="st2" d="M957.1,171.3c0.5-0.1,1-0.1,1.5,0c0.1,0,0.2,0,0.3-0.1c0-0.1,0-0.2-0.2-0.3c-0.6-0.1-1.2-0.1-1.8,0
                            c-0.1,0-0.2,0.2-0.2,0.3C956.8,171.2,957,171.3,957.1,171.3L957.1,171.3z"/>
                        <path name="OCTAVIO" class="st2" d="M957.1,173.2c0.8,0.1,1.5,0.1,2.2,0c0.1,0,0.2-0.2,0.2-0.3s-0.2-0.2-0.3-0.1c-0.7,0.1-1.4,0.1-2,0
                            c-0.1,0-0.2,0-0.3,0.1C956.9,173,957,173.2,957.1,173.2L957.1,173.2z"/>
                        <path name="OCTAVIO" class="st2" d="M958.2,176c0.6-0.2,1.2-0.5,1.7-0.9c0.1-0.1,0.1-0.2,0.1-0.3s-0.2-0.1-0.3-0.1c-0.5,0.4-1,0.6-1.6,0.8
                            c-0.1,0-0.2,0.1-0.2,0.3C957.9,175.9,958.1,176,958.2,176L958.2,176z"/>
                        <path name="OCTAVIO" class="st2" d="M959.4,177c0.5-0.2,0.9-0.5,1.3-0.8c0.1-0.1,0.1-0.2,0-0.3s-0.2-0.1-0.3,0s-0.2,0.2-0.2,0.2l0,0l0,0l0,0l0,0
                            c0,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c0,0-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0s0,0,0,0l0,0c-0.1,0-0.2,0.1-0.2,0.3
                            C959.1,177,959.2,177.1,959.4,177L959.4,177z"/>
                        <path name="OCTAVIO" class="st2" d="M960.1,178.7c0.3-0.1,0.6-0.3,0.9-0.5c0.1-0.1,0.1-0.2,0-0.3s-0.2-0.1-0.3,0c0,0,0,0.1-0.1,0.1l0,0l0,0l0,0
                            c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1l0,0l0,0l0,0h-0.1c-0.1,0-0.2,0.1-0.2,0.3C959.9,178.7,960,178.8,960.1,178.7
                            L960.1,178.7z"/>
                        <path name="OCTAVIO" class="st2" d="M966.3,176.6c-0.6-0.4-1.3-0.7-1.8-1.2c-0.6-0.5-1-1.1-1.4-1.8c-0.3-0.7-0.6-1.5-0.3-2.2
                            c0.2-0.6,0.7-1.2,1.3-1.4c-0.1,0-0.2-0.1-0.2-0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.2,0.2,0.5,0,0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.3
                            s-0.2-0.1-0.2-0.1c-0.7,0.3-1.2,0.8-1.5,1.5c-0.3,0.8-0.2,1.6,0.1,2.3c0.3,0.8,0.7,1.6,1.4,2.2c0.6,0.6,1.4,1,2.2,1.5
                            C966.3,177.1,966.5,176.8,966.3,176.6L966.3,176.6z"/>
                        <path name="OCTAVIO" class="st2" d="M963.9,171.1c-0.2,0.3-0.3,0.8-0.3,1.2s0.2,0.9,0.6,1.1c0.4,0.3,0.9,0.1,1.1-0.3c0.3-0.4,0.5-0.8,0.4-1.3
                            s-0.4-0.9-0.9-0.9c-0.1,0-0.2,0.1-0.2,0.2c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.4,0.3,0.5,0.5c0.1,0.3-0.1,0.6-0.3,0.9
                            c-0.2,0.2-0.4,0.5-0.7,0.3c-0.2-0.1-0.3-0.5-0.4-0.7c0-0.3,0-0.7,0.2-1c0.1-0.1,0-0.2-0.1-0.3C964.1,171,964,171,963.9,171.1
                            L963.9,171.1z"/>
                        <path name="OCTAVIO" class="st2" d="M965.8,170.7c0.7,0.3,1.5,0.6,1.8,1.3c0.2,0.4,0.1,0.9,0,1.3c-0.1,0.4-0.2,0.9-0.3,1.3
                            c-0.1,0.3,0.3,0.4,0.4,0.1c0.2-0.5,0.3-1,0.4-1.5c0.1-0.5,0.1-0.9-0.1-1.4c-0.4-0.8-1.3-1.2-2.1-1.5
                            C965.7,170.2,965.6,170.6,965.8,170.7L965.8,170.7z"/>
                        <path name="OCTAVIO" class="st2" d="M965.4,166.7c-0.6-0.1-1,0.6-1,1.1s0.2,1,0.5,1.4c0.4,0.4,0.9,0.6,1.4,0.4c0.6-0.2,0.8-0.8,0.8-1.3
                            c0-0.3,0-0.7-0.2-0.9c-0.2-0.3-0.5-0.4-0.8-0.4s-0.3,0.4,0,0.4c0.4,0,0.6,0.5,0.6,0.8s0,0.8-0.4,1c-0.3,0.1-0.8,0-1-0.2
                            c-0.3-0.2-0.5-0.6-0.5-0.9c0-0.4,0.1-1,0.6-0.9c0.1,0,0.2-0.1,0.2-0.2C965.6,166.8,965.5,166.7,965.4,166.7L965.4,166.7z"/>
                        <path name="OCTAVIO" class="st2" d="M967.3,169.8c0.3,0.4,0.7,0.6,1.1,0.9c0.1,0.1,0.2,0,0.3-0.1c0-0.1,0-0.2-0.1-0.3c-0.2-0.1-0.4-0.2-0.6-0.4
                            l0,0l0,0c0,0,0,0-0.1-0.1l-0.1-0.1c-0.1-0.1-0.2-0.2-0.2-0.2s-0.2-0.1-0.3,0C967.2,169.6,967.2,169.7,967.3,169.8L967.3,169.8z"/>
                        <path name="OCTAVIO" class="st2" d="M880.9,232.7c-0.2,0.2-1.1,1.6-0.8,3.1s0.3,2.2,0,3.1c-0.3,0.9-0.3,2.4,0,2.9c0,0-2.6,1.8-1.3,4
                            c0,0,0.4,1.1,0,2.6s-0.7,2.6-0.3,4.2c0,0-1.8,25.8-0.7,36.2c1.1,10.5,3.7,20.6,3.1,22.9s-2.3,4.6-2.6,6.3s0.4,6.5,0.9,7.6
                            s2.6,0,1.2,3s-4,6.9-3,10.3s1.5,3.6,0.9,5.5s-1.7,5-1.1,6.5s0.5,5.3-0.2,7.6s-0.2,25.1,0.8,31.9s3.7,14.1,3.6,15.8
                            c-0.1,1.7-0.8,3.5-0.4,4.8c0.4,1.3,2.2,3.1,2.2,4.6s-1.2,2.6,0,4.4c0,0,0.8,0.7,0.3,1.9s-0.6,3.6,0.1,5.1c0.7,1.5,0.7,2.2,0.5,4.2
                            s-0.1,4.6,0.1,7c0.2,2.4-0.7,4.2,0,5.9c0.9,2.2,1.1,3.3,3.7,4.4c1.8,0.8,4,3.6,6.3,4.2c3.7,0.9,17.5,0.9,22.8-2.2
                            c0,0,1.5-3.5-2.8-5.8c0,0-3.2-1.6-4.1-4.2c-0.8-2.6-2.4-4.8-2.1-8.3c0.3-3.6,0.4-4.6-0.7-5.5c0,0-0.2-9.6-2-10.5
                            c0,0-1.6-1.1-2.6-0.9c0,0,1.3-2.9,0.1-3.9c0,0,0.4-2.2,0.3-4.8c-0.1-2.5-0.9-3.9-1.4-5.6c0,0-1.2-5.5-0.8-9.9s-0.5-30.9-0.5-32
                            s0.9-1.5,0.9-3.1s-0.8-5.2,0.2-6.8c1-1.6,3.7-4.6,3.4-8.2s0.4-7.9,1.4-10.3s1.3-8.3,1.2-10.5s0.3-5.8,0.9-8.1
                            c0.6-2.3,6.1-30.7,6.3-31.4c0,0,1,2.3,1.6,4.5s3.9,16.6,4,19.8c0.1,3.2-0.2,7.3,0.3,9.1s0.2,2.3,0,4.3s0.7,3.7,1.4,5.2
                            s2.5,5.6,2,8.7s-1.9,9.2-1.3,12.2c0.6,3.1,1.1,4.2,0.8,6.1s-0.8,2.7,0,4.5s1.7,10.2,1.7,13.8c0,3.6,0.1,13.1,0.5,16.1
                            c0.4,3.1,0.9,7.6,0.4,9.2s-0.8,2.4-0.2,4s1.1,3,0.4,4.2c-0.7,1.3-0.8,1.9,0,4.4s0.7,3.8,0.4,5.4s-1.7,2.4,0.2,4.9
                            c0,0-2,3.4-1.8,4.8s0.2,4.6-0.1,5.9s-2.4,9.3-1.9,10.7s1,3.6,3.1,4c1.4,0.2,13.1,4.8,14,5.4s16.5,3.5,24.1-2.4
                            c0,0,2.5-3.7-3.7-5.2c0,0-3.4-0.9-5-2.3s-4.8-3.5-5.6-6.1s-1.8-7.8-2.4-8.2c0,0,1.5-11-2.6-10.6c0,0,3.8-3.9,1.4-9.3
                            c0,0,2.5-29.1,3.1-35.4s1.7-15.4,1.5-18.6s1.6-14,1.2-16c-0.4-2-1.2-3.5-1-5s2-8.8,1.4-10.5c-0.5-1.8-0.1-12.2-0.1-15.5
                            s0.2-17.9,0.4-20.9c0.3-3.1-1.2-14.4-2.3-18.2c-1.1-3.8-2.8-6.9-3.1-8.9s0.1-6.5-0.2-7.6c-0.3-1-0.8-2.6-2-3.9
                            c-1.2-1.3-1.6-2.6-1.6-4.1s-1.5-4.4-1.5-4.4s-9.1,9-20.1,9c-12.1,0-17.3-0.5-22.3-2.4s-11.5-5-13.2-6
                            C885.5,234.4,880.9,232.7,880.9,232.7z"/>
                        <path name="OCTAVIO" class="st3" d="M911.9,265.4c3.3-0.3,6.7,0.1,10,0.2c3.2,0.1,6.4-0.4,9.4-1.4c0.5-0.2,0.3-1-0.2-0.8c-3,1.1-6.2,1.5-9.4,1.4
                            c-3.3-0.1-6.5-0.5-9.8-0.2C911.4,264.6,911.4,265.5,911.9,265.4L911.9,265.4z"/>
                        <path name="OCTAVIO" class="st3" d="M916.6,265.1c0.4,2.4-0.3,4.9-0.8,7.2c-0.6,2.7-1,5.5-1.3,8.3c-0.1,0.5,0.8,0.5,0.8,0
                            c0.3-2.9,0.8-5.8,1.4-8.6c0.5-2.3,1.1-4.7,0.7-7.1C917.3,264.3,916.5,264.5,916.6,265.1L916.6,265.1z"/>
                        <path name="OCTAVIO" class="st3" d="M902.5,258.2c3.7,2,7.5,3.7,11.5,5c0.5,0.2,0.8-0.6,0.2-0.8c-3.9-1.3-7.7-2.9-11.2-4.9
                            C902.4,257.2,902,258,902.5,258.2L902.5,258.2z"/>
                        <path name="OCTAVIO" class="st3" d="M925,263.6c1.4-2.3,2-4.9,2.4-7.5c0.5-3,0.9-6,1.4-9c0.1-0.5-0.7-0.8-0.8-0.2c-0.5,2.9-0.9,5.9-1.4,8.8
                            c-0.4,2.6-0.9,5.2-2.3,7.5C924,263.7,924.7,264.1,925,263.6L925,263.6z"/>
                        <path name="OCTAVIO" class="st3" d="M916.7,260c0.6-4.1,1-8.2,1.4-12.3c0-0.6-0.8-0.5-0.8,0c-0.3,4.1-0.8,8.1-1.3,12.1
                            C915.8,260.3,916.6,260.5,916.7,260L916.7,260z"/>
                        <path name="OCTAVIO" class="st3" d="M933.6,242.1c0.7,0.3,1.4,0.6,2.1,0.6c0.8,0.1,1.6-0.1,2.3-0.2c1.6-0.3,3.2-0.6,4.7-1.1
                            c0.5-0.2,0.3-1-0.2-0.8c-1.4,0.5-2.8,0.8-4.2,1c-0.7,0.1-1.5,0.3-2.2,0.3s-1.4-0.2-2.1-0.5C933.5,241.2,933.1,241.9,933.6,242.1
                            L933.6,242.1z"/>
                        <path name="OCTAVIO" class="st3" d="M914.1,245.2c0.3,1.1,1.5,1.4,2.5,1.5c1.6,0.2,3.3,0.2,5,0.1c1.6-0.1,3.2-0.3,4.7-0.6
                            c0.6-0.1,1.4-0.2,1.9-0.6c0.5-0.4,0.6-1,0.7-1.6c0.1-0.5-0.7-0.8-0.8-0.2c-0.1,0.5-0.1,1.1-0.7,1.3c-0.5,0.2-1.2,0.3-1.8,0.4
                            c-1.3,0.2-2.7,0.4-4,0.5s-2.6,0.1-3.8,0c-0.8-0.1-2.5,0-2.8-0.9C914.8,244.4,913.9,244.7,914.1,245.2L914.1,245.2z"/>
                        <path name="OCTAVIO" class="st3" d="M901.9,242.9c-0.2,0.5,0,1,0.4,1.3c0.4,0.4,1,0.4,1.6,0.4c1,0,2.1,0,3-0.6c0.5-0.3,0-1-0.4-0.7
                            c-0.7,0.4-1.5,0.5-2.2,0.4c-0.4,0-0.8,0-1.2-0.1c-0.2-0.1-0.5-0.3-0.4-0.6c0.1-0.2-0.1-0.5-0.3-0.5
                            C902.1,242.5,901.9,242.6,901.9,242.9L901.9,242.9z"/>
                        <path name="OCTAVIO" class="st3" d="M881.9,239.8c1.4,0.3,2.6,0.9,3.9,1.5c1.3,0.6,2.6,1,3.9,1.2c2.7,0.6,5.5,0.6,8.2,0.1
                            c0.5-0.1,0.3-0.9-0.2-0.8c-2.7,0.5-5.5,0.5-8.1-0.1c-1.3-0.3-2.6-0.7-3.8-1.3c-1.2-0.5-2.4-1.1-3.7-1.4
                            C881.6,238.9,881.4,239.7,881.9,239.8L881.9,239.8z"/>
                        <path name="OCTAVIO" class="st0" d="M890,427.1c-0.7-2.9-1.4-6.9,1.5-9c1.3-0.9,3-1.2,4.5-1.4c1.8-0.3,3.5-0.5,5.3-0.6c0.5,0,0.5-0.9,0-0.9
                            c-2,0.2-3.9,0.4-5.9,0.7c-1.7,0.3-3.4,0.7-4.8,1.8c-2.9,2.4-2.3,6.5-1.5,9.6C889.3,427.9,890.1,427.7,890,427.1L890,427.1z"/>
                        <path name="OCTAVIO" class="st0" d="M884.7,422.7c1.3,0.1,2.7-0.1,4-0.5c0.5-0.2,0.3-1-0.2-0.8c-1.2,0.4-2.5,0.5-3.7,0.4c-0.2,0-0.4,0.2-0.4,0.4
                            C884.3,422.5,884.5,422.7,884.7,422.7L884.7,422.7z"/>
                        <path name="OCTAVIO" class="st0" d="M928.9,417.3c0.3-0.2,0.5-0.5,0.8-0.7c0.1-0.1,0.2-0.2,0.4-0.3c0.3-0.1,0.4,0,0.5,0.2
                            c0.4,0.6,0.6,1.2,0.9,1.8c0.6,1.3,1.2,2.7,1.7,4c0.1,0.4,0.8,0.5,0.8,0c0.2-1.4,0.1-2.9,0.1-4.3c0-1.2,0.1-2.9,1.2-3.6
                            c0.6-0.4,1.3-0.4,2-0.5c0.8-0.1,1.6-0.2,2.4-0.3c1.5-0.2,3-0.4,4.5-0.5c0.5-0.1,0.5-0.9,0-0.9c-1.7,0.2-3.3,0.4-5,0.6
                            c-0.8,0.1-1.6,0.2-2.4,0.3c-0.7,0.1-1.5,0.2-2.1,0.6c-1,0.8-1.4,2.1-1.5,3.4c-0.1,1.6,0.1,3.3-0.2,4.9c0.3,0,0.5,0,0.8,0
                            c-0.7-1.8-1.4-3.6-2.3-5.3c-0.3-0.6-0.6-1.4-1.4-1.5c-0.3,0-0.6,0.2-0.9,0.4c-0.4,0.3-0.8,0.7-1.2,1
                            C927.8,417.1,928.4,417.7,928.9,417.3L928.9,417.3z"/>
                        <path name="OCTAVIO" class="st2" d="M903.4,458.2c-11.7,0-13.8-2.9-14.9-4.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5-0.6-1.3-0.9-2-1.2
                            c-0.5-0.2-1.1-0.5-1.6-0.8c-1.2-0.9-2-4.5-2-6.6c0-1.8,1.8-2.6,1.9-2.7c0.2-0.1,0.5,0,0.6,0.2s0,0.5-0.2,0.6
                            c-0.1,0-1.4,0.6-1.4,1.9c0,2.1,0.8,5.3,1.7,5.9c0.4,0.3,0.9,0.5,1.4,0.7c0.8,0.4,1.7,0.7,2.3,1.4c0.1,0.1,0.2,0.3,0.4,0.5
                            c1,1.3,3.2,4.4,16.9,3.9c5.2-0.5,10.9-1.3,11.5-1.9s1.2-3.3,0.6-4.9c-0.2-0.5-0.4-0.8-0.7-0.9c-0.2,0-0.4-0.3-0.3-0.5
                            c0-0.2,0.3-0.4,0.5-0.4c0.5,0.1,1.1,0.4,1.4,1.4c0.6,1.8,0,4.9-0.8,5.8c-1.1,1.1-10.2,2-12,2.2
                            C905.2,458.2,904.3,458.2,903.4,458.2z"/>
                        <path name="OCTAVIO" class="st2" d="M948.5,454.2c-7.6,0-10.2-2.3-11.6-3.6c-0.2-0.2-0.4-0.4-0.6-0.5c-0.6-0.5-2.6-0.8-4.5-1.1
                            c-3-0.5-6-1-7.2-2.3c-1-1.1-2.5-4.3-2.4-6.6c0-1.9,2.3-2.7,2.4-2.7c0.2-0.1,0.5,0,0.6,0.3c0.1,0.2,0,0.5-0.3,0.6
                            c0,0-1.7,0.6-1.7,1.8c0,2,1.3,4.9,2.2,5.9c0.9,1.1,4.1,1.6,6.6,2c2.2,0.4,4.1,0.7,4.9,1.3c0.2,0.1,0.4,0.3,0.6,0.5
                            c1.7,1.5,4.5,4,14.6,3.2c5-0.3,13.3-1.3,14.3-3.1c0.5-1,0.5-2.7-0.1-4.1c-0.3-0.8-0.8-1.4-1.3-1.5c-0.2-0.1-0.4-0.4-0.3-0.6
                            s0.3-0.4,0.6-0.3c0.8,0.2,1.5,1,1.9,2.1c0.6,1.4,0.8,3.6,0,5c-1.6,2.9-13.7,3.6-15,3.6C950.9,454.2,949.6,454.2,948.5,454.2z"/>
                        <path name="OCTAVIO" class="st2" d="M917,170.4c-2.8,2.6-5.6,5.2-7.4,8.5c-1.4,2.7-2.1,5.7-3.5,8.3c-0.9,1.7-2.2,3.3-2.2,5.2
                            c-0.1,1.9,1,3.8,1.2,5.7c0.3,3.5-2.5,6.9-1.7,10.3c0.4,1.8,1.8,3.2,3,4.8c1.6,2.1,2.8,4.6,4.3,6.8s3.4,4.3,6,5.2
                            c3.8,1.3,8.1-0.3,11-3.1s4.7-6.5,6.3-10.2c0.7-1.4,1.3-2.9,2-4.4c1.3-2.9,2.7-5.8,3.7-8.9c0.6-1.8,1-3.6,1.4-5.4
                            c0.7-3.1,1.4-6.1,2.1-9.2c0.3-1.1,0.5-2.3,0.5-3.5c-0.1-1.7-0.9-3.2-1.7-4.7c-1.2-2.2-2.5-4.5-4.4-6c-1.5-1.1-3.2-1.8-5-2.4
                            c-2.3-0.7-5.2-1.9-7.6-1.6C921.6,166.3,919.2,168.4,917,170.4z"/>
                        <g>
                            <path name="OCTAVIO" class="st0" d="M915.4,190.5c-1.4,0.8-2.8,1.7-3.7,3c-1.2,1.6-1.6,3.6-1.6,5.6s0.5,3.9,1,5.8c0.3,1.1,0.8,2.3,1.8,2.7
                                c0.5,0.2,1.1,0.2,1.6,0.4c1.3,0.6,1.6,2.7,3,2.9c0.9,0.1,1.7-0.6,2.6-0.7c0.9-0.1,1.8,0.4,2.6,0.1c0.6-0.2,1-0.7,1.5-0.9
                                c0.6-0.3,1.4-0.1,2.1-0.2c1.2-0.1,2.4-0.8,3.1-1.8c0.6-0.9,0.9-2.1,1.3-3.1c0.7-1.4,1.8-2.6,2.9-3.8c1.1-1.1,2.4-2.4,2.7-4
                                c0.2-1,0-2.1,0.1-3.1c0.1-0.6,0.3-1.1,0.5-1.7c0.3-1.1,0.4-2.2,0.3-3.3c0-0.2,0-0.5-0.1-0.7c-0.1-0.3-0.4-0.5-0.6-0.7
                                c-1.5-1.1-3.5-1.3-5.3-0.9c-1.4,0.3-2.7,0.9-3.8,1.7c-0.6,0.4-0.9,0.7-1.6,0.7s-1.5-0.2-2.2-0.2
                                C920.8,188.6,917.9,189.2,915.4,190.5z"/>
                            <path name="OCTAVIO" class="st2" d="M917.8,211.5c-0.1,0-0.2,0-0.3,0c-1-0.1-1.5-0.9-1.9-1.7c-0.3-0.6-0.7-1.1-1.2-1.3c-0.2-0.1-0.5-0.1-0.8-0.2
                                c-0.3-0.1-0.6-0.1-0.8-0.2c-1.2-0.5-1.7-1.8-2.1-3c-0.5-1.8-1-3.9-1-5.9c0-1.6,0.3-3.9,1.7-5.9c0.9-1.2,2.2-2.3,3.9-3.2l0,0
                                c2.5-1.3,5.4-2,8.3-1.9c0.3,0,0.7,0.1,1,0.1c0.4,0.1,0.8,0.1,1.1,0.1c0.5,0,0.7-0.2,1.1-0.5l0.3-0.2c1.4-0.9,2.7-1.5,4-1.7
                                c2.1-0.5,4.2-0.1,5.7,1c0.2,0.2,0.6,0.4,0.7,0.8c0.1,0.3,0.2,0.6,0.2,0.8c0.1,1.2,0,2.3-0.3,3.5c0,0.2-0.1,0.4-0.2,0.5
                                c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.4,0,0.9,0,1.4c0,0.6,0,1.1-0.1,1.7c-0.3,1.7-1.6,2.9-2.7,4.1L934,201c-1,1-2.1,2.2-2.8,3.6
                                c-0.2,0.4-0.3,0.7-0.4,1.1c-0.2,0.7-0.5,1.4-1,2.1c-0.8,1.1-2.1,1.9-3.5,1.9c-0.2,0-0.4,0-0.7,0c-0.5,0-0.9,0-1.3,0.1
                                c-0.2,0.1-0.4,0.2-0.6,0.4c-0.3,0.2-0.6,0.4-1,0.6c-0.6,0.2-1.1,0.1-1.7,0c-0.4-0.1-0.7-0.1-1-0.1s-0.6,0.2-1,0.3
                                C918.8,211.3,918.3,211.5,917.8,211.5z M915.6,190.9c-1.6,0.8-2.8,1.8-3.6,2.9c-1.3,1.8-1.5,3.9-1.5,5.4c0,2,0.5,3.9,1,5.7
                                c0.3,1,0.7,2.1,1.5,2.4c0.2,0.1,0.4,0.1,0.7,0.2c0.3,0.1,0.7,0.1,1,0.3c0.7,0.3,1.1,1,1.5,1.6s0.8,1.2,1.3,1.3
                                c0.4,0.1,0.8-0.1,1.2-0.3s0.8-0.3,1.2-0.4c0.5-0.1,0.9,0,1.3,0.1c0.5,0.1,0.9,0.1,1.2,0c0.2-0.1,0.5-0.2,0.7-0.4s0.5-0.4,0.8-0.5
                                c0.5-0.2,1.1-0.2,1.6-0.2c0.2,0,0.4,0,0.6,0c1.1-0.1,2.2-0.7,2.8-1.6c0.4-0.6,0.6-1.2,0.9-1.9c0.1-0.4,0.3-0.8,0.5-1.1
                                c0.7-1.6,1.9-2.8,3-3.9l0.1-0.1c1.1-1.1,2.2-2.2,2.5-3.6c0.1-0.5,0.1-1,0.1-1.5s0-1,0-1.5c0.1-0.4,0.2-0.9,0.3-1.3
                                c0-0.2,0.1-0.3,0.2-0.5c0.3-1,0.4-2.1,0.3-3.2c0-0.2,0-0.4-0.1-0.5c-0.1-0.2-0.3-0.4-0.5-0.5c-1.3-0.9-3.1-1.3-5-0.8
                                c-1.2,0.3-2.4,0.8-3.7,1.6l-0.3,0.2c-0.5,0.3-0.8,0.6-1.5,0.6c-0.4,0-0.9,0-1.3-0.1c-0.3,0-0.6-0.1-0.9-0.1
                                C920.8,189,918.1,189.6,915.6,190.9L915.6,190.9z"/>
                        </g>
                        <g>
                            <path name="OCTAVIO" class="st0" d="M916.7,212.9c-0.9-0.1-1.8,0.1-2.4,0.8c-1,1.1-0.5,2.7-0.1,4.1c0.3,0.9,0.6,1.8,1.2,2.5c0.7,1,1.9,1.7,3,2.3
                                c0.6,0.3,1.2,0.7,1.9,0.8c0.8,0.1,1.5-0.1,2.2-0.4c2.5-1.2,4.2-3.5,5.7-5.8c1.6-2.4,3.3-4.8,4-7.5c0.1-0.5,0.1-1.3-0.4-1.4
                                c-0.2-0.1-0.5,0.1-0.7,0.2c-1.5,0.8-2.7,2.2-4.2,3c-1.1,0.6-2.3,0.9-3.5,1.2s-2.4,0.8-3.6,0.9C918.7,213.8,917.9,213,916.7,212.9
                                z"/>
                            <path name="OCTAVIO" class="st2" d="M920.6,223.8c-0.2,0-0.3,0-0.5,0c-0.8-0.1-1.5-0.5-2.1-0.8c-1.1-0.6-2.3-1.3-3.1-2.4
                                c-0.6-0.8-0.9-1.8-1.2-2.7c-0.5-1.5-0.9-3.3,0.2-4.5c0.6-0.7,1.7-1,2.8-0.9l0,0c0.5,0.1,1,0.2,1.4,0.4c0.5,0.2,1,0.4,1.6,0.3
                                c0.8-0.1,1.6-0.4,2.4-0.6c0.4-0.1,0.8-0.2,1.2-0.4c1.2-0.3,2.3-0.6,3.4-1.2c0.7-0.4,1.3-0.9,2-1.4c0.7-0.6,1.4-1.1,2.2-1.6
                                c0.3-0.1,0.6-0.3,1-0.2c0.3,0.1,0.5,0.2,0.6,0.5c0.3,0.5,0.2,1.3,0.1,1.5c-0.8,2.9-2.5,5.4-4.1,7.7c-1.4,2.1-3.2,4.7-5.9,5.9
                                C921.9,223.7,921.2,223.8,920.6,223.8z M916.3,213.3c-0.7,0-1.3,0.2-1.7,0.6c-0.8,0.9-0.5,2.4,0,3.7c0.3,0.8,0.6,1.7,1.1,2.4
                                c0.7,1,1.8,1.6,2.8,2.2c0.6,0.3,1.1,0.6,1.8,0.7c0.6,0.1,1.2-0.1,2-0.4c2.4-1.1,4.1-3.5,5.5-5.6c1.6-2.3,3.2-4.7,4-7.4
                                c0.1-0.3,0.1-0.7,0-0.8c0-0.1,0-0.1-0.1-0.1s-0.3,0.1-0.4,0.1c-0.8,0.4-1.4,0.9-2.1,1.5c-0.7,0.5-1.3,1.1-2.1,1.5
                                c-1.1,0.6-2.4,1-3.6,1.3c-0.4,0.1-0.8,0.2-1.2,0.3c-0.8,0.2-1.7,0.5-2.5,0.6s-1.4-0.1-2-0.3c-0.4-0.1-0.8-0.3-1.2-0.3
                                C916.6,213.3,916.4,213.3,916.3,213.3z"/>
                        </g>
                        <g>
                            <g>
                                <path name="OCTAVIO" class="st3" d="M917.4,194.2c-1.1,0.4-2.2,0.8-3.3,1.2c-0.5,0.2-1.1,0.4-1.5,0.8c-0.6,0.5-0.9,1.4-1,2.2
                                    c-0.1,1.5,0.6,3.1,2,3.6c1.2,0.4,2.5-0.2,3.6-0.7c1-0.5,2.1-0.9,3-1.6c0.9-0.7,1.7-1.7,1.8-2.8c0.2-1-0.3-2.3-1.2-2.9
                                    C920,193.2,918.5,193.8,917.4,194.2z"/>
                                <path name="OCTAVIO" class="st2" d="M914.5,202.5c-0.3,0-0.7,0-1-0.1c-1.7-0.5-2.4-2.4-2.3-4c0.1-1,0.5-1.9,1.1-2.4c0.5-0.4,1.1-0.7,1.7-0.9
                                    l3.3-1.2l0,0l0.2-0.1c1.1-0.4,2.6-1,3.8-0.1c1,0.7,1.6,2.1,1.4,3.3c-0.2,1.1-0.9,2.2-2,3.1c-0.9,0.7-1.9,1.1-2.9,1.5l-0.3,0.1
                                    C916.6,202,915.6,202.5,914.5,202.5z M917.6,194.6l-3.3,1.2c-0.5,0.2-1,0.4-1.4,0.7c-0.6,0.6-0.8,1.4-0.8,1.9
                                    c-0.1,1.2,0.5,2.7,1.7,3.1c1.1,0.3,2.3-0.2,3.3-0.6l0.3-0.1c0.9-0.4,1.9-0.8,2.7-1.4c0.5-0.4,1.5-1.3,1.7-2.5
                                    c0.1-0.9-0.3-2-1-2.5c-0.8-0.6-2-0.1-3,0.2L917.6,194.6z"/>
                            </g>
                            <g>
                                <path name="OCTAVIO" class="st3" d="M927.9,191.7c-0.4,0.3-0.7,0.7-1.1,0.9c-0.4,0.2-0.8,0.4-1,0.7c-0.4,0.6,0.2,1.4,0.7,1.9
                                    c0.7,0.6,1.4,1.2,2.3,1.4c0.9,0.2,1.9,0,2.5-0.6c0.4-0.4,0.6-0.9,0.9-1.4c0.6-0.8,1.5-1.2,2.3-1.8s1.4-1.7,0.9-2.5
                                    c-0.4-0.8-1.6-0.9-2.5-0.9C931.3,189.6,929.2,190.6,927.9,191.7z"/>
                                <path name="OCTAVIO" class="st2" d="M929.5,197.1c-0.3,0-0.5,0-0.8-0.1c-0.8-0.2-1.6-0.7-2.5-1.5s-1.2-1.7-0.8-2.4c0.2-0.4,0.6-0.6,0.9-0.8
                                    c0.1,0,0.2-0.1,0.2-0.1c0.3-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.2-0.2,0.3-0.3l0,0c1.3-1.1,3.5-2.2,5.2-2.4c1.5-0.1,2.5,0.3,2.9,1.1
                                    c0.6,1.1-0.1,2.4-1,3.1c-0.3,0.2-0.6,0.4-0.9,0.6c-0.5,0.3-1,0.6-1.3,1.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.3-0.3,0.6-0.6,0.9
                                    C931.1,196.8,930.3,197.1,929.5,197.1z M928.2,192c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.2-0.6,0.5-0.9,0.7c-0.1,0.1-0.2,0.1-0.3,0.2
                                    c-0.2,0.1-0.5,0.2-0.6,0.4c-0.2,0.4,0.2,0.9,0.6,1.3c0.8,0.7,1.5,1.1,2.1,1.3c0.8,0.2,1.7,0,2.1-0.5c0.2-0.2,0.3-0.4,0.5-0.7
                                    c0.1-0.2,0.2-0.4,0.4-0.6c0.4-0.6,1-0.9,1.6-1.3c0.3-0.2,0.6-0.4,0.8-0.6c0.6-0.5,1.1-1.4,0.8-2c-0.4-0.7-1.5-0.7-2.1-0.6
                                    C931.4,190,929.3,191.1,928.2,192L928.2,192z"/>
                            </g>
                            <g>
                                <path name="OCTAVIO" class="st3" d="M922.1,201.6c-0.3,0.6-0.6,1.2-0.9,1.9c-0.3,0.6-0.6,1.1-0.7,1.7c-0.1,0.4,0,0.9,0.3,1.1
                                    c0.3,0.2,0.8,0.1,1.2-0.2c0.3-0.3,0.6-0.6,0.9-0.9c1-0.8,2.6-0.3,3.6-1.1c0.3-0.3,0.5-0.7,0.7-1.1c0.7-1.6,0.4-3.9-1.6-4.3
                                    C923.9,198.5,922.7,200.4,922.1,201.6z"/>
                                <path name="OCTAVIO" class="st2" d="M921.2,206.9c-0.2,0-0.5-0.1-0.7-0.2c-0.4-0.3-0.6-0.8-0.5-1.5s0.4-1.3,0.7-1.9l0.9-1.9c0.5-1,1.9-3.4,4-3
                                    c0.9,0.2,1.5,0.6,1.9,1.4c0.5,0.9,0.5,2.3,0,3.5c-0.2,0.4-0.4,0.9-0.8,1.2c-0.6,0.5-1.3,0.6-2.1,0.7c-0.6,0.1-1.2,0.1-1.6,0.4
                                    c-0.2,0.1-0.3,0.3-0.4,0.4c-0.2,0.2-0.3,0.3-0.5,0.5C921.9,206.7,921.6,206.9,921.2,206.9z M925.2,199.2c-1.4,0-2.4,1.9-2.8,2.6
                                    l0,0l-0.9,1.9c-0.3,0.5-0.5,1.1-0.6,1.6c0,0.2,0,0.6,0.1,0.7c0.2,0.1,0.5,0,0.7-0.2c0.1-0.1,0.3-0.2,0.4-0.4
                                    c0.2-0.2,0.3-0.4,0.5-0.5c0.6-0.4,1.3-0.5,2-0.6c0.6-0.1,1.2-0.1,1.6-0.5c0.2-0.2,0.4-0.5,0.6-0.9c0.4-0.9,0.4-2,0-2.7
                                    c-0.3-0.5-0.7-0.8-1.3-1C925.4,199.2,925.3,199.2,925.2,199.2z M922.1,201.6L922.1,201.6L922.1,201.6z"/>
                            </g>
                            <path name="OCTAVIO" class="st2" d="M914.7,197.7c-0.1,0.2-0.2,0.5,0,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.9,0.2,3.8-0.1,3.1-1.6
                                C917.7,195.6,915.2,196.8,914.7,197.7z"/>
                            <path name="OCTAVIO" class="st2" d="M929.4,192.5c-0.5,0.4-1,0.9-1.1,1c-0.1,0.4,0.3,0.8,0.7,0.8s0.8-0.2,1.2-0.4c0.3-0.2,0.7-0.4,1-0.6
                                c0.3-0.2,0.7-0.5,0.7-0.9c0-0.3-0.3-0.5-0.6-0.5c-0.3-0.1-0.5,0-0.8,0.1L929.4,192.5z"/>
                        </g>
                        <path name="OCTAVIO" class="st2" d="M832.4,94.1c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.5l6.7-0.6c1.2-0.1,2-1.1,1.9-2.3l-3-35.6
                            c-0.1-1.4-0.5-2.4-1.1-2.9c-0.5-0.4-1.3-0.6-2.4-0.5c-0.2,0-0.5-0.1-0.5-0.4c0-0.2,0.1-0.5,0.4-0.5c1.3-0.2,2.3,0.1,3,0.7
                            c0.8,0.7,1.3,1.8,1.4,3.5l3,35.6c0.1,0.8-0.2,1.6-0.7,2.2s-1.2,1-2,1.1L832.4,94.1L832.4,94.1z"/>
                        <g>
                            <circle name="OCTAVIO" class="st3" cx="832" cy="55.2" r="1.3"/>
                            <path name="OCTAVIO" class="st0" d="M832,56.9c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7S832.9,56.9,832,56.9z M832,54.3
                                c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8S832.4,54.3,832,54.3z"/>
                        </g>
                    </g>
                </g>
                <g id="PAUL" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="PAUL" class="st0" d="M751.6,451c-0.9-1.1-0.5-3.1-0.2-4.2c-0.5,0.2-1.7,1-1.7,3.5s0.7,4.8,1.8,6.2c0.3,0.4,3.2,3.5,11.9,3.5
                                c8,0,14.7-2.4,15.1-5.5c0.1-0.7,0.2-1.3,0.3-1.8c0.4-2,0.5-2.7-0.4-3.2c0.1,1.3,0,1.8,0,1.8c-1.5,1.8-7.8,3-15.2,3.3
                                C757.1,454.8,752.9,452.7,751.6,451z"/>
                            <path name="PAUL" class="st0" d="M755.5,197c1.3-0.2,2.4,0,3.5,0.2c0.8,0.2,1.5,0.3,2.3,0.3c11.4-0.1,16.2-2.5,16.3-2.5
                                c3-1.2,2.4-3.9,2.4-4.1c0-0.1,0-0.2,0-0.3c2-3.5,0.7-6.2,0.6-6.3c-0.1-0.1-0.1-0.3,0-0.4c1.3-2.7-1.2-4.9-1.3-5s-0.2-0.3-0.1-0.4
                                c0.3-1.1,0.2-1.9-0.2-2.7c-0.5-0.8-1.3-1.2-1.6-1.2c-0.8-0.2-6.3-0.2-8.5,0c-2.4,0.2-3.8-1.9-3.9-2.8c-0.1-0.7,0.3-1.9,1-3.9
                                c0.6-1.9,1.4-4.2,1.3-5.2c0-0.2-0.2-0.4-0.4-0.5c-0.5-0.3-1.4-0.1-1.8,0.1c-0.4,0.2-1.6,2-2.3,3c-0.6,0.9-0.9,1.3-1.1,1.5
                                c-0.5,0.4-3.3,3.6-4.2,5c-0.4,0.6-1.1,2.1-1.9,3.7c-1.1,2.2-2.3,4.6-3,5.6c-1.4,1.8-9.5,3.2-10.4,3.4h-0.1
                                c-8-0.5-16.2-0.6-22.8-0.3c-0.2,0-0.3,0-0.4,0c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4c0,0,0.1,0,0.3,0c1.7-0.1,3.4-0.1,5.2-0.1
                                c-2-1.1-3.9-2.5-5.3-4.2c-0.3-0.4-0.6-0.8-0.9-1.3c-1.1,1.8-4.4,7.6-5.6,13.9c-1.7,8.7,3.4,11.1,3.6,11.2
                                c6.3,1.8,15,1.6,20.3,0.4c3.6-0.8,8.9-2.7,13.1-4.2C752.3,198,754.6,197.1,755.5,197z"/>
                            <path name="PAUL" class="st0" d="M781.7,128.3L781.7,128.3c8.7,0,15.6-3.8,19.2-10.3c0,0,1.9-4.1,2.2-9c0-0.2,0.2-0.4,0.5-0.4
                                c0.2,0,0.4,0.2,0.4,0.5c-0.1,1.2-0.3,2.3-0.5,3.4h0.1c1.5,0.8,3-0.6,3.5-1.9c0.6-1.6,0.6-3.4,0.5-5c-0.1-0.9-0.2-1.8-0.5-2.7
                                c-0.2-0.7-0.4-1.7-0.9-2.3c-0.5-0.5-1.3-0.6-1.8,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,1.1-0.7,2.6-1.7,2.6c-1.3,0-0.8-5.9-0.8-5.9
                                c0.6-6.8-4.2-11.4-5.2-12.8c-3.5-5.1-10.4-2.5-12.6-2.6c-2.2,0-3.2-0.8-4.9-0.7c-9.8,0.6-10.1,13.7-10.1,17.8v0.1
                                c0,0.1,0,11,0.9,16.4C770.8,120.9,775.8,128.3,781.7,128.3z"/>
                            <path name="PAUL" class="st0" d="M775.1,429c-0.8-0.4-0.7,1.7-0.7,1.7s0.7,2.5,0.8,3.7c0,0.2,0.1,0.6,0.2,1l0,0c4.4,4.8,15.8,3.5,15.9,3.4
                                c0.1,0,0.1,0,0.2,0c21,8,37.6,3.3,39.8,2.1c2.5-1.4,1.4-6.5,1.2-7.1c-0.1-0.4-1.1-1-2-1.4c0.3,1-0.1,1.5-0.1,1.5
                                c-7.6,4.3-30.1,1.4-32.2,0.7c-2.1-0.7-11.1-2.4-14.5-2.7C780.3,431.7,775.9,429.5,775.1,429z"/>
                            <path name="PAUL" class="st0" d="M781.7,129.2L781.7,129.2c-2.9,0-5.9-1.7-8.4-4.7c-0.2-0.2-0.4-0.5-0.6-0.8c0,2.6-0.2,5.2,0.5,7.7
                                c0.4,1.5,1.5,2.6,2.5,3.7c0.9,0.9,1.9,1.8,2.9,2.6c1.9,1.5,4,2.8,5.8,4.4c1.2,1.1,2.3,2.3,3.4,3.5c0.9,1,1.5,2.1,2.2,3.3
                                c0.5,0.8,1,1.5,1.4,2.3c1.5-3.1,3.8-5.7,6-8.2c1.2-1.4,2.3-2.8,3.2-4.4c-0.2-1.8-0.3-3.7-0.3-5.5c0-2.4,0-4.8,0.2-7.2v-0.1
                                c0.2-2.3,0.5-4.7,0.9-7C797.6,125.4,790.5,129.2,781.7,129.2z"/>
                            <path name="PAUL" class="st0" d="M818.5,226.9c-0.2-5.5-0.6-10.9-1-16.4c-0.2-2.7-0.6-5.4-0.5-8.1c0.1-3.8,0.7-7.6,1.6-11.4
                                c2-7.9,5.2-15.4,8.1-23.1c0.2-0.5,1-0.3,0.8,0.2c-0.8,2-1.5,3.9-2.3,5.9c-2.4,6.2-4.8,12.4-6.2,18.9c0.9,0.1,1.9,0.2,2.8,0.3
                                c3.5,0.3,7,0.4,10.5-0.2c0.6-0.1,1.3-0.2,1.9-0.4c0.7-0.2,0.9-0.5,1-1.2s0-1.4,0.2-2.1c0.2-0.5,0.5-0.9,0.7-1.4
                                c0.3-0.6,0.1-1.3,0-1.9c-0.1-0.5-0.2-1-0.3-1.5c-0.2-1.2-0.4-2.4-0.6-3.6c-0.5-2.8-0.9-5.7-1.3-8.5c-0.8-5.4-1.5-10.9-2.2-16.3
                                c-0.2-1.5-0.4-3-0.7-4.4c0-0.1-0.1-0.4-0.1-0.4c-0.2-0.6-0.8-1.5-1.2-2.2c-0.9-1.3-2-2.4-3.2-3.4c-1.6-1.3-3.2-2.4-4.8-3.6
                                c-1.6-1.2-3.2-2.4-4.8-3.5c-2.2-1.5-4.5-2.9-6.8-4.1c0.3,0.4,0.5,0.9,0.8,1.3c1.3,2.7,1.6,5.9,0.4,8.7c-0.1,0.1-0.2,0.2-0.4,0.2
                                c-5.7-0.2-12.2,1.4-15,6.9c-0.2,0.5-1,0.1-0.7-0.4c2.9-5.7,9.5-7.4,15.5-7.3c0.6-1.5,0.8-3.2,0.4-4.9c-0.6-3-2.5-5.5-4.7-7.6
                                c-1.1-1-2.4-1.8-3.5-2.8c-0.5-0.4-1-0.9-1.4-1.4c-0.2,3.3-0.2,6.6,0.1,9.8c0.5-1.2,0.8-2.5,0.9-3.9c0-0.6,0.9-0.6,0.8,0
                                c0,2-0.7,3.8-1.6,5.5c0,0.1-0.1,0.2-0.1,0.2c-0.8,1.4-1.8,2.7-2.8,3.9c-2.4,2.9-5.2,5.7-6.7,9.2c0.1,0.1,0.2,0.3,0.2,0.5
                                c0.7,1.5,1.1,3.1,1.4,4.7c0.1,0.5-0.7,0.8-0.8,0.2c-0.4-1.8-0.8-3.5-1.7-5.1v-0.1c0-0.1-0.1-0.2-0.1-0.2
                                c-0.4-0.7-0.8-1.3-1.2-1.9c-0.4-0.7-0.8-1.4-1.2-2c-1.5-2.5-3.7-4.6-6-6.4c-1.9-1.5-4-2.8-5.8-4.4c-1.1-1-2.2-2-3-3.2
                                c-0.8-1.1-1.3-2.2-1.5-3.6c-0.3-1.9-0.3-3.9-0.2-5.9c-1.6,0.9-3.1,2.2-4.3,3.7l0,0c-0.4,0.5-0.7,1-1,1.6c-0.8,1.4-1.4,3-1.9,4.6
                                c-0.4,1.4-0.9,2.9-1,4.3c2.3-0.3,4.6-0.4,6.9,0.1c2.4,0.4,4.7,1.4,7,2.2c0.5,0.2,0.3,1-0.2,0.8c-2.2-0.8-4.4-1.7-6.7-2.2
                                c-2.4-0.5-4.9-0.4-7.3,0c-0.3,0-0.5-0.1-0.5-0.4c0-1.8,0.5-3.5,1.1-5.1c0.5-1.6,1.1-3.2,2-4.7c0-0.1,0.1-0.1,0.1-0.2
                                c-4.7,2.2-10.1,2.3-15,3.9c-2.7,0.9-5.3,2.1-7.8,3.4c-2.4,1.3-4.6,2.8-6.2,5.1c-1.7,2.4-3.2,4.9-4.7,7.4c-1.6,2.5-3.2,5-4.8,7.6
                                s-3.2,5.2-4.9,7.7c-0.8,1.3-1.6,2.5-2.4,3.8c-0.4,0.6-0.8,1.2-1.1,1.8c-0.3,0.7-0.3,1.3-0.2,2.1c0.1,0.6,0,1.2-0.2,1.8
                                c-0.3,0.6-0.5,1.1-0.4,1.8c0.1,1.2,1,2.3,1.8,3.2c1.7,1.8,3.8,3.2,6,4.2c5,0,10.5,0.1,15.9,0.5c3.5-0.6,8.9-1.9,9.8-3
                                c0.7-0.9,1.9-3.3,2.9-5.4c0.8-1.7,1.5-3.1,2-3.8c1-1.5,3.8-4.7,4.3-5.1c0.1-0.1,0.6-0.8,1-1.4c1.2-1.8,2.1-3.1,2.6-3.3
                                c0.6-0.3,1.8-0.5,2.6-0.1c0.5,0.2,0.8,0.6,0.8,1.1c0.2,1.2-0.5,3.5-1.3,5.6c-0.5,1.4-1,3-1,3.5c0.1,0.5,1.1,2.3,3,2.1
                                s7.7-0.2,8.8,0c0.7,0.1,1.6,0.7,2.2,1.6c0.4,0.6,0.7,1.6,0.4,3.1c0.6,0.7,2.5,2.9,1.4,5.6c0.3,0.7,1.2,3.4-0.7,6.8
                                c0.1,0.7,0.3,3.6-2.9,4.9c-0.2,0.1-5,2.4-16.6,2.6c-0.9,0-1.7-0.2-2.5-0.3c-1-0.2-2-0.4-3.2-0.2c-0.8,0.1-3.2,1-5.9,2
                                c-0.6,0.2-1.3,0.5-2,0.7c0,0.1,0.1,0.1,0.1,0.2c0.5,2,0.9,4.1,1.3,6.1c1.4,7.8,2.2,15.6,2.3,23.5c0,3.8-0.2,7.6-0.5,11.4
                                c-0.2,1.9-0.4,3.9-0.7,5.8c-0.1,1-0.4,2-0.2,3c0.2,0.9,0.8,1.5,1.5,2.1c2.4,2,5.5,3.3,8.5,4.3c3.2,1,6.4,1.7,9.7,2.1
                                c6.9,0.9,14,1,20.9,0.7c3.8-0.2,7.5-0.5,11.3-0.9c4-0.5,7.9-1,11.8-1.8c3.4-0.7,7.5-2.2,8.2-6c0.4-1.9-0.6-3.6-1.2-5.3
                                c-0.5-1.3-0.8-2.7-1.1-4c-0.6-2.7-1.1-5.4-1-8.1C818.7,231.3,818.6,229.1,818.5,226.9z"/>
                            <path name="PAUL" class="st0" d="M834.8,193.4c-0.1,0.1-0.2,0.1-0.3,0.1c-1.4,0.4-2.8,0.5-4.2,0.7c-3.8,0.4-7.7,0.1-11.5-0.4
                                c-0.2,1.1-0.4,2.3-0.6,3.4c-0.5,3.6-0.5,7.2-0.1,10.9c0.2,2.3,0.4,4.6,0.5,6.8c0.1,1.7,0.2,3.3,0.3,5c0.4,1,1.2,3.9,3.8,13.2
                                c2.6,9.2,3.9,14.5,3.9,15.9c0.1,2.8-1.2,6-2.2,8.6c-0.5,1.4-1,2.6-1.1,3.4c-0.3,2.2,0.3,12,0.3,12.1l0,0c1,0.2,2.4,0.3,3.2-0.4
                                c0.4-0.4,0.5-1.1,0.6-1.6c0.2-0.7,0.2-1.4,0.4-2.1c0.6-2.9,1.7-5.6,3.1-8.1c0.3-0.5,1,0,0.7,0.4c-1.7,2.9-2.7,6-3.3,9.2
                                c-0.1,0.4-0.2,0.8-0.3,1.2c-0.1,0.3-0.1,0.6-0.2,0.9c-0.2,0.4-0.5,0.8-1,1c-0.9,0.5-2.1,0.5-3.1,0.3c0.5,1.5,2,1.5,2.1,1.5
                                c3,0.2,6.6,0.2,7-0.3c0.8-0.8,5.7-10.5,6.2-12.3s-0.8-7.6-3-16.9C834.1,237,834.7,199.7,834.8,193.4z"/>
                        </g>
                        <path name="PAUL" class="st1" d="M772.6,123.6c0,0,4.6,4.6,7.4,5.1s11.8-2,13.5-2.8s7.9-7.1,7.9-7.1s-8.9,14.6-15.4,14.4
                            C779.6,133.1,772.6,123.6,772.6,123.6z"/>
                        <path name="PAUL" class="st3" d="M719,169c1.5,1.4,2.8,3,4.3,4.4c2.6,2.5,5.5,4.6,8.5,6.5c2.5,1.6,5.1,2.9,7.7,4.1c0.5,0.2,0.9-0.5,0.4-0.7
                            c-4.2-2-8.3-4.3-12-7.1c-2.3-1.7-4.4-3.7-6.3-5.8c-0.7-0.7-1.3-1.4-2-2.1C719.2,168,718.6,168.6,719,169L719,169z"/>
                        <path name="PAUL" class="st3" d="M828.3,187.5c-4.2,0-7.6-0.7-7.8-0.7c-0.2-0.1-0.4-0.3-0.3-0.5s0.3-0.4,0.5-0.3c0.1,0,8.5,1.8,15.7-0.3
                            c0.2-0.1,0.5,0.1,0.5,0.3c0.1,0.2-0.1,0.5-0.3,0.5C833.8,187.2,830.9,187.5,828.3,187.5z"/>
                        <path name="PAUL" class="st3" d="M826.8,190.6c-3.9,0-7.3-0.4-7.5-0.4s-0.4-0.2-0.4-0.5s0.2-0.4,0.5-0.4c0.1,0,10.6,1.1,16.4-0.6
                            c0.2-0.1,0.5,0.1,0.5,0.3c0.1,0.2-0.1,0.5-0.3,0.5C833.4,190.4,829.9,190.6,826.8,190.6z"/>
                        <path name="PAUL" class="st3" d="M731.9,184c-0.1,0-0.1,0-0.2-0.1c-6.7-3.4-13.5-10.6-13.5-10.6c-0.2-0.2-0.2-0.4,0-0.6c0.2-0.2,0.4-0.1,0.6,0
                            c0.1,0.1,6.7,7.2,13.3,10.5c0.2,0.1,0.3,0.4,0.2,0.6C732.2,183.9,732.1,184,731.9,184z"/>
                        <circle name="PAUL" class="st3" cx="786.8" cy="152.4" r="1.7"/>
                        
                            <ellipse name="PAUL" transform="matrix(0.9942 -0.1075 0.1075 0.9942 -12.6377 88.1602)" class="st3" cx="811.4" cy="161.3" rx="3.1" ry="1.7"/>
                        <g>
                            <path name="PAUL" class="st2" d="M779,114.4c-0.5,0.2-0.3,1,0.2,0.8c3.5-1.2,7.6,1.2,10.8-1.1c0.4-0.3,0-1.1-0.4-0.7c-1.5,1.1-3.3,1-5.1,0.8
                                C782.7,114.1,780.8,113.8,779,114.4z"/>
                            <path name="PAUL" class="st2" d="M783.4,109c0.5-0.2,0.3-1-0.2-0.8c-1.2,0.4-2.6,0.4-3.7-0.1c-1.3-0.6-1.3-1.8-0.9-3c0.8-2.3,2.1-4.4,2.2-6.9
                                c0-0.6-0.8-0.6-0.8,0c-0.1,1.5-0.7,2.9-1.2,4.2c-0.5,1.2-1.2,2.6-1.2,3.9C777.3,109.3,781.2,109.7,783.4,109z"/>
                            <path name="PAUL" class="st2" d="M785.9,156.4c-0.6-1.4-1.6-2.5-2.4-3.8c-0.8-1.4-1.3-2.9-1.5-4.6c0-0.5-0.9-0.6-0.8,0c0.1,1.5,0.5,3,1.2,4.3
                                c0.7,1.4,1.8,2.6,2.5,3.9c1.5,2.8,1.3,6.1,1.9,9.1c0.1,0.5,0.9,0.3,0.8-0.2C787.1,162.3,787.2,159.1,785.9,156.4z"/>
                            <path  name="PAUL" class="st2" d="M786.8,150.4c-1.1,0-2,0.9-2,2s0.9,2,2,2s2-0.9,2-2S787.9,150.4,786.8,150.4z M786.8,153.7
                                c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3C788.2,153.1,787.6,153.7,786.8,153.7z"/>
                            <path name="PAUL" class="st2" d="M811.2,159.4c-1.9,0.2-3.3,1.2-3.2,2.3c0.1,1,1.4,1.6,3,1.6c0.2,0,0.4,0,0.7,0c1.9-0.2,3.3-1.2,3.2-2.3l0,0
                                C814.8,159.9,813.1,159.2,811.2,159.4z M811.6,162.7c-1.7,0.2-2.9-0.4-3-1s1-1.4,2.7-1.6c0.2,0,0.4,0,0.6,0
                                c1.3,0,2.3,0.5,2.3,1.1C814.3,161.7,813.2,162.5,811.6,162.7z"/>
                            <path name="PAUL" class="st2" d="M822.7,260.9c-0.3,2.3,0.3,11.9,0.3,12.3c0.2,2.6,2.1,3.2,3.1,3.2l0,0c0.7,0,2.1,0.1,3.6,0.1
                                c1.8,0,3.6-0.1,4.1-0.6c0.9-0.9,5.8-10.7,6.4-12.7c0.5-2-0.6-7.3-3-17.3c-2.3-9.6-1.4-52.8-1.4-53.3c0.3-0.6,0.3-1.4,0.4-2.2
                                c0.1-0.7,0.3-1.2,0.7-1.8c0.3-0.4,0.4-0.9,0.4-1.4c0-1.2-0.4-2.5-0.6-3.7c-0.5-2.7-0.9-5.5-1.3-8.2c-0.8-5.8-1.6-11.6-2.4-17.4
                                c-0.3-1.9-0.5-3.7-0.8-5.5c-0.4-2.1-1.6-3.9-3-5.4c-1.1-1.2-2.5-2.2-3.8-3.2c-1.9-1.4-3.8-2.8-5.7-4.2c-2.8-2.1-5.8-3.9-8.8-5.6
                                c-0.6-0.4-1.2-0.7-1.9-1c-1.2-1.4-2.6-2.7-4.1-3.8c-1.3-0.9-2.7-1.8-3.3-3.3c0.2-2.4,0.5-4.8,1-7.2c0.3-1.8,0.8-3.5,1.3-5.2
                                c1.1,0.4,2.4,0,3.2-0.9c1.1-1.1,1.4-2.8,1.6-4.3c0.2-1.8,0-3.6-0.4-5.3c-0.3-1.1-0.6-2.6-1.6-3.2c0.3-1,0.9-3.7,0.9-5.7
                                c0-1.9,0.4-13.3-4.1-17.2s-12.2-11.4-16.5-9.2c0,0-1.8-3.2-6.1,0.9c0,0-3.3-3.2-5.4,2.1c0,0-3-0.8-4.5,2.5c0,0-2.6-2.5-3.9,4.1
                                c0,0-2.2-3.8-3,9.7c0,0-0.4,6.1,2.2,10.6c0,0,1.3,2.2,2.2,3c0,2.9,0.2,11,0.9,15.4c0.3,2.1,1.3,4.4,2.7,6.5c0,0.4,0,0.8,0,1.2
                                c-1.9,1-3.6,2.4-5,4.2c-4.7,2.7-10.2,2.8-15.3,4.3c-2.8,0.8-5.4,2-8,3.3c-2.4,1.2-4.8,2.6-6.6,4.7c-1.8,2.2-3.3,4.8-4.8,7.2
                                c-1.7,2.6-3.3,5.3-5,7.9c-1.7,2.7-3.4,5.3-5,8c-0.8,1.3-1.7,2.6-2.5,4c-0.7,1.1-1.7,2.3-1.8,3.7c0,0.8,0.3,1.5,0.1,2.3
                                c-0.2,0.7-0.6,1.2-0.6,1.9c0,0.5,0.1,1,0.2,1.5c-1.2,1.6-4.8,8.2-6,14.7c-1.8,9.4,4,12.1,4.2,12.2c3.4,1,7.4,1.4,11.3,1.4
                                c3.5,0,6.9-0.3,9.5-0.9c2.9-0.6,6.9-2,10.6-3.3c0,0.1,0,0.1,0,0.1c1.8,8.1,3,16.3,3.4,24.5c0.2,3.9,0.1,7.8-0.1,11.6
                                c-0.2,3.9-0.7,7.8-1.2,11.7c-0.1,0.9-0.1,1.9,0.3,2.7c0.3,0.5,0.7,1,1.1,1.4c-0.5,1.4-2.1,6.4-1.9,11.7
                                c0.2,5.6,2.3,25.7,2.8,30.3s0.1,12.1-0.1,15.5s-0.8,5.8,0.3,7.5s-1.8,2.7-0.8,4.5s2.1,3.1,2.3,5.2s-3,6.2-1.7,8.7
                                c1.3,2.4,1.7,2.5,0.7,4.1s-2.8,4.1-1.7,5.7s0.6,3.7,0,4.4c0,0-1.3-0.7-0.8,5.5c0.4,6.2,1,33.2,1.4,38.7s1.8,17.3,1.4,20.6
                                c-0.4,3.3-3.1,4.1-1.1,6.4s1.6,2.3,1.6,5.5s2.1,2.8,2.1,2.8l0.6,3c0,0-2.8,11.4-3.8,12.8l0,0c-0.1,0-0.2,0-0.2,0
                                c-0.1,0-2.7,0.6-2.6,4.4c0,2.7,0.8,5.2,2,6.7c0.5,0.6,3.6,3.9,12.5,3.9c7.3,0,15.4-2.2,16-6.2c0.1-0.7,0.2-1.2,0.3-1.8
                                c0.4-2,0.7-3.4-1.1-4.3c-0.1,0-0.2,0-0.2,0c-0.1-0.9-0.4-2.1-0.8-3.6c-0.8-3.1-1.5-6-1.9-8c4.9,4,14.5,3,15.6,2.9
                                c9.1,3.5,17.7,4.6,24.6,4.6c8,0,13.8-1.5,15.8-2.6c3-1.6,1.9-7,1.6-8.1c-0.3-1.2-3-2.2-3.5-2.4c-1.8-2.3-7.2-5.8-23.3-9.8
                                c0,0-4.5-7.1-7.6-5.8c0,0-1.1-0.7-0.4-1.4c0.7-0.7,4.7-2.8,0.3-4.5c0,0,1-1.7-0.8-3.1c0,0-1.6-0.9-0.4-2.5c1.1-1.7,2.3-7,1.3-9.6
                                c0,0,1.8-19.6,2.7-25.4c0.8-5.8,2.3-26,2.8-28.8c0.6-2.8,2-6.2,1.6-8.9c0,0,3-2.1,2.7-6.8c0,0,3.7-14.2,4.8-17.6s6-22.8,6.7-26.5
                                c0.6-3.3,3.6-25.1,1.7-26.6c0.3-0.4,0.6-0.9,0.8-1.4c0.7-1.7,0.5-3.4-0.1-5c-0.6-1.5-1.2-2.9-1.5-4.5c-0.4-1.5-0.7-3-0.9-4.6
                                c-0.2-1.4-0.4-2.8-0.4-4.2c0-0.9,0-1.9,0-2.8c-0.1-2.6-0.2-5.3-0.3-7.9c2.1,7.2,6.6,23.1,6.7,25.5c0.1,2.6-1.1,5.8-2.1,8.3
                                C823.3,258.8,822.8,260,822.7,260.9z M769.1,99.4L769.1,99.4c0-4.2,0.3-17.3,10.1-17.9c1.7-0.1,2.7,0.6,4.9,0.7
                                c2.2,0,9.1-2.5,12.6,2.6c1,1.5,5.8,6,5.2,12.8c0,0-0.5,5.9,0.8,5.9c1,0,1.4-1.5,1.7-2.6c0.1-0.1,0.1-0.2,0.2-0.3
                                c0.5-0.5,1.3-0.5,1.8,0c0.5,0.6,0.7,1.5,0.9,2.3c0.2,0.9,0.4,1.8,0.5,2.7c0.1,1.6,0.1,3.5-0.5,5c-0.5,1.3-1.9,2.7-3.5,1.9h-0.1
                                c0.2-1.1,0.4-2.2,0.5-3.4c0-0.2-0.2-0.4-0.4-0.5c-0.2,0-0.4,0.2-0.5,0.4c-0.4,4.9-2.2,9-2.2,9c-3.5,6.5-10.5,10.3-19.2,10.3l0,0
                                c-5.9,0-10.9-7.4-11.7-12.5C769.1,110.4,769.1,99.5,769.1,99.4z M773.3,124.5c2.5,3,5.5,4.7,8.4,4.7l0,0
                                c8.8,0,15.9-3.8,19.7-10.3c-0.4,2.3-0.8,4.7-0.9,7v0.1c-0.2,2.4-0.2,4.8-0.2,7.2c0,1.8,0.2,3.7,0.3,5.5c-0.9,1.6-2.1,3-3.2,4.4
                                c-2.2,2.6-4.5,5.2-6,8.2c-0.5-0.8-1-1.5-1.4-2.3c-0.7-1.1-1.3-2.3-2.2-3.3c-1.1-1.2-2.2-2.4-3.4-3.5c-1.8-1.6-3.9-2.9-5.8-4.4
                                c-1-0.8-2-1.7-2.9-2.6c-1-1.1-2.1-2.2-2.5-3.7c-0.7-2.5-0.5-5.1-0.5-7.7C772.9,124,773.1,124.2,773.3,124.5z M736.4,203.2
                                c-5.3,1.1-14,1.3-20.3-0.4c-0.2-0.1-5.3-2.5-3.6-11.2c1.2-6.4,4.5-12.1,5.6-13.9c0.3,0.5,0.6,0.9,0.9,1.3
                                c1.4,1.7,3.3,3.1,5.3,4.2c-1.8,0-3.5,0.1-5.2,0.1c-0.2,0-0.3,0-0.3,0c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4c0,0,0.2,0,0.4,0
                                c6.7-0.3,14.8-0.1,22.8,0.3h0.1c0.9-0.1,9-1.6,10.4-3.4c0.7-1,2-3.4,3-5.6c0.8-1.6,1.5-3.1,1.9-3.7c0.9-1.4,3.8-4.6,4.2-5
                                c0.2-0.2,0.5-0.7,1.1-1.5c0.7-1,1.9-2.8,2.3-3c0.4-0.2,1.3-0.4,1.8-0.1c0.2,0.1,0.3,0.3,0.4,0.5c0.2,1-0.6,3.3-1.3,5.2
                                c-0.7,2-1.1,3.3-1,3.9c0.2,0.9,1.5,3.1,3.9,2.8c2.1-0.2,7.7-0.2,8.5,0c0.3,0.1,1.1,0.4,1.6,1.2c0.4,0.7,0.5,1.6,0.2,2.7
                                c0,0.2,0,0.3,0.1,0.4c0.1,0.1,2.6,2.3,1.3,5c-0.1,0.1,0,0.3,0,0.4c0.1,0.1,1.4,2.8-0.6,6.3c0,0.1-0.1,0.2,0,0.3
                                c0,0.1,0.6,2.8-2.4,4.1c0,0-4.9,2.4-16.3,2.5c-0.8,0-1.5-0.1-2.3-0.3c-1-0.2-2.1-0.4-3.5-0.2c-0.9,0.2-3.2,1-6,2
                                C745.3,200.5,740,202.4,736.4,203.2z M778.5,451.3c0,0,0.1-0.5,0-1.8c0.9,0.6,0.8,1.3,0.4,3.2c-0.1,0.5-0.2,1.1-0.3,1.8
                                c-0.5,3.1-7.1,5.5-15.1,5.5c-8.7,0-11.6-3.2-11.9-3.5c-1.1-1.4-1.8-3.6-1.8-6.2c0-2.5,1.2-3.2,1.7-3.5c-0.3,1.1-0.7,3,0.2,4.2
                                c1.3,1.7,5.5,3.8,11.6,3.6C770.7,454.3,776.9,453.1,778.5,451.3z M798.2,434.7c2.1,0.7,24.7,3.6,32.2-0.7c0,0,0.4-0.5,0.1-1.5
                                c0.9,0.4,1.9,1,2,1.4c0.1,0.6,1.3,5.8-1.2,7.1c-2.2,1.2-18.8,5.9-39.8-2.1c-0.1,0-0.1,0-0.2,0c-0.1,0-11.5,1.3-15.9-3.4l0,0
                                c-0.1-0.5-0.2-0.8-0.2-1c-0.1-1.1-0.8-3.7-0.8-3.7s-0.1-2.1,0.7-1.7s5.2,2.7,8.7,3C787.1,432.3,796.1,434,798.2,434.7z
                                M819.7,241.6c0.3,1.4,0.6,2.7,1.1,4c0.7,1.7,1.6,3.4,1.2,5.3c-0.8,3.8-4.8,5.3-8.2,6c-3.9,0.8-7.9,1.3-11.8,1.8
                                c-3.7,0.5-7.5,0.8-11.3,0.9c-7,0.3-14,0.3-20.9-0.7c-3.3-0.5-6.6-1.1-9.7-2.1c-3-1-6-2.3-8.5-4.3c-0.7-0.6-1.3-1.2-1.5-2.1
                                c-0.2-1,0-2,0.2-3c0.3-1.9,0.5-3.9,0.7-5.8c0.3-3.8,0.5-7.6,0.5-11.4c0-7.9-0.8-15.7-2.3-23.5c-0.4-2.1-0.8-4.1-1.3-6.1
                                c0-0.1,0-0.1-0.1-0.2c0.7-0.2,1.3-0.5,2-0.7c2.7-1,5-1.8,5.9-2c1.2-0.2,2.1,0,3.2,0.2c0.8,0.2,1.6,0.3,2.5,0.3
                                c11.6-0.1,16.5-2.5,16.6-2.6c3.2-1.3,3-4.1,2.9-4.9c1.9-3.4,1-6.1,0.7-6.8c1.1-2.7-0.8-5-1.4-5.6c0.4-1.5,0-2.5-0.4-3.1
                                c-0.6-0.9-1.5-1.5-2.2-1.6c-1-0.2-6.8-0.2-8.8,0c-1.9,0.2-2.9-1.6-3-2.1s0.5-2.1,1-3.5c0.7-2.2,1.5-4.4,1.3-5.6
                                c-0.1-0.5-0.4-0.9-0.8-1.1c-0.8-0.4-2-0.2-2.6,0.1c-0.5,0.3-1.4,1.6-2.6,3.3c-0.4,0.6-0.8,1.3-1,1.4c-0.5,0.5-3.3,3.7-4.3,5.1
                                c-0.4,0.6-1.1,2.1-2,3.8c-1,2.1-2.2,4.5-2.9,5.4c-0.9,1.2-6.3,2.4-9.8,3c-5.4-0.3-10.8-0.5-15.9-0.5c-2.2-1-4.3-2.4-6-4.2
                                c-0.8-0.9-1.7-2-1.8-3.2c-0.1-0.7,0.2-1.2,0.4-1.8c0.3-0.6,0.3-1.1,0.2-1.8c-0.1-0.8-0.2-1.4,0.2-2.1c0.3-0.6,0.7-1.2,1.1-1.8
                                c0.8-1.3,1.6-2.5,2.4-3.8c1.6-2.6,3.2-5.2,4.9-7.7c1.6-2.5,3.2-5,4.8-7.6c1.5-2.5,3-5,4.7-7.4c1.6-2.3,3.8-3.8,6.2-5.1
                                c2.5-1.3,5.1-2.6,7.8-3.4c4.9-1.6,10.3-1.7,15-3.9c0,0.1-0.1,0.1-0.1,0.2c-0.8,1.5-1.5,3.1-2,4.7c-0.5,1.6-1.1,3.4-1.1,5.1
                                c0,0.3,0.3,0.5,0.5,0.4c2.4-0.4,4.9-0.5,7.3,0c2.3,0.5,4.5,1.3,6.7,2.2c0.5,0.2,0.7-0.6,0.2-0.8c-2.3-0.9-4.6-1.8-7-2.2
                                c-2.3-0.4-4.6-0.4-6.9-0.1c0.1-1.5,0.6-2.9,1-4.3c0.5-1.6,1.1-3.1,1.9-4.6c0.3-0.5,0.7-1.1,1-1.6l0,0c1.2-1.5,2.7-2.8,4.3-3.7
                                c0,2-0.1,3.9,0.2,5.9c0.2,1.3,0.7,2.5,1.5,3.6c0.9,1.2,2,2.2,3,3.2c1.8,1.6,3.9,2.9,5.8,4.4c2.3,1.8,4.5,3.9,6,6.4
                                c0.4,0.7,0.8,1.4,1.2,2s0.9,1.2,1.2,1.9c0,0.1,0.1,0.2,0.1,0.2v0.1c0.8,1.6,1.3,3.4,1.7,5.1c0.1,0.5,0.9,0.3,0.8-0.2
                                c-0.3-1.6-0.8-3.2-1.4-4.7c-0.1-0.2-0.2-0.3-0.2-0.5c1.5-3.5,4.2-6.3,6.7-9.2c1-1.2,2-2.5,2.8-3.9c0.1-0.1,0.1-0.1,0.1-0.2
                                c0.9-1.7,1.6-3.5,1.6-5.5c0-0.6-0.8-0.6-0.8,0c0,1.4-0.4,2.7-0.9,3.9c-0.3-3.3-0.3-6.6-0.1-9.8c0.4,0.5,0.8,1,1.4,1.4
                                c1.2,0.9,2.4,1.8,3.5,2.8c2.2,2,4.1,4.5,4.7,7.6c0.3,1.6,0.2,3.3-0.4,4.9c-5.9-0.1-12.5,1.6-15.5,7.3c-0.2,0.5,0.5,0.9,0.7,0.4
                                c2.8-5.5,9.3-7.1,15-6.9c0.1,0,0.3-0.1,0.4-0.2c1.3-2.8,0.9-6-0.4-8.7c-0.2-0.5-0.5-0.9-0.8-1.3c2.3,1.3,4.6,2.7,6.8,4.1
                                c1.7,1.1,3.2,2.3,4.8,3.5s3.3,2.4,4.8,3.6c1.2,1,2.4,2.1,3.2,3.4c0.4,0.7,1,1.6,1.2,2.2c0,0,0.1,0.3,0.1,0.4
                                c0.3,1.5,0.5,3,0.7,4.4c0.8,5.4,1.5,10.9,2.2,16.3c0.4,2.8,0.8,5.7,1.3,8.5c0.2,1.2,0.4,2.4,0.6,3.6c0.1,0.5,0.2,1,0.3,1.5
                                c0.1,0.6,0.3,1.3,0,1.9c-0.2,0.5-0.6,0.9-0.7,1.4c-0.2,0.7-0.2,1.4-0.2,2.1c-0.1,0.7-0.3,1-1,1.2c-0.6,0.2-1.3,0.3-1.9,0.4
                                c-3.5,0.5-7,0.5-10.5,0.2c-0.9-0.1-1.9-0.2-2.8-0.3c1.4-6.5,3.8-12.8,6.2-18.9c0.8-2,1.5-3.9,2.3-5.9c0.2-0.5-0.6-0.7-0.8-0.2
                                c-2.9,7.6-6.2,15.1-8.1,23.1c-0.9,3.7-1.5,7.5-1.6,11.4c0,2.7,0.3,5.4,0.5,8.1c0.5,5.4,0.8,10.9,1,16.4c0.1,2.2,0.2,4.4,0.2,6.6
                                C818.7,236.3,819.2,239,819.7,241.6z M826.8,249c0-1.3-1.4-6.7-3.9-15.9c-2.6-9.2-3.5-12.2-3.8-13.2c-0.1-1.7-0.2-3.3-0.3-5
                                c-0.2-2.3-0.3-4.6-0.5-6.8c-0.4-3.6-0.4-7.2,0.1-10.9c0.2-1.1,0.4-2.3,0.6-3.4c3.8,0.5,7.6,0.8,11.5,0.4c1.4-0.1,2.9-0.3,4.2-0.7
                                c0.1,0,0.2-0.1,0.3-0.1c-0.1,6.2-0.7,43.6,1.4,52.7c2.2,9.3,3.5,15,3,16.9c-0.5,1.8-5.3,11.5-6.2,12.3c-0.5,0.5-4,0.5-7,0.3
                                c-0.1,0-1.5,0-2.1-1.5c1,0.1,2.2,0.2,3.1-0.3c0.4-0.2,0.8-0.5,1-1c0.1-0.3,0.2-0.6,0.2-0.9c0.1-0.4,0.2-0.8,0.3-1.2
                                c0.5-3.2,1.6-6.4,3.3-9.2c0.3-0.5-0.5-0.9-0.7-0.4c-1.5,2.5-2.5,5.2-3.1,8.1c-0.1,0.7-0.2,1.4-0.4,2.1c-0.1,0.5-0.2,1.3-0.6,1.6
                                c-0.8,0.7-2.2,0.5-3.2,0.4l0,0c0-0.1-0.6-9.9-0.3-12.1c0.1-0.8,0.6-2,1.1-3.4C825.6,255,826.9,251.8,826.8,249z"/>
                        </g>
                        <path name="PAUL" class="st3" d="M775.4,427.6c1.2-0.4,1.3-1.7,1.2-2.8c0-0.8-0.2-1.5-0.2-2.3c0-0.9,0.2-1.8,0.4-2.7c0.4-1.8,0.9-3.5,1.1-5.4
                            c0.2-1.9,0.1-3.8,0-5.6c-0.4-7.4-0.3-14.8-0.1-22.1c0.2-6.9,0.6-13.7,1.2-20.6c0.6-7,1.5-14,2.6-21c0.1-0.6,0.2-1.2,0.1-1.9
                            c-0.1-0.6-0.2-1.1-0.2-1.7c0-1.5,0.8-2.7,1.8-3.9c0.9-1,2.1-2.1,2.5-3.4s-0.3-2.7-1-3.9c-0.4-0.7-0.8-1.3-1.1-2
                            c-0.3-0.5-1,0-0.7,0.4c0.7,1.3,1.7,2.6,2,4s-0.8,2.5-1.7,3.5c-1,1.1-2,2.1-2.4,3.5c-0.4,1.2-0.2,2.3-0.1,3.5
                            c0,0.7-0.1,1.4-0.2,2.1c-0.1,0.9-0.2,1.7-0.4,2.5c-0.2,1.7-0.5,3.4-0.7,5.1c-0.4,3.4-0.8,6.7-1.2,10c-0.7,6.7-1.1,13.3-1.3,20
                            c-0.3,7.1-0.3,14.2-0.2,21.4c0.1,3.7,0.7,7.4,0,11c-0.3,1.8-0.9,3.5-1.1,5.3c-0.1,0.8-0.2,1.6-0.2,2.5c0,0.7,0.2,1.3,0.2,2
                            c0,0.5-0.1,1.2-0.6,1.4C774.7,427,774.9,427.8,775.4,427.6L775.4,427.6z"/>
                        <path name="PAUL" class="st3" d="M785.9,332.5c0.8-9,1.7-18,2.5-27c0.8-8.4,1.3-16.8,1.4-25.2c0-2.4,0-4.8,0-7.1c0-1.1-0.2-1.9-0.8-2.8
                            c-0.5-0.9-1.2-1.7-1.7-2.6c-0.3-0.5-1,0-0.7,0.4c0.5,0.8,1,1.5,1.5,2.3s0.8,1.6,0.8,2.6c0,2.1,0.1,4.1,0.1,6.2
                            c0,4.1-0.2,8.3-0.4,12.4c-0.5,8.5-1.3,17-2.2,25.5c-0.5,5.1-1,10.2-1.4,15.4C785,333.1,785.8,333.1,785.9,332.5L785.9,332.5z"/>
                        <path name="PAUL" class="st3" d="M789.5,273c3.7-1.3,7-3.4,10.5-5.1c0.5-0.2,0.1-1-0.4-0.7c-3.4,1.7-6.7,3.8-10.3,5
                            C788.7,272.3,789,273.1,789.5,273L789.5,273z"/>
                        <path name="PAUL" class="st3" d="M761.2,341c3.2-1.9,6.7-3.4,10.3-4.6c0.5-0.2,0.3-1-0.2-0.8c-3.7,1.2-7.2,2.7-10.5,4.7
                            C760.3,340.6,760.7,341.3,761.2,341L761.2,341z"/>
                        <path name="PAUL" class="st3" d="M764.5,343c1.4-0.7,2.9-1.4,4.4-2c0.2-0.1,0.4-0.3,0.3-0.5c-0.1-0.2-0.3-0.4-0.5-0.3c-1.6,0.6-3.1,1.3-4.6,2
                            C763.6,342.5,764,343.3,764.5,343L764.5,343z"/>
                        <path name="PAUL" class="st3" d="M791.6,339.3c1-0.6,2.1-1.1,3.2-1.6c0.2-0.1,0.4-0.3,0.3-0.5c-0.1-0.2-0.3-0.4-0.5-0.3
                            c-1.2,0.5-2.3,1-3.4,1.7C790.7,338.9,791.1,339.6,791.6,339.3L791.6,339.3z"/>
                        <path name="PAUL" class="st3" d="M755,262.4c2.5,0.6,5.1,1,7.7,1.1c1.1,0.1,2.3,0,3.3-0.4c1.1-0.5,2-1.2,2.9-1.9c0.4-0.4-0.2-1-0.6-0.6
                            c-0.8,0.7-1.7,1.4-2.6,1.8c-1.1,0.4-2.2,0.4-3.3,0.3c-2.4-0.2-4.8-0.5-7.1-1C754.7,261.4,754.5,262.2,755,262.4L755,262.4z"/>
                        <path name="PAUL" class="st0" d="M757.9,434.4c0.6-1.3,1.7-2.1,3.1-2.5c1.5-0.4,3.1-0.5,4.6-0.6c1.3-0.1,3-0.4,4.1,0.4c1,0.7,1.8,2,3.2,1.6
                            c0.5-0.2,0.3-1-0.2-0.8c-0.8,0.2-1.4-0.4-1.9-0.9s-1-0.8-1.7-1c-1.4-0.4-3.1-0.1-4.6,0s-3.1,0.2-4.5,0.8c-1.2,0.5-2.3,1.4-2.8,2.6
                            C756.9,434.4,757.7,434.9,757.9,434.4L757.9,434.4z"/>
                        <path name="PAUL" class="st0" d="M778.4,423.8c4.1,0.7,8.2,0.8,12.4,0.3c1.6-0.2,3.6-0.2,4.2-1.9c0.3-0.8,0.5-1.7,1-2.5c0.5-0.9,1-1.7,1.7-2.4
                            c0.4-0.4-0.2-1-0.6-0.6c-1.1,1.3-2,2.8-2.5,4.4c-0.3,0.9-0.4,1.6-1.4,1.8c-0.9,0.3-1.8,0.3-2.8,0.5c-3.9,0.5-7.8,0.4-11.7-0.3
                            C778.1,422.9,777.9,423.7,778.4,423.8L778.4,423.8z"/>
                    </g>
                </g>
                <g id="RICK_G" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="RICK_G" class="st0" d="M620.3,89.5c1.8-0.5,2.2-1,2.9-1.9l0.2-0.2c0.8-0.9,1.9-1.1,3-1.3c0.2,0,0.5-0.1,0.7-0.1
                                c0.6-0.1,1.1,0,1.6,0.1c0,0,0.1,0,0.2,0h0.1c1.1,0.2,2.4,0.5,4.8-0.1c4-1,5.3-2.8,6.3-4c2.4-3.8,2.3-8.1,1.9-10.9
                                c-0.1-0.6-0.4-1.2-0.7-1.6c0.4,3,0.2,7-2.1,10.7c-1,1.3-2.5,3.3-6.8,4.4c-2.6,0.7-4.1,0.3-5.2,0.1c-0.6-0.1-1.1-0.2-1.6-0.1
                                c-0.2,0.1-0.5,0.1-0.7,0.1c-1,0.2-1.9,0.3-2.5,1l-0.2,0.2c-0.8,0.9-1.3,1.6-3.3,2.1c-1.8,0.5-7.3,1.4-9.6,1.4c-0.3,0-0.5,0-0.7,0
                                c-0.1,0-0.4,0-0.7-0.1c0.3,0.5,0.7,0.8,1,1.1l0,0l0,0c0.7,0.4,1.3,0.5,1.4,0.5C611.8,91.1,618.2,90.1,620.3,89.5z"/>
                            <path name="RICK_G" class="st0" d="M608.7,88.6C608.7,88.6,608.8,88.6,608.7,88.6c1.5,0.2,7.9-0.8,10-1.4c1.8-0.5,2.2-1,2.9-1.9l0.2-0.2
                                c0.8-0.9,1.9-1.1,3-1.3c0.2,0,0.5-0.1,0.7-0.1c0.7-0.1,1.3,0,1.9,0.1c1.1,0.2,2.4,0.5,4.8-0.1c4-1,5.3-2.8,6.3-4
                                c2.4-3.8,2.3-8.1,1.9-10.9c-0.2-1.4-3.4-1.5-4.5-1.4c-6.7,0.2-21.7,7-23.2,7.8c-0.8,0.4-1.6,1.6-2.1,2.5l0,0
                                c-0.4,0.8-0.8,1.5-1.2,2.2c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.6-0.5,1.2-0.7,1.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.2,0.7
                                c-0.6,1.7-0.8,3.2-0.8,4.4c0.1,0.3,0.2,0.6,0.3,0.8C608,88.6,608.6,88.6,608.7,88.6L608.7,88.6z"/>
                            <path name="RICK_G" class="st0" d="M647.6,85.4C647.8,85.6,647.7,85.5,647.6,85.4L647.6,85.4z"/>
                            <path name="RICK_G" class="st0" d="M621.8,106.8c0.9,0.2,1.5-0.6,1.8-1.7s0.3-1.7,0.9-1.7s1.3,0.1,1.6,1.9s0.8,1.7,2.5,1.7
                                c3.3,0.2,3.6-2.7,5.2-2.7c1.6-0.1,2.5,1.2,1.5,4.3c-0.5,1.5-1.6,4.8-3.2,8c5.9-0.1,8.4-0.7,11-2.5c1.2-0.8,3.3-4.6,4.3-6.4
                                c0.7-1.2,0.7-1.3,0.9-1.4s0.5-0.1,0.6,0.1s0.1,0.4,0,0.6c-0.1,0.1-0.4,0.7-0.7,1.2c-1.6,2.8-3.3,5.8-4.6,6.7
                                c-2.8,2-5.5,2.6-12,2.7c-1.6,2.8-3.6,5.3-5.9,6c-0.2,0.7-0.6,1.3-1.1,1.8c0.4,0.3,0.8,0.6,1.2,0.9c1,0.7,2,1.1,3.1,1.4
                                c2.3,0.6,4.7,0.8,7.1,0.7c2.5,0,4.9-0.3,7.3-0.9c3.8-1,7.5-2.8,10.3-5.6c-0.1,0-0.1-0.1-0.2-0.2c-0.6-1-1.2-2-1.6-3
                                c-0.5-1.2-0.6-2.6-0.6-3.9c0-1.7,0.3-3.3,0.7-4.9c0.3-1.5,0.7-3.1,1.3-4.5c-0.6,0.1-1.1,0.1-1.6-0.2c-0.5-0.3,0-1,0.4-0.7
                                c0.7,0.4,1.5-0.1,2.2-0.4s1.3-0.6,1.9-1c1.3-0.9,2-2.3,2.1-3.9c0.1-0.8,0-1.5,0-2.3c0-0.6-0.1-1.4-0.5-2
                                c-0.5-0.6-1.4-0.6-2.1-0.5c-0.8,0.1-1.5,0.4-2.2,0.9c-0.4,0.3-1-0.3-0.6-0.6c0.2-0.1,0.3-0.2,0.5-0.4c-0.4-0.3-2.3-0.7-2.8-0.8
                                c-0.5,0-0.6,0.9-1,2.1c-0.3,1.1-0.8,1.2-1.5,1.2s-1.4-0.1-1.6-0.6c-0.5-1,0.8-5.9,0.8-5.9c0-0.6-0.7-1-1.1-1.2
                                c-0.4,0.5-0.9,1-1.4,1.5c-2.8,2.3-6.6,2.4-9.8,1.1c-1.5-0.6-2.6-1.5-3.7-2.7c-0.4-0.5-0.9-0.9-1.4-1.3c-0.3-0.2-0.7-0.6-1.1-0.8
                                c-0.6-0.1-1-0.2-1.5-0.1c-0.2,0.1-0.5,0.1-0.7,0.1c-1,0.2-1.9,0.3-2.5,1l-0.2,0.2c-0.2,0.2-0.3,0.4-0.5,0.6l0.1,0.1
                                c0.2,0.5,0.3,0.9,0.6,1.3c0.1,0.2,0.2,0.4,0.4,0.6l0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.2,0.2c0.2,0.2,0.2,0.4,0,0.6
                                s-0.4,0.2-0.6,0c-0.3-0.2-0.5-0.5-0.6-0.8c-0.3,1.4-0.9,2.9-1.9,4c-0.2,0.2-0.4,0.3-0.6,0.5c0.6,3.4,0.5,6.9-0.1,10.4
                                C621.2,106.4,621.5,106.8,621.8,106.8z"/>
                            <path name="RICK_G" class="st0" d="M644.1,80.9c-0.2,0.4-0.3,0.8-0.5,1.2c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.1,0.1,0.2s0.3,0.2,0.5,0.3
                                c0.6,0.4,1.3,0.8,1.9,1.2c0.3,0.2,0.6,0.5,0.9,0.7c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0.1,0.1
                                c0.1-0.1,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.3,0.2-0.5c0.2-0.4,0.3-0.8,0.5-1.2c0.5-1.5,0.7-3.4-0.3-4.8c-0.3-0.5-0.8-0.9-1.3-1.3
                                c-0.1,0-0.2-0.1-0.2-0.1c-0.6-0.4-1.2-0.7-1.8-0.9C645.4,77.7,644.7,79.4,644.1,80.9z"/>
                            <path name="RICK_G" class="st0" d="M634,86.8c-1.5,0.4-2.6,0.4-3.5,0.4c0.3,0.3,0.7,0.6,1,0.9c0.4,0.4,0.8,0.9,1.2,1.3c0.6,0.5,1.3,1,2,1.3
                                c1.6,0.7,3.5,1.1,5.2,0.9c3.2-0.2,6-2.7,6.8-5.8l0,0c-0.2-0.2-0.5-0.5-0.7-0.7c-0.7-0.6-1.5-1.1-2.3-1.6
                                c-0.3-0.2-0.8-0.4-1.1-0.8c-0.2-0.3-0.1-0.6,0-1c0.4-1,0.8-1.9,1.2-2.9s0.5-2,0.2-3.1c0-0.1,0-0.1,0-0.2c-0.1-0.3,0-0.6,0.4-0.6
                                c0.7,0.1,1.2,0.3,1.8,0.6c-0.8-1.6-2.3-3.5-3.4-4.7c0.5,3,0.5,7.4-2,11.4C639.8,83.8,638.3,85.8,634,86.8z"/>
                            <path name="RICK_G" class="st0" d="M648,86.2c-0.2,0.1-0.3,0.2-0.5,0.1c0,0.1,0,0.2-0.1,0.3c0.5,0.4,7.5,6,7.6,6.1c5,2.9,1.9-1.7,0.5-3
                                c-1.2-1.1-5.1-4.9-6.2-6.1c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.5-0.4,0.9-0.6,1.3C648.5,85.5,648.3,85.9,648,86.2z"/>
                            <path name="RICK_G" class="st0" d="M622.9,90L622.9,90c-0.1-0.3-0.1-0.5-0.2-0.7c-0.5,0.4-1.1,0.7-2.2,1c-1.8,0.5-7.3,1.4-9.6,1.4
                                c1.4,1,2.8,2.1,4.3,2.8c1.5,0.7,3.8,1.5,5.3,0.4C622.1,94,622.6,91.7,622.9,90z"/>
                            <path name="RICK_G" class="st0" d="M688.8,176.5c-3.7,0.3-7.3,1-10.8,2c-0.8,0.2-1.6,0.5-2.3,0.7c0.8,2.1,5.9,15.6,6,18.9
                                c0,1.4-0.5,33.1-3,38.5c0,0.1-0.9,1.4-1.9,3.8l0,0c-0.2,0.6-0.5,1.2-0.7,1.9c-0.5,1.6-1.2,4.2-1.9,6.7c-1,3.5-1.9,6.9-2.4,7.9
                                c-0.1,0.3-0.3,0.7-0.5,1.1c-0.7,1.3-2.6,5.3-2.1,5.9c0.5,0.5,2.5,0.2,3.8-0.1h0.1c-0.1-2.7-0.2-4.9-0.2-5c0-0.2,0.2-0.4,0.4-0.5
                                c0.2,0,0.4,0.2,0.5,0.4c0,0.1,0.5,11,0.5,13.5c0,2,1.7,2.1,1.9,2.1h0.1c3.1,0.7,6.8-0.1,7.9-1.2c0.8-0.8,1.9-4.8,2.4-7.4
                                c0-0.2,0.2-0.4,0.4-0.3c0.2,0,0.4,0.2,0.4,0.4c0.5,4.1,1.3,4.5,1.4,4.6c0.3,0.1,0.7-0.4,0.9-0.6c0.5-1.7,1-6.8,1.4-15.3v-0.6
                                c0.1-2.2-0.8-13.3-0.8-13.4c0-0.1,0-0.1,0-0.1c3.8-15.2,7.3-35.5,7.9-42.3c0.5-5.9-4.4-19.6-5.1-21.5
                                C691.6,176.3,690.1,176.4,688.8,176.5z"/>
                            <path name="RICK_G" class="st0" d="M672.2,252.7c0.3-1.2,0.7-2.6,1.1-3.9c0.7-2.5,1.4-5.1,1.9-6.7c0.2-0.7,0.5-1.3,0.7-1.9
                                c0-1.7-0.3-3.3-0.8-4.9c-1.4-5.1-4.3-9.7-5.5-14.9c-0.4,2.7-0.6,5.5,0.1,8.2c0.1,0.5-0.7,0.8-0.8,0.2c-0.8-3.2-0.5-6.5,0-9.7
                                c0.1-0.4,0.2-0.9,0.2-1.4v-0.1c0.4-2.5,0.8-5,1.4-7.4c0.6-2.8,1.3-5.7,1.8-8.5c1-5.4,1.8-10.8,2.7-16.3c-2.2,0.1-4.5,0-6.5-0.6
                                c-1.1-0.3-2.5-0.9-2.8-2.2c-0.1-0.6-0.1-1.3-0.1-2c0-1.3,0-2.5,0-3.8c0-3.7,0.1-7.5,0.2-11.2c0.1-4.3,0.3-8.5,0.5-12.8
                                c0.2-3.5,0.4-6.9,0.9-10.4c0.1-0.5,0.9-0.5,0.8,0c-0.5,3.8-0.7,7.7-0.9,11.5c-0.4,7.7-0.6,15.5-0.6,23.2c0,1.2,0,2.4,0,3.6
                                c0,0.4,0,0.7,0,1.1c0,0.1,0,0.3,0,0.4v0.1v0.1c0.3,1,1.7,1.4,2.7,1.6c2.4,0.6,4.9,0.5,7.4,0.3c-0.9-2.4-1.6-4.4-1.9-5.1
                                c-2.4,0.8-4.7,1.8-6.8,3.2c-0.5,0.3-0.9-0.4-0.4-0.7c1.7-1.1,3.6-2,5.5-2.7c3.7-1.4,7.5-2.4,11.4-3c1.8-0.3,3.5-0.5,5.3-0.6
                                c1.2-0.1,2.4-0.1,3.5,0.1h0.1c0.8,0.1,1.6,0.4,2.3,0.8c0.5,0.3,0,1-0.4,0.7c-0.4-0.2-0.8-0.4-1.3-0.5c0.2,0.7,0.6,1.7,1.1,3.1
                                c0.8-0.2,1.7-1.1,2.2-1.8c0.4-0.5,0.2-1.3,0.1-1.9c-0.1-0.8-0.3-1.6-0.5-2.4c-0.6-3.3-1.3-6.5-2-9.8c-1.3-5.9-2.9-11.7-4.6-17.5
                                c-0.8-2.8-1.7-5.6-2.7-8.4c-0.5-1.4-1-2.7-1.5-4c-0.2-0.6-0.5-1.2-0.8-1.8c-0.8-1.4-1.9-2.6-3.3-3.6c-1.1-0.8-2.3-1.2-3.5-1.7
                                c-1.3-0.5-2.5-0.9-3.8-1.4c-2.6-0.9-5.3-1.8-7.9-2.6c-1.3-0.4-2.7-1.1-4.1-1.2c-0.5,0-1.1,0.1-1.6,0.2c-0.1,0-0.1,0-0.2,0
                                c-0.8,0.2-1.5,0.3-2.3,0.3c-0.5,0-0.5-0.8,0-0.8s0.9-0.1,1.4-0.2c-1.9-1.5-4.4-1.9-6.8-1.7c0.3,0.6,0.6,1.1,1,1.6
                                c0.2-0.2,0.3-0.3,0.5-0.5c0.4-0.4,1,0.2,0.6,0.6c-1.7,2-3.8,3.6-6.1,4.8c-4.5,2.3-9.6,3.2-14.6,3.1c-2.5-0.1-5-0.3-7.3-1.2
                                c-0.9-0.3-1.7-0.8-2.4-1.4c-0.7-0.5-1.4-1.1-1.8-1.9c-0.3-0.5,0.5-0.9,0.7-0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1-0.1,0.2-0.1,0.2-0.2
                                c0.1-0.1,0.2-0.2,0.2-0.2l0,0l0,0c0,0,0,0,0-0.1c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3v-0.1c-0.1,0-0.1,0-0.2,0
                                c-0.7-0.1-1.6-0.2-2.4-0.7c-0.6,0.2-1.1,0.3-1.7,0.4c-1.3,0.2-2.4-0.1-3.6-0.6c-2.2-0.8-4.4-1.7-6.7-2.3
                                c-1.1-0.3-2.3-0.5-3.4-0.9c-1.1-0.3-2.2-0.7-3.3-1.1c-2.1-0.7-4.2-1.3-6.3-2.1c-1.6-0.7-3.3-1.4-5-1.8c1.1,1.2,2.1,2.4,3,3.7
                                c1.1,1.5,1.8,3.1,2.6,4.7s1.8,3.2,2.7,4.8c0.3,0.5-0.5,0.9-0.7,0.4c-1.1-1.9-2.2-3.8-3.2-5.8c-0.5-1-0.9-1.9-1.5-2.8
                                s-1.3-1.8-2-2.6c-0.8-1-1.7-1.9-2.6-2.9l0,0c-0.5-0.6-1.1-1.2-1.7-1.7c-0.8-0.7-1.5-1.4-2.3-2.1c-0.5-0.4-1-0.8-1.6-0.9
                                c0,0,0,0,0,0.1c1,4.3,0.4,8.6,0.5,13c0.1,3.9,0.8,7.8,1.3,11.7c0.2,1.6,0.3,3.3,0.3,4.9c0,0.7,0,1.4,0,2.1c0,0.9,0.1,1.8,0,2.7
                                c-0.2,1.6-2.1,1.8-3.2,1.1c-0.7-0.4-1.2-1-1.7-1.6c-0.4-0.5-0.7-1-1-1.6c0.4,1.5,1.1,3,2.1,4.2c1.4,1.6,3.5,2.3,5.3,3.2
                                c3.5,1.7,6.8,3.8,9.9,6.1c-0.5-3.7-0.8-7.4-0.9-11.1c0-0.6,0.8-0.6,0.8,0c0,1.9,0.1,3.8,0.3,5.7c0.2,2.1,0.4,4.2,0.8,6.3l0,0
                                c0.3,1.7,0.6,3.5,1,5.2c0.8,3.7,1.4,7.4,1.2,11.2s-0.8,7.6-1,11.5c-0.3,3.6-0.4,7.2-0.5,10.9c-0.2,7.1-0.4,14.3-1.5,21.4
                                c-0.5,3.5-1.3,7-2.5,10.4c-1.1,3.4-2.7,6.5-4.1,9.7c-0.7,1.6-1.4,3.3-1.8,5c-0.2,0.8-0.4,1.7-0.6,2.6c0,0.3-0.1,0.7-0.1,1
                                c0,0.1,0,0.1,0,0.2c0.1,0.2,0,0.1,0.1,0.2c0.1,0.3,0.3,0.6,0.5,0.8c1.3,2.1,3.2,3.8,5.2,5.3c3.7,2.6,7.9,4.4,12.2,5.5
                                c6.8,1.8,13.9,2.2,21,1.8c7.5-0.4,14.9-1.5,22.3-2.5C658.1,255.5,665.2,254.3,672.2,252.7L672.2,252.7z"/>
                            <path name="RICK_G" class="st0" d="M584.7,144.5c0.3,0.1,0.6,0.2,1,0.1c0.8-0.2,0.7-1,0.7-1.6c0-0.8,0-1.6,0-2.4c0-0.9,0-1.9-0.1-2.9
                                c-1.3-0.8-3.1-2.2-5.2-3.8c-0.1,1.7-0.3,3.4-0.2,5.1c0.6,1.4,1.3,2.8,2.2,4C583.6,143.7,584.1,144.3,584.7,144.5z"/>
                            <path name="RICK_G" class="st0" d="M585.7,131.3c-0.5-3.6-0.9-7.1-0.9-10.7c0.1-3.4,0.4-6.9-0.4-10.3c-0.1,0-0.1,0-0.2,0
                                c-7.2-4.2-12-10.8-12-10.9v-0.1c-8-20.7-13.1-42.2-13-44.7c0.1-1.8-0.5-4.5-1-6.5c-0.2-0.9-0.3-1.6-0.4-2.1
                                c-0.3-1.8,1.6-4.9,3.5-7.8l0.1-0.2c0.8-1.3,1.1-2.5,0.7-3.2c-0.2-0.4-0.7-0.6-1.2-0.6c-0.9,0-4.1,3.4-6.7,6.7
                                c-0.2,0.2-0.4,0.2-0.6,0.1s-0.2-0.4-0.1-0.6c0.3-0.4,1.1-1.4,2.1-2.5c-0.7-3.7-1.8-7.2-3.1-10.7c-0.6-1.6-1.3-3.2-2.1-4.7
                                c-0.5-1-1.3-3.2-2.8-2.6c-0.6,0.2-1.1,0.5-1.8,0.2c-0.6-0.3-0.9-0.8-1.7-0.7c-0.6,0.1-1.2,0.5-1.5,1.1s-0.5,1-1.2,1
                                c-1.6,0.1-2.5,1.5-2.5,3.1c0,1.7,0.6,3.5,1.2,5.1c0.9,2.5,1.9,5,2.9,7.4c0,0,0.1,0.1,0.1,0.2c0.3,0.9,0.7,1.7,1,2.6
                                c0.2,0.5-0.6,0.7-0.8,0.2c-0.3-0.8-0.7-1.7-1-2.5c-1.4-1-2.8-2.3-4.2-3.5c-1.3-1.2-2.6-2.2-3.7-3.1c-0.4-0.3-0.7-0.5-1.1-0.6
                                c-0.3,0-0.6,0-0.7,0.3c-0.1,0.2,0,0.4,0,0.6c0.5,2.2,2,4.1,3.4,5.7l2.5,3c0.8,1,1.6,2,2.2,3.1c0.3,0.7,0.6,1.4,0.8,2.2l0.1,0.3
                                c1,3,2.2,6,3.4,8.8c0.5,1.1,1,2.2,1.5,3.2c0.5,0.7,1,1.4,1.6,2.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.3,1.2,30.8,8.6,50.4
                                c1.4,3.6,22,21.6,29.2,26.6C586.1,134.9,585.9,133.1,585.7,131.3z"/>
                        </g>
                        <path name="RICK_G" class="st1" d="M581.1,133.6l5.5,3.8c0,0,0.9,7.8-0.9,7.6C581.6,144.6,579.4,138,581.1,133.6z"/>
                        <path name="RICK_G" class="st1" d="M648.6,107c0,0-1.5,12.1-11.6,14.3c-9.3,2.1-9.7,0.2-9.7,0.2l4.4-4.5c0,0,6.9,0.7,11.8-2.2
                            C644.8,113.2,648.6,107,648.6,107z"/>
                        <path name="RICK_G" class="st3" d="M640.1,67.6c0,0,2,1.2,2.3,3.3c0.3,2.1,0.8,6.9-2,11.3c-1.1,1.4-2.5,3.2-6.5,4.2s-5.2-0.3-6.7,0
                            c-1.2,0.2-2.6,0.2-3.4,1.2s-1.2,1.7-3.3,2.2s-8.6,1.7-10.1,1.4c0,0-6.9-0.8-1.3-11.9"/>
                        <path name="RICK_G" class="st1" d="M644.5,75.8c0.4,1.1,0.1,2.4-0.3,3.5c-0.3,1-0.7,1.9-1.1,2.8c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0.2,0.2,0.3,0.3
                            c1.4,0.9,2.9,1.7,4,3c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0,0.3-0.1,0.4-0.3c0.9-1.4,1.4-2.9,1.5-4.6c0-0.6,0-1.3-0.2-1.9
                            c-0.4-1-1.2-1.8-2-2.4s-1.7-1.1-2.7-1.2"/>
                        <path name="RICK_G" class="st3" d="M606.6,208c0,0,2.9,13.5,18.1-8s32.2-51.1,32.2-51.1s3.4,20.7-3.1,31.3s-26.8,46.6-40.5,42.2
                            C606,220.2,606.6,208,606.6,208z"/>
                        <path name="RICK_G" class="st1" d="M610.1,77.4c0.1-0.1,1.1-2.1,2.3-2.7c1.5-0.8,16.5-7.6,23.3-7.8c0,0,4.6-0.3,5,1.8c0.3,2.1,0.8,6.9-2,11.3
                            c-1.1,1.4-2.5,3.2-6.5,4.2s-5.2-0.3-6.7,0c-1.2,0.2-2.6,0.2-3.4,1.2s-1.2,1.7-3.3,2.2s-8.6,1.7-10.1,1.4
                            C608.7,89,603.6,89.3,610.1,77.4z"/>
                        <g>
                            <g>
                                <path name="RICK_G" class="st2" d="M549.6,41.5c-1.1,0.7-1.7,1.9-2.1,3.1c-0.5,1.3-0.8,2.8-0.7,4.2c0,0.5,0.9,0.5,0.9,0
                                    c-0.1-1.3,0.1-2.5,0.5-3.7c0.4-1,0.8-2.2,1.8-2.8C550.5,42,550,41.2,549.6,41.5z"/>
                                <path name="RICK_G" class="st2" d="M623.4,103.3c0.7-0.1,1.4-0.1,2.1,0c0.6,0.1,1.4,0.3,1.9,0.7c0.4,0.4,1-0.2,0.6-0.6
                                    c-0.6-0.6-1.5-0.8-2.3-0.9c-0.8-0.2-1.7-0.2-2.5,0c-0.2,0-0.3,0.3-0.3,0.5C623,103.3,623.2,103.4,623.4,103.3z"/>
                                <path name="RICK_G" class="st2" d="M626.2,101c1.7,0,2.7-0.8,3.2-2.6c0.5-1.8-1.2-3.4-2.1-3.9c-1.4-0.8-1.7-0.7-2-0.4s0.5,0.7,0.2,1.7
                                    s-1.2,2.1-1.5,3.2C623.8,99.9,624.5,100.9,626.2,101z"/>
                                <path name="RICK_G" class="st2" d="M645.9,82.6c0.4-0.7,0.7-1.4,0.9-2.2c0.2-0.9,0.1-1.7,0-2.6c0-0.2-0.3-0.3-0.5-0.3
                                    c-0.2,0.1-0.3,0.3-0.3,0.5c0.1,0.7,0.2,1.4,0,2.2c-0.1,0.7-0.4,1.3-0.8,1.9C644.8,82.7,645.6,83.1,645.9,82.6z"/>
                                <path name="RICK_G" class="st2" d="M695.3,180.6c0.8-0.2,1.5-0.9,2.1-1.4c0.3-0.3,0.6-0.6,0.8-0.9c0.3-0.5,0.2-1.2,0.1-1.8
                                    c-0.2-1.5-0.5-2.9-0.8-4.4c-0.3-1.6-0.6-3.1-1-4.7c-1.3-6.2-2.8-12.3-4.6-18.4c-0.9-3-1.8-5.9-2.7-8.9c-0.5-1.5-1-3-1.5-4.4
                                    c-0.4-1.2-0.8-2.4-1.4-3.6c-0.6-1.1-1.5-2.2-2.4-3.1c-1.9-1.8-4.2-2.6-6.6-3.5c-3-1.1-6.1-2.1-9.2-3.1c-0.8-0.2-1.5-0.5-2.3-0.8
                                    s-1.6-0.6-2.4-0.7c-0.8-0.1-1.5,0-2.3,0.1c-2.2-2.1-5.2-2.6-8.1-2.4h-0.1c0-0.1-0.1-0.2-0.2-0.4c-0.4-1.2-0.6-2.4-0.6-3.7
                                    c0-0.1,0-0.1,0-0.2c0.4,0.3,1.4,0.8,1.5-0.6c0.2-1.8,0.4-4.1,1.2-6.4c0,0,1.1-2.4,1.4-3.6c1-0.6,1.8-1.4,2.3-2.6
                                    c0.6-1.4,0.6-2.9,0.5-4.4c-0.1-1-0.2-2.1-1-2.8c0.8-1.6,2.6-6.4-2.6-12.7c0,0-3-3.4-8.3-5.8v0.1c-1.2-2.4-4.2-5.9-5.1-6.6
                                    c-0.7-1.1-1.6-1.8-1.8-1.8c-1.4-1.1-4.2-0.9-4.6-0.9c-6.9,0.2-21.8,7-23.5,7.9c-1.2,0.6-2.2,2.2-2.5,2.9l0.4,0.2l-0.4-0.2
                                    c-0.4,0.7-0.7,1.4-1.1,2l0,0l0,0c-0.2,0.3-0.3,0.7-0.5,1c-0.2,0.5-0.4,1-0.6,1.4c0,0.1-0.1,0.2-0.1,0.3
                                    c-0.1,0.2-0.2,0.4-0.2,0.6c-0.7,1.9-1,3.5-0.8,4.6c0.1,0.8,0.2,1.6,0.6,2.2c0.4,0.8,1,1.3,1.5,1.7c1.7,1.2,3.3,2.5,5.1,3.6
                                    s4.6,2.2,6.7,1.4c0.4,2.8,0.5,5.6,0.1,8.4l0,0c-0.8-0.1-1.2,1.9-1.2,1.9c-0.8,2.3-0.4,7.2-0.2,10.5c0.3,2.8,1.2,4.4,2.3,5.4
                                    c-0.2,0.1-0.4,0.1-0.6,0.2c-1.2,0.3-2.3,0.1-3.4-0.4c-2.2-0.8-4.3-1.7-6.6-2.3c-2.2-0.6-4.5-1.1-6.6-1.9c-2-0.8-4.1-1.3-6.1-2.1
                                    c-2.2-0.9-4.5-1.9-6.8-2.4c-1-1-1.9-2-2.9-2.9c-0.6-0.6-1.3-1.3-2-1.7c-0.7-0.4-1.6-0.4-2.4-0.1c0,0-0.1,0-0.1,0.1
                                    c-6.2-3.9-10.4-9.5-10.8-10c-7.8-20.2-13-41.9-12.9-44.3c0.1-1.9-0.5-4.7-1-6.8c-0.2-0.8-0.3-1.6-0.4-2
                                    c-0.2-1.5,1.8-4.7,3.3-7.2L562,38c1.4-2.2,1-3.5,0.7-4.1c-0.4-0.7-1.1-1.1-2-1.1c-1,0-3,1.9-4.6,3.7c-0.7-3.3-1.6-6.6-2.8-9.8
                                    c-0.7-1.7-1.4-3.4-2.2-5c-0.6-1.2-1.2-2.9-2.6-3.3c-0.7-0.2-1.2,0.1-1.9,0.4c-0.5,0.1-0.7-0.1-1-0.4c-0.3-0.2-0.5-0.3-0.9-0.4
                                    c-1.3-0.3-2.4,0.7-2.9,1.7c-0.2,0.4-0.5,0.3-0.9,0.4c-0.4,0-0.7,0.2-1,0.4c-0.6,0.4-1.1,0.9-1.4,1.5c-0.8,1.6-0.4,3.4,0.1,5
                                    c0.6,2.1,1.5,4.1,2.3,6.1c0.3,0.8,0.6,1.5,0.9,2.3c-1-0.8-2-1.7-3-2.5c-1.3-1.2-2.6-2.3-3.8-3.1c-0.4-0.3-0.8-0.6-1.4-0.8
                                    c-0.6-0.1-1.4,0.1-1.7,0.8c-0.2,0.4-0.1,0.8,0,1.1c0.6,2.4,2.2,4.3,3.6,6.1l2.5,3c0.8,0.9,1.5,1.9,2.1,3c0.3,0.6,0.5,1.3,0.7,2
                                    l0.1,0.3c1,3,2.2,6,3.5,8.9c0.5,1.1,1,2.2,1.6,3.3c0.5,0.8,1,1.5,1.6,2.1c0.1,2.4,1.5,31.5,8.7,50.5c1.2,3.1,15,15.6,24.1,22.9
                                    c-0.1,1.9-0.3,3.9-0.2,5.9v0.1c0.2,2.2,0.7,4.4,2,6.4c1.2,1.8,3,2.8,4.9,3.7c2,0.9,3.9,1.9,5.8,3.1c2.1,1.3,4.1,2.6,6.1,4.1
                                    c0.6,3.6,1.5,7.2,2,10.8c0.5,3.9,0.1,7.9-0.4,11.8c-0.4,3.7-0.7,7.5-0.8,11.2c-0.3,7.3-0.4,14.6-1.2,21.9
                                    c-0.4,3.6-1,7.2-1.9,10.7s-2.2,6.9-3.7,10.2c-1.5,3.2-3,6.4-3.7,9.9c-0.2,1.1-0.5,2,0,3c0.9,1.9,2.5,3.5,4,4.9
                                    c0.7,0.6,1.4,1.1,2.1,1.6c-0.7,3.4-5.3,26.3-6,78.8c-0.1,8,1.6,7,0,10.2s0.2,2.7,1.1,4.4c0.9,1.7,1.8,2.2,0.4,3.4
                                    c-2.3,2-1,4.9-0.5,5.6c1.4,2.1,3.5,35.9,2.6,41.8s-0.9,8.6,1.1,11.1s0.7,3.4,0,5.7s-1.6,6.8,0.2,8.1c1.8,1.3,2.7,4.9,1.8,6.5
                                    s-2,5-5,8.6s-11.1,14.1-12,20.2c0,0-2.3,6.6,0,8.2c0,0,7.8,6.1,17.4-1.8c9.7-8,13.1-14.3,16.2-16.4s9.5-3.7,9.8-7.7
                                    c0.2-4-1.4-14.3-1.4-14.3s4.5-4.2,3.4-8.2s-1.9-2.8-2.9-7c-1.2-5.4-2.8-33.1-1.9-41.8c0.9-8.6,3-13.9,1.8-15.9
                                    c-1.1-2,2.3-5.4-0.9-7c0,0,2.7-7,0.9-8.4c0,0,10.2-44.2,11.4-46.1c0,0,11.8,31.2,12.3,35.1c0,0,2.3,9,1.8,12.2
                                    s-3.1,9.6,0.1,12.3s2,3.2,1.8,5s-2,4.3-1.1,6.8s1.8,29.8-0.2,37c-2,7.3-5,10.3-3,12.5s4.8,4,3.6,6.6c-1.1,2.6-2.3,4.4,0,6
                                    c0,0-1.7,10.2-0.2,13.4c0.7,1.5,6.4,3.2,8.2,5.2s9.8,9.3,12.5,10.7c2.7,1.4,20.9,8.4,25.9,1.1c0,0,4.1-9.3-10.5-18.6
                                    c0,0-9.3-8-11.8-10.9c0,0,5.9-4.1,0-9.5c0,0,4.1-2.7,2.6-6.1s-3-5.7-1.7-9.8c1.3-4.1,2-38.9,2.4-42.3c0,0,0.1-3.2-0.7-4.3
                                    c0,0,4.2-4.8,2.6-8.6c0,0,0.4-33.2-1.2-49.3c-1.5-15.2-2.3-20.4-2.4-21c0.6,0.7,1.4,0.9,2,1c0.8,0.2,1.7,0.3,2.6,0.3
                                    c2.6,0,5.1-0.7,6-1.7c0.8-0.8,1.6-3.6,2.1-5.6c0.4,1.9,1,2.9,1.8,3.1c1,0.2,1.7-0.9,1.8-1.1v-0.1c0.8-2.5,1.3-12.4,1.4-15.6
                                    v-0.6c0.1-2.2-0.8-12.4-0.8-13.4c3.8-15.2,7.3-35.6,7.9-42.4C699.3,193.7,697,185.7,695.3,180.6z M655.6,89.6
                                    c1.4,1.3,4.5,5.9-0.5,3c-0.1-0.1-7.2-5.7-7.6-6.1c0-0.1,0.1-0.2,0.1-0.3c0.2,0,0.3,0,0.5-0.1c0.3-0.2,0.5-0.6,0.7-0.9
                                    c0.2-0.4,0.4-0.9,0.6-1.3c0-0.1,0.1-0.2,0.1-0.3C650.5,84.7,654.4,88.5,655.6,89.6z M645,76.1c0.6,0.2,1.2,0.5,1.8,0.9
                                    c0,0.1,0.1,0.1,0.2,0.1c0.5,0.4,1,0.8,1.3,1.3c1,1.3,0.7,3.2,0.3,4.8c-0.1,0.4-0.3,0.8-0.5,1.2c-0.1,0.2-0.2,0.3-0.2,0.5
                                    c-0.1,0.1-0.2,0.5-0.3,0.5c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.4-0.3c-0.3-0.2-0.6-0.5-0.9-0.7
                                    c-0.6-0.4-1.2-0.8-1.9-1.2c-0.2-0.1-0.4-0.2-0.5-0.3c-0.1-0.1-0.1,0-0.1-0.2c0-0.1,0-0.2,0.1-0.3c0.2-0.4,0.3-0.8,0.5-1.2
                                    C644.7,79.4,645.4,77.7,645,76.1z M647.6,85.4C647.7,85.5,647.8,85.6,647.6,85.4L647.6,85.4z M646.2,75.7
                                    c-0.6-0.3-1.2-0.5-1.8-0.6c-0.3,0-0.5,0.3-0.4,0.6c0,0.1,0,0.1,0,0.2c0.4,1,0.1,2.1-0.2,3.1s-0.7,2-1.2,2.9
                                    c-0.2,0.3-0.2,0.7,0,1s0.7,0.6,1.1,0.8c0.8,0.5,1.6,1,2.3,1.6c0.2,0.2,0.4,0.4,0.7,0.7l0,0c-0.8,3.1-3.5,5.6-6.8,5.8
                                    c-1.7,0.1-3.6-0.2-5.2-0.9c-0.7-0.3-1.4-0.8-2-1.3c-0.5-0.4-0.8-0.9-1.2-1.3c-0.3-0.3-0.7-0.6-1-0.9c0.9,0.1,2,0,3.5-0.4
                                    c4.2-1.1,5.7-3,6.8-4.4c2.5-4,2.5-8.4,2-11.4C643.9,72.2,645.4,74.2,646.2,75.7z M610.5,77.6L610.5,77.6c0.5-1,1.4-2.2,2.2-2.6
                                    c1.5-0.8,16.5-7.6,23.2-7.8c1.1-0.1,4.3,0,4.5,1.4c0.5,2.9,0.5,7.1-1.9,10.9c-1,1.3-2.3,3-6.3,4c-2.4,0.6-3.7,0.3-4.8,0.1
                                    c-0.7-0.1-1.3-0.3-1.9-0.1c-0.2,0.1-0.5,0.1-0.7,0.1c-1,0.2-2.2,0.3-3,1.3l-0.2,0.2c-0.7,0.8-1.1,1.4-2.9,1.9
                                    c-2.1,0.6-8.5,1.6-9.9,1.4c0,0,0,0-0.1,0l0,0c0,0-0.7,0-1.1-0.6c-0.1-0.2-0.2-0.4-0.3-0.8c-0.1-1.2,0.2-2.7,0.8-4.4
                                    c0.1-0.2,0.2-0.4,0.2-0.7c0-0.1,0-0.1,0.1-0.2c0.2-0.5,0.5-1.1,0.7-1.7c0.1-0.2,0.1-0.3,0.2-0.4C609.6,79.2,610,78.4,610.5,77.6
                                    z M607.9,89.3c0.3,0.1,0.6,0.1,0.7,0.1c0.2,0,0.4,0,0.7,0c2.3,0,7.8-0.9,9.6-1.4c2-0.6,2.6-1.2,3.3-2.1l0.2-0.2
                                    c0.6-0.7,1.5-0.8,2.5-1c0.2,0,0.5-0.1,0.7-0.1c0.5-0.1,1,0,1.6,0.1c1.1,0.2,2.6,0.5,5.2-0.1c4.2-1.1,5.7-3,6.8-4.4
                                    c2.3-3.7,2.5-7.8,2.1-10.7c0.3,0.4,0.6,1,0.7,1.6c0.5,2.9,0.5,7.1-1.9,10.9c-1,1.3-2.3,3-6.3,4c-2.4,0.6-3.7,0.3-4.8,0.1h-0.1
                                    c0,0-0.1,0-0.2,0c-0.6-0.1-1.1-0.2-1.6-0.1c-0.2,0.1-0.5,0.1-0.7,0.1c-1,0.2-2.2,0.3-3,1.3l-0.2,0.2c-0.7,0.8-1.1,1.4-2.9,1.9
                                    c-2.1,0.6-8.5,1.6-9.9,1.4c0,0-0.7-0.1-1.4-0.5l0,0l0,0C608.6,90.1,608.2,89.8,607.9,89.3z M615.3,94.6
                                    c-1.6-0.7-2.9-1.8-4.3-2.8c2.3,0,7.8-0.9,9.6-1.4c1-0.3,1.7-0.6,2.2-1c0.1,0.2,0.2,0.4,0.2,0.6c0,0,0,0,0,0.1
                                    c-0.3,1.7-0.9,3.9-2.3,5C619.1,96.1,616.8,95.3,615.3,94.6z M621.7,95.1c1-1.1,1.5-2.6,1.9-4c0.2,0.3,0.4,0.6,0.6,0.8
                                    c0.2,0.2,0.4,0.2,0.6,0c0.2-0.2,0.2-0.4,0-0.6c-0.1-0.1-0.2-0.2-0.2-0.2l-0.1-0.1l-0.1-0.1c-0.1-0.2-0.2-0.4-0.4-0.6
                                    c-0.2-0.4-0.4-0.8-0.6-1.3c0-0.1,0-0.1-0.1-0.1c0.2-0.2,0.3-0.4,0.5-0.6l0.2-0.2c0.6-0.7,1.5-0.8,2.5-1c0.2,0,0.5-0.1,0.7-0.1
                                    c0.5-0.1,0.9,0,1.5,0.1c0.4,0.2,0.8,0.5,1.1,0.8c0.5,0.4,1,0.9,1.4,1.3c1.1,1.2,2.2,2.1,3.7,2.7c3.3,1.3,7.1,1.2,9.8-1.1
                                    c0.5-0.4,1-0.9,1.4-1.5c0.4,0.2,1.1,0.6,1.1,1.2c0,0-1.3,4.9-0.8,5.9c0.2,0.4,0.9,0.6,1.6,0.6c0.7,0,1.2-0.1,1.5-1.2
                                    s0.5-2.1,1-2.1s2.4,0.5,2.8,0.8c-0.2,0.1-0.4,0.2-0.5,0.4c-0.4,0.3,0.2,0.9,0.6,0.6c0.6-0.5,1.4-0.8,2.2-0.9
                                    c0.7-0.1,1.6-0.1,2.1,0.5c0.4,0.5,0.5,1.3,0.5,2c0,0.8,0.1,1.5,0,2.3c-0.1,1.6-0.8,3-2.1,3.9c-0.6,0.4-1.2,0.7-1.9,1
                                    c-0.6,0.3-1.5,0.8-2.2,0.4c-0.5-0.3-0.9,0.5-0.4,0.7c0.5,0.3,1,0.3,1.6,0.2c-0.6,1.4-1,3-1.3,4.5c-0.4,1.6-0.6,3.3-0.7,4.9
                                    c0,1.3,0.2,2.7,0.6,3.9c0.4,1.1,1,2.1,1.6,3c0,0.1,0.1,0.1,0.2,0.2c-2.8,2.8-6.4,4.6-10.3,5.6c-2.4,0.6-4.8,0.9-7.3,0.9
                                    c-2.4,0-4.8-0.1-7.1-0.7c-1.1-0.3-2.2-0.7-3.1-1.4c-0.4-0.3-0.8-0.6-1.2-0.9c0.5-0.5,0.9-1.1,1.1-1.8c2.4-0.7,4.4-3.2,5.9-6
                                    c6.5-0.1,9.2-0.7,12-2.7c1.2-0.9,3-3.9,4.6-6.7c0.3-0.5,0.6-1.1,0.7-1.2c0.2-0.2,0.2-0.4,0-0.6c-0.1-0.2-0.4-0.2-0.6-0.1
                                    c-0.1,0.1-0.2,0.2-0.9,1.4c-1,1.8-3.1,5.6-4.3,6.4c-2.6,1.8-5.1,2.4-11,2.5c1.6-3.2,2.8-6.5,3.2-8c1-3.1,0.1-4.4-1.5-4.3
                                    s-1.9,2.9-5.2,2.7c-1.7,0-2.2,0.1-2.5-1.7c-0.3-1.8-1.1-1.9-1.6-1.9s-0.5,0.6-0.9,1.7s-0.9,1.9-1.8,1.7
                                    c-0.4-0.1-0.6-0.4-0.7-0.9c0.6-3.4,0.6-6.9,0.1-10.4C621.3,95.5,621.5,95.3,621.7,95.1z M557,110.2c-7.5-19.6-8.6-50.1-8.6-50.4
                                    c0-0.1,0-0.2-0.1-0.3c-0.6-0.6-1.1-1.3-1.6-2.1c-0.6-1-1.1-2.1-1.5-3.2c-1.2-2.8-2.5-5.8-3.4-8.8l-0.1-0.3
                                    c-0.2-0.7-0.5-1.5-0.8-2.2c-0.6-1.2-1.4-2.2-2.2-3.1l-2.5-3c-1.4-1.7-2.9-3.5-3.4-5.7c0-0.2-0.1-0.5,0-0.6
                                    c0.1-0.2,0.5-0.3,0.7-0.3c0.4,0.1,0.7,0.3,1.1,0.6c1.1,0.9,2.4,2,3.7,3.1c1.4,1.2,2.8,2.4,4.2,3.5c0.3,0.8,0.7,1.7,1,2.5
                                    c0.2,0.5,1,0.3,0.8-0.2c-0.3-0.9-0.7-1.7-1-2.6c0-0.1,0-0.1-0.1-0.2c-1-2.5-2-4.9-2.9-7.4c-0.6-1.6-1.3-3.3-1.2-5.1
                                    c0-1.5,0.9-3,2.5-3.1c0.7,0,0.9-0.4,1.2-1s0.8-1,1.5-1.1s1.1,0.4,1.7,0.7c0.6,0.3,1.2,0,1.8-0.2c1.5-0.6,2.3,1.5,2.8,2.6
                                    c0.8,1.5,1.4,3.1,2.1,4.7c1.4,3.5,2.4,7.1,3.1,10.7c-1,1.1-1.7,2.1-2.1,2.5c-0.1,0.2-0.1,0.5,0.1,0.6c0.2,0.2,0.5,0.1,0.6-0.1
                                    c2.6-3.3,5.8-6.7,6.7-6.7c0.6,0,1,0.2,1.2,0.6c0.4,0.7,0.1,1.9-0.7,3.2l-0.1,0.2c-1.8,3-3.7,6-3.5,7.8c0.1,0.5,0.2,1.2,0.4,2.1
                                    c0.4,2,1,4.8,1,6.5c-0.1,2.5,5,23.9,13,44.7v0.1c0,0.1,4.8,6.7,12,10.9c0,0,0.1,0,0.2,0c0.7,3.4,0.5,6.9,0.4,10.3
                                    c-0.1,3.6,0.4,7.2,0.9,10.7c0.2,1.8,0.5,3.6,0.6,5.4C579.1,131.8,558.4,113.8,557,110.2z M586.3,137.8c0,0.9,0.1,1.9,0.1,2.9
                                    c0,0.8,0,1.6,0,2.4c0,0.6,0.1,1.5-0.7,1.6c-0.3,0.1-0.7,0-1-0.1c-0.6-0.2-1.1-0.9-1.5-1.4c-0.9-1.2-1.6-2.6-2.2-4
                                    c-0.1-1.7,0-3.4,0.2-5.1C583.2,135.6,585,137,586.3,137.8z M590.3,246.4c-0.2-0.3-0.3-0.6-0.5-0.8c-0.1-0.2,0,0-0.1-0.2
                                    c0-0.1,0-0.1,0-0.2c0-0.3,0.1-0.7,0.1-1c0.1-0.9,0.3-1.7,0.6-2.6c0.5-1.7,1.1-3.4,1.8-5c1.4-3.2,3-6.4,4.1-9.7
                                    c1.1-3.4,1.9-6.9,2.5-10.4c1.1-7.1,1.3-14.2,1.5-21.4c0.1-3.6,0.3-7.2,0.5-10.9c0.3-3.8,0.9-7.6,1-11.5
                                    c0.2-3.8-0.5-7.5-1.2-11.2c-0.4-1.7-0.7-3.5-1-5.2l0,0c-0.3-2.1-0.6-4.2-0.8-6.3c-0.2-1.9-0.2-3.8-0.3-5.7c0-0.6-0.9-0.6-0.8,0
                                    c0,3.7,0.3,7.4,0.9,11.1c-3.1-2.3-6.4-4.4-9.9-6.1c-1.9-0.9-3.9-1.6-5.3-3.2c-1-1.2-1.7-2.6-2.1-4.2c0.3,0.5,0.6,1.1,1,1.6
                                    c0.5,0.6,1,1.3,1.7,1.6c1.1,0.6,3,0.5,3.2-1.1c0.1-0.9,0-1.8,0-2.7c0-0.7,0-1.4,0-2.1c0-1.6-0.2-3.3-0.3-4.9
                                    c-0.5-3.9-1.2-7.8-1.3-11.7c-0.1-4.3,0.5-8.7-0.5-13c0,0,0,0,0-0.1c0.6,0.1,1.1,0.4,1.6,0.9c0.8,0.7,1.5,1.4,2.3,2.1
                                    c0.6,0.6,1.1,1.1,1.7,1.7l0,0c0.9,0.9,1.8,1.9,2.6,2.9c0.7,0.8,1.4,1.7,2,2.6s1,1.9,1.5,2.8c1,2,2.1,3.9,3.2,5.8
                                    c0.3,0.5,1,0,0.7-0.4c-0.9-1.6-1.9-3.2-2.7-4.8c-0.8-1.6-1.5-3.3-2.6-4.7c-1-1.3-2-2.5-3-3.7c1.7,0.5,3.3,1.2,5,1.8
                                    c2,0.8,4.2,1.4,6.3,2.1c1.1,0.4,2.2,0.8,3.3,1.1c1.1,0.3,2.3,0.6,3.4,0.9c2.3,0.6,4.5,1.4,6.7,2.3c1.2,0.4,2.3,0.8,3.6,0.6
                                    c0.6-0.1,1.2-0.3,1.7-0.4c0.9,0.5,1.8,0.7,2.4,0.7c0.1,0,0.1,0,0.2,0v0.1c0,0.1-0.1,0.2-0.2,0.3c0,0,0,0.1-0.1,0.1
                                    c0,0,0,0,0,0.1l0,0l0,0c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1-0.2-0.2-0.3-0.3-0.5c-0.3-0.5-1,0-0.7,0.4
                                    c0.5,0.8,1.2,1.3,1.8,1.9c0.8,0.6,1.5,1,2.4,1.4c2.3,0.9,4.8,1.2,7.3,1.3c5,0.1,10.1-0.7,14.6-3.1c2.3-1.2,4.4-2.8,6.1-4.8
                                    c0.4-0.4-0.2-1-0.6-0.6c-0.2,0.2-0.3,0.3-0.5,0.5c-0.3-0.5-0.7-1.1-1-1.6c2.4-0.2,4.9,0.2,6.8,1.7c-0.5,0.1-0.9,0.2-1.4,0.2
                                    s-0.5,0.9,0,0.8c0.8,0,1.5-0.2,2.3-0.3c0.1,0,0.1,0,0.2,0c0.5-0.1,1.1-0.2,1.6-0.2c1.4,0.1,2.8,0.7,4.1,1.2
                                    c2.6,0.9,5.3,1.7,7.9,2.6c1.3,0.4,2.6,0.9,3.8,1.4s2.5,0.9,3.5,1.7c1.3,0.9,2.4,2.2,3.3,3.6c0.3,0.6,0.5,1.2,0.8,1.8
                                    c0.5,1.3,1,2.7,1.5,4c1,2.8,1.9,5.6,2.7,8.4c1.7,5.8,3.3,11.6,4.6,17.5c0.7,3.2,1.4,6.5,2,9.8c0.2,0.8,0.3,1.6,0.5,2.4
                                    c0.1,0.6,0.3,1.4-0.1,1.9c-0.5,0.7-1.4,1.5-2.2,1.8c-0.5-1.3-0.8-2.4-1.1-3.1c0.4,0.1,0.9,0.3,1.3,0.5c0.5,0.3,0.9-0.5,0.4-0.7
                                    c-0.7-0.4-1.5-0.6-2.3-0.8h-0.1c-1.2-0.2-2.4-0.2-3.5-0.1c-1.8,0.1-3.5,0.3-5.3,0.6c-3.9,0.6-7.7,1.6-11.4,3
                                    c-1.9,0.7-3.7,1.6-5.5,2.7c-0.5,0.3,0,1,0.4,0.7c2.1-1.3,4.4-2.4,6.8-3.2c0.2,0.6,1,2.6,1.9,5.1c-2.4,0.1-5,0.2-7.4-0.3
                                    c-1-0.2-2.3-0.6-2.7-1.6v-0.1v-0.1c0-0.1,0-0.3,0-0.4c0-0.4,0-0.7,0-1.1c0-1.2,0-2.4,0-3.6c0-7.7,0.2-15.5,0.6-23.2
                                    c0.2-3.9,0.4-7.7,0.9-11.5c0.1-0.5-0.8-0.5-0.8,0c-0.5,3.4-0.7,6.9-0.9,10.4c-0.2,4.3-0.4,8.5-0.5,12.8
                                    c-0.1,3.7-0.2,7.5-0.2,11.2c0,1.3,0,2.5,0,3.8c0,0.6,0,1.3,0.1,2c0.2,1.3,1.7,1.9,2.8,2.2c2.1,0.6,4.3,0.6,6.5,0.6
                                    c-0.9,5.4-1.7,10.9-2.7,16.3c-0.5,2.9-1.2,5.7-1.8,8.5c-0.5,2.5-1,4.9-1.4,7.4v0.1c-0.1,0.4-0.2,0.9-0.2,1.4
                                    c-0.5,3.2-0.9,6.5,0,9.7c0.1,0.5,1,0.3,0.8-0.2c-0.7-2.7-0.5-5.5-0.1-8.2c1.2,5.1,4.1,9.8,5.5,14.9c0.5,1.6,0.8,3.3,0.8,4.9
                                    c-0.2,0.6-0.5,1.2-0.7,1.9c-0.5,1.6-1.2,4.2-1.9,6.7c-0.4,1.3-0.7,2.7-1.1,3.9l0,0c-7,1.6-14.1,2.8-21.2,3.8
                                    c-7.4,1-14.8,2.1-22.3,2.5c-7,0.4-14.1,0-21-1.8c-4.3-1.1-8.6-2.9-12.2-5.5C593.5,250.2,591.6,248.5,590.3,246.4z M676.7,238.4
                                    c-0.6-5.5-3.6-10.4-5.4-15.6c-0.6-1.6-1-3.3-1.2-5c0.4-2.3,0.8-4.5,1.2-6.8c0.6-2.8,1.3-5.6,1.8-8.5c0.5-2.8,1-5.5,1.5-8.3
                                    c0.5-3,0.9-5.9,1.4-8.9c0.3,0,0.5,0,0.8-0.1c0.1,0,0.1,0,0.2-0.1c1.7,4.7,3.7,10.8,3.8,12.8c0,0.3-0.5,32.9-2.9,38.1
                                    C677.8,236.2,677.3,237,676.7,238.4z M698.1,198c-0.6,6.8-4.1,27.2-7.9,42.3c0,0.1,0,0.1,0,0.1c0,0.1,1,11.2,0.8,13.4v0.6
                                    c-0.4,8.5-0.9,13.7-1.4,15.3c-0.2,0.2-0.6,0.7-0.9,0.6c-0.2,0-1-0.4-1.4-4.6c0-0.2-0.2-0.4-0.4-0.4s-0.4,0.1-0.4,0.3
                                    c-0.5,2.5-1.6,6.5-2.4,7.4c-1.1,1.1-4.8,1.9-7.9,1.2h-0.1c-0.2,0-1.9-0.1-1.9-2.1c0-2.5-0.5-13.4-0.5-13.5
                                    c0-0.2-0.2-0.4-0.5-0.4s-0.4,0.2-0.4,0.5c0,0,0.1,2.3,0.2,5h-0.1c-1.2,0.4-3.3,0.6-3.8,0.1c-0.5-0.6,1.5-4.6,2.1-5.9
                                    c0.2-0.4,0.4-0.8,0.5-1.1c0.5-1,1.4-4.3,2.4-7.9c0.7-2.5,1.4-5.1,1.9-6.7c0.2-0.7,0.4-1.3,0.7-1.9l0,0c1-2.4,1.8-3.8,1.9-3.8
                                    c2.5-5.4,3-37.1,3-38.5c-0.1-3.3-5.2-16.8-6-18.9c0.8-0.3,1.5-0.5,2.3-0.7c3.5-1,7.1-1.7,10.8-2c1.3-0.1,2.8-0.2,4.2,0
                                    C693.6,178.4,698.6,192.1,698.1,198z"/>
                                <path name="RICK_G" class="st2" d="M615.1,205.9c1.5-1.2,2.8-2.8,4-4.2c2.6-3,5.1-6.2,7.4-9.5c4.7-6.6,8.7-13.7,12.8-20.7
                                    c2.3-3.9,4.6-7.9,7.1-11.7c0.3-0.5-0.4-0.9-0.7-0.4c-4.2,6.6-8,13.4-12,20.1c-4,6.7-8.3,13.3-13.3,19.4
                                    c-1.4,1.7-2.8,3.3-4.3,4.9c-1.1,1.2-3.3,3.4-5,2c-0.4-0.3-0.9,0.4-0.4,0.7C612,207.6,613.8,206.8,615.1,205.9z"/>
                                <path name="RICK_G" class="st2" d="M617.4,227.6c2.8-0.4,5.3-1.8,7.4-3.7c2.3-2.1,4.1-4.7,6-7.1c2.2-2.8,4.5-5.4,6.9-7.9
                                    c2.7-2.9,5.5-5.7,8.3-8.5c0.4-0.4-0.2-1-0.6-0.6c-4.7,4.8-9.5,9.5-13.8,14.7c-2,2.4-3.7,4.9-5.8,7.2c-1.9,2.1-4.1,4-6.8,4.8
                                    c-2.8,0.8-6,0.6-8.1-1.4c-0.4-0.4-1,0.2-0.6,0.6C612.2,227.3,614.8,227.9,617.4,227.6z"/>
                                <path name="RICK_G" class="st2" d="M612.6,78.5c0.5,0,1-0.4,1-1s-0.4-1-1-1s-1,0.4-1,1S612.1,78.5,612.6,78.5z M612.6,77
                                    c0.3,0,0.5,0.2,0.5,0.5s-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5S612.3,77,612.6,77z"/>
                                <path name="RICK_G" class="st2" d="M636.9,72c0.5,0,1-0.4,1-1s-0.4-1-1-1s-1,0.4-1,1S636.4,72,636.9,72z M636.9,70.5c0.3,0,0.5,0.2,0.5,0.5
                                    s-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5S636.6,70.5,636.9,70.5z"/>
                            </g>
                            <circle name="RICK_G" class="st0" cx="636.9" cy="71" r="0.7"/>
                        </g>
                        <path name="RICK_G" class="st3" d="M620.8,282c2.5,0.3,4.9,0.8,7.2,1.8c1.3,0.6,2.5,1.3,4,1.7c1,0.3,2.1,0.4,3.2,0.3c0.5,0,0.5-0.9,0-0.9
                            c-1.7,0-3.2-0.3-4.8-1c-1.5-0.7-3-1.5-4.7-1.9c-1.6-0.5-3.2-0.7-4.9-0.9C620.2,281.1,620.2,281.9,620.8,282L620.8,282z"/>
                        <path name="RICK_G" class="st3" d="M629.8,284.8c1.2,2.3,2.5,4.5,3.6,6.8c0.2,0.5,1,0.1,0.7-0.4c-1.1-2.3-2.5-4.5-3.6-6.8
                            C630.2,283.9,629.5,284.4,629.8,284.8L629.8,284.8z"/>
                        <path name="RICK_G" class="st3" d="M652.7,259.4c1,2.6,2.7,5,5.3,6.2c1.5,0.6,3.2,0.6,4.8,0.6c1.9,0.1,3.9,0.2,5.8,0.5c0.5,0.1,0.8-0.7,0.2-0.8
                            c-1.7-0.2-3.4-0.4-5.1-0.5c-1.6-0.1-3.4,0.1-4.9-0.4c-2.6-0.9-4.3-3.3-5.2-5.8C653.3,258.6,652.5,258.8,652.7,259.4L652.7,259.4z"
                            />
                        <path name="RICK_G" class="st0" d="M580.3,460.7c2.8,1.3,5.9,2.1,9,1.5c2.8-0.5,5.1-2.1,7.2-4c2.7-2.4,5.1-5.2,7.6-7.8c2.8-2.9,5.7-5.6,8.9-8
                            c1.7-1.2,3.6-2.2,5.1-3.7c1.3-1.3,2.1-3.3,1.4-5.1c-0.2-0.5-1-0.3-0.8,0.2c0.6,1.7-0.3,3.4-1.5,4.5c-1.3,1.2-3,2.1-4.5,3.2
                            c-3,2.2-5.7,4.7-8.3,7.3c-2.5,2.5-4.8,5.2-7.3,7.6c-1.9,1.9-4.1,3.7-6.7,4.5c-3.2,1-6.6,0.2-9.6-1.1
                            C580.2,459.8,579.8,460.5,580.3,460.7L580.3,460.7z"/>
                        <path name="RICK_G" class="st0" d="M692.2,451.5c-3.4,1.5-7,2.2-10.7,1.9c-3.1-0.2-6.2-1.2-8.9-2.7c-6-3.3-9.9-9.3-15-13.6
                            c-0.7-0.6-1.4-1.2-2.2-1.7s-1.6-0.8-2.4-1.2c-1.6-0.9-3.1-2.6-2.7-4.6c0.1-0.5-0.7-0.8-0.8-0.2c-0.4,1.6,0.3,3.2,1.5,4.4
                            c1.2,1.2,3,1.6,4.3,2.6c3,2.1,5.5,4.9,8,7.6c2.5,2.6,5,5.1,8,7c2.8,1.7,5.8,2.8,9,3.2c4.2,0.6,8.5-0.2,12.3-1.9
                            C693.2,452,692.7,451.2,692.2,451.5L692.2,451.5z"/>
                        <circle name="RICK_G" class="st0" cx="612.6" cy="77.5" r="0.7"/>
                    </g>
                </g>
                <g id="GERA" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="GERA" class="st0" d="M475.9,156.5c1.5,1.3,3,2.6,4.4,3.8s3,2.4,4.7,3.2c1.5,0.7,3.1,1.1,4.6,1.4c0-0.9,0.6-1.7,1.5-2
                                c0.5-0.2,1.1-0.1,1.6,0.1c0.5,0.3,0.9,0.7,1,1.3c0.1,0.3,0.5,1.8,1,3.7c1,0.2,1.9,0.5,2.6,1.2c0.5,0.6,0.8,1.3,1.2,1.9v-0.4
                                c0-0.2,0.1-0.3,0.2-0.4c0.3-0.1,0.5-0.3,0.8-0.4c-0.5-1.2-1-2.4-1.6-3.5c-0.5-1.1-1.1-2.3-1.8-3.3c-0.7-0.9-1.6-1.4-2.6-2.1
                                c-0.8-0.6-1.6-1.2-2.3-1.9c-1.5-1.6-2.5-3.4-3.6-5.4c-0.6-1.1-1.4-2.3-2.2-3.4l0,0c-1.7-2.6-3.2-5.2-2.3-8.4
                                c0.1-0.5,1-0.3,0.8,0.2c-0.7,2.3,0.3,4.6,1.5,6.5c0.1-3.5,0.6-7.1-0.5-10.5V138c0,0,0,0.1-0.1,0.1c-1.3,0.5-2.5,1.2-3.7,1.9
                                c-1.1,0.7-2.1,1.5-2.6,2.7c-1.1,2.6,0,5.4,1.4,7.7c0.3,0.5-0.5,0.9-0.7,0.4c-1.1-1.8-2-4-1.9-6.1c-0.8,0.2-1.5,0.8-1.9,1.5
                                c-0.5,1-0.4,2.2-0.1,3.3c0.7,2.1,2.4,3.9,4.3,5.1c0.5,0.3,0,1-0.4,0.7c-2.2-1.4-4.3-3.7-4.9-6.3c0-0.1,0-0.1,0-0.1
                                c-1.3,0.6-1.8,2.3-1.5,3.6C473.4,154.2,474.7,155.4,475.9,156.5z"/>
                            <path name="GERA" class="st0" d="M487.8,152c0.6,1,1.1,2,1.7,3.1c1,1.8,2.3,3.4,3.9,4.7c0.8,0.6,1.7,1.1,2.5,1.8c0.8,0.6,1.3,1.4,1.8,2.3
                                c0.9,1.7,1.7,3.5,2.5,5.3c0.9-1.7,2.5-2.9,3.9-4.1c1.7-1.4,3.1-3.1,4.5-4.9c1.3-1.8,2.6-3.7,3.7-5.7c1.9-3.4,2.5-7.3,2.8-11.2
                                c-2.5,3.5-6.4,6.6-12.1,6.6c-0.6,0-1.2,0-1.9-0.1c-7.4-0.8-11.8-4.7-14.5-8.9c0.4,3.1-0.1,6.2-0.1,9.3
                                C486.9,150.7,487.4,151.3,487.8,152z"/>
                            <path name="GERA" class="st0" d="M481,117.1c-1.5,2.1-1.5,5.1-1.2,7.5c0.1,1.2,0.4,2.5,0.8,3.7c0.4,1,1.1,2.4,2.3,2.6
                                c-0.2-1.3-0.3-2.1-0.3-2.1c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.5,0.4c0,0.2,1,18.4,17.6,20.1c6.1,0.6,10.9-1.9,14.2-7.6
                                c2.5-4.3,3.3-8.9,3.3-8.9c0-0.2,0.3-0.4,0.5-0.4s0.4,0.2,0.4,0.5c0,0-0.1,0.8-0.4,1.9h0.1c1.1,0.1,1.9-1.5,2.4-2.2
                                c0.5-1,0.9-2,1.2-3.1c0.6-2.2,1.6-4.9,0.3-7.1c0-0.1-0.1-0.2,0-0.3c-0.1,0.2-0.2,0.3-0.3,0.4c0,0-0.8,4.6-1.8,6.9
                                c0,0-0.6,2-0.9,1.9c-0.3-0.1-0.4-0.7-0.2-1.6c0,0,0.5-5.4,0.8-6.6s1-4.3,0.7-4.9c-0.3-0.5-2.1-1.5-2-4.2s-0.4-6.2-1-7.5
                                c-0.7-1.3-1.8-2.5-1.2-3.8c0,0-4.5-0.9-7.3-0.1c0,0-0.4-0.2,1.6-2.3c0,0-1.9,0.5-3.2,2.6c-1.4,2.1-4.5,8.2-7.8,8.7
                                c-3.3,0.6-11.2,0.1-12,0.4c0,0,0.9-1.6,3.2-2.2c0,0-2-0.2-2.8,0.7c-1.1,1.1-2.6,4.4-4.7,4.9c-1.6,0.4-2.1,1-2.7,1.6
                                C481.1,116.8,481.1,117,481,117.1z"/>
                            <path name="GERA" class="st0" d="M499.5,171l0.5,5.6c1.1,0,2.6-0.1,3.2-0.3c-0.1-1-0.4-4.5-0.5-6C502.1,170.4,500.7,170.4,499.5,171z"/>
                            <path name="GERA" class="st0" d="M460.7,338.3c-0.1,1.2-0.2,2.5-0.3,3.7c-0.5,5.7-1,11.4-1.1,17.1s0.3,11.4,0.6,17
                                c0.7,11.4,1.6,22.8,2.2,34.2c0.1,1,0,2,0.6,2.8c0.4,0.6,1,1.1,1.6,1.5c1.2,0.7,2.6,1.1,4,1.3c1.9,0.4,4.1-0.2,5.6-1.5
                                c1.7-1.5,1.7-4.3,1.9-6.4c0.6-5.6,1.6-11.1,2.7-16.6c0.6-2.8,1.1-5.6,1.5-8.4s0.6-5.7,0.7-8.5c0.3-5.6,0.5-11.3,0.8-17
                                c0.2-4.5,0.7-9.2,2.7-13.3c-0.7,0.1-1.4,0.2-2.3,0.2C477.4,344.9,466.5,341.8,460.7,338.3z"/>
                            <path name="GERA" class="st0" d="M509,160.8c-1.3,1.8-2.8,3.4-4.4,4.8c-1.4,1.2-3,2.4-3.8,4.1c1.3-0.3,2.3-0.2,2.4-0.2c0.2,0,0.4,0.2,0.4,0.4
                                c0,0.5,0.1,2,0.2,3.4c0.8-1,1.3-2.2,1.8-3.4c0.6-1.3,1.3-2.5,2.3-3.6c0.3-0.3,0.6-0.6,0.9-0.9l0,0l0.1-0.1c0,0,0,0,0.1-0.1
                                c0.7-0.6,1.4-1.2,2.2-1.7l0,0l0,0c0.8-0.6,1.6-1.1,2.4-1.7c1.7-1.3,3.3-2.8,5-4.2c0.8-0.7,1.6-1.5,2.3-2.3c0.8-1,1.5-2.1,2.1-3.3
                                c0.7-1.2,1.4-2.4,1.5-3.8c0.1-1.3-0.5-2.3-1.2-3.2c0,1.1-0.2,2.2-0.7,3.3c-0.6,1.3-1.6,2.4-2.6,3.5c-0.4,0.4-1-0.2-0.6-0.6
                                c0.9-0.9,1.7-1.9,2.3-3.1c0.6-1.3,0.7-2.7,0.7-4.1c0-1.4-0.3-2.5-1.4-3.4c-1-0.9-2.3-1.2-3.6-1.5c-0.4,0.9-0.9,1.9-1.5,2.8
                                c-0.1,2.4-0.3,4.8-0.8,7.2c-0.5,2.2-1.2,4.2-2.3,6.1C511.7,157.1,510.4,159,509,160.8z"/>
                            <path name="GERA" class="st0" d="M544.3,354.9c-0.2-3.8-0.5-7.5-0.8-11.3c-4.9,1.7-14,2.4-14,2.4c-4.4-0.1-7.6-0.8-10-1.6
                                c0.4,0.8,0.7,1.5,1,2.4c1.7,4.8,1.7,10,1.9,15c0.3,5.7,0.4,11.4,0.9,17s1.7,11.2,2.8,16.7c0.5,2.8,1,5.6,1.4,8.5
                                c0.2,1.4,0.3,2.7,0.5,4.1c0.1,1.2,0.2,2.4,0.5,3.6c0.2,1,0.6,2,1.3,2.8c0.8,0.7,1.9,1.2,2.9,1.5c1.6,0.4,3.1,0.1,4.6-0.3
                                c1.4-0.4,2.9-1.1,3.7-2.3c0.5-0.8,0.5-1.7,0.6-2.7c0.1-1.5,0.2-2.9,0.3-4.3c0.7-11.4,1.6-22.8,2.2-34.2
                                C544.4,366.3,544.6,360.6,544.3,354.9z"/>
                            <path name="GERA" class="st0" d="M530.7,279.1c1.6-1.1,2.8-2.6,3.9-4.3c1.1-1.7,2.3-3.4,3.3-5.1c0.5-0.8,1-1.7,1.1-2.6c0.1-0.5,0.1-1,0.1-1.4
                                c0-0.3,0-0.7-0.1-1l-0.1,0.1c-0.5,0.7-1.2,1.3-1.9,1.8c0.2,0.2,0.4,0.5,0.6,0.7c0.4,0.4-0.2,1-0.6,0.6c-1.4-1.6-2.9-3-4.3-4.5
                                c-1.2-1.3-2.4-2.7-3.2-4.4c-0.4-0.9-0.8-1.8-1-2.7c-0.3-1-0.7-2-1-3v-0.1v-0.1c-0.3-1-0.5-1.9-0.8-2.9c-0.2-0.7-0.4-1.5-0.4-2.3
                                c-0.1-1.7,0.1-3.4,0.4-5c0.1-0.5,0.9-0.3,0.8,0.2c-0.2,1.3-0.4,2.6-0.4,3.9c0.9-2.1,2.1-4.1,3.2-6.1c1.2-2.1,2.5-4.1,3.5-6.3
                                c1.1-2.3,1.7-4.8,1.9-7.4c0.4-5.1-0.6-10.2-0.8-15.2c-1.6,3.3-3,6.7-3.2,10.4c0,0.6-0.9,0.6-0.8,0c0.2-3.4,1.2-6.5,2.7-9.6
                                c2.5-5.5,6.3-10.5,7.5-16.5c0.3-1.6,0.4-3.2,0.4-4.8s0.1-3.2,0.2-4.8c0.2-3.3,0.6-6.6,1.1-9.9c0.1-0.5,0.9-0.3,0.8,0.2
                                c-0.6,3.7-1,7.4-1.2,11.1c-0.2,3.1,0,6.2-0.8,9.3c-0.7,2.9-2,5.6-3.3,8.2c-0.6,1.2-1.3,2.4-1.9,3.6l0,0c0.2,0.6,0.8,1,1.3,1.2
                                c0.6,0.2,1.2,0.8,0.8,1.5c-0.2,0.3-0.5,0.5-0.8,0.8s-0.5,0.6-0.8,0.9c-0.4,0.6-0.8,1.2-1.1,1.9c0.1,0.9,0.2,1.7,0.2,2.5
                                c0,0,0,0,0.1,0.1c0.4,0.4,0.9,1.3,1.6,1.3c0.4,0,0.8-0.2,1.1-0.3c0.4-0.1,0.7-0.2,1.1-0.3c1.5-0.4,3-0.7,4.5-0.8
                                c0.5-0.1,0.5,0.8,0,0.9c-1.7,0.2-3.4,0.5-5.1,1c-0.1,0-0.1,0-0.2,0.1l0,0c-0.2,0.2-0.5,0.4-0.7,0.7c-0.4,0.4-0.6,0.7-0.8,1.2
                                c-0.6,1.1-1.1,2.4-1.2,3.7v0.1c0,0.9-0.1,1.9-0.2,2.9c-0.3,2.5-1,4.8-2.1,7.1c-0.8,1.6-1.7,3.2-2.6,4.7c0.6-0.2,1.3-0.3,1.8,0.1
                                c0.7,0.5,1,1.5,1.3,2.3c0.3,0.9,0.6,1.9,0.9,2.8c0.4,0.9,0.8,1.8,1.3,2.6c0.3,0.5-0.5,0.9-0.7,0.4c-0.9-1.4-1.5-3-2-4.6
                                c-0.2-0.8-0.5-1.6-0.9-2.3c-0.2-0.4-0.4-0.7-0.9-0.6c-0.4,0.1-0.8,0.2-1.2,0.4c-0.1,0-0.2,0.1-0.2,0c-1.4,2.3-2.7,4.5-3.6,7
                                c-0.1,0.1-0.2,0.2-0.3,0.3l0,0c0.1,0.8,0.4,1.6,0.6,2.4c0.2,0.6,0.3,1.3,0.5,1.9c0.2,0.2,0.5,0.5,0.8,0.6c0.3,0.2,0.6,0.4,1,0.6
                                c1,0.7,1.9,1.3,2.9,2c0.2,0.1,0.3,0.4,0.1,0.6c-0.1,0.2-0.4,0.3-0.6,0.1c-1-0.7-2-1.4-3-2.1c-0.2-0.1-0.4-0.3-0.7-0.4
                                c0.6,1.7,1,3.4,1.9,4.9c1.5,2.5,3.7,4.5,5.7,6.6c1-0.6,1.8-1.4,2.3-2.4c0.5-0.9,0.7-1.9,1.3-2.7c0.6-0.7,1.4-0.9,2.2-0.4
                                c0.3,0.2,0.8,0.6,1.2,0.6s0.5-0.5,0.6-0.9c0.1-0.8,0.3-1.5,0.9-2c0.2-0.2,0.5-0.3,0.6-0.6c0.1-0.2,0-0.5,0.1-0.8
                                c0.1-0.4,0.2-0.9,0.3-1.3c0.7-1.8,2.1-3.1,3.4-4.4c1.5-1.6,2.8-3.2,3.8-5.1c1.1-2.1,1.9-4.4,2.8-6.6c1-2.4,2-4.8,2.9-7.1
                                c0.4-1.1,0.8-2.2,1.1-3.4c0.3-1.3,0.4-2.6,0.4-4c0-2.6,0-5.1-0.1-7.7c-0.1-2.1-0.2-4.2-0.4-6.3c-1.2,2.6-2.5,5.1-3.9,7.6
                                c-0.3,0.5-1,0.1-0.7-0.4c1.1-1.9,2.1-3.9,3-5.9c0.9-1.9,1.9-3.9,2.6-5.9c1.2-3.7,0.3-7.4-0.9-11c-0.6-1.8-1.2-3.6-1.7-5.4
                                c-0.5-1.9-0.8-3.9-1.2-5.8c-1.5-7.1-3.9-14-7.5-20.4c0,0.1,0,0.1,0,0.2c-0.5,2.5-2.4,4.5-3,7c-0.1,0.5-1,0.3-0.8-0.2
                                c0.6-2.6,2.8-4.7,3-7.5c0.1-1.2-0.3-2.2-1.2-3.1c-0.4-0.4-0.9-0.8-1.2-1.3c-0.3-0.4-0.6-0.9-0.9-1.4c-0.7-1-1.7-1.8-2.7-2.5
                                s-2-1.4-3.1-2.1c-2.1-1.4-4.3-2.6-6.8-3.3s-5.1-0.7-7.6-1.6l0,0c-0.1,1.5-0.8,2.8-1.5,4.1c-1.2,2.3-2.6,4.3-4.6,6
                                c-1.7,1.5-3.4,3-5.2,4.4c-0.6,0.4-1.2,0.9-1.7,1.3c0.2,0.4,0.1,1.1,0,2.2v0.4c-0.2,1.6-0.6,10.5-0.6,14.8c0,1.6,0.3,2.9,0.6,4.4
                                c0.5,2.2,1,4.7,0.7,8.3c-0.4,4.4,0.3,13.7,0.7,18.2c0.1,1.1,0.2,1.9,0.2,2.4c0,0.4,0.1,1,0.1,1.6v0.1c0.3,3.7,0.5,6.9-0.3,8.9
                                c-0.3,0.8-1.1,1.3-2,1.3c-0.3,0-0.5-0.1-0.8-0.1c-1-0.4-1.4-1.4-1.2-2.7c0.3-1.9,0.2-4.9,0-7c-0.1-0.7-0.1-1.3-0.1-1.8
                                c0-0.4-0.1-1.2-0.2-2.2v-0.1c-0.4-5-1.1-14.2-0.7-19c0.3-2.9-0.1-4.9-0.6-6.9c-0.4-1.6-0.7-3.2-0.7-5.3c0-2.9,0.5-10.8,1-14.2
                                c-0.1,0.1-0.2,0.2-0.3,0.4c-1.8,2.2-2.3,5.1-4.2,7.2l0,0c0.1,1.1,0.2,1.9,0.2,1.9c0,0.1,0,0.3-0.2,0.4c-0.6,0.5-2.4,0.6-3.5,0.6
                                c-0.4,0-0.7,0-0.9,0c-0.2,0-0.4-0.2-0.4-0.4l-0.4-4.3l0,0c-0.8-0.8-1.1-1.9-1.8-2.8c-0.3-0.4-0.6-0.6-1.1-0.8
                                c-0.2-0.1-0.5-0.2-0.8-0.2c0.5,2.4,1,5.2,0.9,7.2c0,0.5-0.1,1-0.1,1.4c-0.3,3.1-0.5,6.2,0.1,10.3c0.8,4.7,2.3,22.7,1.2,26.1
                                c-0.3,0.9-1.1,1.5-2,1.5c-0.2,0-0.5,0-0.7-0.1c-1-0.3-1.5-1.3-1.4-2.6c0.2-2.4-0.5-18.8-1.4-24.2c-0.8-4.6-0.5-8.2-0.2-11.3v-0.1
                                c0-0.4,0.1-0.9,0.1-1.3c0.2-2.5-1.2-7.9-1.9-10c-2.2-0.5-4.3-1-6.2-2.1c-1.8-1.1-3.4-2.5-5-3.9s-3.3-2.6-4.7-4.2
                                c-1.1-1.2-2-2.7-1.8-4.4c0-0.1,0-0.2,0-0.2c-0.8,0.5-1.7,0.9-2.5,1.3c-1.9,0.8-3.9,1.4-6,1.9c-1.8,0.4-3.8,0.7-5.5,1.5
                                c-0.9,0.4-1.6,0.9-2.3,1.6c-0.8,0.8-1.3,1.7-2.1,2.5c-0.7,0.7-1.5,1.2-2.3,1.9s-1.3,1.6-1.7,2.5c-0.4,1-0.6,2-0.8,2.9
                                c-0.3,1.1-0.6,2.3-0.9,3.4c-0.6,2.2-1.3,4.4-2,6.6c-1.5,4.5-3,8.9-4.5,13.4c-0.7,2.2-1.4,4.5-2,6.7c-0.5,2.1-1,4.2-1.1,6.4
                                c0,1.1,0,2.2,0.2,3.2s0.6,1.9,0.9,2.9c0.1,0.4,0.2,0.8,0.4,1.2c0.2,0.7,0.1,1.4,0.1,2.2c0,4.8-0.2,9.6-0.1,14.4
                                c0,2.2,0.4,4.3,1.7,6.1c0.6,0.9,1.4,1.7,2,2.6c0.6,0.9,1.1,1.8,1.6,2.7c0.9,1.8,1.5,3.8,2,5.8c0.1,0.4,0.2,0.8,0.3,1.2
                                c0.3-0.8,0.7-1.5,1.1-2.2c1.1-1.9,1.8-4.1,1.8-6.3c0-0.6,0.9-0.6,0.9,0c-0.1,2.1-0.6,4.1-1.5,5.9c-0.4,0.8-0.9,1.5-1.3,2.4
                                c-0.3,0.7-0.7,1.6-0.6,2.4c0.2,0.9,0.6,1.8,1.4,2.4c0.8,0.6,1.5,1.1,1.9,2s0.7,1.8,1.3,2.6c1.1,1.4,3.1,2.1,4.8,1.7
                                c0.1,0,0.3,0,0.4,0.1c0.2-0.5,0.5-0.9,0.6-1.4c0.2-0.8,0.2-1.6,0-2.4c-0.1-0.5,0.7-0.8,0.8-0.2c0.4,1.4,0.1,2.7-0.5,4
                                c-0.6,1.4-0.6,2.8,0,4.2c0.3,0.7,0.7,1.4,1.1,2c0.4,0.5,0.7,1.2,1.2,1.7s1.4,0.3,2,0.5c0.5,0.1,1,0.3,1.4,0.5
                                c0-1.4,0.4-2.8,0.9-4.1c0.2-0.5,1-0.3,0.8,0.2c-0.5,1.4-0.9,2.8-0.8,4.2c0,0.6,0.1,1.4,0.7,1.6c0.7,0.2,1.4,0.3,2.1,0.4l0,0
                                c1-0.9,2-1.9,2.9-2.8c2.5-2.8,4.6-5.9,6.4-9.2c0.9-1.6,1.7-3.3,2.5-5c0.5-1.1,1.1-2.3,1.4-3.5c-0.1,0.1-0.3,0.1-0.5,0
                                c-0.1,0-0.2-0.1-0.2-0.1l0,0c0,0,0,0-0.1,0h-0.1c-0.2-0.1-0.4-0.1-0.5-0.1s-0.2,0-0.3-0.1l0,0l0,0c0,0-0.1,0-0.2,0c0,0,0,0-0.1,0
                                c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.5-0.6,0.7-0.8,0.2c-0.5-1.5-1.5-2.7-2.9-3.5c-0.6-0.4-1.2-0.7-1.8-1.2c-0.6-0.5-0.9-1.1-1.2-1.8
                                c-0.6-1.4-1.1-2.9-1.9-4.2c-0.7-1.3-1.8-2.3-2.3-3.7c-0.5-1.3-0.5-2.7-0.6-4c-0.2-1.4-0.4-2.8-0.7-4.2c-0.6-2.7-1.5-5.3-3-7.6
                                c-0.7-1.2-1.6-2.2-2.6-3.3c-0.9-1-1.6-2-1.9-3.3c-0.1-0.5,0.7-0.8,0.8-0.2c0.4,1.6,1.5,2.7,2.5,3.8c0.1,0.1,0.2,0.2,0.3,0.3
                                c0.1-0.3,0.2-0.7,0.3-1c0.5-1.3,1.4-2.3,2.5-3.1h0.1c-0.2-1.1-0.7-2-1.3-3c-0.8-1.3-1.4-2.5-1.9-3.9s-0.9-2.8-1.6-4.1
                                c-0.7-1.2-1.5-2.4-2.2-3.6c-0.8-1.3-1.2-2.6-1.4-4.1c-0.2-1.5-0.4-2.9-0.8-4.4s-1-2.9-1.8-4.3c-0.3-0.5,0.5-0.9,0.7-0.4
                                c0.8,1.5,1.5,3.1,2,4.7c0.5,1.6,0.6,3.3,0.9,5c0.3,1.5,1,2.8,1.8,4.1c0.8,1.2,1.5,2.3,2.1,3.6c0.6,1.4,1,2.8,1.5,4.2
                                s1.4,2.6,2.1,3.9c2.3,4.4,0,9.4,1,14c0,0,0,0,0,0.1c0.3,1.1,0.6,2.2,0.9,3.4c0.2,1.3,0.4,2.6,0.6,3.9c0.2,1.5,0.2,3,1.1,4.3
                                c0.4,0.6,0.9,1.2,1.3,1.8s0.8,1.3,1.1,2c0.3,0.7,0.6,1.3,0.9,2c0.3,0.6,0.5,1.4,0.9,1.9c0.8,1.1,2.3,1.5,3.3,2.4
                                c0.5,0.5,1,1,1.4,1.6c0.8,0,1.7,0.2,2.4,0.6c0.1,0,0.1,0.1,0.1,0.1c0.1-0.6,0.1-1.2,0-1.9c-0.1-0.5,0.7-0.8,0.8-0.2
                                c0.4,2.3-0.6,4.5-1.6,6.6c-1.7,3.6-3.6,7.1-5.9,10.3c-1.1,1.6-2.3,3.1-3.7,4.5c-1.4,1.4-2.8,2.9-4.6,3.8
                                c-0.5,0.3-0.9-0.5-0.4-0.7c0.4-0.2,0.7-0.4,1.1-0.7c-0.2,0-0.4-0.1-0.6-0.1c-0.4-0.1-0.8-0.1-1.1-0.3c0,0.3,0,0.7,0,1
                                c0.1,1.1,0.4,2.3,1.2,3.1c0.5,0.5,1,0.8,1.5,1.2c0.5,0.4,0.8,0.9,1.1,1.4c0.8,1.3,1.5,2.6,2.3,3.9c0.9,1.3,1.8,2.6,2.8,3.9
                                c1,1.2,2.1,2.1,3.6,2.6c3,1,6.2,0.1,9.1-0.9c0.5-0.2,1.1-0.4,1.6-0.6l0,0c2.5-1,5-2,7.8-2.1c0.5,0,0.5,0.8,0,0.9
                                c-1.8,0.1-3.5,0.6-5.1,1.2c1.2,0,2.5-0.1,3.7-0.1c2.3-0.1,4.6-0.1,6.9-0.3c2.1-0.2,4.1-0.8,6.2-1c2.1-0.3,4.3-0.3,6.4-0.2
                                c3.8,0,7.8,0.2,11.5-0.8C527.7,280.8,529.3,280.1,530.7,279.1z M524.3,244.3c-2.6,1.1-5.4,1.5-8.2,1.7
                                c-10.7,0.9-21.6,0.6-32.4,0.5c-0.5,0-0.5-0.9,0-0.9c3,0,6.1,0.1,9.1,0.1c5.4,0,10.7,0,16.1-0.2c2.6-0.1,5.2-0.2,7.8-0.5
                                c2.5-0.2,5-0.7,7.3-1.6C524.6,243.2,524.8,244.1,524.3,244.3z"/>
                        </g>
                        <path name="GERA" class="st3" d="M495.1,215.1c-0.2,0-0.4,0-0.5-0.1c-0.9-0.3-1.2-1.2-1.1-2.2c0.2-2.3-0.4-18.8-1.4-24.3
                            c-0.8-4.5-0.4-8.1-0.2-11.2c0-0.5,0.1-1,0.1-1.4c0.2-2.7-1.3-8.4-1.9-10.4c-0.3-0.9,0.2-1.9,1.1-2.1c0.9-0.3,1.9,0.2,2.1,1.1
                            c0.2,0.8,2.4,7.9,2.1,11.7c0,0.5-0.1,1-0.1,1.5c-0.3,3.1-0.5,6.3,0.1,10.4c0.8,4.6,2.3,22.7,1.2,25.9
                            C496.5,214.6,495.9,215.1,495.1,215.1z"/>
                        <path name="GERA" class="st3" d="M511.7,225.9c-0.2,0-0.4,0-0.6-0.1c-0.9-0.4-1.1-1.3-1-2.2c0.3-1.9,0.2-4.9,0-7.1c-0.1-0.7-0.1-1.3-0.1-1.8
                            c0-0.4-0.1-1.2-0.2-2.3c-0.4-5-1.1-14.2-0.7-18.9c0.3-3-0.1-5-0.6-7.1c-0.3-1.6-0.7-3.2-0.7-5.2c0-3.6,0.8-14.2,1.3-15.5
                            c0,0,1.3-1,2.3-1.8c0.7-0.6,0.5,1.1,0.4,2.5c-0.2,1.7-0.6,10.6-0.6,14.8c0,1.6,0.3,3,0.6,4.4c0.5,2.2,1,4.6,0.6,8.1
                            c-0.5,4.4,0.3,13.8,0.7,18.3c0.1,1.1,0.2,1.9,0.2,2.4s0.1,1.1,0.1,1.7c0.3,3.6,0.5,6.8-0.3,8.7C513,225.4,512.4,225.9,511.7,225.9
                            z"/>
                        <g>
                            <path name="GERA" class="st2" d="M495.4,136.1c-0.5-0.2-0.7,0.6-0.2,0.8c2.6,0.9,5.3-0.2,7.9,0.2c1.4,0.2,2.6,0.7,4,0.9s2.8,0.1,4.2-0.2
                                c0.5-0.1,0.3-0.9-0.2-0.8c-1.4,0.3-2.8,0.4-4.2,0.1c-1.3-0.2-2.6-0.7-3.9-0.9C500.4,135.9,497.8,136.9,495.4,136.1z"/>
                            <path name="GERA" class="st2" d="M501.6,142.4c-0.2,0.1-0.4,0.3-0.3,0.5s0.3,0.4,0.5,0.3c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0h0.1
                                c0.2,0,0.4,0,0.6,0c0.4,0,0.8,0.1,1.1,0.3c0.2,0.1,0.5,0.1,0.6-0.1c0.1-0.2,0.1-0.5-0.1-0.6C503.6,142.1,502.6,142.1,501.6,142.4
                                z"/>
                            <path name="GERA" class="st2" d="M483.4,125.1c0.3-0.9,1.5-7.7,1.5-7.7c-0.4,0.1-2.4-0.2-2.4-0.2c-0.5,0.9-0.3,7.9-0.2,8.9
                                C482.5,127.1,483.1,126,483.4,125.1z"/>
                            <path name="GERA" class="st2" d="M506,130.2c-0.5,0-0.5,0.9,0,0.9c1.4,0,3.7-1,3.2-2.8c-0.2-0.6-0.5-1.2-0.8-1.7c-0.5-0.9-0.9-1.8-1.3-2.7
                                c-0.4-0.8-0.8-1.6-0.9-2.5c-0.1-0.5-0.9-0.3-0.8,0.2c0.1,0.7,0.4,1.3,0.7,1.9c0.4,0.8,0.8,1.7,1.2,2.5c0.3,0.7,0.7,1.4,1,2.1
                                c0.2,0.5,0.4,1-0.1,1.4C507.5,130,506.7,130.2,506,130.2z"/>
                            <path name="GERA" class="st2" d="M463.8,224.4c-1.9-0.6-3.5-1.3-5-2.7c-1.4-1.3-2.7-2.7-3.9-4.1c-2.5-3.1-4.6-6.4-6.7-9.8
                                c-0.3-0.5-1,0-0.7,0.4c2.2,3.5,4.3,7,7,10.1c1.3,1.5,2.7,3,4.2,4.4c1.4,1.3,3.1,1.9,4.9,2.5C464.1,225.4,464.3,224.6,463.8,224.4
                                z"/>
                            <path name="GERA" class="st2" d="M483.7,246.5c10.8,0.1,21.6,0.4,32.4-0.5c2.8-0.2,5.6-0.6,8.2-1.7c0.5-0.2,0.3-1-0.2-0.8
                                c-2.3,1-4.8,1.4-7.3,1.6c-2.6,0.2-5.2,0.4-7.8,0.5c-5.4,0.2-10.7,0.2-16.1,0.2c-3,0-6.1-0.1-9.1-0.1
                                C483.2,245.6,483.2,246.5,483.7,246.5z"/>
                            <path name="GERA" class="st2" d="M569,445.4c-3.2-6.3-8.6-11.1-10.7-13.2c-2.2-2.1-7.3-7.7-7.3-7.7c-0.3-1.3-2.4-3.9-2.4-3.9
                                c-0.1-3.4-2.8-2.5-2.8-2.5c-0.2-0.1-0.9-2.1-0.8-4.4c0-1.8-1.7-2-2.5-2.1c0.1-1.3,0.1-2.5,0.2-3.8c0.1-1.5,0.2-3,0.3-4.5
                                c0.2-2.9,0.4-5.9,0.6-8.8c0.4-5.8,0.8-11.6,1.2-17.5c0.3-5.8,0.8-11.7,0.7-17.5c-0.1-5.4-0.6-10.9-1-16.3
                                c0.3-0.1,0.7-0.3,0.9-0.4c0,0,1.3-0.1,1-2.9c0,0-0.8-3.8,0-7.2c0.9-3.4,0.9-18.6,0-22.1s-1-6.7-0.2-9.4s0.8-8-0.9-11.2
                                c-1.6-3.1-3-4.3-2.4-8c0.6-3.8,1.3-8.4-0.6-11.5c0,0-1.3-2.6-2.3-3.6c0-0.1,0-0.2,0-0.3c0.1-0.9,0.1-1.9-0.5-2.7l0,0
                                c0.3-0.5,0.5-1.1,0.7-1.7c0.2-0.4,0.4-0.8,0.7-1c0.4-0.3,0.8,0,1.1,0.2c0.6,0.4,1.4,0.9,2.1,0.4c0.6-0.4,0.6-1.3,0.8-1.9
                                c0.1-0.5,0.3-0.7,0.7-1s0.6-0.6,0.7-1.1c0.1-0.5,0.1-1,0.3-1.5c0.1-0.5,0.4-0.9,0.6-1.3c0.5-0.9,1.2-1.6,1.9-2.3
                                c1.5-1.5,3-3,4.1-4.8c1.2-2,2-4.1,2.9-6.3c1-2.4,2-4.9,3-7.3c1-2.3,1.8-4.7,2-7.2c0.3-2.9,0.1-6,0-8.9s-0.2-5.8-0.6-8.7
                                c0.2-0.5,0.4-1,0.6-1.5c0.7-1.8,1.2-3.6,1.1-5.5c0-1.8-0.4-3.7-0.9-5.4c-0.5-1.8-1.2-3.6-1.7-5.4c-0.6-1.9-1-3.9-1.4-5.9
                                c-0.8-3.8-1.6-7.5-2.9-11.2c-1.4-4-3.2-7.9-5.2-11.6c-0.1-0.2-0.3-0.2-0.4-0.2c0-0.7-0.2-1.4-0.5-2c-0.6-1.1-1.7-1.7-2.4-2.7
                                c-0.7-1.1-1.4-2-2.3-2.8c-1-0.8-2-1.5-3-2.2c-2.2-1.4-4.4-2.9-6.8-3.8c-2.9-1.1-6.1-1-9-2c-0.1,0-0.2,0-0.3,0
                                c-0.2-1.4-1-2.4-1.9-3.5c0,0,0,0-0.1-0.1v-0.1c0-1.4-0.5-2.7-1.5-3.6c-1.1-1-2.5-1.4-3.9-1.8c0.5-1.1,0.8-2.2,1.1-3.1
                                c0.1,0.1,0.2,0.2,0.3,0.2c1.2,0.1,2.2-1.2,2.8-2.1c0.7-1.1,1.1-2.3,1.5-3.5c0.8-2.6,1.8-5.6,0.3-8.1c-0.1-0.2-0.4-0.2-0.6-0.1
                                c1.5-2.3,2.1-5.2,2.1-5.2c1.3-7.1-3.6-14.2-3.6-14.2c0.8,0.2,1.7,1.3,1.6,0.1c0-2.9-2.3-4.5-2.3-4.5c1.5-0.7,1.1-1.2,1.1-1.2
                                c-2.5-0.2-4.2-1.4-4.2-1.4c1.5-0.5,1.4-0.9,1.4-0.9c-2.7-0.1-4.1-2.1-7.4-4.6c-3.2-2.5-5.7-1.3-5.7-1.3c-0.9,0.1-4.8-2.8-4.8-2.8
                                c-0.5,0.6,0.4,1.9,0.4,1.9c-4.2-1-10.1,3.7-10.8,4.2c-0.6,0.5-2.3,0.3-2.3,0.3c-0.3,0.4,0.9,1.4,0.9,1.4s-1.6,0.8-2.6,1.4
                                s-3.3,0.9-3.3,0.9c-0.5,1.4,1.8,1.5,1.8,1.5c-7.9,8.9-4.6,8.4-4.6,8.4c-1.4,2.9-0.4,10.8-0.4,10.8c1-0.8,1.2-0.6,3.2-1.1
                                s3.6-3.8,4.7-4.9c0.8-0.9,2.8-0.7,2.8-0.7c-2.3,0.6-3.2,2.2-3.2,2.2c0.8-0.3,8.7,0.2,12-0.4s6.5-6.7,7.8-8.7
                                c1.4-2.1,3.2-2.6,3.2-2.6c-2,2.1-1.6,2.3-1.6,2.3c2.8-0.9,7.3,0.1,7.3,0.1c-0.5,1.3,0.6,2.5,1.2,3.8c0.7,1.3,1.1,4.8,1,7.5
                                s1.7,3.7,2,4.2s-0.4,3.7-0.7,4.9c-0.3,1.2-0.8,6.6-0.8,6.6c-0.2,0.8-0.2,1.4,0.2,1.6c0.3,0.1,0.9-1.9,0.9-1.9
                                c1-2.3,1.8-6.9,1.8-6.9c0.1-0.2,0.2-0.3,0.3-0.4c0,0.1,0,0.2,0,0.3c1.3,2.2,0.3,4.8-0.3,7.1c-0.3,1.1-0.7,2.1-1.2,3.1
                                c-0.4,0.8-1.3,2.4-2.4,2.2h-0.1c0.3-1.2,0.4-1.9,0.4-1.9c0-0.2-0.1-0.4-0.4-0.5c-0.2,0-0.5,0.1-0.5,0.4c0,0.1-0.7,4.6-3.3,8.9
                                c-3.4,5.7-8.1,8.2-14.2,7.6c-16.6-1.8-17.6-19.9-17.6-20.1c0-0.2-0.2-0.4-0.5-0.4c-0.2,0-0.4,0.2-0.4,0.4c0,0,0,0.9,0.3,2.1
                                c-1.2-0.1-2-1.6-2.3-2.6c-0.4-1.2-0.7-2.4-0.8-3.7c-0.3-2.4-0.2-5.4,1.2-7.5c0.3-0.4-0.4-0.9-0.7-0.4c-0.8,1.2-1.2,2.6-1.3,4.1
                                c-0.2,1.5-0.1,3.1,0.1,4.6c0.2,1.4,0.5,2.9,1.2,4.1c0.6,1.1,1.5,2.2,2.8,2.3c0,0,0,0,0.1,0c0.3,1.6,0.8,3.6,1.7,5.7
                                c-1.4,0.6-2.8,1.2-4.1,2c-1.2,0.7-2.2,1.7-2.8,3c-0.2,0.5-0.3,1-0.4,1.5c-1,0.1-1.9,0.7-2.5,1.6c-0.5,0.8-0.7,1.7-0.7,2.6
                                c-0.9,0.3-1.6,1-2,1.9c-0.1,0-0.2,0-0.3,0c-2,1.2-4.2,2.1-6.4,2.7c-1.9,0.5-3.9,0.8-5.8,1.4c-1.8,0.5-3.5,1.4-4.8,2.8
                                c-0.7,0.8-1.3,1.8-2.2,2.5c-0.8,0.7-1.7,1.3-2.4,2c-1.4,1.5-1.9,3.5-2.3,5.4c-0.6,2.4-1.2,4.7-2,7.1c-1.4,4.6-3,9.2-4.5,13.7
                                c-0.8,2.3-1.5,4.6-2.2,7c-0.6,2.2-1.2,4.3-1.6,6.6c-0.3,2.1-0.5,4.3,0,6.4c0.3,1.4,1,2.7,1.3,4.1c0.1,0.3,0,0.6,0,0.9
                                c0,0.6,0,1.3,0,1.9c0,1.3,0,2.6,0,3.8c0,2.6,0,5.1,0,7.7c0,2.1,0,4.4,0.7,6.4c0.8,2,2.5,3.5,3.6,5.3s1.9,3.9,2.5,5.9
                                c0.3,1.1,0.5,2.1,0.8,3.2c0.1,0.6,0.2,1.1,0.4,1.7l0,0l0,0c0.1,0.4,0.2,0.8,0.4,1.1c0.5,1,1.2,1.5,2,2.2c0.9,0.7,1.2,1.7,1.6,2.6
                                c0.8,1.6,2.2,2.8,3.9,3.2c0.8,0.2,1.6,0.2,2.4,0c-0.3,1.4,0,2.8,0.5,4c0.3,0.7,0.7,1.3,1.1,2c0.4,0.5,0.8,1.2,1.2,1.6
                                c0.5,0.5,1.1,0.5,1.7,0.5s1.1,0.2,1.6,0.4c-1.1,2-6.1,11.6-5.4,15.2c0.9,4,2.8,7.5,1.6,11.4c-1.1,3.9-3.3,6.9-2.8,10.3
                                s0.7,5-0.1,7.4c-0.8,2.4-1.3,5.9-1.2,8c0.1,2.1,0.2,5.8,0,7.7c-0.2,1.9-0.2,1.9-0.2,1.9s-0.5,0.4,0,2.3s-1.9,2.3,2.9,6.3
                                c0.3,0.3,0.7,0.6,1.1,0.9l0,0c-0.5,5.8-1,11.6-1.3,17.5s0,11.7,0.3,17.5c0.6,11.7,1.6,23.3,2.3,35c0.1,1.3,0.1,2.5,0.2,3.8
                                c-0.8,0-2.5,0.3-2.5,2.1c0,2.3-0.6,4.3-0.8,4.4c0,0-2.6-0.9-2.8,2.5c0,0-2.1,2.6-2.4,3.9c0,0-5.2,5.6-7.3,7.7
                                c-2.2,2.1-7.6,6.9-10.7,13.2c0,0-1.5,2.9,0,5.2c1.3,1.9,6.7,5.5,14.8,3.8c6.2-1.5,12.2-6.3,18.2-14.7c0,0,1.1-1.7,2.9-2.4
                                c1.8-0.7,5.7-2.5,6.1-3.7c0.4-1.1,1.1-9.5,0-12.4c-0.5-1.1-1-1-0.8-2.6c0,0,0.6-3.7-0.2-5.6c0.3-0.8,0.4-1.6,0.5-2.5
                                c0.2-1.4,0.3-2.8,0.5-4.2c0.4-2.9,0.8-5.8,1.3-8.7c1-5.6,2.4-11.2,2.9-16.9c0.6-5.8,0.7-11.7,1-17.5c0.2-5.2,0.2-10.7,1.8-15.8
                                c0.4-1.1,0.8-2.1,1.4-3.1c2.3-0.6,3-1.9,3.5-4.2c0.7-3.1,0.1-4.5,1.3-6.5c1.3-2,2.3-3.3,2.5-7.4s1.3-6.4,2.5-8.9
                                c1.3-2.5,0.4-1.4,1.3-5.8s1.5-6,2.6-9l1.1-3.3l2.8-0.2c0,0,2,2,3,3.5s2.1,4.3,2.4,7.3c0.2,3,1.6,9.8,3,12.7
                                c1.4,2.9,2.3,2.9,2.3,6.2s-0.6,7.8,0,8.9s-1,2.8,0,3.8c0.5,0.5,1.9,1.7,4.6,2.8c2.5,4.5,2.8,9.8,3.1,14.9
                                c0.3,5.9,0.5,11.7,0.9,17.5c0.2,2.8,0.4,5.7,0.9,8.5c0.5,2.9,1,5.7,1.6,8.5s1.1,5.7,1.5,8.6c0.4,2.8,0.7,5.6,1,8.4
                                c0.1,0.8,0.2,1.7,0.5,2.4c-0.8,1.9-0.2,5.7-0.2,5.7c0.2,1.7-0.3,1.5-0.8,2.6c-1.1,2.9-0.4,11.3,0,12.4s4.4,3,6.1,3.7
                                c1.8,0.7,2.9,2.4,2.9,2.4c6,8.4,12,13.2,18.2,14.7c8.1,1.7,13.5-1.9,14.8-3.8C570.5,448.2,569,445.4,569,445.4z M521,140.5
                                c1.1,0.9,1.4,2.1,1.4,3.4c0,1.4,0,2.8-0.7,4.1c-0.6,1.1-1.5,2.1-2.3,3.1c-0.4,0.4,0.2,1,0.6,0.6c1-1.1,2-2.1,2.6-3.5
                                c0.5-1,0.6-2.1,0.7-3.3c0.7,0.9,1.3,1.9,1.2,3.2c-0.1,1.4-0.9,2.6-1.5,3.8c-0.6,1.1-1.3,2.3-2.1,3.3c-0.7,0.9-1.5,1.6-2.3,2.3
                                c-1.6,1.4-3.3,2.9-5,4.2c-0.8,0.6-1.6,1.2-2.4,1.7l0,0l0,0c-0.8,0.5-1.5,1.1-2.2,1.7l-0.1,0.1l-0.1,0.1l0,0
                                c-0.3,0.3-0.6,0.6-0.9,0.9c-1,1.1-1.7,2.3-2.3,3.6c-0.5,1.2-1,2.4-1.8,3.4c-0.1-1.4-0.2-2.8-0.2-3.4c0-0.2-0.2-0.4-0.4-0.4
                                c-0.1,0-1.1-0.1-2.4,0.2c0.8-1.7,2.4-2.9,3.8-4.1c1.7-1.4,3.1-3.1,4.4-4.8c1.4-1.8,2.6-3.8,3.8-5.7c1.1-1.9,1.9-4,2.3-6.1
                                c0.5-2.4,0.7-4.8,0.8-7.2c0.6-0.9,1.1-1.9,1.5-2.8C518.7,139.2,520,139.6,521,140.5z M511.5,185.7c0.5,2.1,1,4.6,0.6,8
                                c-0.5,4.4,0.3,13.9,0.7,18.4c0.1,1.1,0.2,1.9,0.2,2.3c0,0.4,0.1,1,0.1,1.6v0.1c0.3,3.6,0.5,6.6-0.2,8.5c-0.3,0.6-1,1-1.7,0.7
                                c-0.8-0.3-0.8-1.2-0.7-1.8c0.3-1.9,0.2-5,0-7.2c-0.1-0.7-0.1-1.3-0.1-1.8c0-0.4-0.1-1.2-0.2-2.2v-0.1c-0.4-4.9-1.1-14.1-0.7-18.8
                                c0.3-3.1-0.1-5.1-0.6-7.2c-0.3-1.6-0.7-3.2-0.7-5.1c0-3.5,0.8-13.7,1.2-15.2c0.3-0.2,1.2-1,2-1.6c0,0.4,0,1.1-0.1,1.6v0.4
                                c-0.2,1.6-0.6,10.5-0.6,14.9C510.9,182.8,511.2,184.2,511.5,185.7z M502.7,170.4c0,1.5,0.4,5,0.5,6c-0.6,0.2-2.1,0.3-3.2,0.3
                                l-0.5-5.6C500.7,170.4,502.1,170.4,502.7,170.4z M501,149.7c0.6,0.1,1.3,0.1,1.9,0.1c5.8,0,9.6-3,12.1-6.6
                                c-0.3,3.9-0.9,7.8-2.8,11.2c-1.1,2-2.4,3.9-3.7,5.7c-1.3,1.8-2.8,3.5-4.5,4.9c-1.5,1.2-3,2.4-3.9,4.1c-0.8-1.8-1.6-3.6-2.5-5.3
                                c-0.5-0.9-1-1.7-1.8-2.3s-1.7-1.1-2.5-1.8c-1.6-1.3-2.9-2.9-3.9-4.7c-0.5-1-1-2.1-1.7-3.1c-0.4-0.6-0.8-1.3-1.3-1.9
                                c0-3.1,0.4-6.3,0.1-9.3C489.2,144.9,493.6,148.9,501,149.7z M474.5,149c0,0.1,0,0.1,0,0.1c0.6,2.6,2.6,4.9,4.9,6.3
                                c0.5,0.3,0.9-0.4,0.4-0.7c-1.9-1.2-3.6-3-4.3-5.1c-0.4-1.1-0.5-2.2,0.1-3.3c0.4-0.8,1.1-1.4,1.9-1.5c0,2.1,0.8,4.2,1.9,6.1
                                c0.3,0.5,1,0.1,0.7-0.4c-1.3-2.3-2.4-5.1-1.4-7.7c0.5-1.2,1.5-2.1,2.6-2.7c1.2-0.7,2.5-1.4,3.7-1.9c0,0,0.1,0,0.1-0.1v0.1
                                c1.1,3.4,0.6,7,0.5,10.5c-1.2-1.9-2.2-4.2-1.5-6.5c0.2-0.5-0.7-0.8-0.8-0.2c-0.9,3.1,0.6,5.8,2.3,8.4l0,0
                                c0.8,1.2,1.6,2.3,2.2,3.4c1,1.9,2,3.8,3.6,5.4c0.7,0.7,1.5,1.4,2.3,1.9c0.9,0.6,1.9,1.1,2.6,2.1s1.2,2.2,1.8,3.3
                                c0.6,1.2,1.1,2.3,1.6,3.5c-0.3,0.1-0.5,0.2-0.8,0.4c-0.1,0.1-0.2,0.2-0.2,0.4v0.4c-0.4-0.7-0.7-1.4-1.2-1.9
                                c-0.6-0.8-1.6-1.1-2.6-1.2c-0.5-1.9-0.9-3.3-1-3.7c-0.2-0.6-0.5-1-1-1.3s-1.1-0.3-1.6-0.1c-0.9,0.3-1.5,1.1-1.5,2
                                c-1.6-0.4-3.2-0.7-4.6-1.4c-1.7-0.8-3.2-2-4.7-3.2c-1.5-1.3-3-2.5-4.4-3.8c-1.2-1.1-2.5-2.3-2.9-3.9
                                C472.7,151.3,473.1,149.6,474.5,149z M495.1,188c0.7,4.4,2.3,22.6,1.2,25.7c-0.2,0.7-1,1-1.6,0.8c-0.8-0.3-0.8-1.3-0.8-1.7
                                c0.2-2.4-0.5-18.9-1.4-24.4c-0.7-4.5-0.4-8-0.2-11.1v-0.1c0-0.4,0.1-0.9,0.1-1.3c0.2-2.7-1.3-8.6-2-10.6
                                c-0.2-0.7,0.2-1.4,0.9-1.6c0.1,0,0.2-0.1,0.4-0.1c0.2,0,0.4,0.1,0.6,0.1c0.3,0.2,0.5,0.4,0.6,0.8c0.2,0.8,2.4,7.8,2.1,11.5
                                c0,0.5-0.1,1-0.1,1.5C494.7,180.7,494.4,183.9,495.1,188z M461,264.9c-0.5-0.5-0.8-1.1-1.2-1.7c-0.4-0.6-0.8-1.3-1.1-2
                                c-0.5-1.4-0.6-2.8,0-4.2c0.6-1.3,0.9-2.6,0.5-4c-0.1-0.5-1-0.3-0.8,0.2c0.2,0.8,0.2,1.6,0,2.4c-0.1,0.5-0.4,1-0.6,1.4
                                c-0.1-0.1-0.2-0.1-0.4-0.1c-1.8,0.4-3.7-0.3-4.8-1.7c-0.6-0.8-0.9-1.7-1.3-2.6c-0.5-0.9-1.2-1.4-1.9-2c-0.8-0.6-1.2-1.4-1.4-2.4
                                c-0.1-0.8,0.2-1.7,0.6-2.4c0.4-0.8,0.9-1.6,1.3-2.4c0.9-1.9,1.4-3.9,1.5-5.9c0-0.6-0.8-0.6-0.9,0c-0.1,2.2-0.7,4.4-1.8,6.3
                                c-0.4,0.7-0.8,1.4-1.1,2.2c-0.1-0.4-0.2-0.8-0.3-1.2c-0.5-2-1.1-3.9-2-5.8c-0.5-0.9-1-1.9-1.6-2.7c-0.6-0.9-1.4-1.7-2-2.6
                                c-1.3-1.8-1.6-3.9-1.7-6.1c-0.1-4.8,0-9.6,0.1-14.4c0-0.8,0.1-1.5-0.1-2.2c-0.1-0.4-0.2-0.9-0.4-1.2c-0.4-1-0.8-1.9-0.9-2.9
                                c-0.2-1.1-0.2-2.2-0.2-3.2c0.1-2.2,0.6-4.3,1.1-6.4c0.6-2.3,1.3-4.5,2-6.7c1.4-4.5,3-8.9,4.5-13.4c0.7-2.2,1.4-4.4,2-6.6
                                c0.3-1.1,0.6-2.3,0.9-3.4c0.2-1,0.5-2,0.8-2.9c0.4-1,0.9-1.8,1.7-2.5c0.7-0.7,1.6-1.2,2.3-1.9c0.8-0.8,1.4-1.7,2.1-2.5
                                c0.7-0.7,1.5-1.2,2.3-1.6c1.7-0.8,3.6-1.1,5.5-1.5c2.1-0.4,4.1-1,6-1.9c0.9-0.4,1.7-0.8,2.5-1.3c0,0.1,0,0.2,0,0.2
                                c-0.2,1.7,0.7,3.2,1.8,4.4c1.4,1.6,3.1,2.8,4.7,4.2s3.2,2.8,5,3.9c1.9,1.1,4.1,1.6,6.2,2.1c0.6,2.1,2,7.5,1.9,10
                                c0,0.4-0.1,0.9-0.1,1.3v0.1c-0.3,3.2-0.6,6.8,0.2,11.3c0.9,5.4,1.6,21.8,1.4,24.2c-0.1,1.3,0.4,2.3,1.4,2.6
                                c0.2,0.1,0.4,0.1,0.7,0.1c0.9,0,1.7-0.6,2-1.5c1.1-3.4-0.4-21.4-1.2-26.1c-0.7-4-0.4-7.2-0.1-10.3c0-0.5,0.1-1,0.1-1.4
                                c0.2-2-0.3-4.8-0.9-7.2c0.2,0.1,0.5,0.1,0.8,0.2c0.4,0.2,0.8,0.4,1.1,0.8c0.7,0.9,1,2,1.8,2.8l0,0l0.4,4.3c0,0.2,0.2,0.4,0.4,0.4
                                c0.1,0,0.5,0,0.9,0c1.1,0,2.9-0.1,3.5-0.6c0.1-0.1,0.2-0.2,0.2-0.4c0,0-0.1-0.9-0.2-1.9l0,0c1.9-2.1,2.4-5,4.2-7.2
                                c0.1-0.1,0.2-0.3,0.3-0.4c-0.5,3.4-1,11.3-1,14.2c0,2,0.4,3.7,0.7,5.3c0.5,2.1,0.9,4,0.6,6.9c-0.5,4.8,0.3,14,0.7,19v0.1
                                c0.1,1,0.1,1.8,0.2,2.2c0,0.5,0.1,1.1,0.1,1.8c0.2,2.1,0.2,5.1,0,7c-0.2,1.3,0.3,2.3,1.2,2.7c0.2,0.1,0.5,0.1,0.8,0.1
                                c0.9,0,1.7-0.5,2-1.3c0.8-2,0.6-5.2,0.3-8.9V216c0-0.6-0.1-1.1-0.1-1.6s-0.1-1.3-0.2-2.4c-0.4-4.5-1.1-13.9-0.7-18.2
                                c0.4-3.6-0.2-6.1-0.7-8.3c-0.3-1.4-0.6-2.8-0.6-4.4c0-4.3,0.4-13.1,0.6-14.8v-0.4c0.1-1.1,0.1-1.8,0-2.2c0.6-0.4,1.2-0.8,1.7-1.3
                                c1.8-1.4,3.5-2.9,5.2-4.4c2-1.7,3.3-3.8,4.6-6c0.7-1.3,1.4-2.6,1.5-4.1l0,0c2.5,0.9,5.1,0.9,7.6,1.6c2.4,0.7,4.7,1.9,6.8,3.3
                                c1,0.7,2.1,1.4,3.1,2.1s2,1.5,2.7,2.5c0.3,0.5,0.6,0.9,0.9,1.4c0.4,0.5,0.8,0.9,1.2,1.3c0.9,0.9,1.3,1.9,1.2,3.1
                                c-0.2,2.7-2.4,4.8-3,7.5c-0.1,0.5,0.7,0.8,0.8,0.2c0.6-2.5,2.5-4.4,3-7c0-0.1,0-0.1,0-0.2c3.5,6.4,6,13.2,7.5,20.4
                                c0.4,1.9,0.8,3.9,1.2,5.8c0.5,1.8,1.1,3.6,1.6,5.4c1.2,3.6,2.1,7.3,0.9,11c-0.6,2-1.6,4-2.6,5.9c-1,2-2,4-3,5.9
                                c-0.3,0.5,0.5,0.9,0.7,0.4c1.4-2.5,2.7-5,3.9-7.6c0.2,2.1,0.4,4.2,0.4,6.3c0.1,2.6,0.2,5.1,0.1,7.7c0,1.3-0.1,2.7-0.4,4
                                c-0.2,1.2-0.6,2.3-1.1,3.4c-0.9,2.4-1.9,4.8-2.9,7.1c-0.9,2.2-1.7,4.4-2.8,6.6c-1,1.9-2.3,3.6-3.8,5.1c-1.3,1.3-2.7,2.7-3.4,4.4
                                c-0.2,0.4-0.3,0.9-0.4,1.3c0,0.2,0,0.5-0.1,0.8s-0.3,0.4-0.6,0.6c-0.7,0.5-0.8,1.2-0.9,2c-0.1,0.3-0.1,0.9-0.6,0.9
                                c-0.4,0-0.8-0.5-1.2-0.6c-0.8-0.5-1.7-0.3-2.2,0.4c-0.6,0.8-0.8,1.8-1.3,2.7c-0.6,1-1.4,1.8-2.3,2.4c-2-2.1-4.2-4.1-5.7-6.6
                                c-0.9-1.5-1.4-3.2-1.9-4.9c0.2,0.1,0.4,0.3,0.6,0.4c1,0.7,2,1.4,3,2.1c0.2,0.1,0.5,0,0.6-0.1c0.1-0.2,0-0.5-0.2-0.6
                                c-1-0.7-1.9-1.3-2.8-2c-0.3-0.2-0.6-0.4-1-0.6c-0.3-0.2-0.6-0.4-0.8-0.6c-0.2-0.6-0.3-1.3-0.5-1.9c-0.2-0.8-0.5-1.6-0.6-2.4l0,0
                                c0.1,0,0.2-0.1,0.3-0.3c1-2.5,2.3-4.7,3.6-7c0.1,0,0.2,0,0.2,0c0.4-0.2,0.8-0.3,1.2-0.4c0.5-0.1,0.7,0.2,0.9,0.6
                                c0.4,0.7,0.7,1.5,0.9,2.3c0.5,1.6,1.1,3.1,2,4.6c0.3,0.5,1,0,0.7-0.4c-0.5-0.8-1-1.7-1.3-2.6c-0.4-0.9-0.6-1.9-0.9-2.8
                                c-0.3-0.8-0.6-1.8-1.3-2.3c-0.5-0.4-1.2-0.3-1.8-0.1c0.9-1.6,1.8-3.1,2.6-4.7c1.1-2.3,1.8-4.6,2.1-7.1c0.1-0.9,0.2-1.9,0.2-2.9
                                v-0.1c0-1.3,0.5-2.6,1.2-3.7c0.2-0.5,0.5-0.9,0.8-1.2c0.2-0.2,0.5-0.5,0.7-0.7l0,0c0.1,0,0.1,0,0.2-0.1c1.7-0.5,3.4-0.8,5.1-1
                                c0.5-0.1,0.5-0.9,0-0.9c-1.5,0.2-3,0.4-4.5,0.8c-0.4,0.1-0.7,0.2-1.1,0.3c-0.4,0.1-0.7,0.3-1.1,0.3c-0.7,0-1.2-0.9-1.6-1.3
                                c0,0,0,0-0.1-0.1c-0.1-0.9-0.2-1.7-0.2-2.5c0.3-0.7,0.6-1.3,1.1-1.9c0.2-0.3,0.5-0.6,0.8-0.9c0.2-0.2,0.6-0.4,0.8-0.8
                                c0.4-0.7-0.2-1.2-0.8-1.5c-0.6-0.2-1.1-0.6-1.3-1.2l0,0c0.6-1.2,1.3-2.4,1.9-3.6c1.4-2.6,2.6-5.3,3.3-8.2c0.7-3,0.6-6.2,0.8-9.3
                                c0.2-3.7,0.6-7.4,1.2-11.1c0.1-0.5-0.7-0.8-0.8-0.2c-0.5,3.3-0.9,6.6-1.1,9.9c-0.1,1.6-0.2,3.2-0.2,4.8s-0.1,3.2-0.4,4.8
                                c-1.2,6-4.9,11.1-7.5,16.5c-1.4,3-2.5,6.2-2.7,9.6c0,0.6,0.8,0.6,0.9,0c0.2-3.7,1.5-7.1,3.2-10.4c0.2,5.1,1.2,10.1,0.8,15.2
                                c-0.2,2.6-0.9,5-1.9,7.4c-1,2.2-2.3,4.3-3.5,6.3s-2.3,4-3.2,6.1c0-1.3,0.1-2.6,0.4-3.9c0.1-0.5-0.7-0.8-0.8-0.2
                                c-0.3,1.6-0.5,3.3-0.4,5c0,0.8,0.2,1.5,0.4,2.3c0.3,0.9,0.5,1.9,0.8,2.9v0.1v0.1c0.3,1,0.7,2,1,3c0.3,0.9,0.6,1.8,1,2.7
                                c0.8,1.6,2,3,3.2,4.4c1.4,1.5,2.9,3,4.3,4.5c0.4,0.4,1-0.2,0.6-0.6c-0.2-0.2-0.4-0.5-0.6-0.7c0.8-0.5,1.4-1,1.9-1.8l0.1-0.1
                                c0.1,0.3,0.1,0.6,0.1,1c0,0.5,0,1-0.1,1.4c-0.2,1-0.6,1.8-1.1,2.6c-1,1.7-2.2,3.4-3.3,5.1c-1.1,1.6-2.3,3.1-3.9,4.3
                                c-1.4,1-3,1.7-4.7,2.1c-3.8,1-7.7,0.9-11.5,0.8c-2.1,0-4.3,0-6.4,0.2c-2.1,0.3-4.1,0.8-6.2,1c-2.3,0.3-4.6,0.3-6.9,0.3
                                c-1.2,0-2.5,0.1-3.7,0.1c1.7-0.6,3.4-1.1,5.1-1.2c0.5,0,0.5-0.9,0-0.9c-2.7,0.2-5.3,1.2-7.8,2.1l0,0c-0.5,0.2-1.1,0.4-1.6,0.6
                                c-2.9,1.1-6,1.9-9.1,0.9c-1.5-0.5-2.6-1.4-3.6-2.6s-1.9-2.6-2.8-3.9s-1.6-2.6-2.3-3.9c-0.3-0.5-0.7-1-1.1-1.4
                                c-0.5-0.4-1.1-0.7-1.5-1.2c-0.8-0.9-1.1-2-1.2-3.1c0-0.3,0-0.7,0-1c0.4,0.2,0.8,0.2,1.1,0.3c0.2,0,0.4,0.1,0.6,0.1
                                c-0.4,0.2-0.7,0.5-1.1,0.7c-0.5,0.3,0,1,0.4,0.7c1.7-0.9,3.2-2.4,4.6-3.8c1.3-1.4,2.6-2.9,3.7-4.5c2.3-3.2,4.1-6.8,5.9-10.3
                                c1-2.1,2-4.2,1.6-6.6c-0.1-0.5-0.9-0.3-0.8,0.2c0.1,0.6,0.1,1.2,0,1.9c0-0.1-0.1-0.1-0.1-0.1c-0.8-0.4-1.6-0.6-2.4-0.6
                                c-0.4-0.6-0.8-1.1-1.4-1.6c-1-0.9-2.5-1.3-3.3-2.4c-0.4-0.6-0.6-1.3-0.9-1.9c-0.3-0.7-0.6-1.4-0.9-2c-0.3-0.7-0.7-1.4-1.1-2
                                s-0.9-1.2-1.3-1.8c-0.9-1.3-0.9-2.8-1.1-4.3c-0.1-1.3-0.3-2.6-0.6-3.9c-0.2-1.1-0.5-2.3-0.9-3.4c0,0,0,0,0-0.1
                                c-1.1-4.6,1.2-9.7-1-14c-0.7-1.3-1.5-2.5-2.1-3.9c-0.5-1.4-0.9-2.8-1.5-4.2c-0.5-1.3-1.3-2.4-2.1-3.6c-0.8-1.3-1.5-2.6-1.8-4.1
                                c-0.3-1.7-0.4-3.4-0.9-5s-1.1-3.2-2-4.7c-0.3-0.5-1-0.1-0.7,0.4c0.8,1.4,1.4,2.8,1.8,4.3c0.4,1.4,0.6,2.9,0.8,4.4
                                c0.2,1.5,0.7,2.8,1.4,4.1c0.7,1.2,1.6,2.3,2.2,3.6c0.7,1.3,1.1,2.7,1.6,4.1c0.5,1.4,1.1,2.6,1.9,3.9c0.6,1,1,1.9,1.3,3h-0.1
                                c-1.2,0.7-2,1.8-2.5,3.1c-0.1,0.3-0.2,0.6-0.3,1c-0.1-0.1-0.2-0.2-0.3-0.3c-1-1.1-2.1-2.3-2.5-3.8c-0.1-0.5-0.9-0.3-0.8,0.2
                                c0.3,1.3,1.1,2.3,1.9,3.3c0.9,1.1,1.8,2.1,2.6,3.3c1.5,2.3,2.4,4.9,3,7.6c0.3,1.4,0.5,2.8,0.7,4.2c0.2,1.3,0.2,2.8,0.6,4
                                c0.5,1.4,1.6,2.5,2.3,3.7c0.8,1.4,1.3,2.8,1.9,4.2c0.3,0.7,0.6,1.3,1.2,1.8c0.5,0.5,1.2,0.8,1.8,1.2c1.4,0.8,2.4,2,2.9,3.5
                                c0.2,0.5,1,0.3,0.8-0.2c-0.1-0.2-0.2-0.5-0.3-0.7c0,0,0,0,0.1,0c0,0,0.1,0,0.2,0l0,0l0,0c0.1,0,0.2,0,0.3,0.1
                                c0.2,0,0.4,0.1,0.5,0.1h0.1c0,0,0,0,0.1,0l0,0c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.3,0.1,0.5,0c-0.3,1.2-0.9,2.4-1.4,3.5
                                c-0.8,1.7-1.6,3.3-2.5,5c-1.8,3.3-3.9,6.4-6.4,9.2c-0.9,1-1.8,2-2.9,2.8l0,0c-0.7-0.1-1.4-0.2-2.1-0.4c-0.6-0.2-0.7-1-0.7-1.6
                                c-0.1-1.5,0.4-2.9,0.8-4.2c0.2-0.5-0.6-0.8-0.8-0.2c-0.4,1.3-0.9,2.7-0.9,4.1c-0.5-0.2-0.9-0.4-1.4-0.5
                                C462.4,265.2,461.5,265.3,461,264.9z M536.5,220.2c0-0.3,0-0.5-0.1-0.8c0.3,0.3,0.6,0.6,1,0.7c0.2,0.1,0.3,0.1,0.5,0.1
                                c-0.3,0.4-0.6,0.8-0.8,1.2c-0.2,0.3-0.3,0.6-0.5,0.9C536.6,221.6,536.5,220.9,536.5,220.2z M536,210.1c0.2,0.3,0.5,0.6,0.9,0.9
                                c0.2,0.2,0.5,0.2,0.8,0.4c0.1,0,0.2,0.1,0.3,0.2l0,0c0,0.1-0.1,0.1-0.2,0.2c-0.7,0.6-1.3,1.4-1.8,2.1c-0.1-1-0.1-2.1-0.1-3.1
                                c0-0.1,0-0.1,0-0.2C535.8,210.4,535.9,210.3,536,210.1z M464.3,216.3v-0.1c0-0.1,0-0.1,0-0.2v-0.1v-0.1c0.1-0.4,0.2-0.8,0.3-1.2
                                c0.4-1,1.1-1.9,2-2.5c0.1,1,0.1,2.1,0.1,3.1c-0.1,1.6-0.2,3.1-0.2,4.7c-0.2-0.5-0.4-0.9-0.7-1.4
                                C465.4,217.8,464.8,217,464.3,216.3z M481.6,357.7c-0.3,5.6-0.5,11.3-0.8,17c-0.1,2.8-0.3,5.7-0.7,8.5c-0.4,2.8-0.9,5.6-1.5,8.4
                                c-1.1,5.5-2.1,11-2.7,16.6c-0.2,2.1-0.2,4.9-1.9,6.4c-1.5,1.3-3.7,1.8-5.6,1.5c-1.4-0.3-2.8-0.6-4-1.3c-0.6-0.4-1.2-0.9-1.6-1.5
                                c-0.5-0.9-0.5-1.8-0.6-2.8c-0.6-11.4-1.6-22.8-2.2-34.2c-0.3-5.7-0.7-11.3-0.6-17s0.6-11.4,1.1-17.1c0.1-1.2,0.2-2.5,0.3-3.7
                                c5.8,3.5,16.7,6.5,21.2,6.3c0.9,0,1.7-0.1,2.3-0.2C482.2,348.5,481.8,353.2,481.6,357.7z M526,395.5c-1.1-5.5-2.3-11.1-2.8-16.7
                                c-0.5-5.7-0.6-11.4-0.9-17c-0.2-5-0.2-10.2-1.9-15c-0.3-0.8-0.6-1.6-1-2.4c2.4,0.8,5.7,1.5,10,1.6c0,0,9.1-0.7,14-2.4
                                c0.3,3.8,0.6,7.5,0.8,11.3c0.3,5.7,0.1,11.4-0.2,17.1c-0.6,11.4-1.5,22.8-2.2,34.2c-0.1,1.5-0.2,2.9-0.3,4.3c0,1-0.1,1.9-0.6,2.7
                                c-0.8,1.2-2.3,1.9-3.7,2.3c-1.5,0.5-3,0.8-4.6,0.3c-1-0.3-2.1-0.7-2.9-1.5c-0.8-0.7-1.1-1.8-1.3-2.8c-0.2-1.2-0.3-2.4-0.5-3.6
                                c-0.1-1.4-0.3-2.7-0.5-4.1C527,401.2,526.6,398.3,526,395.5z"/>
                        </g>
                        <path name="GERA" class="st1" d="M486.5,140.7c0,0,14.5,18.4,28.5,2.2c0,0-8.1,11.9-14.2,11.9C494.2,154.9,486.5,140.7,486.5,140.7z"/>
                        <path name="GERA" class="st3" d="M487.9,117.4c0.3,0.3,0.6,0.5,0.9,0.7s0.4,0.3,0.4,0.6c0,0.9,0,1.7,0.2,2.6c0.3,1,0.9,1.9,1.8,2.4
                            c1.4,0.9,3.2,0.9,4.8,0.9c1.7,0.1,3.4,0.2,4.7-1.1c0.8-0.8,1.6-2,2-3.1c0.2-0.6,0.4-1.4,0.4-2.1l0,0c1.5-0.6,2.9,0.5,2.9,0.5l0,0
                            c-0.1,0.6-0.1,1.3,0,1.8c0.1,1.2,0.6,2.5,1.2,3.5c1,1.5,2.7,1.8,4.4,2.1c1.6,0.3,3.3,0.7,4.9,0.1c1-0.3,1.8-1.1,2.2-2
                            c0.4-0.8,0.5-1.6,0.7-2.5c0.1-0.3,0.2-0.4,0.5-0.5c0.4-0.1,0.7-0.3,1-0.5c0.7-0.4,1.4-1,1.1-1.9c-0.3-0.8-1.5-1.4-2.3-1.8
                            c-1-0.5-2-0.9-3.1-1.2c-2.3-0.6-4.6-0.8-7-0.7c-1.2,0.1-2.6,0.3-3.2,1.4c0,0,0,0,0,0.1c-0.7-0.3-2-0.7-3.6-0.2c0-0.1,0-0.1,0-0.1
                            c-0.3-1.3-1.7-1.8-2.8-2.1c-2.3-0.6-4.6-0.9-6.9-0.8c-1.1,0.1-2.2,0.2-3.2,0.5c-0.8,0.2-2.1,0.5-2.6,1.2
                            C486.8,116.1,487.4,116.8,487.9,117.4z M507,117.6c0.4-1.2,1.6-1.3,2.8-1.4c2.7-0.1,5.7,0.2,8.3,1.2c1.5,0.7,0.7,4,0.6,4.2l0,0
                            l0,0l0,0c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.3-0.1,0.7-0.2,1c-0.2,0.8-0.6,1.5-1.3,2c-1,0.7-2.2,0.8-3.4,0.6c-0.9-0.1-1.9-0.3-2.8-0.5
                            c-0.8-0.2-1.6-0.3-2.3-0.8c-0.9-0.7-1.3-1.8-1.6-2.9C506.6,120.3,506.6,118.8,507,117.6z M491.5,114.5c2.8-0.4,5.7-0.1,8.3,0.6
                            c1.1,0.3,2.2,0.7,2.4,2c0.1,1.3-0.1,2.8-0.8,3.9c-0.5,1-1.2,2-2.2,2.5c-0.7,0.3-1.6,0.3-2.4,0.3c-0.9,0-1.9-0.1-2.8-0.2
                            c-1.1-0.1-2.4-0.4-3.2-1.3c-0.5-0.6-0.8-1.4-0.8-2.2c0-0.4,0-0.7,0-1.1c0-0.2,0-0.4,0-0.6l0,0l0,0l0,0
                            C489.9,118.3,489.8,114.8,491.5,114.5z"/>
                        <path name="GERA" class="st3" d="M488.1,114.1c0,0-2.3,1.4-1.3,2.6c0.4,0.5-5.9,0.2-5.9-0.5C480.8,114.8,486.4,114.2,488.1,114.1z"/>
                        <path name="GERA" class="st3" d="M495.7,300c4.5-0.9,9-1.8,13.5-0.8c0.5,0.1,0.8-0.7,0.2-0.8c-4.7-1-9.4-0.1-14,0.8
                            C494.9,299.3,495.2,300.1,495.7,300L495.7,300z"/>
                        <path name="GERA" class="st3" d="M500.6,286.5c0,2.5-0.2,4.9-0.9,7.3c-0.1,0.5,0.7,0.8,0.8,0.2c0.7-2.5,1-5,0.9-7.5
                            C501.4,285.9,500.6,285.9,500.6,286.5L500.6,286.5z"/>
                        <path name="GERA" class="st3" d="M509.7,285.3c-0.2,1.7-0.7,3.4-1.4,5c-0.1,0.2-0.1,0.5,0.1,0.6s0.5,0.1,0.6-0.1c0.8-1.7,1.3-3.5,1.5-5.4
                            c0-0.2-0.2-0.4-0.4-0.4C509.8,284.9,509.7,285.1,509.7,285.3L509.7,285.3z"/>
                        <path name="GERA" class="st3" d="M514.7,315.5c3.3,5.7,8.1,10.2,13.1,14.5c1.6,1.4,3.3,2.7,4.6,4.3c0.4,0.4,1-0.2,0.6-0.6
                            c-1.1-1.4-2.6-2.5-3.9-3.7c-1.2-1.1-2.5-2.1-3.7-3.2c-2.4-2.1-4.6-4.4-6.7-6.8c-1.2-1.5-2.4-3.2-3.3-4.9
                            C515.1,314.6,514.4,315,514.7,315.5L514.7,315.5z"/>
                        <path name="GERA" class="st3" d="M459.9,312.4c1,3.7,1.9,7.4,3.5,10.9c0.8,1.7,1.9,3.3,2.9,4.9c1.1,1.7,2.3,3.4,3.5,5.1c1.3,2,2.6,3.9,3.9,5.8
                            c0.3,0.5,1,0,0.7-0.4c-2.3-3.4-4.5-6.7-6.8-10.1c-1.1-1.6-2.2-3.2-3.1-4.9c-0.9-1.7-1.5-3.5-2.1-5.3c-0.6-2.1-1.2-4.2-1.8-6.2
                            C460.6,311.6,459.7,311.9,459.9,312.4L459.9,312.4z"/>
                        <path name="GERA" class="st0" d="M466.1,418.4c0.2,0.7,0.1,1.4,0.4,2.1s1,1,1.6,1.2c1.6,0.5,3.3,0.5,5,0.2c0.5-0.1,0.3-0.9-0.2-0.8
                            c-1.4,0.2-2.9,0.2-4.3-0.2c-0.5-0.1-1.1-0.4-1.3-0.9c-0.2-0.6-0.1-1.2-0.3-1.8C466.8,417.6,465.9,417.9,466.1,418.4L466.1,418.4z"
                            />
                        <path name="GERA" class="st0" d="M536.8,418.2c-0.2,0.6-0.1,1.2-0.3,1.8c-0.2,0.6-0.8,0.8-1.3,1c-1.4,0.4-2.9,0.5-4.3,0.2
                            c-0.5-0.1-0.8,0.7-0.2,0.8c1.6,0.3,3.4,0.2,5-0.2c0.6-0.2,1.3-0.5,1.6-1.2s0.2-1.4,0.4-2.1C537.8,417.9,537,417.6,536.8,418.2
                            L536.8,418.2z"/>
                    </g>
                </g>
                <g id="IRENE" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="IRENE" class="st0" d="M380.1,217.7c-1.8,1.5-4.2,2.2-6.4,3.1c-2.5,1-5.1,2-7.7,2.7c-1.2,0.3-2.4,0.6-3.6,0.8c-1,0.2-2,0.3-3,0.3
                                c-1.8-0.1-3.4-1-3.6-3c-0.1-1.1,0.2-2.3,0.3-3.4c0.1-1.3,0.2-2.5,0.2-3.8c0-2.6-0.3-5.1-0.7-7.6c-0.4-2.3-0.7-4.6-0.6-7
                                s0.3-4.7,0.4-7.1c0-2.3-0.1-4.7-0.9-6.9c-0.3-0.9-0.8-1.7-1.2-2.6c-0.2,3.6-0.5,7.1-1.5,10.6c-0.8,2.9-1.8,5.8-2.4,8.8
                                s-0.7,6.1-1.2,9.1c-1,5.3-3.1,10.4-6,15c-2.4,3.9-5.4,7.4-8.6,10.5c6.5-3,12.7-6.8,18.6-10.9c0.5-0.3,0.9,0.4,0.4,0.7
                                c-6.4,4.5-13.2,8.6-20.4,11.8c-0.1,0.1-0.2,0-0.3,0c-2.9,2.6-6,5.1-9.2,7.3c0,0.1,0,0.1,0,0.2c-0.8,1.3-1.7,2.6-2.7,3.8
                                c-0.5,0.6-1,1.2-1.5,1.8c0.2-0.1,0.4-0.1,0.6-0.2c2-0.7,3.9-1.4,5.9-1.9c0.5-0.1,0.8,0.7,0.2,0.8c-1.4,0.4-2.8,0.8-4.1,1.3
                                c-0.6,0.2-1.3,0.4-1.9,0.7c-0.6,0.2-1.3,0.4-1.8,0.7s-1.1,0.8-0.4,1.2c0.5,0.4,1.1,0.6,1.7,0.9c1.2,0.6,2.3,1.2,3.5,1.8
                                c2.2,1,4.4,1.9,6.7,2.5c2.4,0.6,4.8,1,7.2,1.4c2.5,0.4,5,0.8,7.5,1.1c2.5,0.2,5,0.3,7.5,0.3c5.1,0,10.3,0.1,15.4,0.1
                                c2.5,0,5.1,0,7.6,0.1c2.1,0,4.3,0.1,6.2-0.9c-0.1-0.1-0.2-0.2-0.3-0.4c-0.2-0.3-0.4-0.6-0.6-1c-0.4-0.7-0.8-1.4-1.2-2
                                c-0.7-1.3-1.4-2.7-1.8-4.2c-0.8-2.8-0.8-5.9-0.6-8.8s0.6-5.8,1.6-8.6c0.9-2.7,2.1-5.3,2.8-8.1c0.6-2.5,1.1-5.1,2.7-7.2
                                c-0.6-0.8-1.2-1.6-1.8-2.4C380.5,218.5,380.3,218.1,380.1,217.7z M375.8,231.7c-0.6,1.9-1.4,3.7-2.3,5.4
                                c-1.7,3.1-3.7,5.9-5.2,9.1c-0.8,1.5-1.3,3.1-2,4.7c-0.6,1.4-1.3,2.9-2.6,3.9c-0.4,0.4-1-0.2-0.6-0.6c1.4-1.2,2.1-2.9,2.8-4.6
                                c0.6-1.5,1.2-3,1.9-4.5c0.8-1.5,1.7-3,2.6-4.5c1.8-2.9,3.6-5.8,4.6-9.1C375.2,230.9,376,231.2,375.8,231.7z"/>
                            <path name="IRENE" class="st0" d="M371.2,181.9c-0.9,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7S372.1,181.9,371.2,181.9z"/>
                            <path name="IRENE" class="st0" d="M214.7,70.8c-1.2-0.5-2.3-1-3.5-1.4c-0.8-0.3-1.8-0.6-2.7-0.4c-0.4,0.1-0.8,0.4-1.2,0.6
                                c1.2,0.5,2.5,0.9,3.8,1.1C212.4,70.8,213.5,70.8,214.7,70.8z"/>
                            <path name="IRENE" class="st0" d="M380.7,199.1c-0.9,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7S381.6,199.1,380.7,199.1z"/>
                            <path name="IRENE" class="st0" d="M364.8,160.9c-4.5,2.7-10.3,3.2-15.2,1.5c0.1,0.2,0.2,0.4,0.3,0.6c0.6,1.3,1.4,2.6,2,3.9
                                c0.5,1.1,0.9,2.1,1.1,3.2c1.7-0.7,3.5-0.6,5.2-0.5c2.1,0,4.1-0.4,6-1.4l0,0c-0.4-1.4-0.8-2.9-0.4-4.4
                                C364,162.8,364.4,161.9,364.8,160.9z M360,166.4c-0.8,0.8-1.8,1.3-2.9,1.6c-0.2,0.1-0.5-0.1-0.5-0.3c-0.1-0.2,0.1-0.5,0.3-0.5
                                c0.5-0.1,0.9-0.3,1.3-0.5s0.8-0.5,1.2-0.8c0.2-0.2,0.4-0.2,0.6,0C360.1,165.9,360.1,166.2,360,166.4z"/>
                            <path name="IRENE" class="st0" d="M371.6,263.5c-0.9,0-1.8,0-2.6,0c0,0.5-0.2,1.4-0.3,2.4l0,0c1.1-0.1,2.1-0.2,3.2-0.3
                                C371.8,264.6,371.7,263.8,371.6,263.5z"/>
                            <path name="IRENE" class="st0" d="M371.6,266.5c-1,0.1-2,0.2-3,0.3c-0.4,2.4-0.8,5.1-1,6.2c1.3-0.2,2.6-0.4,3.9-0.6c0.6-1.9,0.7-4.1,0.5-5.9
                                C371.9,266.5,371.7,266.5,371.6,266.5z"/>
                            <path name="IRENE" class="st0" d="M350.6,263.3c0.9,0.4,1.7,0.9,2.6,1.2c1-0.4,2-0.7,3.1-0.8c1.9-0.1,3.8,0.2,5.6,0.6c0.6,0.1,1.1,0.2,1.3,0.9
                                c0.1,0.2,0.1,0.5,0.1,0.7c0.6,0.1,1.2,0.1,1.8,0.1c0-1.1,0-2.1,0-2.6c-0.7,0-1.3,0-2,0C358.9,263.4,354.7,263.3,350.6,263.3z"/>
                            <path name="IRENE" class="st0" d="M361.9,267.1c-0.6,0.1-1.2,0-1.8,0c-0.6,0-1.3-0.1-1.9-0.1c-0.7,0-1.5-0.1-2.2-0.2c-0.5,0-1.4-0.2-1.7,0.3
                                c-0.3,0.5,0.8,0.5,1.1,0.6c0.4,0.2,0.6,0.5,0.8,0.9c0.3,0.9-0.2,1.7-1,2.1c-0.4,0.2-0.9,0.2-1.3,0.2c-0.3,0-0.8-0.1-0.5,0.4
                                c0.1,0.2,0.4,0.3,0.6,0.4c1,0.4,2,0.5,3,0.5c0.9,0.1,1.7,0,2.6,0.2c0.6,0.1,1,0.7,1,1.2c1.1,0,2.3-0.1,3.4-0.1
                                c0.6-1.5,0.8-4.2,0.9-6.5c-0.7,0-1.4-0.1-2.1-0.2C362.6,266.9,362.3,267.1,361.9,267.1z"/>
                            <path name="IRENE" class="st0" d="M385,214.6c-0.9,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7s1.7-0.8,1.7-1.7S385.9,214.6,385,214.6z"/>
                            <path name="IRENE" class="st0" d="M375.4,266c0.1,1.9,0.1,4.2,0.1,5.4c0.2,0,0.4-0.1,0.6-0.1c3-0.9,6-1.9,8.8-3.3c1.2-0.6,2.9-1.5,2.7-3.1
                                c-0.1-0.6-0.2-1.4-0.9-1.6c-0.4-0.1-0.7,0-1.1,0.1c-0.6,0.1-1.1,0.3-1.7,0.5c-1,0.4-2,0.8-3.1,1.1c-1,0.3-2,0.5-3,0.7
                                C377,265.8,376.2,265.9,375.4,266z"/>
                            <path name="IRENE" class="st0" d="M379.1,264.5v-0.1l0,0c0-0.2-0.1-0.3-0.1-0.5v-0.1c0.1,0.1,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2s-0.1-0.1-0.1-0.2
                                l0,0l0,0c-0.1,0-0.1,0-0.2,0c-0.9,0.2-1.7,0.2-2.6,0.2c-0.2,0-0.4,0-0.6,0c0.1,0.4,0.2,1,0.3,1.7
                                C376.6,265,377.8,264.8,379.1,264.5L379.1,264.5z"/>
                            <path name="IRENE" class="st0" d="M332.7,264.1c0.3-0.8,0.8-2,1-2.5c-1.9-0.3-3.7-0.7-5.6-1.2L332.7,264.1z"/>
                            <path name="IRENE" class="st0" d="M440.1,83.5c-2.2-1.5-4.4-3-6.6-4.5c-0.5-0.3-1-0.7-1.5-1c-0.6-0.3-1.1-0.2-1.7,0c-1.1,0.4-2.1,1.1-3,1.9
                                c-0.9,0.7-1.7,1.5-2.5,2.3s-1.6,1.6-2.3,2.6c-1.1,1.5-1.8,3.8-0.3,5.2c0.4-0.6,0.7-1.3,1.1-1.9c0,0,0,0,0-0.1
                                c-0.2-0.1-0.4-0.1-0.6-0.2c-0.5-0.1-0.3-0.9,0.2-0.8c1.7,0.4,3.1,1.4,4.5,2.4c1.6,1.2,3.1,2.4,4.7,3.6s3.1,2.4,4.7,3.7
                                c1.3,1,3.1,2.5,4.9,1.7c0.5-0.2,0.9,0.5,0.4,0.7c-0.8,0.4-1.6,0.4-2.3,0.2c-0.3,0.8-0.6,1.6-0.9,2.4c1.5,0.1,3.2,0.2,4.5-0.7
                                c0.7-0.4,1.2-1.2,1.6-1.9c0.4-0.9,0.8-1.7,1.2-2.6c0.7-1.7,1.4-3.5,1.9-5.3c0.2-0.8,0.5-1.6,0.3-2.4c-0.1-0.7-0.6-1.2-1.3-1.5
                                c-0.5-0.2-1.1-0.4-1.6-0.6c0.3,0.5,0.5,1,0.7,1.5c0.1,0.5-0.7,0.8-0.8,0.2C444.7,86.1,442,84.8,440.1,83.5z"/>
                            <path name="IRENE" class="st0" d="M256.8,96.2c0.9-1.1,1.7-2.3,2.7-3.3c0.1-0.1,0.2-0.2,0.4-0.3c-2.7-2.3-5.5-4.5-8.4-6.6
                                c-2.5-1.8-4.9-3.7-7.3-5.6c-2.4-2.1-4.5-4.4-6.7-6.7c-2.1-2.3-4.5-4.3-7.3-5.7c-0.7-0.4-1.4-0.7-2.1-1c-0.6-0.2-1.2-0.5-1.9-0.5
                                c-1.8,0-1.3,1.9-0.6,2.9c1.5-0.1,2.9,0,4.4,0.4c0.5,0.1,0.3,0.9-0.2,0.8c-3.4-0.8-6.8-0.2-10.2,0.4c-3.3,0.6-6.7,1-10,0.2
                                c-1.1-0.3-2.2-0.7-3.2-1.1c-0.1,0-0.1,0-0.2-0.1c-0.3-0.2-0.7-0.3-1-0.4c-0.8-0.3-1.5-0.7-2.3-1c-0.6-0.2-1.4-0.7-2.1-0.3
                                c-0.5,0.3-0.6,1-0.5,1.6c0.2,0.7,0.7,1.1,1.3,1.5c1.3,0.9,2.8,1.5,4.2,2.3c2.9,1.6,5.9,3.4,9.1,4.2c3.3,0.8,6.6,0.3,9.9,0.9
                                c5.6,0.9,10,4.9,13.8,8.8c4.8,4.9,9.5,9.8,14.2,14.7C254,100,255.3,98.1,256.8,96.2z"/>
                            <path name="IRENE" class="st0" d="M488.3,52.8c-0.6-0.1-1.5-0.3-2.1-0.2l0,0c-2.9,0.5-5.8,0.2-8.6-0.2c-0.5-0.1-0.3-0.9,0.2-0.8
                                c3.1,0.5,6.5,0.9,9.6-0.1c0.4-0.1,0.9-0.2,1.3-0.5l0,0l0,0c0,0,0,0,0.1-0.1c-0.1,0.1,0-0.2,0,0c0-0.2,0-0.2-0.1-0.3
                                c-0.2-0.2-0.5-0.3-0.8-0.4c-0.5-0.2-1.1-0.2-1.6-0.2c-0.7,0-1.3-0.1-2-0.2c-1.4-0.3-2.9-0.6-4.3-0.8c-2.4-0.5-4.9-1-7.3-1.5
                                c-2.8-0.6-5.5-1.3-8.3-1.9c-1.5-0.3-3-0.5-4.5-0.1c-1.4,0.4-2.5,1.5-3.5,2.6c-2.1,2.4-4,4.9-5.9,7.4c-2,2.5-4,5-6,7.6
                                c-3.9,4.9-7.8,9.8-11.6,14.6c0.4,0.2,0.8,0.6,1.2,0.8c2.5,1.7,5,3.4,7.4,5.1c0.6,0.4,1.2,0.8,1.8,1.3c2.8-4.9,5.6-9.8,8.7-14.5
                                c2.7-4.2,5.6-8.4,8.9-12.2c0.7-0.8,1.5-1.6,2.5-2.1c1.1-0.5,2.3-0.6,3.4-0.7c2-0.2,4.1-0.3,6.1-0.3s3.9,0,5.9-0.1
                                c1.7,0,3.5,0,5.2-0.2c0.9-0.1,1.7-0.2,2.5-0.2c0.7,0,1.4-0.1,2.1-0.3c0.4-0.1,0.5-0.2,0.3-0.6C488.8,53,488.6,52.9,488.3,52.8z"
                                />
                            <path name="IRENE" class="st0" d="M437.4,98.3c-1.7-1.1-3.2-2.5-4.8-3.7c-1.7-1.3-3.4-2.6-5-3.9c-1.1-0.9-2.2-1.8-3.5-2.4c0,0.1,0,0.1,0,0.2
                                c-2,3.8-4.5,7.1-7,10.5c-2.3,3.2-4.7,6.3-7,9.4c-2.2,2.9-4.3,5.9-6.8,8.7c-1.1,1.2-2.2,2.4-3,3.9s-1,3.1-1,4.7
                                c0,3.1,0.6,6.4-0.9,9.4c-0.2,0.5-1,0.1-0.7-0.4c1-2.1,1-4.4,0.9-6.7c-0.2,0.3-0.4,0.6-0.5,0.9c-0.2,0.4-0.5,0.8-0.9,1
                                c-0.6,0.5-1.4,0.9-1.9,1.6c-0.8,1.4-0.2,3.2,0.1,4.7c0.3,1.4,0.5,3-0.4,4.3c-0.3,0.4-1,0-0.7-0.4c0.9-1.3,0.5-2.9,0.2-4.4
                                c-1.5,2.5-3.1,4.9-5.6,6.4c-0.7,0.4-1.4,0.9-2,1.5c-0.5,0.6-0.9,1.4-1.2,2.1c-0.6,1.6-1.2,3.3-1.8,5c-0.2,0.5-1,0.3-0.8-0.2
                                c0.6-1.6,1.1-3.3,1.8-4.9c-0.6,0.3-1.4,0.5-2.1,0.6c-1.4,0.1-2.8,0-4.2,0.3c-1.2,0.2-2.4,0.6-3.2,1.6c-0.7,0.8-1.2,1.8-2,2.5
                                c0.6,0.7,1.2,1.4,1.8,2.1c1.3,1.6,2.6,3.1,3.9,4.6c1,1.2,2.1,2.5,1.8,4.2c0,0.2-0.2,0.3-0.4,0.3c-2.6,0.1-5.3,0.2-8,0.6
                                c-2.2,0.3-4.3,1-5.7,2.8c-0.3,0.4-0.9-0.2-0.6-0.6c1.4-1.8,3.7-2.6,6-3c2.6-0.4,5.3-0.5,7.9-0.6c0.1-1.5-1.4-2.9-2.3-3.9
                                c-1.1-1.3-2.2-2.7-3.4-4c-1.1-1.3-2.2-2.7-3.4-4c-0.8-0.9-1.6-2.1-2.8-2.4c0.4,0.6,0.7,1.3,0.8,2.1c-0.2-0.1-0.4-0.2-0.6-0.4
                                c0,1.2,0,2.5-0.2,3.8c-0.3,2.6-0.8,5.1-1.7,7.5c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.2-0.1,0.3c-0.4,1-0.8,1.9-1.1,2.9
                                c-0.4,1.2-0.5,2.4-0.2,3.6c0.5,2.3,1.7,4.4,1.5,6.8c-0.2,2.5-1.4,4.8-1.4,7.3c0,5,2.9,9.5,5.8,13.3c2.8,3.7,5.9,7.4,7.4,11.9
                                c0.8,2.3,1,4.7,1.5,7.1c0.2,1.2,0.5,2.4,1,3.6c0.5,1.1,1.2,2.1,1.9,3c1.5,1.9,3.1,3.7,4.2,5.9c1.3,2.4,2.4,4.8,3.6,7.1
                                s2.5,4.6,3.9,6.9c0.6,1.1,1.2,2.2,2,3.2c0.8,0.9,1.8,1.5,3,1.3c2.2-0.4,4.1-2.2,5.7-3.6c1.7-1.5,3.4-3.1,4.8-4.9
                                c1.4-1.7,2.6-3.6,3.4-5.6c0.9-2.2,1.1-4.6,0.8-7c-0.2-1.4-0.5-2.7-0.8-4c-0.3-1.2-0.6-2.4-1-3.6c-0.8-2.1-2.5-3.5-4.5-4.7
                                c1.3,3.8,2.3,7.7,3.3,11.6c0.1,0.5-0.7,0.8-0.8,0.2c-0.8-3-1.5-6.1-2.5-9.1c-0.8-2.7-1.8-5.4-3-7.9c-1.1-2.5-2.7-4.8-3.6-7.4
                                c-1.6-4.9-0.7-10.5-2.9-15.3c-0.2-0.5,0.5-0.9,0.7-0.4c1.2,2.7,1.5,5.6,1.8,8.5c0,0.1,0,0.2,0,0.3c0.5-2.9,1-5.9,1.4-8.9
                                c0.2-1.6,0.3-3.3,0-4.9c-0.3-1.5-0.9-2.9-1.4-4.3c-1.1-3-1.9-6.1-2.5-9.2c-0.3-1.4-0.4-2.9-0.8-4.3c-0.4-1.2-1.1-2.1-2-2.9
                                c-1.6-1.4-3.6-2.6-4.5-4.7c-0.2-0.5,0.6-0.7,0.8-0.2c1,2.4,3.6,3.4,5.2,5.3c0.9,1,1.3,2.2,1.5,3.5c0.3,1.6,0.5,3.1,0.8,4.6
                                c0.3,1.6,0.8,3.1,1.2,4.6c0.3-1.6,1.1-3.1,1.9-4.5c1.2-1.9,2.4-3.6,3.4-5.6c1.9-3.7,3.2-7.6,4.8-11.5c0.7-1.8,1.6-3.6,2.8-5.2
                                c1.1-1.5,2.3-3,3.5-4.4c1.2-1.5,2.4-3.1,3.2-4.9c0.9-1.7,1.5-3.6,1.9-5.5c0.8-3.9,0.8-7.9,0.8-11.8c0-0.6,0.9-0.6,0.9,0
                                c0,2.8,0,5.7-0.3,8.6c1.6-1.5,3.2-3,4.9-4.5c1.5-1.4,3-2.6,3.9-4.5c0.9-1.8,1.5-3.7,2.2-5.6c1.5-3.8,3-7.7,4.5-11.5
                                C438.4,98.9,437.9,98.6,437.4,98.3z M371.2,186.1c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5
                                S372.6,186.1,371.2,186.1z M380.7,203.3c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S382.1,203.3,380.7,203.3z
                                M385,218.8c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S386.4,218.8,385,218.8z M394.4,232.1
                                c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5C396.9,230.9,395.8,232.1,394.4,232.1z M402.7,238.1
                                c1.5-1.1,2.8-2.6,4.2-4.8c0.1-0.2,0.4-0.2,0.6-0.1c0.2,0.1,0.2,0.4,0.1,0.6c-1.4,2.2-2.8,3.8-4.4,5c-1.9,1.5-4.2,2.3-6.3,2.3
                                c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4l0,0C398.9,240.2,400.9,239.5,402.7,238.1z M395.6,165.2c1.7,2.1,2.2,4.8,2.2,7.5
                                c0,1.3,0,2.5-0.1,3.8s-0.2,2.7-0.6,4c-0.7,2.3-2.3,4.2-4.4,5.4c-1.1,0.6-2.4,1.1-3.6,1s-2.1-0.8-2.8-1.7c-0.8-1-1.5-2.2-2.3-3.3
                                c-0.6-0.9-1.5-2.5-2.8-2.3c-0.5,0.1-0.8-0.7-0.2-0.8c1.2-0.2,2.1,0.5,2.9,1.4c0.9,1.1,1.6,2.3,2.4,3.5c0.6,0.9,1.4,2,2.5,2.3
                                c1.2,0.3,2.5-0.2,3.6-0.9c2.1-1.2,3.5-3.1,4.1-5.5c0.3-1.3,0.4-2.6,0.4-3.9c0-1.2,0.1-2.4,0-3.5c-0.1-2.2-0.5-4.6-2-6.4
                                C394.6,165.4,395.2,164.8,395.6,165.2z M391.8,166.6c0.8-1.1,1.8-2.4,2.1-3.8c0.1-0.7,0-1.4-0.6-1.8c-0.6-0.4-1.3-0.4-1.9-0.4
                                c-0.5,0-0.5-0.9,0-0.9c0.8,0,1.7,0.1,2.4,0.5c0.6,0.4,1,1.1,1,1.9c0.1,1.8-1.3,3.5-2.3,4.9c-1.1,1.5-2.3,2.9-3.6,4.2
                                s-2.6,2.7-4,3.9c-1.3,1.2-2.8,2.2-4.5,2.6c-1.5,0.4-3.4,0.4-4.7-0.5c-0.7-0.5-0.7-1.1-0.4-1.8c0.3-0.8,0.6-1.6,1-2.3
                                c0.7-1.5,1.5-3,2.5-4.4c1.9-2.8,4.2-5.3,6.9-7.3c0.4-0.3,0.9,0.4,0.4,0.7c-2.6,2-5,4.5-6.8,7.3c-0.8,1.2-1.5,2.4-2.1,3.7
                                c-0.3,0.7-0.6,1.4-0.9,2.1c-0.1,0.3-0.5,0.9-0.2,1.2c0.2,0.2,0.7,0.4,1,0.4c3.1,1,6.1-1.2,8.2-3.2
                                C387.6,171.5,389.9,169.1,391.8,166.6z M397.7,153.8c-1-1.8-2-3.5-3-5.2c-0.5-0.9-1-1.8-1.5-2.7c-0.2-0.4-0.5-0.8-0.7-1.2
                                c-0.2-0.3-0.4-0.7-0.8-0.6c-0.5,0.1-0.5-0.8,0-0.9c0.8-0.1,1.3,0.7,1.7,1.3c0.5,1,1.1,1.9,1.7,2.9c1.1,2,2.2,3.9,3.4,5.9
                                C398.7,153.8,398,154.2,397.7,153.8z"/>
                            <path name="IRENE" class="st0" d="M343.7,222.2c1.2-2.4,2.1-4.8,2.8-7.4c0.8-2.8,1.1-5.6,1.4-8.4c0.3-3,1-5.8,1.8-8.7c0.9-2.9,1.7-5.7,2.1-8.6
                                c0.5-3,0.6-6.1,0.6-9.2c0-1.5,0-3,0-4.5s0-3-0.3-4.5c-0.5-2.9-2.1-5.3-3.3-7.9c-0.2-0.4-0.3-0.7-0.5-1.1
                                c-0.1-0.1-0.1-0.2-0.1-0.3c-0.9-2.1-1.5-4.2-1.8-6.4l0,0c-1.5,0.6-3.5,1-5.2,0.6c0,0.1,0,0.1-0.1,0.2c-1.1,1.3-2,2.9-2.6,4.5
                                c-0.5,1.4-0.8,2.9-0.9,4.4c0,0.8,0,1.5,0.1,2.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1l0.2-0.1c0.1-0.1,0.3-0.1,0.4-0.2
                                c0.3-0.1,0.7-0.3,1-0.4c3-0.9,6.2-0.9,9.2,0.1c0.5,0.2,0.3,1-0.2,0.8c-1.9-0.6-3.9-0.9-5.9-0.6c-0.9,0.1-1.8,0.3-2.7,0.5
                                c-0.4,0.1-0.8,0.3-1.2,0.4c-0.3,0.1-0.7,0.4-1.1,0.4c-0.8-0.1-0.7-1.1-0.8-1.6c-0.1-0.8-0.1-1.7,0-2.5c0.3-3.2,1.5-6.2,3.5-8.7
                                c-0.1,0-0.2-0.1-0.2-0.1c-0.9-0.5-1.5-1.6-2.2-2.4c-1.3-1.7-2.7-3.4-4-5.1h-0.1c-1.1-0.1-2.1-0.3-3.2-0.4c-1-0.1-2.1-0.2-3.2-0.4
                                s-2-1-2.9-1.6c-0.9-0.7-1.9-1.4-2.8-2c-2-1.3-4.1-2.4-6.2-3.3c-1-0.4-2-0.8-2.9-1.4c-0.8-0.5-1.4-1.1-1.9-1.8
                                c-1-1.2-1.8-2.9-3.5-3.3c0.2,0.7,0.3,1.4,0.3,2.2c0,0.6-0.8,0.6-0.9,0c-0.1-1.9-0.8-3.7-1.7-5.4c-0.9-1.6-1.8-3.1-2.9-4.5
                                c-1-1.4-2.2-2.3-3.8-2.9c-1.4-0.6-2.8-1.1-4-2.1c-1.1-0.9-2.1-2.1-3-3.2c-1-1.2-2.1-2.3-3.3-3.4c-1.3-1.1-2.7-2-4-3.1
                                c-1.2-1-2.3-2.1-3.4-3.2c-2.1-2.2-4.2-4.4-6.9-5.8c-0.4,0.7-1,1.3-1.4,1.9c-0.9,1.2-1.9,2.4-2.8,3.6c-1.8,2.4-3.7,4.8-5.5,7.2
                                c-0.2,0.2-0.3,0.4-0.5,0.6c1,1.8,2.2,3.4,3.4,4.9c2.3,2.8,4.9,5.5,7.6,8c1.4,1.3,2.8,2.4,4.4,3.4c1.4,0.9,2.9,1.8,4.1,3
                                c0.4,0.4-0.2,1-0.6,0.6c-0.8-0.8-1.7-1.4-2.6-2.1c0.5,0.9,0.8,1.9,1.4,2.8c0.9,1.4,2.1,2.7,3.1,4c1.1,1.4,2.3,2.7,3.4,4.1
                                c1,1.2,2,2.4,3.5,3.1c1.3,0.6,2.6,0.9,3.7,1.9c0.4,0.4-0.2,1-0.6,0.6c-1.1-1-2.6-1.3-3.9-1.9c1,1.4,2.3,2.6,3.8,3.5
                                c1.6,1.1,3.2,2,4.7,3.2c1.9,1.4,3.5,3.1,5.1,4.8c3.5,3.5,6.9,7,10.4,10.5c0.1-1.9,0.2-3.7,0.3-5.6c0-2.1,0-4.2,0-6.3
                                c0-1,0-1.9-0.2-2.9c-0.1-0.7-0.3-1.5-1-1.8c-0.5-0.2-0.1-1,0.4-0.7c1.7,0.8,1.5,3.3,1.6,4.8c0.1,2.4,0.1,4.9,0.1,7.3
                                c-0.1,2.1-0.2,4.3-0.4,6.4c-0.2,2.1-0.5,4.3-0.6,6.4c-0.1,2.2,0.1,4.3,0.4,6.4c0.3,2,0.4,3.9,0.1,5.9c-0.3,2.1-0.9,4.1-1,6.2
                                c-0.1,2.1,0,4.2,0,6.3c0,3.8-0.3,7.7-0.8,11.5c2.8-4,5.1-8.5,5.8-13.4c0.1-0.5,0.9-0.5,0.9,0c-0.2,1.3-0.4,2.6-0.8,3.9
                                c-1.6,5.2-4.7,9.8-8,14c-1.8,2.3-3.7,4.5-5.4,6.9c-0.9,1.1-1.7,2.3-2.5,3.5s-1.5,2.4-1.9,3.7s-0.6,2.7-0.2,4s1.1,2.4,1.8,3.5
                                c1.7,2.4,3.7,4.8,5.5,7.1c0.8,1,1.5,2.2,2.6,2.9c1.2,0.9,2.6,0.7,3.9,0.1c1.2-0.6,2.4-1.4,3.6-2.1c1.2-0.7,2.3-1.5,3.4-2.3
                                C330.6,239.5,338.8,231.9,343.7,222.2z M313.2,246.6c-0.9-1.2-1.6-2.5-2.4-3.8c-0.8-1.2-1.8-2.2-2.7-3.3c-0.9-1-1.6-2.1-2.1-3.4
                                c-0.5-1.3-1-2.7-1.8-3.8c-0.3-0.4,0.4-0.9,0.7-0.4c0.8,1.1,1.3,2.4,1.8,3.6c0.5,1.3,1.2,2.5,2.2,3.6c0.9,1.1,1.9,2.1,2.7,3.2
                                c0.8,1.2,1.5,2.6,2.4,3.8C314.2,246.4,313.6,247.1,313.2,246.6z M338.6,172.6c0.4,0.4-0.2,1-0.6,0.6c-2.6-2.3-5.3-4.6-8.1-6.6
                                c-0.6-0.5-1.3-1-2-1.4c-0.6-0.4-1.2-0.7-1.7-0.1c-0.9,1-1.2,2.5-1.4,3.8c-0.3,2.7,0.3,5.6,0.9,8.2c0.2,0.7,0.4,1.4,0.8,2
                                s0.9,1,1.5,1.4c1.3,0.9,2.7,1.8,4.1,2.6c0.6,0.4,1.2,0.9,1.9,1.1c0.7,0.2,1.4,0.1,2.1-0.2c1.3-0.7,2.1-2,2.9-3.2
                                c0.3-0.5,1,0,0.7,0.4c-0.9,1.5-1.9,3-3.6,3.7c-0.8,0.3-1.6,0.4-2.4,0.1c-0.9-0.3-1.6-0.9-2.4-1.3c-1.5-0.9-3.1-1.8-4.4-2.9
                                c-1.2-1-1.8-2.4-2.1-3.9c-0.7-3-1.3-6.3-0.6-9.4c0.3-1.2,0.9-3,2.1-3.5c0.7-0.3,1.3,0,1.9,0.4c0.7,0.5,1.4,1,2.2,1.6
                                C333.2,168,336,170.3,338.6,172.6z M322.9,187.8c1,2.1,2.1,4.4,4.3,5.5c1,0.5,2.1,0.8,3.1,0.9c0.6,0.1,1.2,0.2,1.8,0.2
                                c0.5,0.1,1.3,0.2,1.6-0.3c0.3-0.5,0.4-1.3,0.6-1.9c0.2-0.6,0.4-1.2,0.6-1.8c0.4-1.3,0.8-2.7,1.2-4c0.2-0.5,1-0.3,0.8,0.2
                                c-0.5,1.7-1,3.3-1.6,5c-0.3,0.8-0.5,1.7-0.8,2.5c-0.2,0.5-0.5,1-1.1,1.1s-1.3,0-2-0.1c-0.5-0.1-1.1-0.1-1.6-0.2
                                c-1.2-0.2-2.4-0.6-3.5-1.2c-2.3-1.3-3.5-3.9-4.5-6.2c-2-4.9-2.3-10.7,1.2-15c0.4-0.4,0.9,0.2,0.6,0.6c-1.7,2-2.5,4.7-2.5,7.3
                                C321.2,183.1,321.9,185.6,322.9,187.8z M318.3,164.5c-0.4,0.3-1-0.3-0.6-0.6c2.6-2.1,5.3-4.2,7.7-6.5c1.2-1.1,2.2-2.2,3.3-3.4
                                c1-1.1,2-2.1,3.3-2.8c0.5-0.3,0.9,0.5,0.4,0.7c-1.3,0.7-2.4,1.8-3.4,2.9c-1,1.1-2.1,2.2-3.2,3.3
                                C323.4,160.4,320.8,162.4,318.3,164.5z M318.1,245.5c-0.4,0.4-1-0.2-0.6-0.6c2.7-2.4,5.4-4.8,8.1-7.2
                                c8.2-7.4,18.5-15.8,18.6-27.9c0-0.6,0.9-0.6,0.9,0c0,3.1-0.8,6.2-2.1,9.1c-2.4,5.4-6.4,9.8-10.6,13.8
                                C327.7,237,322.9,241.2,318.1,245.5z"/>
                            <path name="IRENE" class="st0" d="M270.3,105.2c0.8-1.1,1.7-2.1,2.4-3.2c0.6-0.9,1.4-2.1,0.7-3.2c-0.3-0.5-0.8-0.8-1.3-1
                                c-0.5-0.3-1.1-0.6-1.6-0.9c-1.1-0.7-2.3-1.3-3.5-2c-1.9-1.1-4.8-3.3-6.9-1.4c-0.9,0.8-1.6,1.8-2.3,2.8c-0.8,1-1.6,2.1-2.3,3.1
                                c-0.8,1-1.5,2.1-2.2,3.1c-0.7,1.1-1.4,2.1-2.1,3.2c-0.6,1.1-1,2.4-0.4,3.7c0.4,0.9,1.2,1.5,1.9,2.2c2.2,1.8,4.7,3.4,7.1,4.9
                                c0.3,0.1,0.8-0.1,1-0.2c0.5-0.2,0.9-0.6,1.4-0.9c1-0.8,1.7-1.9,2.5-3C266.6,109.9,268.5,107.6,270.3,105.2z"/>
                            <path name="IRENE" class="st0" d="M365.1,174.6c0.3-1.3,0.2-2.6-0.1-3.8c-0.2-0.6-0.4-1.2-0.5-1.7c-1.7,0.9-3.6,1.4-5.6,1.5
                                c-1.9,0-4-0.3-5.8,0.5c0.2,1.5,0.2,2.9,0.2,4.4c0,2,0,3.9,0,5.9l0,0c0.6,1.6,1.6,3.1,2.1,4.7c0.7,2.3,0.8,4.8,0.7,7.2
                                c-0.1,2.3-0.3,4.5-0.4,6.8c0,2.3,0.3,4.6,0.7,6.9c0.4,2.4,0.7,4.8,0.7,7.1c0,1.2-0.1,2.4-0.2,3.6c-0.1,1.1-0.4,2.3-0.4,3.4
                                c0,2.2,2.1,2.6,4,2.5c2.3-0.2,4.5-0.8,6.7-1.4c2.3-0.7,4.5-1.5,6.7-2.4c2-0.8,4.1-1.5,5.8-2.9c-1.6-3.9-1.4-8.3-3.1-12.2
                                c-2-4.6-5.5-8.2-8.2-12.2c-2.8-4.1-5.1-8.9-4.2-14C364.4,177.2,364.8,175.9,365.1,174.6z"/>
                            <path name="IRENE" class="st0" d="M343.8,263c-2-0.2-4-0.5-6.1-0.8c-0.4-0.1-0.7-0.1-1-0.2c-0.6,1-0.9,3.1-0.9,3.1c0.6,1,8.1,4,8.1,4
                                c0.7,0,2.8-4.5,3.4-5.9C346.1,263.2,344.9,263.1,343.8,263z"/>
                            <path name="IRENE" class="st0" d="M394.4,227.9c-0.9,0-1.7,0.8-1.7,1.7s0.8,1.7,1.7,1.7c0.9,0,1.7-0.8,1.7-1.7
                                C396.1,228.6,395.3,227.9,394.4,227.9z"/>
                            <path name="IRENE" class="st0" d="M352.3,265c-0.7-0.3-1.5-0.7-2.2-1c-0.5,2.2-2.8,6.1-3,6.4l2.2,0.4c0.1-0.4,0.1-0.9,0.2-1.3
                                c0.2-1.1,0.6-2.2,1.4-3.1C351.2,265.8,351.7,265.4,352.3,265z"/>
                            <path name="IRENE" class="st0" d="M339.1,153c0.3,0.4,0.6,0.8,1,1.2c0.4,0.5,1,0.7,1.6,0.8c2.1,0.4,4.4-0.4,6.2-1.4c3.8-2,6.8-5.1,9.4-8.4
                                c1.7-2.2,3.1-4.5,4.6-6.8c0.3-0.5,1,0,0.7,0.4c-0.7,1.1-1.4,2.2-2.1,3.3c-1.1,1.7-2.4,3.4-3.7,5c-2.6,3.1-5.8,6-9.5,7.7
                                c0.2,1.3,0.5,2.7,0.9,4c0.3,0.8,0.6,1.6,0.9,2.4c5.2,2.2,11.6,1.5,16.2-1.7c0.4-1.1,0.8-2.2,1.1-3.4c0.7-2.7,1-5.4,0.9-8.1
                                c0-0.3,0.2-0.4,0.4-0.4c-0.9-1.3-1.5-3.4-1.5-3.4c-0.5,1.3,0.4,5.4,0,5.8c-2.4-5.5-1-14.1-1-14.1c-0.8-0.2-1.7-1.1-1.7-1.1
                                s3,0.1,4.9-6.3c1.8-6.4-3.6-7.3-3.6-7.3c-0.7,2.9,1.4,7.3,1.4,7.3c-2.3,1.1-5.4-0.8-5.4-0.8c-1,5.2-5.2,7-5.2,7
                                c1.1-1.4,0.8-8.2,0.5-9.6c-0.4-1.4-4-5.6-5.8-6.7c-1.7-1.1-3-3.1-3-3.1c-0.7,4.2,5,8.4,5,8.4c-1,0.7-8.6-0.2-14.1-0.7
                                c-5.5-0.4-7.4-4.2-7.4-4.2c1.4,3.3-0.6,6.3-1.6,7.5s-1.1,3-1.1,3c0.1,0.4,0.1,0.7,0,0.9c0.2,0,0.3,0.1,0.4,0.3
                                c1,3.1,1.1,6.4,1.7,9.5c0.3,1.4,0.7,2.7,1.5,3.9c0.7,0.9,1.5,1.8,2.3,2.7C335.8,148.6,337.4,150.9,339.1,153z M336.6,144.7
                                c3.4-0.6,6.7-2.2,9.3-4.5c0.4-0.4,1,0.2,0.6,0.6c-2.8,2.4-6.1,4-9.7,4.7C336.3,145.6,336.1,144.8,336.6,144.7z M332.8,140.5
                                c-0.5-0.1-1-0.3-1.4-0.6c-0.4-0.4-0.3-1-0.2-1.5c0.3-2.7,0.7-5.4,1-8.1c0.1-0.5,0.9-0.5,0.9,0c-0.3,2.2-0.5,4.4-0.8,6.6
                                c-0.1,0.6-0.1,1.1-0.2,1.7c0,0.3-0.1,0.6,0.1,0.8c0.2,0.1,0.5,0.2,0.6,0.3c0.9,0.2,1.9,0.1,2.7-0.4c0.5-0.3,0.9,0.5,0.4,0.7
                                C335,140.5,333.9,140.7,332.8,140.5z"/>
                        </g>
                        <path name="IRENE" class="st1" d="M362.2,138.7c0,0-10,14.2-15.4,16.1c0,0,0,3.4,0.9,4C347.7,158.8,359.9,154.2,362.2,138.7z"/>
                        <path name="IRENE" class="st3" d="M356.3,264.1c-2.1,0.2-4.2,1-5.4,2.8c-0.8,1.1-1.1,2.5-1.2,3.9c-0.1,0.9-0.1,1.9,0.3,2.6
                            c0.6,1,1.9,1.4,3.1,1.5c1.8,0.2,3.6,0.1,5.3-0.1c0.8-0.1,1.6-0.4,1.8-1.2c0-0.2-0.2-0.9-0.6-1c-1.2-0.1-4,0-5.8-0.7
                            c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.3-0.2-0.7,0.1-0.9c0.2-0.2,0.6-0.1,0.9-0.1c0.6,0.1,1.3-0.1,1.7-0.6c0.4-0.5,0.3-1.4-0.3-1.6
                            c-0.4-0.2-1-0.1-1.3-0.5s-0.1-1,0.3-1.2s0.9-0.2,1.4-0.2c1.6,0.1,3.2,0.2,4.9,0.3c0.5,0,1.2,0.1,1.5,0c0.5-0.1,0.6-0.5,0.7-0.9
                            s-0.2-0.9-0.6-1C362.2,264.8,358.3,264,356.3,264.1z"/>
                        <g>
                            <path name="IRENE" class="st2" d="M329,154.9c1-1.1,2-2.2,3.4-2.9c0.5-0.3,0.1-1-0.4-0.7c-1.3,0.7-2.3,1.7-3.3,2.8c-1.1,1.2-2.1,2.3-3.3,3.4
                                c-2.4,2.3-5.1,4.3-7.7,6.5c-0.4,0.4,0.2,0.9,0.6,0.6c2.5-2.1,5.1-4.1,7.6-6.3C326.9,157.1,328,156,329,154.9z"/>
                            <path  name="IRENE" class="st2" d="M311.5,242.3c-0.8-1.2-1.8-2.1-2.7-3.2c-0.9-1.1-1.6-2.2-2.2-3.6c-0.5-1.2-0.9-2.5-1.8-3.6
                                c-0.3-0.4-1.1,0-0.7,0.4c0.9,1.1,1.3,2.5,1.8,3.8s1.2,2.4,2.1,3.4c0.9,1.1,1.9,2.1,2.7,3.3c0.8,1.2,1.5,2.6,2.4,3.8
                                c0.3,0.4,0.9-0.2,0.6-0.6C312.9,244.9,312.3,243.5,311.5,242.3z"/>
                            <path name="IRENE" class="st2" d="M343,218.9c1.3-2.9,2-5.9,2.1-9.1c0-0.6-0.9-0.6-0.9,0c-0.1,12.1-10.4,20.5-18.6,27.9
                                c-2.7,2.4-5.4,4.8-8.1,7.2c-0.4,0.4,0.2,1,0.6,0.6c4.8-4.3,9.6-8.5,14.2-12.9C336.6,228.7,340.6,224.3,343,218.9z"/>
                            <path name="IRENE" class="st2" d="M328.2,164.3c-0.6-0.4-1.2-0.7-1.9-0.4c-1.2,0.5-1.8,2.3-2.1,3.5c-0.8,3.1-0.1,6.3,0.6,9.4
                                c0.3,1.5,0.9,2.9,2.1,3.9c1.4,1.1,3,2,4.4,2.9c0.8,0.5,1.5,1.1,2.4,1.3c0.8,0.2,1.6,0.2,2.4-0.1c1.7-0.7,2.7-2.2,3.6-3.7
                                c0.3-0.5-0.5-0.9-0.7-0.4c-0.8,1.2-1.6,2.5-2.9,3.2c-0.6,0.3-1.4,0.4-2.1,0.2s-1.3-0.7-1.9-1.1c-1.4-0.9-2.7-1.7-4.1-2.6
                                c-0.6-0.4-1.1-0.8-1.5-1.4c-0.4-0.6-0.6-1.3-0.8-2c-0.7-2.6-1.2-5.5-0.9-8.2c0.1-1.3,0.5-2.8,1.4-3.8c0.6-0.6,1.1-0.4,1.7,0.1
                                c0.7,0.5,1.3,0.9,1.9,1.4c2.8,2.1,5.5,4.3,8.1,6.6c0.4,0.4,1-0.2,0.6-0.6c-2.6-2.4-5.4-4.6-8.2-6.7
                                C329.6,165.4,328.9,164.8,328.2,164.3z"/>
                            <path name="IRENE" class="st2" d="M323.1,172.7c-3.5,4.2-3.2,10.1-1.2,15c1,2.3,2.2,4.9,4.5,6.2c1.1,0.6,2.3,1,3.5,1.2
                                c0.5,0.1,1.1,0.2,1.6,0.2c0.6,0.1,1.4,0.2,2,0.1c0.6-0.1,0.9-0.6,1.1-1.1c0.3-0.8,0.5-1.7,0.8-2.5c0.5-1.7,1-3.3,1.6-5
                                c0.2-0.5-0.7-0.8-0.8-0.2c-0.4,1.3-0.8,2.7-1.2,4c-0.2,0.6-0.4,1.2-0.6,1.8s-0.3,1.3-0.6,1.9c-0.3,0.5-1.1,0.3-1.6,0.3
                                c-0.6-0.1-1.2-0.1-1.8-0.2c-1.1-0.2-2.1-0.4-3.1-0.9c-2.2-1.1-3.3-3.4-4.3-5.5c-1-2.3-1.7-4.8-1.7-7.2c0-2.6,0.8-5.3,2.5-7.3
                                C324.1,172.9,323.5,172.2,323.1,172.7z"/>
                            <path name="IRENE" class="st2" d="M385.3,173.7c-2.1,2-5.1,4.2-8.2,3.2c-0.3-0.1-0.8-0.2-1-0.4c-0.3-0.3,0.1-0.9,0.2-1.2
                                c0.3-0.7,0.6-1.4,0.9-2.1c0.6-1.3,1.3-2.6,2.1-3.7c1.9-2.8,4.2-5.3,6.8-7.3c0.4-0.3,0-1.1-0.4-0.7c-2.7,2.1-5,4.5-6.9,7.3
                                c-1,1.4-1.8,2.9-2.5,4.4c-0.4,0.8-0.7,1.5-1,2.3s-0.3,1.3,0.4,1.8c1.4,0.9,3.2,0.9,4.7,0.5c1.7-0.4,3.2-1.5,4.5-2.6
                                c1.4-1.2,2.7-2.6,4-3.9c1.3-1.3,2.5-2.7,3.6-4.2c1-1.4,2.4-3.1,2.3-4.9c0-0.8-0.4-1.4-1-1.9c-0.7-0.5-1.6-0.5-2.4-0.5
                                c-0.5,0-0.5,0.9,0,0.9c0.6,0,1.4,0,1.9,0.4c0.6,0.4,0.7,1.1,0.6,1.8c-0.3,1.4-1.3,2.6-2.1,3.8
                                C389.9,169.1,387.6,171.5,385.3,173.7z"/>
                            <path name="IRENE" class="st2" d="M396.9,172.2c0,1.2,0,2.4,0,3.5c0,1.3-0.1,2.6-0.4,3.9c-0.5,2.4-2,4.3-4.1,5.5c-1,0.6-2.4,1.2-3.6,0.9
                                s-1.9-1.4-2.5-2.3c-0.8-1.1-1.5-2.4-2.4-3.5c-0.7-0.9-1.7-1.6-2.9-1.4c-0.5,0.1-0.3,0.9,0.2,0.8c1.3-0.3,2.2,1.4,2.8,2.3
                                c0.8,1.1,1.5,2.2,2.3,3.3c0.7,0.9,1.6,1.6,2.8,1.7c1.3,0.1,2.5-0.4,3.6-1c2.1-1.2,3.7-3.1,4.4-5.4c0.4-1.3,0.5-2.6,0.6-4
                                c0.1-1.3,0.1-2.5,0.1-3.8c-0.1-2.7-0.5-5.4-2.2-7.5c-0.4-0.4-1,0.2-0.6,0.6C396.4,167.6,396.8,170,396.9,172.2z"/>
                            <path name="IRENE" class="st2" d="M395,147.4c-0.5-1-1.1-1.9-1.7-2.9c-0.4-0.6-0.9-1.4-1.7-1.3c-0.5,0.1-0.5,0.9,0,0.9
                                c0.4-0.1,0.6,0.4,0.8,0.6c0.2,0.4,0.5,0.8,0.7,1.2c0.5,0.9,1,1.8,1.5,2.7c1,1.8,2,3.5,3,5.2c0.3,0.5,1,0.1,0.7-0.4
                                C397.3,151.4,396.2,149.4,395,147.4z"/>
                            <path name="IRENE" class="st2" d="M489.3,52.4c-0.3-0.3-0.5-0.3-0.9-0.4c0,0,0,0-0.1,0c0.1,0,0.1,0,0.2-0.1c0.3-0.1,0.5-0.2,0.8-0.4
                                c0.3-0.3,0.4-0.7,0.3-1.1c-0.1-0.5-0.6-0.9-1.1-1.1c-1.1-0.5-2.4-0.3-3.5-0.5c-2.8-0.5-5.7-1.1-8.5-1.7c-2.7-0.5-5.3-1.1-8-1.8
                                c-1.6-0.4-3.2-0.8-4.9-1.1c-1.5-0.2-3.1-0.3-4.5,0.3c-1.3,0.5-2.4,1.5-3.3,2.6c-1.1,1.2-2.1,2.6-3.2,3.9c-2.1,2.7-4.2,5.3-6.3,8
                                c-4.3,5.4-8.6,10.8-12.8,16.2c-0.5,0.6-0.9,1.2-1.4,1.8c-0.4-0.1-0.9-0.2-1.4-0.1c-1.2,0.3-2.4,1.1-3.4,1.9s-1.9,1.6-2.7,2.5
                                c-0.9,0.9-1.8,1.8-2.6,2.8c-0.8,1-1.4,2.2-1.4,3.5c0,1.1,0.4,2.3,1.3,3c-1.4,2.3-3,4.5-4.5,6.6c-2.3,3.2-4.7,6.3-7,9.4
                                c-2.2,3-4.4,6.2-6.9,9c-1.1,1.2-2.2,2.3-3.1,3.7c-0.9,1.3-1.5,2.7-1.6,4.3c-0.1,0.6-0.1,1.3-0.1,1.9c-0.1,1-0.8,2.1-1.4,2.9
                                c-0.7,1.1-2.1,1.4-2.7,2.6c-0.5,1-0.5,2.2-0.3,3.3l-0.1,0.1c-0.9,1.4-1.7,2.9-2.8,4.1c-0.5,0.6-1,1.1-1.6,1.6
                                c-0.6,0.6-1.4,1-2.1,1.5c-0.9,0.6-1.6,1.4-2.1,2.4c-0.1,0-0.2,0-0.3,0.1c-0.9,0.9-2.2,1-3.4,1c-1.4,0-2.7,0.1-4,0.4
                                c-1.1,0.2-2.1,0.7-2.9,1.5c-0.7,0.8-1.2,1.9-2.1,2.5c0,0,0,0.1-0.1,0.1c-0.5-0.6-1-1.2-1.5-1.8c-1-1.1-2-2.2-3.6-2.4
                                c0,0,0,0-0.1,0c-0.4-0.7-0.6-1.5-0.4-2.5c0,0,2.9,2.4,2.6,1.5s-1.2-1.4-1.4-3c0,0,4.1-5.4,4.3-8.9s0.5-14.7-1.4-18.2
                                c-2-3.5-3.4-5.2-5.4-6.1c0,0-7.6-13.4-22.8-6.3c0,0,4.4,0,6.6,3.2c0,0-5.8-3.9-11.4-2.7c0,0-13.5,3.4-15.1,15.7
                                c0,0-1.1,9.2,3.5,12.7c0,0,0.7,0.3,1.1,0c0.8,2.8,1,5.7,1.5,8.6c0.2,1.3,0.5,2.5,1.1,3.7c0.5,1.1,1.3,2,2.1,2.9
                                c0.3,0.3,0.6,0.7,0.8,1c-1.6-0.2-3.2-0.4-4.8-0.7c-0.6-0.1-1.2-0.2-1.7-0.5s-1-0.7-1.4-1.1c-0.9-0.7-1.9-1.4-2.8-2
                                c-1.9-1.2-3.9-2.3-6-3.2c-1-0.4-2-0.8-2.9-1.4c-0.8-0.5-1.5-1.2-2.1-1.9c-1.1-1.4-2.1-3.1-4-3.5c-0.1,0-0.2,0-0.3,0.1
                                c-0.7-2.2-2-4.1-3.2-6c-0.9-1.3-1.7-2.6-3-3.5c-1.4-1-3-1.3-4.5-2.1c-2.8-1.5-4.4-4.5-6.6-6.6c-1.3-1.2-2.7-2.2-4.1-3.3
                                c-1.3-1-2.5-2.1-3.6-3.2c-2.5-2.5-4.9-5.1-8-6.8c0.1-0.2,0.2-0.5,0.3-0.8c0.3-1.1,0-2.1-0.8-2.9c-0.5-0.4-1-0.6-1.5-0.9
                                c-0.7-0.4-1.4-0.8-2.1-1.2c-1.3-0.7-2.5-1.4-3.8-2.2c-1.1-0.6-2.3-1.3-3.6-1.5c-0.7-0.1-1.4,0-2,0.3c-5.2-4.5-11.1-8.2-16.3-12.7
                                c-1.3-1.1-2.5-2.2-3.6-3.5c-1.2-1.3-2.4-2.7-3.6-3.9c-2.3-2.3-4.9-4.2-7.9-5.5c-1.2-0.5-3.1-1.4-4.4-0.5
                                c-1.1,0.8-0.9,2.3-0.3,3.4c-2.6,0.2-5.3,0.8-7.9,1.1c-0.1-0.1-0.1-0.1-0.2-0.1c-1.8-0.5-3.5-1.4-5.3-2c-0.8-0.3-1.8-0.5-2.6-0.3
                                s-1.4,0.8-2.2,1.1c-1.4-0.6-2.8-1.4-4.3-1.8c-1.2-0.3-2.4,0.1-2.7,1.4c-0.2,1.2,0.5,2.3,1.4,3c0.6,0.5,1.4,0.8,2,1.2
                                c0.8,0.4,1.6,0.8,2.3,1.2c1.6,0.8,3.1,1.7,4.7,2.5c1.5,0.8,3,1.4,4.6,1.8s3.4,0.6,5.1,0.6c1.7,0.1,3.5,0,5.2,0.3
                                c1.4,0.2,2.8,0.7,4.1,1.3c2.6,1.3,4.9,3.2,7,5.2c2.4,2.3,4.6,4.7,6.9,7c2.5,2.6,5,5.2,7.5,7.8c0.6,0.7,1.3,1.3,1.9,2l0,0
                                c-0.1,0.2-0.2,0.3-0.3,0.5c-1.3,2-3,4.6-1.5,7c1.1,1.8,3.2,3,4.9,4.2c1.4,1,2.8,1.9,4.2,2.8c0.7,0.4,1.6,0,2.2-0.4
                                c0.4-0.2,0.8-0.5,1.2-0.8c1.8,3.1,4,5.8,6.4,8.3c2.5,2.7,5.1,5.5,8.2,7.5c0.2,0.1,0.4,0.3,0.6,0.4c0,0.1,0,0.2,0.1,0.3
                                c1,1.1,1.4,2.5,2.1,3.8c0.8,1.4,1.9,2.6,3,3.9c1.1,1.4,2.3,2.7,3.4,4.1c0.8,0.9,1.5,1.9,2.4,2.7c-0.1,0.1-0.1,0.2,0,0.4
                                c1.7,3.4,5.4,5.2,8.4,7.2c1.9,1.3,3.4,2.9,5,4.5c1.8,1.8,3.6,3.7,5.4,5.5c2.1,2.1,4.2,4.2,6.2,6.4c-0.2,1.9-0.4,3.8-0.6,5.7
                                c-0.2,2-0.1,4,0.2,5.9c0.3,2.1,0.7,4,0.4,6.1c-0.2,2-0.8,3.9-1,5.9s-0.1,4.1-0.1,6.1c0.1,4.7-0.2,9.5-0.9,14.2c0,0.1,0,0.2,0,0.2
                                c-0.1,0.1-0.2,0.3-0.3,0.4c-1.8,2.4-3.7,4.7-5.5,7s-3.6,4.7-5.1,7.3c-1.3,2.4-2,5-1,7.6s3,4.7,4.7,6.9c1,1.3,2,2.5,3,3.8
                                c0.8,1,1.5,2.1,2.6,2.7c2.5,1.5,5.2-0.4,7.3-1.7c1-0.6,2-1.2,2.9-1.9c-1.3,2-2.9,3.8-4.6,5.5c-0.5,0.4-0.8,0.9-0.6,1.5
                                c-2.7,0.8-4,27.8-3.7,32.6c0.3,5.2,2.1,18.8,1.7,23.5s-2.8,10-1.7,11.5c1.1,1.5,2.7,2.6,2.2,3.1s-1.9,1.5-1.6,2.8
                                c0.3,1.4,0.7,5.7,0.2,8.7s-2.1,9.2-2,10.6c0.2,1.4,1.4,2,1,3.4s-2.3,1.5-2,2.7c0.3,1.3,0.2,6.5-0.5,9s-0.6,10.6-0.8,12.5
                                c-0.2,2-0.9,4.9-0.5,9s0.3,8.9,0.9,11.2c0.6,2.3,1.5,6.6,2.1,9.4s1.6,7.3,1.8,9.1c0.2,1.8-0.3,5.4-0.6,6.8
                                c-0.3,1.5-0.4,3.4,0,4.4s0.5,1.8,0.3,2.2c-0.3,0.4-1.3-0.2-1.5,2.5c-0.3,2.6-0.7,4.4-0.2,4.9c0,0,0,0.9-0.4,1.8
                                c0,0-2.1,7.8,0.1,11.1c2.2,3.3,2.9,3.7,5,5.5c2.1,1.9,3.3,3.6,4.1,4.7c0.8,1.1,11.5,11.9,17.9,11.2c2.4-0.2,4.1-1.2,4.6-4.3
                                c0,0,0.6-4.3-5-11.6c-5.5-7.3-9.8-12-10.2-16.6c0,0,1.1,0.1,1.3-0.9c0.2-0.9,0.3-3.3,0.3-4.6c0,0,0-0.8-0.8-0.9
                                c0,0,0.2-0.5,0.3-3.4s-0.2-15,0.7-19.8c0.9-4.8,2.1-15.4,2.1-18.1c-0.1-2.6-0.9-11.4-0.3-15.2c0.6-3.8,3.2-7.8,3.5-10.7
                                c0.3-3-0.3-5.6,0.8-8.3c1.1-2.6-0.2-4.3,0.6-5.7c0.8-1.5,1-4.1,1-7.2s0.3-6.8,1.8-10.1c1.5-3.3,3.7-13,4.9-17.1
                                c1.3-4.1,1.2-6.6,2.4-9.5c1.2-2.9,2.4-7,3.1-7.6c0.7-0.7,0.5,1.2,0.6,3.3c0.2,2-0.5,6.1,0,10.7s2.1,13.9,1.4,16.5s-3,3.8-0.8,8.9
                                c0,0-0.7,0.1-0.8,1.9s-0.2,11.2-1.6,18c-1.5,6.8-1.7,14.2-3.1,18.9c-1.5,4.7-1.5,8.6-0.8,14.9c0.8,6.3,2.3,14.5,2.6,17.3
                                c0.3,2.8,1.1,5.3,0.9,5.9c-0.3,0.6-1.1,0-1.9,1.5s-2.5,10.7-2.7,11.9c0,0-0.5,4.1,2.3,5.2s5.1,1.4,7.2,1.7
                                c2.1,0.3,6.6,0.7,8.6,1.7s16.6,4.4,22.7,4.4c6,0.1,8.6-0.7,9.5-2s2.4-4.3,1.1-6.2c0,0-1.9-3.7-14.4-9.4c0,0-11.5-4.7-12.4-6
                                s-1.2-4.5-2.7-3.7c-1.5,0.9-2,2-2.5,1.5c0,0-1.2-2-0.7-8.2s0.9-21.4,1-23.7c0.2-2.3-1.1-2.4-0.5-4.5s6.3-23,6.5-25.6s0.4-4.5,1-6
                                s2.9-2.2,2.1-7.4c0,0,2.6-2.1,2.8-6.5c0,0,2.3-6,3.7-9.7c1.4-3.8,4.3-10.1,4.1-17.2s-1.1-9.4,0.1-14.8s2.8-11.2,1.1-14.3
                                c0,0,0.9-2.5-0.6-6c0.8-0.3,1.6-0.7,2.4-1c1.4-0.6,3.4-1.6,3.7-3.3c0.2-1.2-0.3-3-1.6-3.2c-0.9-0.2-1.9,0.2-2.8,0.5
                                c-1.1,0.4-2.2,0.8-3.4,1.2c-0.3,0.1-0.5,0.1-0.8,0.2c-0.1-0.5-0.2-0.9-0.5-1.3c0.5-0.2,1-0.4,1.4-0.7c0.6-0.5-0.1-1.2-0.4-1.6
                                c-0.5-0.8-1-1.6-1.5-2.4c-1-1.7-1.8-3.5-2.2-5.4c-0.4-2-0.4-4.1-0.4-6.2c0-2.1,0.2-4.1,0.6-6.1s1-3.9,1.8-5.8
                                c0.3-0.8,0.6-1.6,0.9-2.5c1.2-0.6,5.6-2.5,8.2-2.4c0.4,0.8,0.7,1.5,1.1,2.2c1.3,2.4,2.6,4.9,4.1,7.2c0.7,1.1,1.3,2.3,2.1,3.4
                                c0.6,0.9,1.4,1.6,2.4,1.9c2.3,0.6,4.5-1,6.2-2.3c1.8-1.5,3.6-3,5.2-4.7c3.1-3.3,5.6-7.3,5.9-11.9c0.2-2.7-0.5-5.4-1.1-8.1
                                c-0.3-1.2-0.6-2.4-1.1-3.5s-1.3-2-2.2-2.8c-0.9-0.7-1.8-1.4-2.8-1.9c-0.1-0.1-0.2-0.1-0.3,0c-0.4-1.1-0.8-2.2-1.3-3.2
                                c-0.6-1.3-1.2-2.6-1.9-3.9c-0.7-1.2-1.4-2.5-1.9-3.8c-0.6-1.5-0.9-3.1-1.1-4.6c0.1-0.1,0.1-0.1,0.2-0.3c0.5-3.4,1-6.7,1.6-10.1
                                c0.2-1.6,0.5-3.1,0.4-4.7c-0.1-1.6-0.6-3.1-1.1-4.5c-0.4-1-0.7-1.9-1-2.9c0.1-0.1,0.2-0.2,0.2-0.3c0.1-1.9,1-3.6,2-5.2
                                c1-1.6,2.1-3.2,3.1-4.9c2-3.6,3.4-7.5,4.8-11.3c0.7-1.9,1.6-3.8,2.7-5.5c1.1-1.6,2.3-3.1,3.5-4.6s2.3-3,3.3-4.7
                                c0.9-1.7,1.6-3.5,2-5.3c0.3-1.1,0.5-2.2,0.6-3.3c0,0,0.1,0,0.1-0.1c1.6-1.5,3.3-3.1,5-4.6c1.5-1.4,3.1-2.7,4.2-4.4
                                c1-1.7,1.6-3.7,2.3-5.5c0.8-2,1.5-4,2.3-6c0.4-1.1,0.8-2.2,1.2-3.2c1.6,0.1,3.2,0.2,4.7-0.5c1.8-0.8,2.6-2.7,3.3-4.4
                                c0.9-1.9,1.6-3.9,2.2-5.9c0.5-1.5,1-3.5-0.4-4.7c-1.2-1.1-3-1.1-4.3-2.1l0,0c5.1-9.2,10.6-18.4,17.4-26.6c0.8-1,1.7-1.9,2.9-2.3
                                s2.5-0.4,3.8-0.5c2-0.1,4-0.2,6-0.2c2.1,0,4.2-0.1,6.3-0.1c1.9,0,3.8-0.2,5.7-0.3c0.7-0.1,1.4-0.1,2.1-0.2
                                c0.7-0.1,1.6-0.3,1.6-1.2C489.8,53.3,489.6,52.8,489.3,52.4z M331.7,143.8c-0.8-1.1-1.2-2.5-1.5-3.9c-0.6-3.2-0.8-6.4-1.7-9.5
                                c-0.1-0.2-0.2-0.3-0.4-0.3c0-0.2,0-0.5,0-0.9c0,0,0.1-1.7,1.1-3c1-1.2,3-4.2,1.6-7.5c0,0,2,3.8,7.4,4.2s13.1,1.4,14.1,0.7
                                c0,0-5.7-4.2-5-8.4c0,0,1.3,2,3,3.1s5.4,5.3,5.8,6.7c0.4,1.4,0.7,8.2-0.5,9.6c0,0,4.2-1.8,5.2-7c0,0,3.1,1.8,5.4,0.8
                                c0,0-2.1-4.5-1.4-7.3c0,0,5.4,1,3.6,7.3c-1.8,6.4-4.9,6.3-4.9,6.3s1,0.9,1.7,1.1c0,0-1.4,8.6,1,14.1c0.4-0.4-0.5-4.5,0-5.8
                                c0,0,0.6,2.1,1.5,3.4c-0.2,0-0.4,0.1-0.4,0.4c0,2.7-0.3,5.5-0.9,8.1c-0.3,1.1-0.6,2.3-1.1,3.4c-4.6,3.2-11.1,3.9-16.2,1.7
                                c-0.3-0.8-0.6-1.6-0.9-2.4c-0.4-1.3-0.7-2.6-0.9-4c3.8-1.7,6.9-4.6,9.5-7.7c1.3-1.6,2.6-3.3,3.7-5c0.7-1.1,1.4-2.2,2.1-3.3
                                c0.3-0.5-0.4-0.9-0.7-0.4c-1.5,2.3-2.9,4.6-4.6,6.8c-2.6,3.3-5.7,6.4-9.4,8.4c-1.9,1-4.1,1.8-6.2,1.4c-0.6-0.1-1.2-0.3-1.6-0.8
                                c-0.3-0.4-0.7-0.8-1-1.2c-1.7-2.1-3.3-4.4-5.1-6.5C333.2,145.7,332.4,144.8,331.7,143.8z M368.4,192.5c2.8,4.1,6.2,7.7,8.2,12.2
                                c1.7,3.9,1.5,8.3,3.1,12.2c-1.6,1.4-3.8,2.1-5.8,2.9c-2.2,0.9-4.4,1.7-6.7,2.4c-2.2,0.7-4.5,1.3-6.7,1.4c-1.8,0.1-3.9-0.2-4-2.5
                                c0-1.1,0.3-2.3,0.4-3.4c0.1-1.2,0.2-2.4,0.2-3.6c0-2.4-0.2-4.8-0.7-7.1c-0.4-2.3-0.7-4.5-0.7-6.9c0-2.3,0.3-4.5,0.4-6.8
                                c0.1-2.4,0-4.9-0.7-7.2c-0.5-1.7-1.5-3.1-2.1-4.7l0,0c0-2,0-3.9,0-5.9c0-1.5,0-2.9-0.2-4.4c1.8-0.9,3.9-0.5,5.8-0.5
                                c2,0,3.9-0.5,5.6-1.5c0.2,0.6,0.4,1.1,0.5,1.7c0.4,1.3,0.4,2.5,0.1,3.8s-0.7,2.6-1,3.9C363.2,183.5,365.6,188.4,368.4,192.5z
                                M364.2,168.2L364.2,168.2c-1.9,1-3.9,1.5-6,1.4c-1.8,0-3.6-0.2-5.2,0.5c-0.2-1.1-0.6-2.2-1.1-3.2c-0.6-1.3-1.3-2.6-2-3.9
                                c-0.1-0.2-0.2-0.4-0.3-0.6c4.9,1.8,10.7,1.2,15.2-1.5c-0.4,0.9-0.8,1.9-1,2.9C363.5,165.4,363.8,166.8,364.2,168.2z M208.5,68.9
                                c0.9-0.2,1.9,0.1,2.7,0.4c1.2,0.4,2.3,1,3.5,1.4c-1.2,0.1-2.4,0-3.5-0.1c-1.3-0.2-2.6-0.6-3.8-1.1
                                C207.7,69.3,208.1,69.1,208.5,68.9z M238.4,87.3c-3.8-3.9-8.2-7.9-13.8-8.8c-3.3-0.5-6.6-0.1-9.9-0.9c-3.3-0.8-6.2-2.6-9.1-4.2
                                c-1.4-0.7-2.9-1.4-4.2-2.3c-0.5-0.3-1.1-0.8-1.3-1.5c-0.1-0.5-0.1-1.2,0.5-1.6c0.6-0.3,1.5,0.1,2.1,0.3c0.8,0.3,1.5,0.7,2.3,1
                                c0.3,0.2,0.7,0.3,1,0.4c0.1,0,0.1,0.1,0.2,0.1c1.1,0.4,2.1,0.8,3.2,1.1c3.3,0.8,6.7,0.4,10-0.2c3.4-0.6,6.8-1.2,10.2-0.4
                                c0.5,0.1,0.8-0.7,0.2-0.8c-1.5-0.4-2.9-0.4-4.4-0.4c-0.7-1-1.1-2.9,0.6-2.9c0.7,0,1.3,0.3,1.9,0.5c0.7,0.3,1.4,0.6,2.1,1
                                c2.8,1.4,5.2,3.4,7.3,5.7c2.1,2.3,4.3,4.6,6.7,6.7c2.3,2,4.8,3.8,7.3,5.6c2.9,2.1,5.7,4.3,8.4,6.6c-0.1,0.1-0.3,0.2-0.4,0.3
                                c-1,1-1.8,2.2-2.7,3.3c-1.4,1.9-2.8,3.8-4.1,5.8C247.9,97.1,243.2,92.1,238.4,87.3z M260.9,116.2c-0.2,0.1-0.7,0.4-1,0.2
                                c-2.5-1.5-4.9-3.1-7.1-4.9c-0.7-0.6-1.5-1.3-1.9-2.2c-0.6-1.2-0.1-2.5,0.4-3.7c0.6-1.1,1.3-2.2,2.1-3.2c0.7-1.1,1.4-2.1,2.2-3.1
                                c0.8-1.1,1.5-2.1,2.4-3.1c0.7-0.9,1.4-2,2.3-2.8c2.1-1.8,5,0.3,6.9,1.4c1.1,0.7,2.3,1.3,3.4,2c0.5,0.3,1.1,0.6,1.6,0.9
                                s1,0.6,1.3,1c0.7,1-0.1,2.3-0.7,3.2c-0.8,1.1-1.6,2.1-2.5,3.2c-1.9,2.4-3.7,4.8-5.6,7.2c-0.8,1-1.5,2.1-2.5,3
                                C261.8,115.7,261.4,116,260.9,116.2z M314.7,250c-1.3,0.6-2.7,0.8-3.9-0.1c-1-0.8-1.8-1.9-2.6-2.9c-1.8-2.4-3.8-4.7-5.5-7.1
                                c-0.8-1.1-1.5-2.2-1.8-3.5c-0.4-1.4-0.2-2.7,0.2-4c0.4-1.3,1.2-2.5,1.9-3.7c0.8-1.2,1.6-2.4,2.5-3.5c1.7-2.3,3.6-4.6,5.4-6.9
                                c3.3-4.2,6.5-8.8,8-14c0.4-1.3,0.6-2.6,0.8-3.9c0.1-0.5-0.8-0.5-0.9,0c-0.6,4.9-3,9.4-5.8,13.4c0.5-3.8,0.8-7.6,0.8-11.5
                                c0-2.1-0.1-4.2,0-6.3s0.7-4.1,1-6.2c0.3-2,0.2-3.9-0.1-5.9c-0.3-2.2-0.6-4.2-0.4-6.4c0.1-2.1,0.5-4.3,0.6-6.4s0.3-4.3,0.4-6.4
                                c0.1-2.4,0-4.9-0.1-7.3c-0.1-1.5,0.1-4-1.6-4.8c-0.5-0.2-0.9,0.5-0.4,0.7c0.7,0.3,0.9,1.1,1,1.8c0.1,0.9,0.1,1.9,0.2,2.9
                                c0.1,2.1,0.1,4.2,0,6.3c0,1.9-0.1,3.7-0.3,5.6c-3.5-3.5-6.9-7-10.4-10.5c-1.6-1.7-3.2-3.4-5.1-4.8c-1.5-1.1-3.2-2.1-4.7-3.2
                                c-1.4-1-2.8-2.1-3.8-3.5c1.3,0.7,2.8,0.9,3.9,1.9c0.4,0.4,1-0.2,0.6-0.6c-1.1-1-2.4-1.3-3.7-1.9c-1.5-0.7-2.4-1.9-3.5-3.1
                                c-1.1-1.4-2.3-2.7-3.4-4.1c-1.1-1.3-2.3-2.5-3.1-4c-0.5-0.9-0.9-1.9-1.4-2.8c0.9,0.6,1.8,1.3,2.6,2.1c0.4,0.4,1-0.2,0.6-0.6
                                c-1.2-1.2-2.6-2.1-4.1-3c-1.6-1-3.1-2-4.4-3.4c-2.6-2.6-5.2-5.2-7.6-8.1c-1.3-1.6-2.4-3.2-3.4-4.9c0.2-0.2,0.4-0.4,0.5-0.6
                                c1.9-2.4,3.7-4.8,5.5-7.2c0.9-1.2,1.9-2.4,2.8-3.6c0.5-0.6,1-1.2,1.4-1.9c2.7,1.5,4.8,3.7,6.9,5.8c1.1,1.1,2.2,2.2,3.4,3.2
                                c1.3,1.1,2.7,2.1,4,3.1c1.2,1,2.3,2.1,3.3,3.4c1,1.1,1.9,2.3,3,3.2c1.2,1,2.6,1.6,4,2.1c1.6,0.6,2.8,1.6,3.8,2.9
                                c1.1,1.4,2,3,2.9,4.5c0.9,1.7,1.6,3.4,1.7,5.4c0,0.6,0.9,0.6,0.9,0c0-0.8-0.1-1.5-0.3-2.2c1.6,0.4,2.5,2,3.5,3.3
                                c0.6,0.7,1.1,1.4,1.9,1.8c0.9,0.6,1.9,1,2.9,1.4c2.1,0.9,4.2,2,6.2,3.3c1,0.6,1.9,1.3,2.8,2s1.7,1.4,2.9,1.6
                                c1,0.2,2.1,0.3,3.2,0.4c1.1,0.1,2.1,0.3,3.2,0.4h0.1c1.4,1.7,2.7,3.4,4,5.1c0.6,0.8,1.2,1.9,2.2,2.4c0.1,0,0.2,0.1,0.2,0.1
                                c-2,2.5-3.3,5.5-3.5,8.7c-0.1,0.8-0.1,1.7,0,2.5c0,0.6,0,1.6,0.8,1.6c0.4,0,0.8-0.2,1.1-0.4c0.4-0.2,0.8-0.3,1.2-0.4
                                c0.9-0.3,1.8-0.4,2.7-0.5c2-0.2,4,0,5.9,0.6c0.5,0.2,0.8-0.6,0.2-0.8c-3-1-6.2-1-9.2-0.1c-0.4,0.1-0.7,0.2-1,0.4
                                c-0.1,0.1-0.3,0.1-0.4,0.2l-0.2,0.1c0,0.1,0,0.1,0-0.1c0-0.1,0-0.1,0-0.2c-0.1-0.7-0.1-1.5-0.1-2.2c0.1-1.5,0.4-3,0.9-4.4
                                c0.6-1.6,1.5-3.2,2.6-4.5c0.1-0.1,0.1-0.1,0.1-0.2c1.7,0.4,3.7,0,5.2-0.6l0,0c0.4,2.2,1,4.3,1.8,6.4c0,0.1,0,0.2,0.1,0.3
                                c0.1,0.4,0.3,0.7,0.5,1.1c1.2,2.6,2.8,5,3.3,7.9c0.2,1.5,0.3,3,0.3,4.5s0,3,0,4.5c0,3.1-0.1,6.2-0.6,9.2
                                c-0.4,2.9-1.3,5.8-2.1,8.6c-0.9,2.9-1.5,5.7-1.8,8.7c-0.3,2.8-0.6,5.6-1.4,8.4c-0.7,2.6-1.7,5-2.8,7.4
                                c-4.9,9.8-13,17.4-21.9,23.5c-1.1,0.8-2.3,1.5-3.4,2.3C317.1,248.6,316,249.4,314.7,250z M328.1,260.4c1.8,0.5,3.7,0.9,5.6,1.2
                                c-0.2,0.5-0.6,1.7-1,2.5L328.1,260.4z M343.8,269.1c0,0-7.5-3-8.1-4c0,0,0.3-2.1,0.9-3.1c0.4,0.1,0.7,0.1,1,0.2
                                c2,0.3,4,0.6,6.1,0.8c1.1,0.1,2.3,0.2,3.5,0.2C346.6,264.6,344.6,269.1,343.8,269.1z M371.5,272.4c-1.3,0.2-2.6,0.5-3.9,0.6
                                c0.2-1.1,0.6-3.9,1-6.2c1-0.1,2-0.2,3-0.3c0.1,0,0.3,0,0.4,0C372.2,268.2,372.1,270.5,371.5,272.4z M351.4,274.1
                                c-0.9-0.4-1.3-1.1-1.4-2c0-0.4,0-0.6,0-0.8c0.1-1.1,0.3-2.3,0.8-3.4s1.4-1.9,2.5-2.5c0.4-0.2,1.1-0.5,1.1-0.5
                                c0.6-0.2,1.2-0.3,1.8-0.4c1.5-0.1,3.1,0.2,4.6,0.4c0.4,0.1,0.8,0.1,1.2,0.2c0.3,0.1,0.3,0.3,0.3,0.5c0,0.4-0.2,0.6-1.1,0.6l0,0
                                c-1.2,0-2.4-0.1-3.6-0.2c-0.6,0-1.1-0.1-1.7-0.1c-0.5,0-1-0.1-1.5,0.1c-0.7,0.2-1.2,1.1-0.8,1.8c0.2,0.3,0.5,0.5,0.8,0.6
                                c0.4,0.1,1,0,1.1,0.6c0.1,0.5-0.2,0.8-0.6,1c-0.5,0.2-1,0-1.5,0c-0.8,0.1-1.1,0.9-0.8,1.5c0.4,0.7,1.4,1,2.1,1.1
                                c0.9,0.2,1.8,0.3,2.7,0.3c0.4,0,0.8,0,1.2,0.1c0.3,0,0.8,0,1,0.1s0.2,0.2,0.2,0.4c0,0,0,0.2-0.1,0.5s-0.6,0.3-0.6,0.3
                                C357.4,274.6,353.2,274.5,351.4,274.1z M360.6,273.5c0-0.6-0.4-1.1-1-1.2c-0.8-0.1-1.7-0.1-2.6-0.2c-1-0.1-2.1-0.2-3-0.5
                                c-0.2-0.1-0.5-0.2-0.6-0.4c-0.3-0.4,0.2-0.4,0.5-0.4c0.5,0,0.9,0,1.3-0.2c0.8-0.4,1.3-1.2,1-2.1c-0.1-0.4-0.4-0.7-0.8-0.9
                                c-0.3-0.1-1.4-0.1-1.1-0.6s1.2-0.3,1.7-0.3c0.7,0,1.5,0.1,2.2,0.2c0.6,0,1.3,0.1,1.9,0.1s1.2,0.1,1.8,0c0.4,0,0.7-0.2,0.9-0.5
                                c0.7,0.1,1.4,0.2,2.1,0.2c-0.1,2.3-0.4,5-0.9,6.5C362.9,273.4,361.7,273.5,360.6,273.5z M371.6,263.5c0.1,0.3,0.2,1.1,0.3,2.1
                                c-1.1,0.1-2.1,0.2-3.2,0.3l0,0c0.1-1,0.3-1.9,0.3-2.4C369.9,263.5,370.8,263.5,371.6,263.5z M365,263.4c0,0.5,0,1.4,0,2.6
                                c-0.6,0-1.2-0.1-1.8-0.1c0-0.2,0-0.5-0.1-0.7c-0.2-0.6-0.8-0.8-1.3-0.9c-1.8-0.4-3.7-0.7-5.6-0.6c-1,0.1-2.1,0.3-3.1,0.8
                                c-0.9-0.3-1.7-0.8-2.6-1.2c4.1,0,8.3,0.1,12.4,0.1C363.7,263.4,364.4,263.4,365,263.4z M347,270.3c0.2-0.2,2.5-4.2,3-6.4
                                c0.7,0.4,1.5,0.7,2.2,1c-0.6,0.4-1.1,0.8-1.5,1.3c-0.7,0.9-1.1,2-1.4,3.1c-0.1,0.4-0.2,0.9-0.2,1.3L347,270.3z M380.8,265
                                c1-0.3,2.1-0.7,3.1-1.1c0.6-0.2,1.1-0.4,1.7-0.5c0.3-0.1,0.7-0.2,1.1-0.1c0.7,0.1,0.8,1,0.9,1.6c0.2,1.6-1.5,2.5-2.7,3.1
                                c-2.8,1.4-5.8,2.5-8.8,3.3c-0.2,0.1-0.4,0.1-0.6,0.1c0-1.1,0-3.5-0.1-5.4c0.8-0.1,1.6-0.2,2.3-0.4
                                C378.8,265.5,379.8,265.2,380.8,265z M378.6,263.2C378.6,263.2,378.6,263.3,378.6,263.2c0.1,0.1,0.1,0.2,0.2,0.2
                                c0,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0.1,0,0v0.1c0.1,0.1,0.1,0.3,0.1,0.5l0,0v0.1l0,0c-1.2,0.3-2.5,0.5-3.7,0.6
                                c-0.1-0.7-0.2-1.3-0.3-1.7c0.2,0,0.4,0,0.6,0c0.9,0,1.8-0.1,2.6-0.2C378.4,263.2,378.5,263.2,378.6,263.2L378.6,263.2z
                                M377.2,236.7c-0.9,2.8-1.4,5.7-1.6,8.6s-0.2,6,0.6,8.8c0.4,1.5,1.1,2.8,1.8,4.2c0.4,0.7,0.8,1.4,1.2,2c0.2,0.3,0.4,0.6,0.6,1
                                c0.1,0.2,0.2,0.3,0.3,0.4c-1.9,1-4.1,1-6.2,0.9c-2.5,0-5.1,0-7.6-0.1c-5.1,0-10.3-0.1-15.4-0.1c-2.5,0-5-0.1-7.5-0.3
                                c-2.5-0.2-5-0.6-7.5-1.1c-2.4-0.4-4.8-0.8-7.2-1.4c-2.3-0.6-4.6-1.5-6.7-2.5c-1.2-0.6-2.4-1.2-3.5-1.8c-0.6-0.3-1.2-0.6-1.7-0.9
                                c-0.7-0.4-0.1-0.9,0.4-1.2c0.6-0.3,1.2-0.5,1.8-0.7c0.6-0.2,1.3-0.5,1.9-0.7c1.4-0.5,2.7-0.9,4.1-1.3c0.5-0.1,0.3-1-0.2-0.8
                                c-2,0.6-4,1.2-5.9,1.9c-0.2,0.1-0.4,0.1-0.6,0.2c0.5-0.6,1-1.2,1.5-1.8c1-1.2,1.9-2.5,2.7-3.8c0-0.1,0-0.1,0-0.2
                                c3.2-2.2,6.4-4.6,9.2-7.3c0.1,0,0.2,0,0.3,0c7.2-3.1,13.9-7.3,20.4-11.8c0.5-0.3,0-1.1-0.4-0.7c-5.9,4.1-12.1,7.9-18.6,10.9
                                c3.3-3.2,6.2-6.6,8.6-10.5c2.9-4.6,5-9.6,6-15c0.5-3,0.6-6.1,1.2-9.1s1.7-5.9,2.4-8.8c0.9-3.5,1.3-7,1.5-10.6
                                c0.4,0.9,0.9,1.7,1.2,2.6c0.8,2.2,1,4.6,0.9,6.9c0,2.4-0.3,4.7-0.4,7.1c-0.1,2.4,0.2,4.6,0.6,7c0.5,2.5,0.7,5.1,0.7,7.6
                                c0,1.3-0.1,2.5-0.2,3.8c-0.1,1.1-0.4,2.2-0.3,3.4c0.2,2,1.8,2.9,3.6,3c1,0.1,2-0.1,3-0.3c1.2-0.2,2.4-0.5,3.6-0.8
                                c2.6-0.7,5.2-1.7,7.7-2.7c2.2-0.9,4.5-1.6,6.4-3.1c0.2,0.4,0.4,0.8,0.7,1.2c0.6,0.8,1.2,1.6,1.8,2.4c-1.6,2.1-2.1,4.7-2.7,7.2
                                C379.3,231.4,378.1,234,377.2,236.7z M434.4,110.6c-0.7,1.9-1.4,3.8-2.2,5.6c-0.9,1.8-2.4,3.1-3.9,4.5c-1.6,1.5-3.2,3-4.9,4.5
                                c0.3-2.8,0.3-5.7,0.3-8.6c0-0.6-0.9-0.6-0.9,0c0,3.9,0,7.9-0.8,11.8c-0.4,1.9-1,3.8-1.9,5.5c-0.9,1.8-2,3.3-3.2,4.9
                                c-1.2,1.5-2.4,2.9-3.5,4.4c-1.1,1.6-2,3.4-2.8,5.2c-1.6,3.9-2.9,7.8-4.8,11.5c-1,1.9-2.3,3.7-3.4,5.6c-0.9,1.4-1.6,2.9-1.9,4.5
                                c-0.5-1.5-0.9-3.1-1.2-4.6c-0.3-1.5-0.5-3.1-0.8-4.6c-0.2-1.3-0.7-2.5-1.5-3.5c-1.6-1.9-4.3-2.9-5.2-5.3c-0.2-0.5-1-0.3-0.8,0.2
                                c0.9,2.1,2.8,3.2,4.5,4.7c0.9,0.8,1.6,1.7,2,2.9c0.4,1.4,0.5,2.9,0.8,4.3c0.6,3.1,1.4,6.2,2.5,9.2c0.5,1.4,1.1,2.8,1.4,4.3
                                c0.4,1.6,0.3,3.2,0,4.9c-0.4,3-0.9,5.9-1.4,8.9c0-0.1,0-0.2,0-0.3c-0.3-2.9-0.6-5.8-1.8-8.5c-0.2-0.5-1-0.1-0.7,0.4
                                c2.2,4.8,1.2,10.3,2.9,15.3c0.9,2.6,2.5,4.9,3.6,7.4c1.2,2.6,2.1,5.2,3,7.9c0.9,3,1.7,6,2.5,9.1c0.1,0.5,1,0.3,0.8-0.2
                                c-1-3.9-2-7.8-3.3-11.6c1.9,1.1,3.6,2.6,4.5,4.7c0.5,1.2,0.7,2.4,1,3.6c0.3,1.3,0.6,2.6,0.8,4c0.3,2.4,0,4.7-0.8,7
                                c-0.8,2.1-2,3.9-3.4,5.6c-1.4,1.8-3.1,3.4-4.8,4.9c-1.6,1.4-3.5,3.2-5.7,3.6c-1.2,0.2-2.2-0.3-3-1.3s-1.4-2.1-2-3.2
                                c-1.4-2.3-2.6-4.6-3.9-6.9c-1.3-2.4-2.4-4.8-3.6-7.1c-1.2-2.2-2.7-3.9-4.2-5.9c-0.7-0.9-1.4-1.9-1.9-3s-0.8-2.4-1-3.6
                                c-0.5-2.4-0.7-4.8-1.5-7.1c-1.5-4.5-4.6-8.2-7.4-11.9c-2.9-3.9-5.8-8.4-5.8-13.3c0-2.5,1.2-4.8,1.4-7.3c0.2-2.4-1-4.5-1.5-6.8
                                c-0.3-1.2-0.1-2.4,0.2-3.6c0.3-1,0.8-2,1.1-2.9c0.1-0.1,0.1-0.2,0.1-0.3s0.1-0.2,0.1-0.4c0.9-2.4,1.4-5,1.7-7.5
                                c0.1-1.2,0.2-2.5,0.2-3.8c0.2,0.2,0.4,0.4,0.6,0.4c-0.1-0.8-0.4-1.4-0.8-2.1c1.2,0.3,2.1,1.5,2.8,2.4c1.1,1.3,2.2,2.7,3.4,4
                                c1.1,1.3,2.2,2.7,3.4,4c0.9,1,2.4,2.4,2.3,3.9c-2.6,0.1-5.3,0.2-7.9,0.6c-2.2,0.4-4.5,1.2-6,3c-0.3,0.4,0.3,1,0.6,0.6
                                c1.4-1.8,3.6-2.5,5.7-2.8c2.6-0.4,5.3-0.4,8-0.6c0.2,0,0.4-0.1,0.4-0.3c0.4-1.7-0.8-3-1.8-4.2c-1.3-1.6-2.6-3.1-3.9-4.6
                                c-0.6-0.7-1.2-1.4-1.8-2.1c0.9-0.7,1.4-1.6,2-2.5c0.8-1,2-1.3,3.2-1.6c1.4-0.3,2.8-0.1,4.2-0.3c0.7-0.1,1.5-0.2,2.1-0.6
                                c-0.6,1.6-1.2,3.3-1.8,4.9c-0.2,0.5,0.6,0.7,0.8,0.2c0.6-1.7,1.1-3.3,1.8-5c0.3-0.8,0.7-1.5,1.2-2.1s1.3-1.1,2-1.5
                                c2.5-1.6,4.1-4,5.6-6.4c0.4,1.4,0.8,3.1-0.2,4.4c-0.3,0.4,0.4,0.9,0.7,0.4c0.9-1.3,0.7-2.9,0.4-4.3c-0.3-1.5-1-3.3-0.1-4.7
                                c0.4-0.7,1.2-1.1,1.9-1.6c0.4-0.3,0.6-0.6,0.9-1c0.2-0.3,0.4-0.6,0.5-0.9c0.1,2.3,0.2,4.6-0.9,6.7c-0.2,0.5,0.5,0.9,0.7,0.4
                                c1.5-2.9,0.9-6.2,0.9-9.4c0-1.6,0.3-3.2,1-4.7c0.8-1.4,1.9-2.7,3-3.9c2.5-2.7,4.6-5.7,6.8-8.7c2.3-3.2,4.7-6.3,7-9.4
                                c2.5-3.4,5-6.7,7-10.5c0-0.1,0-0.1,0-0.2c1.3,0.6,2.4,1.5,3.5,2.4c1.7,1.3,3.4,2.6,5,3.9c1.6,1.2,3.1,2.6,4.8,3.7
                                c0.5,0.3,1,0.6,1.5,0.8C437.4,102.9,435.9,106.8,434.4,110.6z M448.3,88.8c0.1,0.8-0.1,1.7-0.3,2.4c-0.5,1.8-1.2,3.6-1.9,5.3
                                c-0.4,0.9-0.8,1.8-1.2,2.6c-0.4,0.8-0.9,1.5-1.6,1.9c-1.3,0.8-3,0.8-4.5,0.7c0.3-0.8,0.6-1.6,0.9-2.4c0.8,0.2,1.5,0.2,2.3-0.2
                                c0.5-0.2,0.1-1-0.4-0.7c-1.7,0.8-3.6-0.7-4.9-1.7c-1.6-1.2-3.1-2.4-4.7-3.7c-1.5-1.2-3.1-2.4-4.7-3.6c-1.4-1.1-2.7-2.1-4.5-2.4
                                c-0.5-0.1-0.8,0.7-0.2,0.8c0.2,0.1,0.4,0.1,0.6,0.2c0,0,0,0,0,0.1c-0.3,0.7-0.7,1.3-1.1,1.9c-1.5-1.4-0.8-3.8,0.3-5.2
                                c0.7-0.9,1.5-1.7,2.3-2.6c0.8-0.8,1.6-1.6,2.5-2.3c0.9-0.7,1.9-1.4,3-1.9c0.6-0.2,1.2-0.3,1.7,0s1,0.7,1.5,1
                                c2.2,1.5,4.4,3,6.6,4.5c1.9,1.3,4.5,2.6,5.1,5c0.1,0.5,1,0.3,0.8-0.2c-0.1-0.6-0.4-1.1-0.7-1.5c0.5,0.2,1.1,0.4,1.6,0.6
                                C447.7,87.7,448.2,88.1,448.3,88.8z M488.5,54c-0.7,0.2-1.4,0.2-2.1,0.3c-0.9,0.1-1.7,0.2-2.5,0.2c-1.7,0.1-3.5,0.1-5.2,0.2
                                c-2,0-3.9,0-5.9,0.1c-2,0-4.1,0.1-6.1,0.3c-1.2,0.1-2.4,0.2-3.4,0.7s-1.8,1.3-2.5,2.1c-3.2,3.8-6.1,8-8.9,12.2
                                c-3.1,4.7-5.9,9.6-8.7,14.5c-0.6-0.5-1.2-0.9-1.8-1.3c-2.5-1.7-5-3.4-7.4-5.1c-0.4-0.3-0.8-0.6-1.2-0.8
                                c3.9-4.9,7.8-9.8,11.6-14.6c2-2.5,4-5,6-7.6c2-2.5,3.9-5,5.9-7.4c1-1.1,2-2.1,3.5-2.6c1.5-0.4,3-0.2,4.5,0.1
                                c2.8,0.6,5.5,1.3,8.3,1.9c2.4,0.5,4.9,1,7.3,1.5c1.4,0.3,2.9,0.6,4.3,0.8c0.7,0.1,1.3,0.2,2,0.2c0.5,0,1.1,0.1,1.6,0.2
                                c0.3,0.1,0.6,0.2,0.8,0.4c0.1,0.2,0.1,0.2,0.1,0.3c0-0.1-0.1,0.2,0,0c-0.1,0.1,0,0-0.1,0.1l0,0l0,0c-0.4,0.2-0.9,0.4-1.3,0.5
                                c-3.1,1-6.4,0.6-9.6,0.1c-0.5-0.1-0.8,0.7-0.2,0.8c2.8,0.5,5.8,0.8,8.6,0.2l0,0c0.6-0.1,1.5,0.1,2.1,0.2c0.3,0.1,0.5,0.2,0.6,0.5
                                C489,53.7,488.9,53.9,488.5,54z"/>
                            <path name="IRENE" class="st2" d="M359.4,165.8c-0.4,0.3-0.7,0.6-1.2,0.8s-0.8,0.4-1.3,0.5c-0.2,0.1-0.4,0.3-0.3,0.5s0.3,0.4,0.5,0.3
                                c1.1-0.3,2-0.9,2.9-1.6c0.2-0.1,0.2-0.4,0-0.6C359.8,165.6,359.5,165.6,359.4,165.8z"/>
                            <path name="IRENE" class="st2" d="M375,231.5c-1,3.3-2.8,6.2-4.6,9.1c-0.9,1.5-1.8,3-2.6,4.5c-0.8,1.4-1.4,2.9-1.9,4.5
                                c-0.6,1.7-1.4,3.4-2.8,4.6c-0.4,0.4,0.2,0.9,0.6,0.6c1.2-1,2-2.4,2.6-3.9c0.6-1.6,1.2-3.2,2-4.7c1.6-3.1,3.6-6,5.2-9.1
                                c0.9-1.7,1.8-3.5,2.3-5.4C376,231.2,375.2,230.9,375,231.5z"/>
                            <path name="IRENE" class="st2" d="M336.9,145.6c3.6-0.7,6.9-2.3,9.7-4.7c0.4-0.4-0.2-1-0.6-0.6c-2.6,2.3-5.9,3.8-9.3,4.5
                                C336.1,144.8,336.3,145.6,336.9,145.6z"/>
                            <path name="IRENE" class="st2" d="M335.5,139.2c-0.8,0.5-1.8,0.6-2.7,0.4c-0.2-0.1-0.5-0.1-0.6-0.3c-0.2-0.2-0.1-0.5-0.1-0.8
                                c0.1-0.6,0.1-1.1,0.2-1.7c0.3-2.2,0.5-4.4,0.8-6.6c0.1-0.5-0.8-0.5-0.9,0c-0.3,2.7-0.7,5.4-1,8.1c-0.1,0.5-0.2,1.1,0.2,1.5
                                c0.3,0.4,0.9,0.5,1.4,0.6c1.1,0.2,2.2,0,3.1-0.5C336.4,139.7,336,139,335.5,139.2z"/>
                            <path name="IRENE" class="st2" d="M366,107.9c0,0,3.7,1.6,4.4,4.1c0,0,4.7-5.6-0.9-7.8l-1.4,1.2c0,0,1.1-1.7,0.6-3.3c0,0-0.5-1.6-2-2.2
                                c0,0-5.8-9-15.5-0.6C351.1,99.2,360.7,98.8,366,107.9z"/>
                            <path name="IRENE" class="st2" d="M371.2,181.1c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S372.6,181.1,371.2,181.1z
                                M371.2,185.3c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7S372.1,185.3,371.2,185.3z"/>
                            <path name="IRENE" class="st2" d="M383.2,200.8c0-1.4-1.1-2.5-2.5-2.5s-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5S383.2,202.1,383.2,200.8z
                                M380.7,202.4c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7C382.4,201.7,381.6,202.4,380.7,202.4z"/>
                            <path name="IRENE" class="st2" d="M385,213.8c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C387.5,214.9,386.4,213.8,385,213.8z
                                M385,218c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7S385.9,218,385,218z"/>
                            <path name="IRENE" class="st2" d="M394.4,227c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5C396.9,228.2,395.8,227,394.4,227z
                                M394.4,231.2c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7C396.1,230.5,395.3,231.2,394.4,231.2z"/>
                            <path  name="IRENE" class="st2" d="M397,240.2c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4c2.1,0,4.3-0.8,6.3-2.3c1.5-1.2,2.9-2.8,4.4-5
                                c0.1-0.2,0.1-0.5-0.1-0.6s-0.5-0.1-0.6,0.1c-1.4,2.1-2.7,3.6-4.2,4.8C400.9,239.5,398.9,240.2,397,240.2L397,240.2z"/>
                        </g>
                        <path name="IRENE" class="st3" d="M364.4,170.7c0,0-8.4,1.6-10,1.3c0,0,0.8,6.1,0.3,8.2c0,0,3.5,6.5,3.3,9.7c-0.1,3.3-1,9.6-0.8,11.7
                            c0.2,2.1,1.8,7,1.4,10.7c-0.5,3.7-1.2,8.6,0.7,9.2c1.9,0.6,9.1-0.8,13.6-2.2s5.7-3.2,5.7-3.2s-1.4-4.1-1.7-6.2
                            c-0.4-2.1-1.8-5.5-3.6-8.5c-2.7-4.3-4.4-5.1-6.6-8.6c-3.5-5.3-4.9-10.5-3.4-15.6C364.8,172.1,364.4,170.7,364.4,170.7z"/>
                        <path name="IRENE" class="st3" d="M358,276.8c-1.1,3-2.3,5.9-3.7,8.8c-0.7,1.4-1.4,2.8-2.2,4.1c-0.7,1.2-1.3,2.5-2.6,3.2c-0.5,0.3,0,1,0.4,0.7
                            c1.2-0.7,1.9-1.9,2.6-3c0.8-1.5,1.6-3,2.4-4.5c1.5-3,2.7-6,3.8-9.2C359,276.5,358.2,276.2,358,276.8L358,276.8z"/>
                        <path name="IRENE" class="st3" d="M363.6,276.8c-1.4,4.6-3.3,9.1-5.6,13.3c-0.3,0.5,0.5,0.9,0.7,0.4c2.3-4.3,4.2-8.9,5.7-13.5
                            C364.6,276.5,363.8,276.3,363.6,276.8L363.6,276.8z"/>
                        <path name="IRENE" class="st3" d="M342.1,301.8c5-4,10.9-6.8,17.2-8c0.5-0.1,0.3-0.9-0.2-0.8c-6.4,1.2-12.5,4.1-17.6,8.2
                            C341.1,301.5,341.7,302.1,342.1,301.8L342.1,301.8z"/>
                        <path name="IRENE" class="st3" d="M317.8,257.7c-1.4,2.8-1.4,6-1.4,9c-0.1,3.5-0.1,6.9-0.2,10.4c0,0.5,0.8,0.5,0.9,0c0.1-3.3,0.1-6.6,0.2-10
                            c0.1-3,0-6.2,1.3-9C318.8,257.7,318,257.2,317.8,257.7L317.8,257.7z"/>
                        <path name="IRENE" class="st3" d="M322,267.1c2.1,2.4,5,3.8,8,4.6c1.6,0.5,3.4,0.9,5.1,0.8c1.8-0.1,3.2-1,4.6-2c0.4-0.3,0-1-0.4-0.7
                            c-1.4,1-2.7,1.9-4.5,1.9c-1.6,0-3.2-0.4-4.7-0.8c-2.8-0.8-5.5-2.2-7.5-4.4C322.3,266.1,321.7,266.7,322,267.1L322,267.1z"/>
                        <path name="IRENE" class="st3" d="M340.5,270.8c-1.2,1.6-2.8,3-4.5,4c-0.5,0.3,0,1,0.4,0.7c1.8-1.1,3.4-2.5,4.7-4.2c0.1-0.2,0.2-0.4,0-0.6
                            C341,270.7,340.7,270.6,340.5,270.8L340.5,270.8z"/>
                        <path name="IRENE" class="st3" d="M351.2,329.3c0.1,2.5,0.7,4.9,1.7,7.1c0.2,0.5,1,0.1,0.7-0.4c-1-2.1-1.5-4.4-1.6-6.7
                            C352.1,328.7,351.2,328.7,351.2,329.3L351.2,329.3z"/>
                        <path name="IRENE" class="st3" d="M350.4,339.2c0.4,2.4,1,4.7,1.8,7c0.2,0.5,1,0.3,0.8-0.2c-0.8-2.2-1.4-4.6-1.8-7
                            C351.1,338.5,350.3,338.7,350.4,339.2L350.4,339.2z"/>
                        <path name="IRENE" class="st3" d="M333,349.8c-0.6,1.4-1.7,2.5-3,3.2c-0.5,0.3,0,1,0.4,0.7c1.4-0.8,2.6-2,3.3-3.5c0.1-0.2,0.1-0.5-0.1-0.6
                            C333.4,349.6,333.1,349.6,333,349.8L333,349.8z"/>
                        <path name="IRENE" class="st3" d="M332.5,356.1c-0.8,1.1-1.5,2.2-2.4,3.2c-0.8,1-1.8,1.8-2.8,2.5c-0.2,0.1-0.3,0.4-0.1,0.6
                            c0.1,0.2,0.4,0.3,0.6,0.1c1.1-0.8,2.1-1.6,3-2.6c0.9-1.1,1.7-2.3,2.5-3.4C333.6,356.1,332.8,355.6,332.5,356.1L332.5,356.1z"/>
                        <path name="IRENE" class="st3" d="M372.5,332.1c-1.3,1.7-2.9,3.2-4.7,4.3c-0.5,0.3,0,1,0.4,0.7c1.9-1.2,3.6-2.8,5-4.6c0.1-0.2,0-0.5-0.1-0.6
                            C372.9,331.9,372.7,332,372.5,332.1L372.5,332.1z"/>
                        <path name="IRENE" class="st3" d="M369.6,338.8c-1.5,1.8-3.1,3.5-4.9,5.1c-0.4,0.4,0.2,1,0.6,0.6c1.7-1.6,3.4-3.3,4.9-5.1
                            c0.1-0.2,0.2-0.4,0-0.6C370.1,338.7,369.8,338.7,369.6,338.8L369.6,338.8z"/>
                        <path name="IRENE" class="st3" d="M376.7,272.8c0.3,0.7,0.8,1.3,1.5,1.6c0.7,0.4,1.6,0.6,2.4,0.7c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4
                            c-1.1-0.1-2.6-0.5-3-1.7c-0.1-0.2-0.3-0.4-0.5-0.3C376.8,272.3,376.6,272.6,376.7,272.8L376.7,272.8z"/>
                        <path name="IRENE" class="st0" d="M313.4,428.5c1.1,1.7,3,2.4,5,2.8c2.2,0.4,4.6,0.7,6.9,0.4c0.5-0.1,0.5-0.9,0-0.9c-2.1,0.2-4.2,0-6.3-0.3
                            c-1.8-0.3-3.8-0.9-4.9-2.5C313.8,427.6,313.1,428.1,313.4,428.5L313.4,428.5z"/>
                        <path name="IRENE" class="st0" d="M312.1,436c1.6,1.6,4.4,1.8,6.5,2c1.3,0.1,2.6,0.1,3.8,0c1-0.1,2-0.2,2.9-0.6c0.5-0.2,0.3-1-0.2-0.8
                            c-1.9,0.8-4.2,0.7-6.2,0.6c-1.2-0.1-2.4-0.2-3.6-0.5c-0.9-0.2-1.9-0.6-2.6-1.2C312.4,435,311.7,435.6,312.1,436L312.1,436z"/>
                        <path name="IRENE" class="st0" d="M347.3,415.4c1.5,1.9,4.4,2.5,6.7,2.7c1.3,0.1,2.6,0,3.9-0.2c1.1-0.2,2.4-0.5,3.2-1.4
                            c0.3-0.4-0.4-0.9-0.7-0.4c-0.6,0.8-2,0.9-2.9,1.1c-1.1,0.2-2.2,0.2-3.4,0.1c-1.1-0.1-2.2-0.3-3.2-0.6c-1-0.3-2.1-0.8-2.7-1.6
                            C347.7,414.5,347,415,347.3,415.4L347.3,415.4z"/>
                        <path name="IRENE" class="st0" d="M349.1,419.9c0.7,0.7,1.5,1.3,2.4,1.7c1,0.4,2,0.6,3.1,0.6c0.8,0,1.6,0,2.4-0.2c1.1-0.2,1.9-1.3,2.6-2.1
                            c0.3-0.4-0.3-1-0.6-0.6c-0.6,0.8-1.3,1.7-2.3,1.9c-0.7,0.1-1.4,0.1-2.1,0.1c-0.9,0-1.9-0.2-2.8-0.5c-0.8-0.4-1.5-0.9-2.1-1.5
                            C349.3,419,348.7,419.6,349.1,419.9L349.1,419.9z"/>
                    </g>
                </g>
                <g id="BERE" cursor="pointer" onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onTouchEnd={this.onTouchEnd}>
                    <g>
                        <g>
                            <path name="BERE" class="st0" d="M200.4,130.7c0.1-0.5,0.9-0.3,0.8,0.2c-0.1,0.3-0.1,0.6-0.2,0.9c-0.4,1.9-0.7,3.8-1.2,5.6
                                c1.4,0.3,2.4-0.9,3.1-1.9c0.7-1.1,1.4-2.3,1.9-3.6c0.6-1.3,1.2-2.7,1.3-4.1c0.1-1.1,0-2.8-1.1-3.3c-0.2-0.1-0.2-0.2-0.2-0.3
                                c-0.1,0-0.1-0.1-0.1-0.1l0,0c-0.1,0-0.1,0-0.1,0c-0.1,0.6-0.1,1.3-0.3,2.2c-0.3,1.4-2.4,3.8-2.9,4s-0.1-1.1,0.4-2.7
                                c0.4-1.6,0.1-2.5,0-3.6s0.2-1.1,0.4-2.1s-0.3-2.1-0.4-2.6c-0.1-0.5-1.1-1.8-1.2-2.8c-0.1-1-1.3-3.5-1.6-3.9
                                c-0.3-0.5-0.6-0.9-0.5-1.8s-0.2-1-0.6-1.5s0.4-1.3-0.2-3.6c-0.6-2.3-4.9-2.9-6.4-2.9s-7.1,0.7-9.6,0.2c-2.5-0.4-7.7-0.3-9.8,0.5
                                s-3.3,3.8-4.1,6.1s-0.8,2.2-1.1,3.8c-0.3,1.6-0.9,2.5-1.1,3.6c-0.3,1.1-0.9,4.6-0.7,6.7s0.5,4.8,0,4.8s-0.4-0.5-0.5-1.4
                                s-0.5-2.2-1.4-3.2l-0.1-0.1c0,0,0,0-0.1,0c-0.2,0.1-0.3,0.1-0.5,0.2l0,0c-0.3,0.5-0.4,1.1-0.4,1.6c0,0.6,0,1.2,0.1,1.9
                                c0.2,1.2,0.5,2.4,0.9,3.5c0.4,1.2,0.8,2.4,1.4,3.5c0.4,0.9,1,2.1,2.1,1.9c-0.5-1.9-0.9-3.8-1-5.7c0-0.6,0.8-0.6,0.8,0
                                c0,0.5,0.1,0.9,0.1,1.4c0.6,4.7,2.5,9.3,4.9,13.3c1.2,2.1,2.7,4.1,4.4,5.8c1.5,1.4,3.2,2.7,5.2,3.1c2,0.5,4.4,0.1,6.3-0.5
                                c1.9-0.6,3.6-1.7,5.1-3.1c2.9-2.8,4.6-6.6,5.9-10.4C199.2,137.2,199.8,134,200.4,130.7z"/>
                            <path name="BERE" class="st0" d="M245.5,187.2c-0.8,0.4-1.6,0.8-2.3,1.2c-0.6,0.3-1.4,0.7-2,1.2l0,0c-0.6,0.5-1.2,1.1-1.3,1.9
                                c-0.1,0.7,0.1,1.5,0.4,2.1c0.2,0.7,0.4,1.4,0.4,2.1c0,0.9,0,1.6,0.4,2.4c0.4,0.6,0.8,1.2,1.1,1.9c0.2,0.6,0.2,1.3,0.3,2
                                c0.1,0.8,0.3,1.3,1,1.6c0.5,0.3,1.1,0.5,1.5,1c0.5,0.6,0.4,1.5,0.9,2c1.1,1.2,3.4,0.6,4.8,0.3c1.4-0.3,3-0.6,4.2-1.5
                                c0.6-0.4,1-0.9,1.3-1.5c0.3-0.8,0.2-1.7,0.2-2.5c0-1.7,0.7-3.5,0.3-5.2c-0.2-0.8-0.6-1.5-1.1-2.2c-0.5-0.6-0.9-1.1-1.1-1.9
                                c-0.2-0.8-0.1-1.6-0.4-2.3c-0.3-0.8-0.9-1.5-1.5-2.1c-1.1-1.1-2.3-2.1-3.6-2.9c-1.2-0.7-2.6-1-3.4-2.3c-0.8-1.2-1-2.6-1-3.9
                                c0-1.5,0.2-2.9,0.1-4.4c0-0.5,0-1.2-0.3-1.6c-0.3-0.5-1-0.3-1.5-0.2c-1.2,0.4-1.7,1.8-1.8,3c-0.1,1.5,0,3.1,0,4.6
                                c0.1,1.7,0.2,3.5-0.1,5.2c-0.1,0.7-0.3,1.4-0.3,2.1c0,0.5,0.2,0.9,0.4,1.4c1.2-0.9,2.6-1.6,4-2.2
                                C245.6,186.2,246,186.9,245.5,187.2z"/>
                            <path name="BERE" class="st0" d="M107,200.3c0.6-0.1,1.1-0.3,1.5-0.7s0.4-0.9,0.5-1.4c0.2-0.9,0.7-1.4,1.5-1.9c0.5-0.3,0.9-0.5,1-1.1
                                c0.1-0.4,0.1-0.9,0.1-1.4c0-0.8,0.1-1.5,0.7-2.1s1.3-0.9,1.3-1.8c0-0.6-0.1-1.2-0.1-1.8c0-0.5,0.1-1,0.2-1.4c0.2-0.9,0.6-2,0-2.9
                                c-0.5-0.9-1.4-1.4-2.3-1.8c-1.1-0.6-2.2-1.1-3.4-1.5c-0.5-0.2-0.3-1,0.2-0.8c1.1,0.5,2.3,0.9,3.4,1.5c0.9,0.5,1.9,1,2.5,1.8
                                c0.7,0.8,0.8,1.8,0.6,2.8c-0.1,0.6-0.3,1.1-0.4,1.7c-0.1,0.6-0.1,1.1-0.1,1.7c0.1,1,0,1.8-0.8,2.6c-0.3,0.3-0.7,0.5-1,0.9
                                s-0.3,0.9-0.3,1.3c0,0.9,0,1.9-0.7,2.6c-0.3,0.3-0.8,0.5-1.2,0.8c-0.5,0.4-0.7,0.8-0.8,1.4v0.1c0.8,0,1.6,0.1,2.3-0.2
                                c1.1-0.3,1.7-1.3,2.3-2.1c0.6-0.9,1.2-1.8,1.7-2.7c0.7-1.4,1.2-2.8,1.6-4.4c0.3-1.4,0.5-2.8,0.4-4.2c-0.1-1-0.4-1.8-1.1-2.5
                                c-0.6-0.6-1.4-1-2.1-1.4c-0.5-0.4-1.2-0.8-1.5-1.4c-0.3-0.7-0.4-1.6-0.6-2.3c-0.4-1.8-0.8-3.6-1.1-5.5c-0.2-1.7-0.2-3.6-0.9-5.2
                                c-0.3-0.7-0.9-1.5-1.6-1.8c-0.6-0.3-1.6-0.4-1.9,0.3c-0.3,0.8-0.1,1.8,0,2.6c0.1,1,0.3,2,0.3,3c0.1,1.8,0.1,4-1.4,5.3
                                c-0.7,0.6-1.5,0.9-2.2,1.4c-0.9,0.6-1.7,1.3-2.5,2.1s-1.5,1.6-2,2.5c-0.6,1-0.5,2.1-0.6,3.2c-0.1,1.1-0.8,1.8-1.3,2.8
                                s-0.7,2.1-0.5,3.2c0.2,1.1,0.5,2.1,0.6,3.2c0.1,1.1,0,2.2,0.6,3.2c1,1.6,3.3,1.9,5,2.2C104.5,200.3,105.8,200.5,107,200.3z"/>
                            <path name="BERE" class="st0" d="M173.4,154.9c0.8,0.4,1.6,0.9,2.5,1.2c2.1,0.8,4.6,1.6,5.5,3.9c0.2,0,0.3,0,0.5,0s0.4,0,0.6,0
                                c0-1.8,2.6-2.4,3.9-2.8c1.5-0.5,3.1-0.8,4.7-1.3c-3.1,0.4-6.1,0.1-8.4-0.5c-1.2,0-2.3-0.2-3.4-0.7c-2-0.9-3.8-2.4-5.2-4
                                c-0.2-0.3-0.4-0.6-0.7-0.8C173.6,151.5,173.6,153.2,173.4,154.9z"/>
                            <path name="BERE" class="st0" d="M262,201.8c-0.5-0.5-1-1.1-1.4-1.7c-0.8-1.3-1.3-2.8-2.2-4.2c-0.6-0.9-1.3-1.9-2.4-2.2
                                c0.3,0.4,0.6,0.8,0.9,1.3c0.4,0.8,0.6,1.6,0.6,2.4c0,0.9-0.2,1.9-0.4,2.8c-0.2,1.7,0.3,3.5-0.8,5c-0.9,1.1-2.2,1.8-3.5,2.1
                                c-0.9,0.3-1.8,0.5-2.7,0.6s-1.8,0.3-2.7,0.3c-0.8,0-1.5-0.3-2.1-0.8c-0.6-0.6-0.6-1.3-1-2c-0.3-0.7-1.2-0.9-1.7-1.2
                                c-0.6-0.4-0.9-1-1-1.7c-0.1-0.8-0.1-1.6-0.4-2.3s-0.9-1.3-1.2-2c-0.1-0.2-0.1-0.4-0.2-0.6c-0.2,0.9-0.2,1.8-0.2,2.7
                                c0,0.8-0.1,1.5-0.1,2.3s0,1.5,0.5,2.1c0.4,0.6,1,1,1.5,1.5c0.6,0.5,1.1,1,1.7,1.6c1.1,1,2.2,2.1,3.4,3.1c0.4,0.4-0.2,1-0.6,0.6
                                c-1.3-1.2-2.6-2.4-3.8-3.5c-0.5-0.5-1.1-1-1.6-1.4c0,3.1-0.6,6.3-1.8,9.1c-0.7,1.6-1.8,3-2.1,4.7c-0.4,1.9,0.4,3.9,1.1,5.6
                                c0.2,0.5-0.5,0.9-0.7,0.4c-0.7-1.7-1.5-3.5-1.4-5.4c0.1-1.8,1.1-3.2,1.9-4.8c1.6-3.3,2.3-6.9,2.2-10.6c-0.3-0.3-0.5-0.6-0.7-1
                                c-0.3-0.7-0.3-1.4-0.3-2.2c0-0.9,0.1-1.7,0.1-2.5c0.1-1.5,0.2-3,0.8-4.4l0.1-0.1c-0.1-0.8-0.4-1.6-0.6-2.4
                                c-0.2-0.7-0.3-1.5-0.1-2.2s0.8-1.3,1.3-1.8c-0.2-0.6-0.5-1.2-0.5-1.9c-0.1-0.7,0.1-1.4,0.2-2.1c0.1-0.6,0.2-1.2,0.2-1.8
                                c-0.1,0-0.1-0.1-0.1-0.2c-0.6-1.2-1.4-2.2-2.1-3.2c-0.7-1-1.3-2.1-1.9-3.2c-0.5-1-1-2.1-1.8-2.9c-0.8-0.8-1.7-1.4-2.6-2
                                c-0.8-0.6-1.6-1.1-2.3-1.8c-0.8-0.9-1.4-1.9-2.1-2.8c-0.8-0.9-1.8-1.6-2.8-2.3c-1-0.8-2-1.6-3-2.3c-1.9-1.4-4.2-2.6-6.7-2
                                c-0.5,0.1-0.7-0.6-0.3-0.8c-0.4-1.6-1.3-3.1-2.4-4.3c-1.1-1.2-2.4-2-3.6-3.1c-1.3-1.1-1.9-2.6-2.8-4c-0.4-0.7-1-1.3-1.7-1.7
                                c-0.8-0.4-1.7-0.4-2.5-0.3c-1.6,0.2-3.2,0.6-4.8,0.3c0,2.1,0.1,4.2,0.5,6.2c1.3-0.9,2.4-2.1,3.1-3.6c0.2-0.5,1-0.1,0.7,0.4
                                c-1.4,3-4.4,4.8-7.3,5.9c-1.9,0.7-3.8,1.1-5.7,1.6c-0.9,0.2-1.9,0.5-2.8,0.9c-0.9,0.3-2.3,0.7-2.2,1.9c0,0.1,0,0.1,0,0.2
                                c0.1,0.1,0.2,0.3,0.3,0.5c0.3,0.8,0.3,1.7,0.4,2.5c0.5,3,0.9,6.1,1.2,9.1c0.1,0.9,0.3,1.9,0.1,2.8l0,0c0,1.1,0,28,0.1,29.3
                                s0.1,2.3-0.8,2.5h-0.2c-0.7,0.1-1.3,0.2-1.8,0.2s-0.9-0.1-1.2-0.4l-0.1-0.1c-0.2-0.5-0.4-1.6-0.4-3.2c0-1,0.9-26.2,1-27.8
                                c-0.2-0.8-0.3-1.8-0.4-2.6c-0.2-1.7-0.4-3.4-0.6-5.1c-0.2-1.5-0.3-2.9-0.5-4.4c-0.1-0.8-0.2-1.6-0.2-2.4c0-0.4,0.2-0.7,0.4-0.9
                                c-0.4-1.2-1.6-2-2.7-2.5c-1.2-0.6-2.5-0.9-3.7-1.5s-2.3-1.2-3.4-2.1c-0.9-0.7-1.7-1.4-1.8-2.6c-0.1-0.6,0.8-0.5,0.8,0
                                c0.1,1.2,1.7,2.1,2.6,2.7c0.1,0,0.1,0.1,0.2,0.1c0.3-2,0.1-4.1-0.6-6.1c-1.3-0.3-2.4,0.8-3.1,1.8c-0.4,0.5-0.7,1.1-1.1,1.6
                                c-0.4,0.5-0.8,1-1.3,1.4c-1.6,1.9-2.2,4.4-1.4,6.7c0.2,0,0.5,0,0.7,0c0.6,0,0.6,0.9,0,0.9c-3.9-0.1-7.6,1.1-11,3
                                c-1.5,0.8-2.8,1.9-4.2,2.9c-0.7,0.5-1.5,1-2.3,1.4s-1.5,0.7-2.2,1.1c-1.4,0.9-2.5,2.3-3.4,3.7c-1,1.3-1.8,2.7-2.7,4.1
                                c-3.1,5.1-5.7,10.5-9.5,15.1c0.5-0.2,0.9-0.4,1.4-0.7c1.3-0.6,2.8-1.4,3.4-2.8c0.2-0.5,1-0.1,0.7,0.4c-0.6,1.3-1.8,2-3,2.7
                                c-1.3,0.7-2.7,1.3-4,2c-2.3,1.2-4.8,3.2-4.9,6.1c0.1,0.2,0.2,0.4,0.3,0.6c0.5,0.9,0.3,2,0.1,3c0.7,0.1,1.2,0.6,1.4,1.3
                                c0.3,0.9,0.6,1.8,0.8,2.6c0.5,1.8,1,3.7,2,5.3c0.3,0.5-0.4,0.9-0.7,0.4c-0.8-1.4-1.4-2.9-1.8-4.5c-0.2-0.8-0.4-1.5-0.7-2.3
                                c-0.2-0.5-0.4-2.1-1.2-2.1c-0.8,5.1-2,10.1-1.9,15.3c0,0.6-0.8,0.6-0.8,0c-0.1-2.8,0.2-5.5,0.7-8.2c0.4-2.5,0.9-4.9,1.3-7.4
                                c0.1-0.6,0.2-1.3,0.3-1.9c0-0.6-0.1-1-0.4-1.5c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.1-0.1-0.2l0,0c-0.2-0.9-0.1-1.9-0.1-2.7
                                c0-1.1,0-2.2-0.1-3.3s-0.6-1.9-1.4-2.7c-0.4-0.3-0.8-0.6-1-1.1c-0.2-0.4-0.3-0.9-0.6-1.3c-0.3-0.6-0.7-1-1.3-1.1
                                c0,0.1,0,0.2,0,0.2c-0.2,1.4-0.5,2.8-1,4.1c-0.7,2-1.8,3.9-3.1,5.6c-0.6,0.8-1.2,1.6-2.2,1.9c-0.9,0.3-1.8,0.2-2.7,0.2
                                c-0.1,0-0.2,0-0.2-0.1c-0.3,0.5-0.7,1-1.3,1.2c-1.1,0.4-2.3,0.3-3.5,0.2s-2.4-0.3-3.6-0.6c-0.4-0.1-0.7-0.2-1.1-0.4
                                c0.2,1.7,1.3,3.3,2.7,4.4c0.1,0,0.1,0.1,0.1,0.1s0.1,0,0.1,0.1c0.8,0.6,1.7,1,2.6,1.3c0.8,0.3,1.6,0.6,2.2,1.4
                                c0.3,0.4-0.4,0.9-0.7,0.4c-0.5-0.8-1.7-0.9-2.5-1.3c-0.6-0.2-1.1-0.5-1.6-0.8c0,2.7,0.3,5.3,1.2,7.9c0.8,2.4,2.2,4.7,3.7,6.8
                                c1.4,1.9,2.9,3.7,4.5,5.4c1.5,1.6,2.9,3.4,4.8,4.6c1.1,0.6,2.2,1,3.5,1c1.3,0.1,2.6-0.1,3.9-0.2c2.5-0.1,4.9,0.8,7.4,0.6
                                c1.2-0.1,2.3-0.4,3.2-1.1c0.8-0.7,1.4-1.5,2.2-2.3s1.8-1.2,2.8-1.8c0.9-0.5,1.7-1.1,2.3-2c1.2-1.6,1.9-3.5,2.8-5.3
                                c1-2.1,2.5-3.8,3.9-5.7c0.1-0.1,0.1-0.1,0.2-0.1c0-0.5-0.1-1.1,0-1.6c0.1-3.6,1.1-7.1,1.4-10.7c0.1-1.8,0.4-3.5,0.9-5.2
                                c0.5-1.6,1-3.3,0.4-5c-0.2-0.5,0.7-0.7,0.8-0.2c0.6,1.8,0.1,3.7-0.4,5.4c-0.3,1-0.5,1.9-0.7,2.9c-0.1,1.1-0.2,2.1-0.2,3.2
                                c-0.3,3.6-1.4,7.1-1.3,10.8c0.1,1.8,0.5,3.4,1.2,5c0.7,1.5,1.5,2.9,2.2,4.3c0.7,1.5,1.2,3.1,1.6,4.7s0.5,3.3,1,4.9
                                c0.4,1.1,1,2.2,1.5,3.2l0,0l0,0c0.3,0.5,0.5,1,0.8,1.5c0.7,1.5,1,3.1,1.1,4.8c0,0.6-0.8,0.6-0.8,0c-0.1-0.9-0.2-1.8-0.4-2.6
                                c0,1.1-0.2,2.3-0.4,3.4c-0.5,2.2-1.3,4.4-1.8,6.6c-0.5,2.3-0.6,4.8-0.7,7.1c-0.2,4-0.9,8-1,12c1.5-2.9,3.1-5.9,3.6-9.2
                                c0.1-0.5,0.9-0.3,0.8,0.2c-0.2,1.1-0.5,2.3-0.9,3.3c-0.8,2.3-2,4.4-3.1,6.5c-1.2,2.2-2.2,4.5-3,6.9c-0.7,2.2-1.2,4.6-1.5,6.9
                                c1.3,0.6,2.6,1.1,3.9,1.6c0.1-0.2,0.3-0.4,0.6-0.2c1.7,0.9,3.4,1.6,5.3,2.1c1.7,0.4,3.4,0.5,5.1,0.8c1.7,0.2,3.4,0.6,5,1
                                c3.3,0.7,6.8,1.5,10.2,1c0.5-0.1,0.7,0.7,0.3,0.8c14.1,0.6,27-2.1,37.1-5.2c2.5-1,5-1.9,7.5-2.9c0.2-0.1,0.3,0,0.4,0.1
                                c1.6-0.6,3-1.2,4.3-1.8c-0.1-4.2-2.8-8.1-5.1-11.4c-0.3-0.5,0.4-0.9,0.7-0.4c0.6,0.9,1.3,1.9,1.9,2.8c0-1.3,0.2-2.6,0.1-3.9
                                c-0.2-2-0.5-3.9-0.8-5.8c-0.2-1.7-0.4-3.4-0.9-5c-0.5-1.5-1.3-2.9-1.9-4.4c-1.2-2.9-1.4-6-1.5-9.1c-0.1-3.2,0.1-6.4-0.3-9.5
                                c-0.2-1.9-0.8-3.9-1-5.8c-0.4,1.2-0.8,2.4-0.8,3.6c0,0.6-0.9,0.6-0.9,0c0-1.6,0.5-3.1,1-4.6c0.3-0.8,0.6-1.5,0.9-2.2
                                c0,0,0,0,0-0.1l0,0c0.4-1,0.8-1.9,1.2-2.9c0.6-1.4,1.2-2.8,1.6-4.3c0.5-2,0.6-4.1,0.5-6.1c0-0.2,0-0.4-0.1-0.5
                                c-5.4,0.8-10.8,1-16.2,1c-9.9,0-19.8-0.7-29.7,0.1c-4.8,0.4-9.6,1.2-14.3,2.7c-4.2,1.3-9.4,3.3-13.4,0.3c-0.4-0.3,0-1.1,0.4-0.7
                                c2.1,1.6,4.9,1.7,7.4,1.2c2.7-0.6,5.2-1.6,7.9-2.3c9.3-2.4,19-2.5,28.6-2.3c9.7,0.2,19.6,0.7,29.2-0.8c-0.4-3.5-1.5-6.8-2.2-10.2
                                c-0.2-1-0.4-2.1-0.4-3.1s0.1-2,0.2-3c0.1-2.1-0.5-4-1-6.1c-0.9-3.9-0.4-7.9,1.2-11.5c0.2-0.5,1-0.1,0.7,0.4
                                c-1,2.1-1.5,4.4-1.6,6.6c-0.1,2.3,0.6,4.4,1.1,6.6c0.2,0.9,0.4,1.9,0.4,2.9s-0.1,2-0.1,3c-0.1,2.2,0.4,4.1,0.9,6.2
                                c0.5,2.1,1.1,4.2,1.4,6.3c0.1-3.2,0.2-6.4,0.5-9.6c0.1-0.5,0.9-0.6,0.9,0c-0.1,0.9-0.1,1.8-0.2,2.7c-0.2,3.7-0.4,7.5-0.3,11.2
                                c0,0.9,0,1.9,0.1,2.9c0,0.9,0,1.8,0.1,2.6c0.2,1.6,1.5,2.3,2.8,3.2s2,2.3,2.9,3.6c1,1.5,2,3,2.9,4.5c0.4,0.7,0.9,1.4,1.3,2.2
                                c0.3,0.6,0.6,1.3,1,1.9c0.4,0.7,1,1.2,1.8,1.4c0.7,0.2,1.4,0.2,2.2,0.2c2.7,0.1,5.5-0.4,7.8-1.9c1.2-0.8,2-1.9,2.8-3.1
                                c0.8-1.3,1.9-2.2,3.2-3.1c2.6-1.7,4.9-3.9,6-6.8c0.6-1.6,0.9-3.4,1.1-5.1c0.3-1.8,0.6-3.6,0.9-5.4c0.1-0.9,0.3-1.9,0.5-2.8
                                c0.1-0.9,0.3-1.8,0-2.6C263.1,202.9,262.6,202.3,262,201.8z M149,199.9c-0.4-2-1.4-3.9-2.1-5.8c-0.7-1.8-1-3.6-0.9-5.5
                                s0.9-3.7,1.1-5.7c0.2-2,0.4-4.1,0.6-6.1c0.2-1.8,0.4-3.8-0.4-5.5c-0.2-0.5,0.5-0.9,0.7-0.4c0.8,1.9,0.6,4,0.5,6.1
                                c-0.2,2.1-0.3,4.1-0.6,6.1c-0.2,2-1,3.9-1.1,5.9s0.4,3.8,1.1,5.6c0.7,1.7,1.5,3.4,1.8,5.2C149.9,200.2,149.1,200.5,149,199.9z
                                M211.8,261.7c-0.1,1.2-0.5,2.7-1.7,3.4c-1,0.6-2.2,1-3.2,1.4c-3.7,1.4-7.5,2.6-11.4,3.4c-1.9,0.4-3.8,0.7-5.6,0.8
                                c-2,0.1-4,0-6-0.1c-4-0.2-8-0.6-12-1.3c-1.1-0.2-2.3-0.3-3.4-0.6c-1-0.3-1.6-1.1-2-2.1c-0.5-1.5-0.6-3.1-0.7-4.7
                                c0-1.5,0-3.1,0.1-4.6c0.3-4.6,0.1-9.2-0.7-13.8c-0.9-5.3-2.5-10.5-4.2-15.6c-0.2-0.5,0.7-0.8,0.8-0.2c0.4,1.3,0.8,2.6,1.2,3.9
                                c1.5,4.9,2.8,9.8,3.4,14.9c0.3,2.6,0.4,5.3,0.4,7.9c0,1.4-0.2,2.8-0.2,4.3c-0.1,1.6-0.1,3.2,0,4.9c0.1,1.3,0.2,2.9,1.1,4
                                c0.2,0.2,0.4,0.4,0.6,0.5c-0.1,0,0.2,0.1,0.3,0.1c0.4,0.1,0.9,0.2,1.3,0.3c1.5,0.3,3,0.5,4.5,0.7c4,0.5,8.1,0.9,12.2,1
                                c1.9,0,3.7,0,5.6-0.2c2-0.3,4-0.7,5.9-1.2c3.4-0.9,6.8-2,10.1-3.4c0.5-0.2,0.9-0.4,1.4-0.6c0.4-0.2,0.5-0.3,0.8-0.6
                                c1-1.6,0.8-3.9,0.5-5.7c-0.3-2.3-0.9-4.5-1.3-6.8c-0.5-2.9-0.8-5.7-1-8.6c-0.4-5.9-0.2-11.8,0.3-17.7c0.1-0.5,0.9-0.6,0.9,0
                                c-0.1,1.5-0.3,3-0.4,4.4c-0.4,6-0.2,12.1,0.4,18c0.3,2.8,0.8,5.6,1.4,8.3C211.6,257.7,212,259.7,211.8,261.7z M209.3,220.4
                                c0.4-1.3,0.8-2.5,1.2-3.8c0.4-1.2,0.9-2.5,1.1-3.8c0.1-0.5,0.9-0.3,0.8,0.2c-0.2,1.3-0.7,2.5-1.1,3.8c-0.4,1.3-0.8,2.5-1.2,3.8
                                C210,221.1,209.2,220.9,209.3,220.4z M169.6,224.4c8.5-0.7,17-1.3,25.5-1.6c4.1-0.2,8.2-0.2,12.2-0.4c3.3-0.1,6.9-0.6,9.5-2.9
                                c0.4-0.4,1,0.2,0.6,0.6c-2.9,2.6-6.9,3-10.7,3.1c-4.2,0.1-8.5,0.2-12.7,0.4c-4.4,0.2-8.7,0.4-13.1,0.7c-4.3,0.3-8.6,0.7-12.9,1
                                c-4,0.3-8.1,0.4-11.8-1c-0.5-0.2-0.3-1,0.2-0.8C160.7,225.1,165.2,224.8,169.6,224.4z M156.5,217.2c0.7,2,1.6,3.9,2.9,5.7
                                c0.1,0.2,0,0.5-0.1,0.6c-0.2,0.1-0.4,0-0.6-0.1c-1.3-1.8-2.3-3.8-3-5.9C155.5,216.9,156.3,216.7,156.5,217.2z M178.6,170.2
                                c-2.5-1.6-4.8-3.6-6.8-5.9c-0.9-1.1-1.7-2.3-2.5-3.5c-0.8-1.1-1.7-2.2-3-2.6c-0.5-0.2-0.3-1,0.2-0.8c2.7,0.8,3.9,3.9,5.6,5.9
                                c1.9,2.4,4.3,4.5,6.9,6.2C179.5,169.8,179.1,170.5,178.6,170.2z M209.4,159.5c-1.7-0.1-3.7-0.1-5,1.1c-0.6,0.5-1.1,1.2-1.7,1.6
                                c-0.6,0.5-1.4,0.8-2,1.2c-1.4,0.9-2.6,2.1-3.7,3.3c-2.2,2.1-4.6,4.4-7.8,4.5c-0.6,0-0.6-0.8,0-0.9c1.9-0.1,3.6-1.1,5-2.2
                                c1.3-1,2.3-2.2,3.5-3.4c1.2-1.1,2.4-2.1,3.8-2.9c1.4-0.8,2.1-2.2,3.6-2.8c1.4-0.6,2.9-0.6,4.4-0.5
                                C209.9,158.7,209.9,159.5,209.4,159.5z M226.4,167.6c-0.8,1.9-1.6,3.9-2.3,5.9c-0.2,0.5-1,0.3-0.8-0.2c0.7-2,1.5-3.9,2.3-5.9
                                c0.1-0.2,0.3-0.4,0.5-0.3C226.3,167.1,226.5,167.4,226.4,167.6z"/>
                        </g>
                        <path name="BERE" class="st1" d="M181.4,155c0,0,9.1,1.8,15.1-9.4c0,0,1,8.4,0.6,8.6C191.9,156.8,185,156.4,181.4,155z"/>
                        <path name="BERE" class="st3" d="M149.8,282.8c-1-0.4,0.2,2.7,0.7,5.1c0.8,5.1,58.8,19.2,78.4-2.6c0,0,1.5-4.4,1.4-5.4
                            C215.7,286.5,182.1,297.4,149.8,282.8z"/>
                        <path name="BERE" class="st3" d="M182.3,175.7c0.2,0.6,2.1,0.4,2.5-0.5s-1.4-13.5-1.7-14.3s-1-0.6-1.3-0.6c-0.7,0-1.2,0.1-1.2,0.8
                            C180.6,161.9,182.1,175.1,182.3,175.7z"/>
                        <path name="BERE" class="st3" d="M182.3,175.7c0,0-0.9,26.8-1,27.9c0,1.1,0.1,2.6,0.3,3.1c0.5,0.5,2.2,0.1,2.8,0c0.5-0.1,0.5-0.7,0.5-2
                            c-0.1-1.3-0.1-29.3-0.1-29.3"/>
                        <g>
                            <path name="BERE" class="st2" d="M179,169.5c-2.6-1.6-5-3.7-6.9-6.2c-1.6-2.1-2.9-5.1-5.6-5.9c-0.5-0.2-0.8,0.7-0.2,0.8
                                c1.3,0.4,2.2,1.5,3,2.6c0.8,1.2,1.6,2.4,2.5,3.5c1.9,2.3,4.2,4.3,6.8,5.9C179.1,170.5,179.5,169.8,179,169.5z"/>
                            <path name="BERE" class="st2" d="M209.4,158.6c-1.5-0.1-3-0.1-4.4,0.5c-1.5,0.6-2.2,2-3.6,2.8c-1.4,0.8-2.7,1.7-3.8,2.9
                                c-1.2,1.1-2.3,2.3-3.5,3.4c-1.4,1.2-3.1,2.1-5,2.2c-0.6,0-0.6,0.9,0,0.9c3.2-0.2,5.7-2.4,7.8-4.5c1.2-1.2,2.3-2.4,3.7-3.3
                                c0.7-0.4,1.4-0.8,2-1.2c0.6-0.5,1.1-1.1,1.7-1.6c1.4-1.1,3.3-1.1,5-1.1C209.9,159.5,209.9,158.7,209.4,158.6z"/>
                            <path name="BERE" class="st2" d="M156.2,224.4c3.8,1.4,7.9,1.3,11.8,1c4.3-0.3,8.6-0.7,12.9-1c4.4-0.3,8.7-0.5,13.1-0.7
                                c4.2-0.2,8.5-0.2,12.7-0.4c3.7-0.1,7.7-0.6,10.7-3.1c0.4-0.4-0.2-1-0.6-0.6c-2.6,2.3-6.2,2.7-9.5,2.9c-4.1,0.2-8.2,0.2-12.2,0.4
                                c-8.5,0.3-17,0.9-25.5,1.6c-4.4,0.4-8.9,0.7-13.2-0.9C156,223.4,155.7,224.2,156.2,224.4z"/>
                            <path name="BERE" class="st2" d="M175.2,139.7c0.2,0,0.3,0,0.5,0.1c1.8,1.8,4.2,3.4,6.8,3.6c3.1,0.3,5.9-1.6,7.8-3.8c0.1,0,0.2,0,0.3,0
                                c0.5-0.1,0.3-0.9-0.2-0.8c-0.1,0-0.2,0-0.3,0s-0.1,0-0.2,0c-4.9,0.6-9.8,0.6-14.7,0.1C174.6,138.7,174.6,139.6,175.2,139.7z
                                M189,139.7c-1.7,1.7-4,3-6.5,2.8c-1.9-0.2-3.8-1.3-5.4-2.6C181.1,140.2,185,140.1,189,139.7z"/>
                            <path name="BERE" class="st2" d="M146.9,188.9c0.1-2,0.9-3.9,1.1-5.9c0.2-2,0.4-4.1,0.6-6.1c0.2-2.1,0.4-4.1-0.5-6.1
                                c-0.2-0.5-0.9-0.1-0.7,0.4c0.8,1.7,0.5,3.7,0.4,5.5c-0.2,2.1-0.3,4.1-0.6,6.1c-0.2,1.9-0.9,3.7-1.1,5.7c-0.2,1.9,0.2,3.8,0.9,5.5
                                c0.7,1.9,1.6,3.8,2.1,5.8c0.1,0.5,0.9,0.3,0.8-0.2c-0.4-1.8-1.1-3.5-1.8-5.2C147.3,192.7,146.8,190.9,146.9,188.9z"/>
                            <path name="BERE" class="st2" d="M180.3,289.1c-3.4,0.5-6.8-0.3-10.2-1c-1.7-0.4-3.3-0.7-5-1c-1.7-0.2-3.4-0.3-5.1-0.8
                                c-1.8-0.5-3.6-1.2-5.3-2.1c-0.5-0.3-0.9,0.5-0.4,0.7c1.5,0.8,3,1.5,4.7,2s3.5,0.7,5.2,0.9c3.5,0.4,7,1.5,10.5,2
                                c1.9,0.3,3.9,0.3,5.8,0.1C181.1,289.9,180.9,289,180.3,289.1z"/>
                            <path name="BERE" class="st2" d="M202.9,288.8c-2.2,0.4-4.3,0.7-6.5,0.8c-0.6,0-0.6,0.9,0,0.9c3.9-0.2,7.6-1,11.4-1.8
                                c1.9-0.4,3.8-0.8,5.6-1.5c1.9-0.7,3.8-1.4,5.6-2.1c2.1-0.8,4.2-1.6,6.4-2.4c0.5-0.2,0.3-1-0.2-0.8c-3.7,1.4-7.3,2.8-11,4.2
                                C210.5,287.4,206.7,288.1,202.9,288.8z"/>
                            <path name="BERE" class="st2" d="M210.1,265c1.2-0.7,1.6-2.1,1.7-3.4c0.2-2-0.1-4-0.6-6c-0.6-2.8-1.1-5.5-1.4-8.3c-0.7-6-0.8-12-0.4-18
                                c0.1-1.5,0.2-3,0.4-4.4c0.1-0.6-0.8-0.5-0.9,0c-0.6,5.9-0.7,11.8-0.3,17.7c0.2,2.9,0.5,5.8,1,8.6c0.4,2.3,1,4.5,1.3,6.8
                                c0.3,1.8,0.5,4.1-0.5,5.7c-0.3,0.4-0.3,0.4-0.8,0.6c-0.4,0.2-0.9,0.4-1.4,0.6c-3.2,1.4-6.6,2.5-10.1,3.4c-1.9,0.5-3.9,1-5.9,1.2
                                c-1.8,0.2-3.7,0.3-5.6,0.2c-4.1-0.1-8.1-0.5-12.2-1c-1.5-0.2-3-0.4-4.5-0.7c-0.4-0.1-0.9-0.2-1.3-0.3c-0.1,0-0.4-0.1-0.3-0.1
                                c-0.2-0.1-0.4-0.3-0.6-0.5c-0.9-1-1-2.7-1.1-4c-0.1-1.6-0.1-3.2,0-4.9c0.1-1.4,0.2-2.8,0.2-4.3c0.1-2.6-0.1-5.3-0.4-7.9
                                c-0.6-5.1-1.9-10-3.4-14.9c-0.4-1.3-0.8-2.6-1.2-3.9c-0.2-0.5-1-0.3-0.8,0.2c1.7,5.1,3.3,10.3,4.2,15.6c0.8,4.6,0.9,9.2,0.7,13.8
                                c-0.1,1.5-0.2,3.1-0.1,4.6c0,1.5,0.1,3.2,0.7,4.7c0.4,1,0.9,1.8,2,2.1c1.1,0.3,2.3,0.5,3.4,0.6c4,0.6,8,1,12,1.3
                                c2,0.1,4,0.2,6,0.1c1.9-0.1,3.8-0.4,5.6-0.8c3.9-0.8,7.7-2,11.4-3.4C207.9,266,209,265.6,210.1,265z"/>
                            <path name="BERE" class="st2" d="M210.1,220.6c0.4-1.3,0.8-2.5,1.2-3.8c0.4-1.2,0.9-2.5,1.1-3.8c0.1-0.5-0.7-0.8-0.8-0.2
                                c-0.2,1.3-0.7,2.5-1.1,3.8c-0.4,1.3-0.8,2.5-1.2,3.8C209.2,220.9,210,221.1,210.1,220.6z"/>
                            <path name="BERE" class="st2" d="M158.7,223.3c0.1,0.2,0.4,0.3,0.6,0.1c0.2-0.1,0.3-0.4,0.1-0.6c-1.3-1.7-2.2-3.6-2.9-5.7
                                c-0.2-0.5-1-0.3-0.8,0.2C156.4,219.6,157.4,221.6,158.7,223.3z"/>
                            <path name="BERE" class="st2" d="M263.9,202.7c-0.4-0.7-0.9-1.2-1.4-1.8c-1.2-1.3-1.8-2.8-2.6-4.4c-0.6-1.3-1.4-2.6-2.6-3.4
                                c-0.5-0.3-1.1-0.5-1.7-0.5c-0.4-0.8-0.3-1.7-0.5-2.5c-0.3-1.6-1.8-3-3-4.1s-2.6-2-4.1-2.7c-0.8-0.4-1.4-0.8-1.8-1.6
                                c-0.4-0.7-0.6-1.6-0.6-2.4c-0.1-1.6,0.1-3.2,0.1-4.8c0-1.1,0.1-2.7-1.2-3.2c-1.2-0.4-2.6,0.3-3.3,1.3c-0.9,1.1-0.9,2.5-0.9,3.9
                                c0,1.7,0.1,3.4,0.1,5.1c-0.4-0.7-0.9-1.3-1.4-2c-0.7-0.9-1.3-1.9-1.8-3c-0.5-1-1-2.1-1.7-3s-1.6-1.5-2.5-2.1s-1.9-1.2-2.6-2
                                c-0.9-0.8-1.4-1.9-2.2-2.8c-0.8-1-1.9-1.7-2.9-2.5s-2-1.6-3-2.3c-1.9-1.4-4.3-2.6-6.7-2.3c-0.3-1.5-1-2.8-2-4
                                c-1.1-1.3-2.4-2.3-3.7-3.4c-0.7-0.6-1.3-1.1-1.8-1.9c-0.5-0.7-0.9-1.6-1.3-2.3c-0.8-1.4-2-2.5-3.6-2.6c-2.1-0.2-4.1,0.9-6.2,0.4
                                c0-0.1,0-0.3,0-0.4c0.5-1,1-2.1,1.4-3.2c0.5-1.4,0.9-2.8,1.3-4.2c1.5,0.3,2.8-0.5,3.6-1.7c1-1.3,1.8-2.9,2.4-4.4
                                c0.6-1.5,1.3-3.1,1.4-4.7c0.1-1.3-0.2-3.1-1.6-3.7c-0.5-0.2-0.9,0.5-0.4,0.7c1.1,0.6,1.2,2.2,1.1,3.3c-0.2,1.4-0.8,2.8-1.3,4.1
                                c-0.5,1.2-1.2,2.4-1.9,3.6c-0.7,1-1.7,2.2-3.1,1.9c0.5-1.9,0.9-3.8,1.2-5.6c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.5-0.7-0.8-0.8-0.2
                                c-0.6,3.3-1.2,6.5-2.3,9.7c-1.2,3.8-3,7.6-5.9,10.4c-1.4,1.4-3.2,2.5-5.1,3.1c-2,0.7-4.3,1-6.3,0.5s-3.8-1.7-5.2-3.1
                                c-1.7-1.7-3.2-3.7-4.4-5.8c-2.4-4-4.3-8.7-4.9-13.3c-0.1-0.4-0.1-0.9-0.1-1.4c0-0.6-0.9-0.6-0.9,0c0.1,1.9,0.5,3.8,1,5.7
                                c-1.2,0.2-1.7-1-2.2-1.9c-0.5-1.1-1-2.3-1.4-3.5c-0.4-1.1-0.7-2.3-0.9-3.5c-0.1-0.6-0.2-1.2-0.1-1.9c0-0.6,0.1-1.4,0.5-1.8
                                s-0.2-1-0.6-0.6c-1,1-0.8,2.7-0.7,4c0.2,1.5,0.6,3,1.1,4.4s1,2.8,1.7,4.1c0.6,1.1,1.5,1.8,2.8,1.6c0.8,2.4,1.8,4.8,3,7
                                c0.6,1.1,1.2,2.2,1.9,3.2c-1.1,0-2.1,0.7-2.8,1.5c-0.5,0.6-0.9,1.2-1.3,1.9c-0.5,0.7-1,1.2-1.5,1.8c-1.8,2-2.5,4.8-1.7,7.4
                                c-2.8,0.2-5.6,1-8.2,2.2c-1.6,0.8-3,1.7-4.4,2.7c-0.7,0.5-1.4,1.1-2.2,1.6c-0.7,0.5-1.5,0.9-2.3,1.2c-1.6,0.7-2.8,1.7-3.9,3
                                c-1,1.3-2,2.7-2.9,4.1c-1.8,2.8-3.4,5.8-5.1,8.7c-1.9,3.3-3.9,6.5-6.5,9.3c-0.1,0.1-0.1,0.1-0.1,0.2c-0.3,0.2-0.7,0.4-1,0.6
                                c-1.5,0.9-2.8,2.1-3.6,3.6c0-0.2,0-0.4,0-0.6c0-2.1,0.1-4.4-1.6-5.9c-0.5-0.4-0.9-0.8-1.2-1.4c-0.2-0.6-0.4-1.1-0.8-1.6
                                s-0.9-0.8-1.5-0.9c0.1-1.5,0.1-3-0.9-4.2c-0.6-0.7-1.4-1.2-2.2-1.7c-0.7-0.4-1.5-0.8-1.7-1.6c-0.5-1.9-1-3.9-1.3-5.8
                                c-0.3-1.9-0.3-3.8-0.8-5.6c-0.4-1.6-1.5-3.4-3.3-3.7c-0.7-0.1-1.6,0-2,0.7s-0.4,1.7-0.3,2.5c0.2,2.1,0.8,4.4,0.3,6.5
                                c-0.1,0.6-0.3,1.2-0.7,1.6c-0.3,0.4-0.8,0.7-1.2,0.9c-0.9,0.5-1.7,1.1-2.5,1.7c-0.9,0.7-1.7,1.6-2.5,2.5
                                c-0.7,0.9-1.4,1.8-1.6,2.9c-0.1,0.5-0.1,1-0.1,1.6c0,0.7-0.2,1.3-0.6,1.9c-0.6,1-1.2,1.9-1.3,3c-0.1,1.2,0.2,2.3,0.4,3.4
                                c0.3,1.2,0.2,2.4,0.4,3.6c0.2,1.3,1,2.2,2,2.8c0.1,2.1,1.2,4,2.8,5.3c0.1,0.1,0.1,0.1,0.2,0.1c0,2.7,0.2,5.4,1,7.9
                                c0.7,2.3,1.7,4.4,3,6.3c1.3,2,2.8,3.8,4.4,5.6s3.2,3.7,5,5.2c0.8,0.7,1.8,1.3,2.8,1.6c1.2,0.4,2.5,0.4,3.8,0.4
                                c1.3-0.1,2.7-0.2,4-0.1c1.4,0.1,2.7,0.4,4.1,0.6c1.2,0.1,2.4,0.1,3.5-0.2s2-0.9,2.8-1.8c0.8-0.8,1.4-1.7,2.3-2.3
                                c0.9-0.6,2.1-1,3-1.7c1.9-1.5,2.8-3.7,3.8-5.8c0.6-1.2,1.2-2.4,1.9-3.4c0.6-0.9,1.3-1.8,1.9-2.6c0.1,0.8,0.3,1.6,0.6,2.3
                                c0.5,1.5,1.3,2.9,2.1,4.3c0.8,1.5,1.5,2.9,2,4.6c0.4,1.6,0.6,3.3,1,4.9c0.2,0.9,0.4,1.8,0.8,2.6c0.3,0.8,0.8,1.5,1.2,2.3
                                c1.5,4.3-0.7,8.6-1.7,12.8c-0.5,2.2-0.7,4.5-0.8,6.8c-0.1,2.4-0.4,4.7-0.6,7c-0.2,2.5-0.4,5-0.4,7.5c-0.5,1-1,2-1.4,3
                                c-1,2.5-1.8,5-2.2,7.6c-0.4,2.3-0.9,4.9,0.1,7.1c0.3,0.7,0.8,1.3,1.4,1.8c0.1,11.1,1.8,18.3,3.1,27.1c0.4,3,3.8,16,4.6,18.8
                                s0.8,5.8,0.8,8.7c0.2,18.3,1.2,36.6,3.3,54.7c0.1,0.6-0.6,2.8-0.3,3.3l0,0c-0.9,1-1.9,2.3-2.4,4c-1,3-13.5,11.7-17,14.6
                                c-2.9,2.4-7.7,6.7-7.2,10.8c0.1,0.8,0.4,1.6,1,2.4c3,4.2,9.9,7.1,21.7-2.6c0,0,5.2-4.5,7.5-5.6c2.3-1,7.2-1.8,8.4-2.6
                                c0.9-0.6,4.1-3,4.1-7.4c0-1.7-0.5-3.7-1.8-6c0.6-1.5,0.5-9.3,0.6-10.9c0.2-6.7,2-13.3,3.4-19.9c1.7-8.1,2.7-14.3,3.4-22.5
                                c0.2-3.1,0.8-5.3,1.8-8.2c0.5-1.5,0.5-5,1.1-7.2c0.9-3.4-0.8-5.9-0.6-8.8c0.2-2,1-3.9,1.6-5.8c1-3.3,1.3-6.8,1.7-10.2
                                c0.3-3.4,0.6-6.9,0.9-10.3h0.1c0.9,6.4,2.2,12.7,4.1,18.9c1,3.3,2.1,6.6,2.5,10c0.4,3.1,0.3,6.2-0.2,9.3
                                c-0.2,1.2-0.6,2.6-0.1,3.8c0.3,0.7,0.8,1.3,1.2,1.9c0.8,1.3,0.9,7.3,1.1,8.8c3.3,17.7,6.6,32.3,11,49.8c0.1,0.4,0.3,0.7,0.5,1
                                c-1,2.4-1.9,5.8-1.8,7.3c0.1,4.4,3.4,6.7,4.3,7.3c1.2,0.8,6.2,1.4,8.5,2.4s7.7,5.4,7.7,5.4c12,9.5,18.9,6.4,21.8,2
                                c0.5-0.8,0.8-1.6,0.9-2.5c0.4-4.1-4.5-8.2-7.5-10.6c-3.5-2.8-16.3-11.2-17.4-14.2c-0.5-1.5-1.4-2.7-2.2-3.6c1.1-15,2.2-27,0.3-41
                                c-0.8-5.8-2-11.6-2.1-17.4c-0.1-8.2,2-16.2,3.5-24.2c1.9-10.5,2.8-21.6,2-34.5c0.1-0.2,0.3-0.4,0.4-0.6c2.5-4.1,1.2-9-1.1-13
                                c-0.1-1.6,0.1-3.1,0.1-4.7c-0.1-1.7-0.4-3.4-0.6-5c-0.2-1.7-0.4-3.4-0.8-5.1c-0.3-1.5-0.9-2.9-1.6-4.3c-0.7-1.4-1.3-2.9-1.6-4.5
                                s-0.5-3.1-0.6-4.7c-0.1-3.1,0-6.2-0.2-9.3c-0.1-1.8-0.5-3.5-0.8-5.2s-0.6-3.4,0-5.1c0.3-0.7,0.6-1.3,0.8-2
                                c0.6-1.4,1.2-2.8,1.7-4.3c0.2-0.7,0.4-1.4,0.5-2.1c0,0.8,0.1,1.6,0.3,2.4c0.2,0.7,0.7,1.2,1.2,1.6c0.6,0.4,1.2,0.8,1.7,1.2
                                c0.7,0.5,1.2,1.2,1.7,1.9c1,1.6,2.1,3.1,3.1,4.7c1,1.5,1.8,3.1,2.7,4.7c0.3,0.6,0.7,1.3,1.3,1.7c0.6,0.5,1.3,0.7,2.1,0.8
                                c2.8,0.4,5.8,0.1,8.4-1c1.2-0.6,2.4-1.4,3.3-2.4c0.5-0.6,0.9-1.3,1.4-1.9c0.5-0.7,1-1.3,1.7-1.9c1.3-1,2.6-1.8,3.8-2.9
                                c1.2-1.1,2.2-2.3,3-3.7c0.8-1.5,1.3-3.1,1.6-4.8c0.3-1.9,0.6-3.8,0.9-5.8c0.3-1.9,0.8-3.8,0.9-5.8
                                C264.4,204.3,264.3,203.4,263.9,202.7z M241.2,180c-0.1-1.5-0.1-3.1,0-4.6c0.1-1.2,0.6-2.6,1.8-3c0.5-0.2,1.2-0.3,1.5,0.2
                                c0.3,0.4,0.3,1.1,0.3,1.6c0,1.5-0.1,2.9-0.1,4.4c0,1.4,0.2,2.8,1,3.9c0.8,1.2,2.2,1.5,3.4,2.3c1.3,0.8,2.6,1.8,3.6,2.9
                                c0.6,0.6,1.2,1.3,1.5,2.1c0.2,0.8,0.2,1.6,0.4,2.3s0.6,1.3,1.1,1.9c0.5,0.6,0.9,1.4,1.1,2.2c0.4,1.7-0.3,3.5-0.3,5.2
                                c0,0.8,0.1,1.7-0.2,2.5c-0.2,0.6-0.7,1.1-1.3,1.5c-1.2,0.8-2.8,1.2-4.2,1.5s-3.7,0.9-4.8-0.3c-0.5-0.6-0.4-1.4-0.9-2
                                c-0.4-0.5-0.9-0.7-1.5-1c-0.7-0.4-0.9-0.9-1-1.6c-0.1-0.7-0.1-1.3-0.3-2c-0.3-0.7-0.7-1.2-1.1-1.9c-0.5-0.8-0.4-1.6-0.4-2.4
                                c0-0.7-0.2-1.4-0.4-2.1c-0.2-0.7-0.5-1.4-0.4-2.1c0.1-0.8,0.7-1.4,1.3-1.9l0,0c0.6-0.5,1.4-0.9,2-1.2c0.8-0.4,1.5-0.8,2.3-1.2
                                c0.5-0.2,0.1-1-0.4-0.7c-1.4,0.7-2.7,1.4-4,2.2c-0.2-0.5-0.3-0.9-0.4-1.4c-0.1-0.7,0.1-1.4,0.3-2.1
                                C241.3,183.5,241.2,181.7,241.2,180z M179.2,154.7c2,0.9,4.2,0.9,6.3,0.5s4.1-1.3,5.8-2.6c1.9-1.5,3.5-3.4,4.7-5.4
                                c0,2.2,0.1,4.4,0.6,6.5c-1.4,0.8-2.9,1.4-4.4,1.9c-1.9,0.6-3.9,1-5.9,1.6c-1.4,0.4-3.9,1-3.9,2.8c-0.2,0-0.4,0-0.6,0
                                c-0.2,0-0.3,0-0.5,0c-0.9-2.4-3.4-3.1-5.5-3.9c-0.8-0.3-1.7-0.7-2.5-1.2c0.3-1.7,0.2-3.4-0.1-5.1c0.2,0.3,0.4,0.6,0.7,0.8
                                C175.4,152.3,177.1,153.9,179.2,154.7z M184.5,204.6c0,0.9,0,1.6-0.1,1.6h-0.2c-1.6,0.3-2,0.2-2.1,0.1c-0.1-0.3-0.2-1.2-0.2-2.8
                                c0-0.9,0.8-22.8,0.9-27.1c0.4,0.1,0.8,0,1.1-0.1c0.2-0.1,0.4-0.1,0.6-0.2C184.4,181.2,184.4,203.4,184.5,204.6z M184.5,175
                                C184.5,175,184.5,175.1,184.5,175c-0.2,0.3-0.6,0.6-0.9,0.6c-0.2,0-0.7,0.1-0.8-0.1c-0.2-0.6-0.2-1.2-0.3-1.7
                                c-0.2-1.3-0.3-2.6-0.5-3.8c-0.3-2.6-0.6-5.1-0.9-7.7c0-0.3-0.1-0.6-0.1-0.9c0-0.1,0-0.2,0-0.3c0.1-0.4,0.7-0.3,1-0.3
                                c0.5,0,0.7,0.1,0.8,0.6c0.1,0.4,0.1,0.7,0.2,1.1c0.4,2.6,0.8,5.3,1.1,7.9c0.1,1.3,0.3,2.6,0.3,3.9
                                C184.4,174.5,184.4,174.7,184.5,175C184.5,174.9,184.5,175,184.5,175z M98.3,198c-0.6-0.9-0.5-2.1-0.6-3.2
                                c-0.1-1.1-0.4-2.1-0.6-3.2c-0.2-1.1,0-2.2,0.5-3.2c0.5-0.9,1.2-1.6,1.3-2.8c0.2-1.1,0-2.2,0.6-3.2c0.6-0.9,1.3-1.7,2-2.5
                                c0.8-0.8,1.6-1.5,2.5-2.1c0.7-0.5,1.5-0.8,2.2-1.4c1.5-1.3,1.5-3.5,1.4-5.3c-0.1-1-0.2-2-0.3-3c-0.1-0.8-0.3-1.9,0-2.6
                                c0.3-0.7,1.3-0.6,1.9-0.3c0.7,0.3,1.3,1.1,1.6,1.8c0.7,1.6,0.7,3.4,0.9,5.2c0.3,1.8,0.6,3.7,1.1,5.5c0.2,0.8,0.3,1.6,0.6,2.3
                                c0.3,0.6,0.9,1.1,1.5,1.4c0.7,0.5,1.5,0.9,2.1,1.4c0.7,0.7,1,1.5,1.1,2.5c0.1,1.4-0.1,2.8-0.4,4.2c-0.3,1.5-0.9,3-1.6,4.4
                                c-0.5,0.9-1.1,1.8-1.7,2.7s-1.2,1.8-2.3,2.1c-0.8,0.2-1.6,0.1-2.4,0.2v-0.1c0.1-0.6,0.2-1,0.8-1.4c0.4-0.3,0.8-0.4,1.2-0.8
                                c0.7-0.7,0.7-1.7,0.7-2.6c0-0.4,0-0.9,0.3-1.3c0.3-0.4,0.6-0.6,1-0.9c0.8-0.7,0.9-1.5,0.8-2.6c-0.1-0.6-0.1-1.1,0-1.7
                                c0.1-0.6,0.3-1.1,0.4-1.7c0.2-1,0.1-1.9-0.6-2.8c-0.7-0.8-1.6-1.4-2.5-1.8c-1.1-0.6-2.3-1.1-3.4-1.5c-0.5-0.2-0.7,0.6-0.2,0.8
                                c1.1,0.5,2.3,0.9,3.4,1.5c0.9,0.5,1.8,1,2.3,1.8c0.6,0.9,0.2,2,0,2.9c-0.1,0.5-0.2,1-0.2,1.4c0,0.6,0.1,1.2,0.1,1.8
                                c0,0.9-0.8,1.2-1.3,1.8c-0.6,0.6-0.7,1.3-0.7,2.1c0,0.5,0,0.9-0.1,1.4c-0.2,0.5-0.6,0.8-1,1.1c-0.8,0.5-1.3,0.9-1.5,1.9
                                c-0.1,0.5-0.1,1-0.5,1.4s-1,0.6-1.5,0.7c-1.2,0.1-2.4,0-3.6-0.2C101.7,199.9,99.4,199.6,98.3,198z M263.3,206.3
                                c-0.1,0.9-0.3,1.9-0.5,2.8c-0.3,1.8-0.6,3.6-0.9,5.4c-0.3,1.7-0.5,3.4-1.1,5.1c-1.1,3-3.5,5.1-6,6.8c-1.3,0.9-2.4,1.8-3.2,3.1
                                c-0.8,1.2-1.6,2.3-2.8,3.1c-2.3,1.5-5.1,2-7.8,1.9c-0.7,0-1.5-0.1-2.2-0.2c-0.8-0.2-1.4-0.7-1.8-1.4c-0.4-0.6-0.7-1.3-1-1.9
                                c-0.4-0.8-0.8-1.5-1.3-2.2c-0.9-1.5-1.9-3-2.9-4.5c-0.8-1.3-1.6-2.6-2.9-3.6c-1.2-0.9-2.5-1.6-2.8-3.2c-0.1-0.9-0.1-1.7-0.1-2.6
                                c0-0.9-0.1-1.9-0.1-2.9c-0.1-3.7,0.1-7.5,0.3-11.2c0.1-0.9,0.1-1.8,0.2-2.7c0.1-0.6-0.8-0.5-0.9,0c-0.3,3.2-0.4,6.4-0.5,9.6
                                c-0.4-2.1-0.9-4.2-1.4-6.3s-1.1-4.1-0.9-6.2c0.1-1,0.2-2,0.1-3c0-1-0.2-1.9-0.4-2.9c-0.5-2.2-1.1-4.3-1.1-6.6
                                c0.1-2.3,0.6-4.6,1.6-6.6c0.2-0.5-0.5-0.9-0.7-0.4c-1.7,3.6-2.2,7.6-1.2,11.5c0.5,2,1.1,3.9,1,6.1c0,1-0.2,2-0.2,3
                                s0.2,2.1,0.4,3.1c0.8,3.4,1.8,6.8,2.2,10.2c-9.7,1.5-19.5,1-29.2,0.8c-9.6-0.2-19.3-0.1-28.6,2.3c-2.6,0.7-5.2,1.8-7.9,2.3
                                c-2.5,0.5-5.3,0.4-7.4-1.2c-0.4-0.3-0.9,0.4-0.4,0.7c4,3,9.1,1,13.4-0.3c4.6-1.5,9.4-2.3,14.3-2.7c9.9-0.8,19.8-0.1,29.7-0.1
                                c5.4,0,10.9-0.2,16.2-1c0,0.2,0,0.4,0.1,0.5c0.1,2.1,0,4.1-0.5,6.1c-0.4,1.5-1,2.9-1.6,4.3c-0.4,1-0.8,1.9-1.2,2.9l0,0
                                c0,0,0,0,0,0.1c-0.3,0.7-0.6,1.5-0.9,2.2c-0.5,1.5-1,3-1,4.6c0,0.6,0.9,0.6,0.9,0c0-1.2,0.4-2.5,0.8-3.6c0.1,1.9,0.7,3.9,1,5.8
                                c0.4,3.2,0.3,6.4,0.3,9.5c0.1,3.1,0.3,6.2,1.5,9.1c0.6,1.5,1.5,2.9,1.9,4.4c0.5,1.6,0.7,3.3,0.9,5c0.3,1.9,0.6,3.9,0.8,5.8
                                c0.1,1.3,0,2.6-0.1,3.9c-0.6-1-1.3-1.9-1.9-2.8c-0.3-0.5-1.1,0-0.7,0.4c2.8,4,6.1,8.8,4.8,14c-0.9,3.6-4.1,5.9-7.4,7.1
                                c-2.6,0.9-5.3,1.7-7.9,2.4c-3.9,1-7.8,1.9-11.8,2.6c-4.2,0.7-8.4,1.2-12.7,1.1c-3.1-0.1-6.2-0.4-9.3-0.7
                                c-5.6-0.9-12.8-2.2-18.3-3.2c-1.4-0.3-2.8-0.6-4.2-0.9c-1.3-0.3-2.6-0.5-3.9-1l-0.8-0.4c-0.2-0.1-0.4-0.2-0.6-0.3
                                c-0.9-0.5-1.7-1.4-2.1-2.4c-0.4-1.1-0.4-2.2-0.3-3.4c0.2-2.6,0.8-5.1,1.5-7.6c0.8-2.4,1.8-4.7,3-6.9c1.1-2.1,2.3-4.2,3.1-6.5
                                c0.4-1.1,0.7-2.2,0.9-3.3c0.1-0.5-0.7-0.8-0.8-0.2c-0.5,3.3-2.1,6.2-3.6,9.2c0.1-4,0.8-8,1-12c0.1-2.4,0.2-4.8,0.7-7.1
                                c0.5-2.2,1.3-4.4,1.8-6.6c0.3-1.1,0.4-2.3,0.4-3.4c0.2,0.9,0.4,1.7,0.4,2.6c0,0.6,0.9,0.6,0.9,0c-0.1-1.7-0.5-3.3-1.1-4.8
                                c-0.2-0.5-0.5-1-0.8-1.5l0,0l0,0c-0.6-1-1.2-2.1-1.5-3.2c-0.5-1.6-0.7-3.3-1-4.9s-0.8-3.2-1.6-4.7c-0.7-1.5-1.6-2.9-2.2-4.3
                                c-0.7-1.6-1.2-3.3-1.2-5c-0.1-3.6,1-7.2,1.3-10.8c0.1-1.1,0.1-2.1,0.2-3.2c0.1-1,0.4-1.9,0.7-2.9c0.5-1.8,1-3.6,0.4-5.4
                                c-0.2-0.5-1-0.3-0.8,0.2c0.6,1.7,0.1,3.4-0.4,5c-0.5,1.7-0.7,3.4-0.9,5.2c-0.2,3.6-1.2,7.1-1.4,10.7c0,0.5,0,1.1,0,1.6
                                c-0.1,0-0.1,0.1-0.2,0.1c-1.3,1.9-2.8,3.6-3.8,5.7c-0.9,1.8-1.6,3.7-2.8,5.3c-0.6,0.8-1.4,1.5-2.3,2c-1,0.5-2,0.9-2.8,1.8
                                c-0.7,0.7-1.4,1.6-2.2,2.3c-0.9,0.8-2.1,1.1-3.2,1.1c-2.5,0.1-4.9-0.7-7.4-0.6c-1.3,0.1-2.6,0.2-3.9,0.2c-1.2-0.1-2.4-0.4-3.5-1
                                c-1.9-1.1-3.4-2.9-4.8-4.6c-1.6-1.7-3.1-3.5-4.5-5.4c-1.5-2.1-2.8-4.3-3.7-6.8c-0.9-2.5-1.1-5.2-1.2-7.9c0.5,0.3,1,0.6,1.6,0.8
                                c0.8,0.3,1.9,0.5,2.5,1.3c0.3,0.4,1.1,0,0.7-0.4c-0.5-0.8-1.3-1.1-2.2-1.4c-0.9-0.3-1.8-0.8-2.6-1.3c0,0-0.1,0-0.1-0.1
                                s-0.1-0.1-0.1-0.1c-1.4-1.1-2.4-2.6-2.7-4.4c0.3,0.1,0.7,0.3,1.1,0.4c1.2,0.3,2.4,0.5,3.6,0.6c1.1,0.1,2.4,0.2,3.5-0.2
                                c0.6-0.3,1.1-0.7,1.3-1.2c0.1,0,0.1,0.1,0.2,0.1c0.9,0,1.8,0.1,2.7-0.2c1-0.3,1.6-1,2.2-1.9c1.3-1.7,2.4-3.6,3.1-5.6
                                c0.5-1.3,0.8-2.7,1-4.1c0-0.1,0-0.2,0-0.2c0.6,0.1,1,0.5,1.3,1.1c0.3,0.4,0.3,0.9,0.6,1.3c0.2,0.4,0.6,0.8,1,1.1
                                c0.9,0.7,1.3,1.6,1.4,2.7c0.1,1.1,0.1,2.2,0.1,3.3c0,0.9-0.1,1.9,0.1,2.7l0,0c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.2,0.2,0.3
                                c0.3,0.5,0.4,0.9,0.4,1.5s-0.2,1.3-0.3,1.9c-0.4,2.5-0.9,4.9-1.3,7.4c-0.4,2.7-0.7,5.5-0.7,8.2c0,0.6,0.9,0.6,0.8,0
                                c-0.1-5.2,1.1-10.2,1.9-15.3c0.8-0.1,1,1.6,1.2,2.1c0.2,0.8,0.4,1.5,0.7,2.3c0.4,1.6,1,3.1,1.8,4.5c0.3,0.5,1,0,0.7-0.4
                                c-1-1.6-1.5-3.5-2-5.3c-0.2-0.9-0.5-1.8-0.8-2.6c-0.3-0.7-0.7-1.2-1.4-1.3c0.2-1,0.4-2.1-0.1-3c-0.1-0.2-0.3-0.4-0.3-0.6
                                c0.1-2.9,2.6-4.8,4.9-6.1c1.3-0.7,2.7-1.3,4-2c1.2-0.6,2.4-1.4,3-2.7c0.2-0.5-0.5-0.9-0.7-0.4c-0.6,1.4-2.2,2.1-3.4,2.8
                                c-0.5,0.2-0.9,0.4-1.4,0.7c3.8-4.6,6.5-10,9.5-15.1c0.9-1.4,1.7-2.8,2.7-4.1c1-1.4,2-2.8,3.4-3.7c0.7-0.4,1.5-0.8,2.2-1.1
                                c0.8-0.4,1.5-0.9,2.3-1.4c1.4-1,2.7-2,4.1-2.9c3.3-1.9,7.1-3.1,11-3c0.6,0,0.6-0.9,0-0.9c-0.2,0-0.5,0-0.7,0
                                c-0.8-2.4-0.2-4.9,1.4-6.7c0.4-0.5,0.9-0.9,1.3-1.4c0.4-0.5,0.7-1.1,1.1-1.6c0.7-1,1.8-2.1,3.1-1.8c0.7,1.9,0.9,4,0.6,6.1
                                c-0.1,0-0.1-0.1-0.2-0.1c-0.9-0.6-2.5-1.5-2.6-2.7c0-0.5-0.9-0.6-0.8,0c0.1,1.1,0.9,1.9,1.8,2.6c1.1,0.8,2.2,1.5,3.4,2.1
                                s2.5,0.9,3.7,1.5c1.2,0.5,2.3,1.3,2.7,2.5c-0.2,0.2-0.4,0.5-0.4,0.9c0,0.8,0.1,1.6,0.2,2.4c0.2,1.5,0.3,2.9,0.5,4.4
                                c0.2,1.7,0.4,3.4,0.6,5.1c0.1,0.9,0.1,1.8,0.4,2.6c-0.1,1.6-0.9,26.8-1,27.8c0,1.7,0.1,2.8,0.4,3.2l0.1,0.1
                                c0.2,0.2,0.6,0.4,1.2,0.4c0.5,0,1.1-0.1,1.8-0.2h0.2c0.9-0.2,0.9-1.1,0.8-2.5c-0.1-1.3-0.1-28.1-0.1-29.3l0,0
                                c0.2-0.9,0-1.9-0.1-2.8c-0.3-3-0.7-6.1-1.2-9.1c-0.1-0.8-0.2-1.7-0.4-2.5c-0.1-0.2-0.2-0.4-0.3-0.5c0-0.1,0-0.1,0-0.2
                                c-0.1-1.2,1.3-1.6,2.2-1.9c0.9-0.3,1.9-0.6,2.8-0.9c1.9-0.5,3.8-0.9,5.7-1.6c3-1.1,5.9-2.9,7.3-5.9c0.2-0.5-0.5-0.9-0.7-0.4
                                c-0.7,1.5-1.8,2.7-3.1,3.6c-0.4-2.1-0.5-4.1-0.5-6.2c1.6,0.3,3.2-0.1,4.8-0.3c0.9-0.1,1.8-0.1,2.5,0.3c0.7,0.4,1.3,1,1.7,1.7
                                c0.9,1.4,1.5,2.9,2.8,4c1.2,1.1,2.6,1.9,3.6,3.1c1.1,1.2,2,2.7,2.4,4.3c-0.4,0.2-0.2,0.9,0.3,0.8c2.4-0.6,4.7,0.6,6.7,2
                                c1,0.7,2,1.5,3,2.3c0.9,0.7,1.9,1.4,2.8,2.3c0.8,0.9,1.3,1.9,2.1,2.8c0.7,0.7,1.5,1.3,2.3,1.8c0.9,0.6,1.8,1.2,2.6,2
                                s1.3,1.9,1.8,2.9c0.5,1.1,1.1,2.2,1.9,3.2c0.8,1,1.6,2,2.1,3.2c0,0.1,0.1,0.1,0.1,0.2c0,0.6-0.1,1.2-0.2,1.8
                                c-0.1,0.7-0.3,1.4-0.2,2.1c0.1,0.6,0.3,1.3,0.5,1.9c-0.6,0.5-1.1,1.1-1.3,1.8s-0.1,1.5,0.1,2.2c0.2,0.8,0.5,1.6,0.6,2.4l-0.1,0.1
                                c-0.7,1.4-0.7,2.9-0.8,4.4c0,0.9-0.1,1.7-0.1,2.5c0,0.7,0,1.5,0.3,2.2c0.2,0.4,0.4,0.7,0.7,1c0.2,3.6-0.5,7.3-2.2,10.6
                                c-0.8,1.5-1.7,3-1.9,4.8c-0.1,1.9,0.6,3.7,1.4,5.4c0.2,0.5,0.9,0.1,0.7-0.4c-0.7-1.7-1.5-3.7-1.1-5.6c0.4-1.7,1.4-3.1,2.1-4.7
                                c1.2-2.9,1.9-6,1.8-9.1c0.5,0.5,1.1,1,1.6,1.4c1.3,1.2,2.6,2.4,3.8,3.5c0.4,0.4,1-0.2,0.6-0.6c-1.1-1-2.2-2.1-3.4-3.1
                                c-0.6-0.5-1.1-1-1.7-1.6c-0.5-0.5-1.1-0.9-1.5-1.5c-0.5-0.6-0.5-1.3-0.5-2.1s0.1-1.5,0.1-2.3c0-0.9,0.1-1.8,0.2-2.7
                                c0,0.2,0.1,0.4,0.2,0.6c0.3,0.7,0.9,1.3,1.2,2s0.2,1.5,0.4,2.3c0.1,0.7,0.4,1.3,1,1.7s1.4,0.6,1.7,1.2c0.3,0.7,0.3,1.5,1,2
                                c0.6,0.5,1.3,0.7,2.1,0.8c0.9,0.1,1.8-0.1,2.7-0.3s1.8-0.4,2.7-0.6c1.3-0.4,2.7-1,3.5-2.1c1.1-1.5,0.6-3.3,0.8-5
                                c0.1-0.9,0.4-1.9,0.4-2.8c0-0.9-0.2-1.7-0.6-2.4c-0.2-0.5-0.5-0.9-0.9-1.3c1,0.4,1.8,1.3,2.4,2.2c0.8,1.3,1.3,2.8,2.2,4.2
                                c0.4,0.6,0.9,1.1,1.4,1.7c0.5,0.6,1.1,1.1,1.3,1.9C263.6,204.5,263.5,205.4,263.3,206.3z"/>
                            <path name="BERE" class="st2" d="M226.1,167.1c-0.2-0.1-0.4,0.1-0.5,0.3c-0.8,1.9-1.6,3.9-2.3,5.9c-0.2,0.5,0.6,0.7,0.8,0.2
                                c0.7-2,1.5-3.9,2.3-5.9C226.5,167.4,226.3,167.1,226.1,167.1z"/>
                            <path name="BERE" class="st2" d="M162.9,124c0.9,1,1.3,2.3,1.4,3.2c0.1,0.9,0.1,1.5,0.5,1.4c0.5-0.1,0.2-2.7,0-4.8c-0.2-2.1,0.5-5.6,0.7-6.7
                                c0.3-1.1,0.8-2,1.1-3.6s0.2-1.5,1.1-3.8c0.8-2.3,2.1-5.3,4.1-6.1c2.1-0.8,7.2-0.9,9.8-0.5c2.5,0.4,8.1-0.2,9.6-0.2
                                s5.8,0.6,6.4,2.9c0.6,2.3-0.3,3.1,0.2,3.6s0.7,0.7,0.6,1.5s0.2,1.3,0.5,1.8s1.5,2.9,1.6,3.9s1.1,2.3,1.2,2.8s0.5,1.6,0.4,2.6
                                c-0.2,1-0.5,1-0.4,2.1s0.4,2,0,3.6c-0.4,1.6-0.8,2.9-0.4,2.7c0.5-0.2,2.6-2.6,2.8-4c0.3-1.4,0.1-2.4,0.7-3.2
                                c0.6-0.7,1.1-1.2,0.9-3.1c-0.2-1.9-0.6-8.6-0.9-9.5c-0.3-0.9-0.3-2.1-0.9-3.7c-0.6-1.6-4.2-7-5.4-7.9s-1.5-2.4-3.4-3.1
                                s-2.7-0.9-3.3-1.6s-0.5-1.8-2.3-1.7s-1.9,0-2.3-0.5c-0.4-0.5-0.9-0.4-1.4,0s-0.6-1.1-1.9-0.3c-1.2,0.8-0.6-1.2-1.8,0.1
                                s-1.2-1.4-2.8,0.7s-1.6-1.3-2.7,0.3s-0.9-1.4-2.3,0.7c-1.4,2.1-1.6-1.2-2.4,1.2c0,0-1.5,0.9-1.7,2c0,0-1,0.1-1.4,1
                                s-1.2,2.2-2.1,3.4s-3.6,4-3.9,9.2c-0.3,5.2-0.8,7.2-1,8.4c0,0.2-0.5,1.7,0,2.4C162.6,121.8,162,123,162.9,124z"/>
                            <path name="BERE" class="st2" d="M178.7,128.7c1.1-2.1,2.4-4.1,3-6.4c0.1-0.5-0.7-0.8-0.8-0.2c-0.7,2.8-2.5,5.1-3.7,7.6
                                c-0.5,1.1-1,2.4-0.1,3.5c0.9,1,2.4,1,3.7,0.9c0.5,0,0.6-0.9,0-0.9c-1.1,0.1-2.7,0.2-3.2-1c-0.3-0.6,0-1.4,0.3-2
                                C178.1,129.7,178.4,129.2,178.7,128.7z"/>
                            <path name="BERE" class="st2" d="M201.4,134.7c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9S201.9,134.7,201.4,134.7z
                                M201.4,136.1c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5S201.7,136.1,201.4,136.1z"/>
                            <path name="BERE" class="st2" d="M164.5,134.8c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7s-0.3-0.7-0.7-0.7S164.5,134.4,164.5,134.8z
                                M165.2,134.5c0.2,0,0.4,0.2,0.4,0.4s-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4C164.8,134.6,165,134.5,165.2,134.5z"/>
                        </g>
                        <path name="BERE" class="st1" d="M137.2,434.1c3,4.2,9.9,7.1,21.7-2.6c0,0,5.2-4.5,7.5-5.6c2.3-1,7.2-1.8,8.4-2.6c0.9-0.6,4.1-3,4.1-7.4
                            c-1.3,2.8-2.1,3.6-4.2,4.8c-1.7,1-4.2,1.3-6.8,2.1c-3.2,0.9-6.2,3.7-7.2,4.9c-1.9,2.3-4.3,3.5-8.7,6.5s-9.8,1.9-9.8,1.9
                            c0.8-3.2-1.7-4.8-3.6-5.7c-1-0.5-1.7,0.4-2.4,1.2C136.3,432.5,136.6,433.3,137.2,434.1z"/>
                        <path name="BERE" class="st1" d="M251.4,433.9c-2.9,4.3-9.7,7.4-21.8-2c0,0-5.4-4.4-7.7-5.4s-7.3-1.5-8.5-2.4c-0.9-0.6-4.2-2.9-4.3-7.3
                            c1.4,2.7,2.2,3.6,4.3,4.7c1.7,0.9,4.2,1.2,6.9,1.9c3.2,0.8,6.3,3.5,7.3,4.7c2,2.3,4.4,3.4,8.9,6.3s9.8,1.6,9.8,1.6
                            c-0.9-3.2,1.5-4.8,3.4-5.8c1-0.5,1.7,0.3,2.5,1.2C252.2,432.2,251.9,433,251.4,433.9z"/>
                        <path name="BERE" class="st3" d="M201.7,120.5c-0.4-0.9-1.8-1.3-2.7-1.7c-1.1-0.4-2.3-0.7-3.5-0.9c-2.6-0.4-5.1-0.3-7.7,0.1
                            c-1.3,0.2-2.8,0.6-3.3,2c0,0.1,0,0.1-0.1,0.2c-1.6-0.7-3.2-0.4-3.9-0.2v-0.1c-0.5-1.3-2.1-1.7-3.4-1.9c-2.6-0.4-5.1-0.4-7.7,0
                            c-1.2,0.2-2.4,0.5-3.5,0.9c-0.9,0.3-2.2,0.8-2.7,1.7c-0.4,0.9,0.3,1.7,1,2.2c0.3,0.2,0.7,0.4,1,0.6s0.4,0.3,0.5,0.6
                            c0.1,0.9,0.2,1.9,0.5,2.8c0.4,1.1,1.2,1.9,2.2,2.4c1.7,0.8,3.6,0.6,5.4,0.4c1.9-0.1,3.8-0.3,5-1.8c0.8-1,1.5-2.4,1.7-3.7
                            c0.1-0.6,0.2-1.3,0.2-2l0,0c0,0,1.7-1.1,3.3-0.2l0,0c0,0.8,0.1,1.6,0.2,2.3c0.3,1.3,0.9,2.7,1.8,3.7c1.3,1.5,3.2,1.7,5.1,1.8
                            c1.8,0.1,3.7,0.3,5.3-0.5c1-0.5,1.8-1.4,2.2-2.5c0.3-0.9,0.4-1.8,0.5-2.8c0-0.3,0.1-0.5,0.4-0.7c0.4-0.2,0.7-0.4,1-0.6
                            C201.4,122.2,202.1,121.4,201.7,120.5z M179.4,125.2c-0.5,1.1-1,2.3-2.1,3c-0.8,0.5-1.7,0.5-2.6,0.6c-1,0.1-2.1,0.1-3.1,0.2
                            c-1.3,0-2.7-0.1-3.6-1c-0.6-0.6-1-1.4-1.2-2.3c-0.1-0.4-0.1-0.8-0.2-1.2c0-0.2,0-0.4-0.1-0.7l0,0l0,0l0,0c0-0.2-0.6-4,1.2-4.6
                            c3-0.8,6.2-0.8,9.2-0.4c1.2,0.2,2.6,0.5,2.8,1.9C180.1,122.1,180,123.8,179.4,125.2z M198.4,123.7L198.4,123.7L198.4,123.7
                            L198.4,123.7c0,0.2,0,0.5-0.1,0.7c0,0.4-0.1,0.8-0.1,1.2c-0.2,0.9-0.5,1.7-1.1,2.3c-1,0.9-2.3,1.1-3.6,1.1c-1,0-2.1-0.1-3.1-0.1
                            c-0.9-0.1-1.8-0.1-2.6-0.6c-1.1-0.7-1.7-1.9-2.1-3c-0.6-1.3-0.7-3-0.4-4.4s1.6-1.7,2.8-1.9c2.9-0.5,6.2-0.5,9.2,0.2
                            C198.9,119.7,198.4,123.5,198.4,123.7z"/>
                        <path name="BERE" class="st3" d="M201.6,119.7c0,0,1.2,1.8,0,2.6c-0.5,0.3,3.9,1.8,4.4,1.1C206.7,122.3,203.2,120.4,201.6,119.7z"/>
                        <path name="BERE" class="st3" d="M163.5,119.8c0,0-1.4,1.6,0,2.6c0.5,0.3-1.6,0.8-1.9,0.5C160.9,122.2,161.6,120.7,163.5,119.8z"/>
                        <path name="BERE" class="st0" d="M175.3,409.5c-0.1,0-4.5-0.6-6.1-1.9c-0.4-0.3-0.3-1-0.2-1.8c0.1-1,0.2-2.1-0.7-2.6c-1.1-0.7-3.1-1.2-3.2-1.2
                            c-0.2-0.1-0.5,0.1-0.5,0.3c-0.1,0.2,0.1,0.5,0.3,0.5c0,0,2,0.5,3,1.1c0.4,0.2,0.4,0.9,0.3,1.8c-0.1,0.9-0.2,1.9,0.5,2.5
                            c1.8,1.5,6.3,2.1,6.5,2.1c0,0,0,0,0.1,0c0.2,0,0.4-0.2,0.4-0.4C175.7,409.7,175.6,409.5,175.3,409.5z"/>
                        <path name="BERE" class="st0" d="M219.3,406.2c0,0.7,0.1,1.5-0.3,1.7c-1.7,1.2-6.2,1.5-6.2,1.5c-0.2,0-0.4,0.2-0.4,0.5c0,0.2,0.2,0.4,0.4,0.4
                            l0,0c0.2,0,4.8-0.4,6.6-1.7c0.7-0.5,0.7-1.5,0.7-2.5c0-0.9,0-1.6,0.4-1.8c1.1-0.5,3.1-0.9,3.1-0.9c0.2,0,0.4-0.3,0.4-0.5
                            s-0.3-0.4-0.5-0.4c-0.1,0-2.1,0.4-3.3,1C219.3,404.1,219.3,405.2,219.3,406.2z"/>
                    </g>
                </g>
            </svg>


        );
    }

}

export default Personajes;



