import React from 'react';

class Section01 extends React.Component {

    constructor(props) {
        super(props);
        this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.state = {
            isResponsive: this.props['is-responsive'],
            isVideoLoaded: false,
        };
    }

    componentDidMount() {
        
    }

    componentDidUpdate() {
        const state = this.state;
        var newState = state;
        newState.isResponsive = this.props['is-responsive'];
        if(state != newState) {
            this.setState(newState);
        }
    }

    onWindowResize = () => {
        
    }

    onClickButtonVideo = (event) => {
        event.preventDefault();
        this.props['toggle-modal-fn']();
    }

    onLoadedMetadata = (event) => {
        // const video = event.target;
        // if (video.buffered.length === 0) return;
        // var bufferedSeconds = video.buffered.end(0) - video.buffered.start(0);
        // // console.log(bufferedSeconds + ' seconds of video are ready to play!');
        // if (bufferedSeconds >= 15 && !this.state.isVideoLoaded) {
        //     this.props['fade-out-modal-intro']();
        //     this.setState({
        //         isVideoLoaded: true,
        //     });
        // }
    }
    
    render () {
        return(
            <section className={this.props.sel + ' section01'}>
                <video id="bg-video" poster="images/bg-video.jpg" data-autoplay muted loop playsInline webkit-playsinline="true" preload="metadata" onLoadedData={this.onLoadedMetadata}>
                    <source src="video/bg-video01.mp4" type="video/mp4"></source>
                </video>
                <h1>Aprovechamos la <span>tecnología</span> para <span>amplificar</span> las <span>ideas</span></h1>
                <p>Somos un laboratorio de <b>tecnología</b> inspirado por la <b>creatividad</b>. Nuestra misión es ayudar a las marcas a crear <b>experiencias únicas e inolvidables,</b> resultado de la expansión creativa que detonamos a partir de nuestra investigación y prueba de nuevas herramientas tecnológicas.</p>
                <a href="#" className="videoButton" onClick={this.onClickButtonVideo}>
                    <img src="images/BotonPlay.svg"></img>
                </a>
            </section>
        );
    }

}

export default Section01;