import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Personajes from './Personajes';
import Draggable, {DraggableCore} from 'react-draggable';
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from "react-device-detect";
import {TweenMax} from "gsap";
import {Quart, Quint} from "gsap";

class Section04 extends React.Component {

    constructor(props) {
        super(props);  
        // this.slider = React.createRef();
        this.slider = null;
        this.sliderTween = null;
        this.state = {
            style_slider: {
                marginLeft: '0'
            },
            activeDrags: 0,
            deltaPosition: {
                x: 0, y: 0
            },
            bounds: {
                left: 0, 
                top: 0, 
                right: 0, 
                bottom: 0,
            },
            tooltip_style: {
                display: 'none',
            },
            tooltip_data: {},
            rowsContainerStyle: { position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' },
            bgVideoStyle: { display: 'block' },
            isResponsive: this.props['is-responsive'],
            isDraging: false,
        };
        this.timerInterval = null;
        this.mrefreshinterval = 50;
        this.offsetx = 0;
        this.mousex = 0;
        this.lastmousex = 0;
        this.touchx = 0;
        this.lasttouchx = 0;
        this.speed = 5;
        this.tooltip = null;
        this.tooltipTween = null;
        this.data = {
            'YAHIR': {
                nombre: 'Yahir Vite',
                puesto: 'Chief Tech Officer',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '-10px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 1380,
            },
            'MARCO': {
                nombre: 'Marco Cruz',
                puesto: 'FrontEnd Developer',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '-20px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 1380,
            },
            'LUIS': {
                nombre: 'Luis Ayala',
                puesto: 'Lead Developer',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '-10px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 1260,
            },
            'JUAN': {
                nombre: 'Juan de Dios Moreno',
                puesto: 'Creative Technologist',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '-25px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 1140,
            },
            'RICK': {
                nombre: 'Ricardo Escandón',
                puesto: 'FrontEnd Developer',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '-25px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 1030,
            },
            'OCTAVIO': {
                nombre: 'Octavio Palafox',
                puesto: 'FrontEnd Developer',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '-15px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 850,
            },
            'PAUL': {
                nombre: 'Paul Villegas',
                puesto: 'FullStack Developer',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '-15px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 730,
            },
            'RICK_G': {
                nombre: 'Ricardo García',
                puesto: 'Creative Tech Lead',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '-25px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 570,
            },
            'GERA': {
                nombre: 'Gerardo Flores',
                puesto: 'Backend Developer',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '10px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 480,
            },
            'IRENE': {
                nombre: 'Irene Rodríguez',
                puesto: 'FrontEnd Developer',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '-10px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 340,
            },
            'BERE': {
                nombre: 'Berenice Cárcamo',
                puesto: 'Project Manager',
                triangulo_style: {
                    position: 'absolute',
                    bottom: '-13px',
                    left: '83px',
                },
                tooltip_container_style: {
                    top: '0px',
                },
                tooltip_container_mobile_style: {
                    top: '0px',
                },
                tooltip_container_mobile_x: 180,
            },
        };
    }

    componentDidMount() {
        const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const sliderW = this.slider.offsetWidth;
        this.setState({
            bounds: {
                left: -1 * (sliderW - w),
                top: 0, 
                right: 0, 
                bottom: 0,
            },
        });
        window.addEventListener('resize', this.onWindowResize, true);
        window.addEventListener('mousemove', this.onMouseMove, true);
        this.timerInterval = setInterval(() => {
            if (!isTablet && !isMobile) {
                if (this.tooltip) {
                    this.animateIllustration();
                    this.animateTooltip();
                }
            } else {
                if (this.tooltip) {
                    this.animateTooltipMobile();
                }
            }
        }, this.mrefreshinterval);
        this.onWindowResize();
    }

    componentDidUpdate() {
        const state = this.state;
        var newState = state;
        newState.isResponsive = this.props['is-responsive'];
        if(state != newState) {
            this.setState(newState);
        }
    }

    onWindowResize = () => {
        const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        
        const sliderW = this.slider.offsetWidth;
        this.setState({
            style_slider: {
                marginLeft: '0px',
                bounds: {
                    left: -1 * (sliderW - w),
                    top: 0, 
                    right: 0, 
                    bottom: 0,
                },
            },
        });

        if (w <= 992) {
            this.setState({
                rowsContainerStyle: { position: 'inherit', top: '0', left: '0', width: '100%', height: 'auto' },
                // bgVideoStyle: { display: 'none' },
            });
        } else {
            this.setState({
                rowsContainerStyle: { position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' },
                // bgVideoStyle: { display: 'block' },
            });
        }
    }

    onMouseMove = (event) => {
        event.preventDefault();
        this.mousex = event.clientX;
    }

    animateIllustration = () => {
        this.lastmousex = this.mousex;
        const sliderW = this.slider.offsetWidth;
        const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.offsetx = -1 * (((sliderW - w) * this.lastmousex) / w);
        if (w < sliderW) {
            this.sliderTween = TweenMax.to(this.slider, {marginLeft: this.offsetx});
        } else {
            this.setState({
                style_slider: {
                    marginLeft: '0px'
                },
            });
        }
    }

    animateTooltip = () => {
        this.lastmousex = this.mousex - 90;
        this.tooltipTween = TweenMax.to(this.tooltip, {left: this.lastmousex});
    }

    animateTooltipMobile = () => {
        this.lasttouchx = this.touchx - 90;
        this.tooltipTween = TweenMax.to(this.tooltip, {left: this.lasttouchx});
    }

    onStart = () => {
        this.setState({
            activeDrags: ++this.state.activeDrags
        });
    }
    
    onStop = () => {
        this.setState({
            activeDrags: --this.state.activeDrags,
            isDraging: false,
        });
    }

    handleDrag = (e, ui) => {
        const {x, y} = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            },
            isDraging: true,
        });
    }

    toggleDisplayTooltipDesktop = (visible, name, data) => {
        if (visible) {
            this.setState({
                tooltip_style: {
                    display: 'block',
                    ...this.data[name].tooltip_container_style
                },
                triangulo_style: {
                    ...this.data[name].triangulo_style
                },
                tooltip_data: {
                    nombre: this.data[name].nombre,
                    puesto: this.data[name].puesto,
                }
            });
        } else {
            this.setState({
                tooltip_style: {
                    display: 'none'
                },
                tooltip_data: {}
            });
        }
    }

    toggleDisplayTooltipMobile = () => {

    }

    onTouchEnd = (visible, name, data) => {
        if (this.state.isDraging == false) {
            if (visible) {
                this.touchx = this.data[name].tooltip_container_mobile_x;
                this.setState({
                    tooltip_style: {
                        display: 'block',
                        ...this.data[name].tooltip_container_mobile_style
                    },
                    triangulo_style: {
                        ...this.data[name].triangulo_style
                    },
                    tooltip_data: {
                        nombre: this.data[name].nombre,
                        puesto: this.data[name].puesto,
                    }
                });
            }
        }
    } 

    sliderContent = () => {
        const dragHandlers = {onStart: this.onStart, onStop: this.onStop, onDrag: this.handleDrag};
        const {deltaPosition} = this.state;
        if (isMobile || isTablet) {
            return (
                <Draggable axis="x" defaultPosition={{x: 0, y: 0}} { ...dragHandlers } bounds={ this.state.bounds }>
                    <div className="box cursor-x slider" ref={div => this.slider = div} onTouchEnd={this.onTouchEndDraggable}>
                        <Personajes toggleDisplayTooltip={this.toggleDisplayTooltipMobile} on-touch-end={this.onTouchEnd}></Personajes>
                        <div className='tooltip-container' style={this.state.tooltip_style} ref={div => this.tooltip = div}>
                            <p>{ this.state.tooltip_data.nombre }</p>
                            <p>{ this.state.tooltip_data.puesto }</p>
                            <img className='triangulo' src='images/triangulo.svg' width={14} height={14} style={this.state.triangulo_style}></img>
                        </div>
                    </div>
                </Draggable>
            );
        } else {
            return (
                <div className='slider' ref={div => this.slider = div} style={ this.state.style_slider }>
                    <Personajes toggleDisplayTooltip={this.toggleDisplayTooltipDesktop}></Personajes>
                    <div className='tooltip-container' style={this.state.tooltip_style} ref={div => this.tooltip = div}>
                        <p>{ this.state.tooltip_data.nombre }</p>
                        <p>{ this.state.tooltip_data.puesto }</p>
                        <img className='triangulo' src='images/triangulo.svg' width={14} height={14} style={this.state.triangulo_style}></img>
                    </div>
                </div>
            );
        }
    }

    render () {
        return(
            <section className={this.props.sel + ' ' + this.state.isResponsive + ' section04'}>
                
                <video style={{...this.state.bgVideoStyle}} id="bg-video-section-04" poster="images/bg-video.jpg" data-autoplay muted loop playsInline webkit-playsinline="true">
                    <source src="video/bg-video01.mp4" type="video/mp4"></source>
                </video>
                
                <div style={this.state.rowsContainerStyle}>
                    <div className='row-uno'>
                        <div>
                            <h1>
                                <span>El</span>
                                <img src="images/equipo.svg" alt="Equipo"></img>
                            </h1>
                        </div>
                    </div>
                    <div className='row-dos'>
                        <div className='container-slider'>
                            { this.sliderContent() }
                        </div>
                    </div>
                </div>

            </section>
        );
    }

}

export default Section04;