import React from 'react';

class Next extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            className: "next-section-black",
            extra_style: {}
        };
    }

    clickNextSection = (event) => {
        event.preventDefault();
        this.props['click-next-section']();
    }

    changeColor(className, transform) {
        this.setState({
            className: className,
            extra_style: {
                transform: transform
            }
        });
    }

    render () {
        return(
            <a href="#" style={this.state.extra_style} className={'next-section ' + this.state.className} onClick={this.clickNextSection}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25.618" height="26.159" viewBox="0 0 25.618 26.159"><path d="M13.383,0,11.491,1.893l9.531,9.531H0v2.7H20.954l-9.531,9.531,1.96,1.96L26.159,12.775Z" transform="translate(25.618) rotate(90)"/></svg>
            </a>
        );
    }

}

export default Next;