import React from 'react';
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import Section01 from './components/Section01';
import Section02 from './components/Section02';
import Section03 from './components/Section03';
import Section04 from './components/Section04';
import Section05 from './components/Section05';
import Section06 from './components/Section06';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './components/Menu';
import MenuMobile from './components/MenuMobile';
import Next from './components/Next';
import Fonts from './css/fonts.css';
import Styles from './css/styles.css';
import {useSpring, animated} from 'react-spring';
import {Controller} from 'react-spring/renderprops';
import {TweenMax} from "gsap";
import {Quart, Quint} from "gsap";
import Utils from "./helpers/Utils";
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from "react-device-detect";
import DOMPurify from 'dompurify';

const SEL = 'custom-section';
const SECTION_SEL = `.${SEL}`;

// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
// This is no longer required for the scrollOverflow option.
const pluginWrapper = () => {
  /*
  * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
  */
};

const originalColors = ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff'];

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionsColor: [...originalColors],
      modalIsOpened: false,
      modalVideoMil24IsOpened: false,
      modalInitialPosition: {},
      showCloseButton: { position: 'absolute', top: '40px', right: '40px', display: 'none' },
      currentSection: '',
      modalData: null,
      modalStyleOpened: null,
      isResponsive: '',
      minSizeResponsive: 992,
      modalIntroStyle: { display: 'table' },
      modalCreditosStyle: { display: 'none' },
      showCreditosCloseButton: { position: 'absolute', top: '20px', right: '20px' },
    };
    this.next = React.createRef();
    this.modal = null;
    this.modalTweenIn = null;
    this.modal_inner_box = null;
    this.modalInnerTweenIn = null;
    this.modalVideoMil24 = null;
    this.modalVideoMil24TweenIn = null;
    this.modal_text = null;
    this.modalTextTweenIn = null;
    this.modal_intro = null;
    this.modalIntroTween = null;
    this.modalCreditos = null;
    this.modalCreditosTween = null;
    this.modal_video = React.createRef();
    this.modal_video_tween = null;
    this.modal_video_mil24 = React.createRef();
    this.modalVideoTween = null;
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  }

  componentDidMount(){
    window.addEventListener('resize', this.onWindowResize, true);
    this.onWindowResize();
    this.fadeOutModalIntro();
  }

  onWindowResize = () => {
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // console.log('------------');
    // console.log(this.w);
    // console.log('------------');

    if (this.w <= 992) {
      if (isMobile || isTablet) {
        this.setState({
          isResponsive: 'fp-auto-height fp-section',
          minSizeResponsive: this.getMinSizeResponsive()
        });
        window.fullpage_api.reBuild();
      }
    } else {
      this.setState({
        isResponsive: '',
        minSizeResponsive: this.getMinSizeResponsive()
      });
      window.fullpage_api.reBuild();
    }
    
    if (this.state.modalIsOpened) {
      this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      var posX = (this.w / 2) - (this.w * .7) / 2;
      var posY = (this.h / 2) - (this.h * .7) / 2;
      // Calcular responsive modal ---------------------------------------------------------------------------------------------------------------------------->
      
      var description_separator_w = '40%';
      var modal_inner_box_w = '70%';
      var modal_inner_box_h = '70%';

      // if (this.w <= 768 && this.w > 576) {
      //   description_separator_w = '0';
      //   modal_inner_box_w = '80%';
      //   modal_inner_box_h = '60%'
      //   posX = (this.w / 2) - (this.w * .8) / 2;
      //   posY = (this.h / 2) - (this.h * .6) / 2;
      // } else if (this.w <= 576) {
      //   description_separator_w = '0';
      //   modal_inner_box_w = '90%';
      //   modal_inner_box_h = '60%'
      //   posX = (this.w / 2) - (this.w * .9) / 2;
      //   posY = (this.h / 2) - (this.h * .6) / 2;
      // }

      this.modalInnerTweenIn = TweenMax.to(this.modal_inner_box, {height: modal_inner_box_w, width: modal_inner_box_h, left: posX, top: posY, ease: Quart.easeInOut});  
    }
  }

  onLeave(origin, destination, direction) {
    // console.log('onLeave', { origin, destination, direction });
    this.setState({
      currentSection: destination.anchor
    });
    if (destination.anchor == "manifiesto" || destination.anchor == "soluciones") {
      this.next.current.changeColor('next-section-white', 'rotate(0deg)');
    } else if (destination.anchor == "contacto") {
      this.next.current.changeColor('next-section-black', 'rotate(-180deg)');
    } else {
      this.next.current.changeColor('next-section-black', 'rotate(0deg)');
    }
  }

  openModalBox = (modalInitialPosition, modalData) => {
    this.setState({
      modalIsOpened: true,
      modalInitialPosition: modalInitialPosition,
      showCloseButton: { position: 'absolute', top: '40px', right: '40px', display: 'block' },
      modalData: modalData,
    });
    
    // Calcular responsive modal ---------------------------------------------------------------------------------------------------------------------------->
    this.w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    var posX = (this.w / 2) - (this.w * .7) / 2;
    var posY = (this.h / 2) - (this.h * .7) / 2;

    var description_separator_w = '40%';
    var modal_inner_box_w = '70%';
    var modal_inner_box_h = '70%';

    // if (this.w <= 768 && this.w > 576) {
    //   description_separator_w = '0';
    //   modal_inner_box_w = '80%';
    //   modal_inner_box_h = '60%'
    //   posX = (this.w / 2) - (this.w * .8) / 2;
    //   posY = (this.h / 2) - (this.h * .6) / 2;
    // } else if (this.w <= 576) {
    //   description_separator_w = '0';
    //   modal_inner_box_w = '90%';
    //   modal_inner_box_h = '60%'
    //   posX = (this.w / 2) - (this.w * .9) / 2;
    //   posY = (this.h / 2) - (this.h * .6) / 2;
    // }

    this.modalTweenIn = TweenMax.to(this.modal, .4, {top: 0, left: 0, width: '100%', height: '100%', ease: Quart.easeInOut, onComplete: () => {
      this.modal_video.current.currentTime = 0;
      this.modal_video.current.play(0);
    }});
    this.modalInnerTweenIn = TweenMax.to(this.modal_inner_box, .4, {height: modal_inner_box_h, width: modal_inner_box_w, left: posX, top: posY, ease: Quart.easeInOut});
    this.modalImageTweenIn = TweenMax.to(this.modal_text, .4, {delay: .2, opacity: 1});
    this.modalVideoTween = TweenMax.to(this.modal_video_tween, .4, {delay: .2, opacity: 1});
  }

  closeModal = (event) => {
    event.preventDefault();
    this.modal_video.current.pause();
    this.setState({
      showCloseButton: { position: 'absolute', top: '40px', right: '40px', display: 'none' }
    });
    this.modalTweenIn = TweenMax.to(this.modal, .4, {
      top: this.state.modalInitialPosition.y + 'px',
      left: this.state.modalInitialPosition.x + 'px',
      width: this.state.modalInitialPosition.width + 'px',
      height: this.state.modalInitialPosition.height + 'px', 
      ease: Quart.easeInOut, onComplete: () => {
        this.setState({
          modalIsOpened: false,
        });
      }});
    this.modalInnerTweenIn = TweenMax.to(this.modal_inner_box, .4, {height: '350px', width: '100%', top: 0, left: 0, ease: Quart.easeInOut});
    this.modalImageTweenIn = TweenMax.to(this.modal_text, .2, {opacity: 0});
    this.modalVideoTween = TweenMax.to(this.modal_video_tween, .2, {opacity: 0});
  }

  clickNextSection = () => {
    if (this.state.currentSection == 'contacto') {
      window.fullpage_api.moveTo(1);
    } else {
      window.fullpage_api.moveSectionDown();
    }
  }

  getMinSizeResponsive = () => {
    if (isMobile || isTablet) {
      return 992;
    } else {
      return 0;
    }
  }

  openModalCreditos = () => {
    this.setState({
      modalCreditosStyle: { display: 'table', opacity: '0' }
    });
    this.modalCreditosTween = TweenMax.to(this.modalCreditos, .4, {opacity: 1, ease: Quart.easeInOut});
  }

  closeModalCreditos = (event) => {
    event.preventDefault();
    this.setState({
      modalCreditosStyle: { display: 'none', opacity: '0' }
    });
  }

  fadeOutModalIntro = () => {
    this.modalIntroTween = TweenMax.to(this.modal_intro, 1, {delay: 3, opacity: 0, ease: Quart.easeInOut, onComplete: () => {
      this.setState({
        modalIntroStyle: { display: 'none' }
      });
    }});
  }

  openModalVideoBox = () => {
    this.setState({
      modalVideoMil24IsOpened: true,
    });
    this.modalVideoMil24TweenIn = TweenMax.to(this.modalVideoMil24, .6, {delay: 0, opacity: 1, width: '100%', height: '100%', top: '0px', left: '0px', ease: Quart.easeInOut, onComplete: () => {
      this.modal_video_mil24.current.currentTime = 0;
      this.modal_video_mil24.current.play(0);
    }});
    // this.modal_video_mil24.current.currentTime = 0;
    // this.modal_video_mil24.current.play(0);
  }

  closeModalVideoMil24 = (event) => {
    event.preventDefault();
    this.modal_video_mil24.current.pause();
    this.setState({
      modalVideoMil24IsOpened: false,
    });
  }

  render() {
    return (
      <div className="App">
        <Menu />
        <MenuMobile />
        <Next ref={this.next} click-next-section={this.clickNextSection} />
        <ReactFullpage
          debug /* Debug logging */

          // Required when using extensions
          pluginWrapper={pluginWrapper}

          // fullpage options
          licenseKey={'YOUR_KEY_HERE'} // Get one from https://alvarotrigo.com/fullPage/pricing/
          navigation={true}
          navigationPosition='left'
          anchors={['acerca', 'manifiesto', 'soluciones', 'equipo', 'proyectos', 'contacto']}
          menu='#menu'
          sectionSelector={SECTION_SEL}
          onLeave={this.onLeave.bind(this)}
          sectionsColor={this.state.sectionsColor}
          // responsiveHeight={900}
          responsiveWidth={this.state.minSizeResponsive}
          // fitToSection={true}
          // scrollOverflow={true}
          // autoScrolling={false}

          render={({ state, fullpageApi }) => (
            <ReactFullpage.Wrapper>
              <Section01 sel={SEL} is-responsive={this.state.isResponsive} fade-out-modal-intro={this.fadeOutModalIntro} toggle-modal-fn={this.openModalVideoBox}></Section01>
              <Section02 sel={SEL} is-responsive={this.state.isResponsive}></Section02>
              <Section03 sel={SEL} is-responsive={this.state.isResponsive}></Section03>
              <Section04 sel={SEL} is-responsive={this.state.isResponsive}></Section04>
              <Section05 sel={SEL} is-responsive={this.state.isResponsive} toggle-modal-fn={this.openModalBox}></Section05>
              <Section06 sel={SEL} is-responsive={this.state.isResponsive} open-modal-creditos={this.openModalCreditos}></Section06>
            </ReactFullpage.Wrapper>
          )}
        />
        
        {/* Modal Proyectos */}

        <div id='modal-box-projects' ref={div => this.modal = div} style={this.state.modalIsOpened ? {
          display: 'block',
          opacity: '1',
          background: '#fff',
          top: this.state.modalInitialPosition.y + 'px',
          left: this.state.modalInitialPosition.x + 'px',
          width: this.state.modalInitialPosition.width + 'px',
          height: this.state.modalInitialPosition.height + 'px',
        } : {
          display: 'none'
        }}>
          <div className='modal-item-project'>
            <div className='modal-item-project-container' ref={div => this.modal_inner_box = div}>
              <div className='modal-item-project-inner-container'>
                
                <img className={
                  this.state.modalData ? 'modal-item-project-logo ' + this.state.modalData['class'] : ''
                } src={
                  this.state.modalData ? 'images/' + Utils.isRetina(this.state.modalData['logo'], this.state.modalData['logo@2x']) : ''
                } />

                <div className='modal-item-project-video' ref={video => this.modal_video_tween = video}>
                  <video ref={this.modal_video} playsInline webkit-playsinline="true" controls className={
                    this.state.modalData ? this.state.modalData['extra-class-video'] : ''
                  } key={
                    this.state.modalData ? '/video/' + this.state.modalData['video'] : ''
                  } poster={
                    this.state.modalData ? '/video/' + this.state.modalData['poster'] : ''
                  }>
                      <source src={
                        this.state.modalData ? '/video/' + this.state.modalData['video'] : ''
                      } type="video/mp4"></source>
                  </video>
                </div>

                <div className='modal-item-project-text' ref={div => this.modal_text = div} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.modalData ? this.state.modalData['text'] : '')}}></div>
                
              </div>
            </div>
          </div>
          <a href='#' onClick={ this.closeModal } style={ this.state.showCloseButton }>
            <img width={40} height={40} src='images/close.svg'></img>
          </a>
        </div>

       {/* Modal Créditos */}

        <div className='modal-creditos' ref={div => this.modalCreditos = div} style={this.state.modalCreditosStyle}>
          <div className='modal-creditos-inner'>
            <div className='modal-creditos-box'>
              
              <p>Unsplash:</p>

              <ul>
                <li>NordWood Themes</li> 
                <li>Christopher Burns</li>
                <li>NeONBRAND</li>
                <li>ZMorph Multitool 3D Printer</li>
                <li>Reinhart Julian</li>
                <li>Sidekix Media</li>
                <li>Yancy Min</li>
                <li>Marcus Spiske</li> 
                <li>Adi Goldstein</li>
                <li>Ilya Pavlov</li>
                <li>Halacious</li>
                <li>Mika Baumeister</li>
              </ul>
                
              <p>Pexels:</p>

              <ul>
                <li>Pixabay</li>
                <li>H. Emre</li>
              </ul>

              <a href='#' onClick={ this.closeModalCreditos } style={ this.state.showCreditosCloseButton }>
                <img width={30} height={30} src='images/close.svg'></img>
              </a>
            </div>
          </div>    
        </div>

        {/* Modal Video Mil24 */}

        <div id="modal-video-mil24" ref={div => this.modalVideoMil24 = div} style={this.state.modalVideoMil24IsOpened ? {
          display: 'block',
          opacity: '0',
          background: '#000',
          top: '-25%',
          left: '-25%',
          width: '150%',
          height: '150%',
        } : {
          display: 'none'
        }}>
          
          <video ref={this.modal_video_mil24} playsInline webkit-playsinline="true" controls poster="video/mil24-poster.jpg" width="100%" height="100%">
            <source src="video/videomilveinticuatro.mp4" type="video/mp4"></source>
          </video>

          <a href='#' onClick={ this.closeModalVideoMil24 } style={{ position: 'absolute', top: '40px', right: '40px' }}>
            <img width={40} height={40} src='images/closeBlanco.svg'></img>
          </a>

        </div>

        {/* Modal Intro */}

        <div className='modal-intro' style={this.state.modalIntroStyle} ref={div => this.modal_intro = div}>
          <div className='modal-intro-img'>
            <img src='./images/mil24.svg'></img>
          </div>
        </div>

      </div>
    );
  }
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);